import { SELECT_REQUEST, SELECT_SUCCESS, SELECT_FAILURE } from '../types/rate-types'

const selectRequest = (data) => {
  return {
    payload: {
      data,
    },
    type: SELECT_REQUEST,
  }
}

const selectSuccess = (data) => {
  return {
    payload: {
      data,
    },
    type: SELECT_SUCCESS,
  }
}

const selectFailure = (exception) => {
  return {
    payload: {
      exception,
    },
    type: SELECT_FAILURE,
  }
}

export { selectRequest, selectSuccess, selectFailure }
