import React from 'react'
import * as ReactModal from 'react-modal'
import { Modal as ModalApp, Platform, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

const styles = EStyleSheet.create({
  view: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    flex: 1,
    justifyContent: 'flex-start',
    paddingBottom: '20rem',
    paddingLeft: '20rem',
    paddingRight: '20rem',
    paddingTop: '70rem',
  },
})

class Modal extends React.Component {
  render = () => {
    if (Platform.OS === 'web') {
      if (this.props.reactModal) {
        return (
          <ReactModal
            ariaHideApp={false}
            isOpen={this.props.isVisible}
            style={{
              content: {
                height: '90%',
                left: '20px',
                padding: '2px',
                right: '20px',
              },
              overlay: {
                backgroundColor: 'rgba(0,0,0,0.5)',
              },
            }}
          >
            <View>{this.props.children}</View>
          </ReactModal>
        )
      }
      return (
        <ModalApp ariaHideApp={false} onRequestClose={() => {}} transparent visible={this.props.isVisible}>
          <View style={styles.view}>{this.props.children}</View>
        </ModalApp>
      )
    }
    return (
      <ModalApp ariaHideApp={false} onRequestClose={() => {}} transparent visible={this.props.isVisible}>
        <View style={styles.view}>{this.props.children}</View>
      </ModalApp>
    )
  }
}

export { Modal }
