import _ from 'lodash'
import React from 'react'
import { ActivityIndicator, Image, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'

import { selectRequest } from '../actions/schedule-delays'
import { Button } from '../components/button'
import { Modal } from '../components/modal'
import { Notice } from '../components/notice'
import { Right } from '../components/right'
import { COLORS } from '../constants/colors'
import { buildError, buildIcon, buildNavigationOptions } from '../modules/builders'

class ScheduleDelaysList extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const right = (
      <View
        style={{
          paddingRight: 20,
        }}
      >
        <Right
          icon={buildIcon('solid', COLORS.grayDark, 'plus-square', 30)}
          onPress={() => {
            navigation.navigate('ScheduleDelaysAddForm', {
              id: navigation.getParam('id', ''),
            })
          }}
        />
      </View>
    )
    const target = {
      headerRight: () => right,
      headerTitle: 'Schedule Delays',
    }
    return buildNavigationOptions(target)
  }

  state = {
    item: null,
    isVisible: false,
  }

  componentDidMount = () => {
    const data = {
      project: {
        id: this.props.navigation.getParam('id', ''),
      },
    }
    this.props.dispatch(selectRequest(data))
  }

  render = () => {
    if (this.props.scheduleDelays.loading) {
      return this.renderLoading()
    }
    if (this.props.scheduleDelays.data !== null) {
      return this.renderData()
    }
    if (this.props.scheduleDelays.exception !== null) {
      return this.renderException()
    }
    return null
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderData = () => {
    if (!this.props.projects.data) {
      return null
    }
    const project = _.find(this.props.projects.data.data, this.predicate)
    if (!project) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Project</Notice>
        </ScrollView>
      )
    }
    const styles = EStyleSheet.create({
      scrollView: {
        backgroundColor: COLORS.grayUltraLight,
        flexGrow: 1,
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View
          style={{
            paddingBottom: 20,
          }}
        >
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              padding: 20,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                paddingBottom: 5,
              }}
            >
              <View style={{ paddingRight: 20 }}>
                <Text
                  style={{
                    color: COLORS.grayDark,
                  }}
                >
                  PROJECT
                </Text>
              </View>
              <View style={{}}>
                <Text
                  style={{
                    color: COLORS.redLight,
                  }}
                >
                  {project.number}
                </Text>
              </View>
            </View>
            <View style={{}}>
              <Text
                style={{
                  color: COLORS.grayDark,
                }}
              >
                {project.name}
              </Text>
            </View>
          </View>
          <View
            style={{
              padding: 20,
              paddingBottom: 0,
            }}
          >
            <View
              style={{
                paddingBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Total Hours Delayed
              </Text>
            </View>
            <View style={{}}>
              <Text
                style={{
                  color: COLORS.redLight,
                  fontSize: 20,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {project.total_delays}
              </Text>
            </View>
          </View>
        </View>
        {/* */}
        {this.renderItems()}
        {/* */}
        <Modal isVisible={this.state.isVisible}>
          <View
            style={{
              flex: 1,
              backgroundColor: COLORS.white,
              borderRadius: 5,
            }}
          >
            <ScrollView
              style={{
                flex: 1,
              }}
            >
              <View
                style={{
                  paddingBottom: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 20,
                }}
              >
                <View
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    Date and Time
                  </Text>
                </View>
                <View style={{}}>
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {this.state.item ? this.state.item.date : 'N/A'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  paddingBottom: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      lineHeight: 20,
                      marginRight: 10,
                    }}
                  >
                    Number of Hours Delayed
                  </Text>
                </View>
                <View style={{}}>
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {this.state.item ? this.state.item.hours_delayed : 'N/A'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  paddingBottom: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      lineHeight: 20,
                      marginRight: 10,
                    }}
                  >
                    Location
                  </Text>
                </View>
                <View style={{}}>
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {this.state.item ? this.state.item.location : 'N/A'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  paddingBottom: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      lineHeight: 20,
                      marginRight: 10,
                    }}
                  >
                    Issue
                  </Text>
                </View>
                <View style={{}}>
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {this.state.item ? this.state.item.issue : 'N/A'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  paddingBottom: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    marginBottom: 10,
                  }}
                >
                  <Text
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      lineHeight: 20,
                      marginRight: 10,
                    }}
                  >
                    Additional Notes
                  </Text>
                </View>
                <View style={{}}>
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {this.state.item ? this.state.item.notes : 'N/A'}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  paddingLeft: 20,
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <Text
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      lineHeight: 20,
                      marginRight: 10,
                    }}
                  >
                    Photos
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                  }}
                >
                  {this.state.item && this.state.item.photos.length === 0 ? (
                    <View
                      style={{
                        flex: 1,
                        paddingRight: 20,
                        paddingTop: 10,
                      }}
                    >
                      <Notice failure>There are no photos.</Notice>
                    </View>
                  ) : null}
                  {this.state.item &&
                    this.state.item.photos.map((value, key) => {
                      return (
                        <View
                          key={key}
                          style={{
                            paddingTop: 10,
                            paddingRight: 10,
                          }}
                        >
                          <Image
                            resizeMode="cover"
                            source={{
                              uri: value,
                            }}
                            style={{
                              height: 150,
                              width: 150,
                              borderRadius: 5,
                            }}
                          />
                        </View>
                      )
                    })}
                </View>
              </View>
            </ScrollView>
            <View style={{}}>
              <View
                style={{
                  paddingBottom: 20,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 20,
                }}
              >
                <TouchableOpacity onPress={() => this.setState({ isVisible: false })}>
                  <Button secondary>OK</Button>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      </ScrollView>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }

  renderException = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Notice failure>{buildError(this.props.scheduleDelays.exception)}</Notice>
      </ScrollView>
    )
  }

  renderItems = () => {
    const sortBy = _.sortBy(this.props.scheduleDelays.data.data, ['date'])
    const reverse = _.reverse(sortBy)
    return reverse.map((value, key) => this.renderItem(value, key))
  }

  renderItem = (value, key) => {
    return (
      <View
        key={key}
        elevation={2}
        style={{
          backgroundColor: COLORS.white,
          elevation: 2,
          margin: 20,
          marginTop: 0,
          shadowColor: COLORS.black,
          shadowOffset: {
            height: 2,
            width: 2,
          },
          shadowOpacity: 0.1,
          shadowRadius: 2,
        }}
      >
        <View
          style={{
            backgroundColor: COLORS.white,
            borderColor: COLORS.grayLight,
            borderWidth: 1,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.setState({
                item: value,
                isVisible: true,
              })
            }}
          >
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                padding: 10,
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <View style={{ paddingBottom: 10 }}>
                  <Text
                    style={{
                      color: COLORS.grayDark,
                    }}
                  >
                    {value.date}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <View style={{ paddingRight: 10 }}>
                    <Text
                      style={{
                        color: COLORS.grayDark,
                        fontWeight: 'bold',
                      }}
                    >
                      Hours Delayed
                    </Text>
                  </View>
                  <View style={{}}>
                    <Text
                      style={{
                        color: COLORS.redLight,
                      }}
                    >
                      {value.hours_delayed}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                {buildIcon('solid', COLORS.grayDark, 'search-plus', 30)}
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

ScheduleDelaysList = connect(mapStateToProps, mapDispatchToProps)(ScheduleDelaysList)

export { ScheduleDelaysList }
