import React from 'react'
import { Image, KeyboardAvoidingView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { Field, reduxForm, reset, SubmissionError } from 'redux-form'

import { signIn } from '../actions/users'
import { Button } from '../components/button'
import { Input } from '../components/input'
import { Label } from '../components/label'
import { Notice } from '../components/notice'
import { Spinner } from '../components/spinner'
import MetaData from '../components/MetaData'
import { COLORS } from '../constants/colors'
import { buildError } from '../modules/builders'

const initialValues = {
  email: '',
  password: '',
}

const validate = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Invalid Email'
  }
  if (!values.password) {
    errors.password = 'Invalid Password'
  }
  return errors
}

class SignIn extends React.Component {
  render = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        backgroundColor: 'white',
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
      viewLogo: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      viewSignIn: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textSignIn: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '15rem',
        fontWeight: 'bold',
        lineHeight: '20rem',
      },
      viewControl: {
        marginBottom: '20rem',
        marginLeft: '20rem',
        marginRight: '20rem',
      },
      viewForgotPassword: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textForgotPassword: {
        backgroundColor: COLORS.white,
        color: COLORS.orange,
        fontSize: '15rem',
        lineHeight: '20rem',
      },
      viewButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 8,
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Spinner visible={false} />
        <KeyboardAvoidingView
          behavior="padding"
          style={{
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <View style={styles.viewLogo}>
            <Image source={require('../assets/images/logo.png')} />
          </View>
          <View style={styles.viewSignIn}>
            <Text style={styles.textSignIn}>Sign In</Text>
          </View>
          {this.renderError()}
          <View style={styles.viewControl}>
            <Label>Email</Label>
            <Field
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect={false}
              autoFocus={false} // TODO
              component={Input}
              keyboardType="email-address"
              name="email"
              placeholder="Enter your email address"
              returnKeyType="done"
              textContentType="emailAddress"
              type="text"
            />
          </View>
          <View style={styles.viewControl}>
            <Label>Password</Label>
            <Field
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect={false}
              component={Input}
              keyboardType="default"
              name="password"
              placeholder="Enter your password"
              returnKeyType="done"
              secureTextEntry
              textContentType="password"
              type="password"
            />
          </View>

          <View style={styles.viewButton}>
            <TouchableOpacity onPress={this.props.handleSubmit(this.handleSubmit)}>
              <Button primary>Sign In</Button>
            </TouchableOpacity>
          </View>

          <View style={{ marginTop: 8 }}>
            <TouchableOpacity onPress={() => this.props.navigation.navigate('ForgotPassword')}>
              <Text style={{ fontSize: 12, textAlign: 'center', color: 'blue', marginTop: 6 }}>Forgot password?</Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
        <MetaData />
      </ScrollView>
    )
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginBottom: '20rem',
        marginLeft: '20rem',
        marginRight: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  handleSubmit = (values) => {
    const success = (data) => {
      this.props.dispatch(reset('sign-in'))
      this.props.navigation.navigate('Bootstrap')
    }
    const failure = (data) => {
      throw new SubmissionError({
        _error: data.error && data.error === 'You do not have mobile access.' ? data.error : buildError(data),
      })
    }
    return signIn(values, this.props.dispatch).then(success).catch(failure)
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

SignIn = connect(mapStateToProps, mapDispatchToProps)(SignIn)

SignIn = reduxForm({
  destroyOnUnmount: false,
  form: 'sign-in',
  initialValues,
  validate,
})(SignIn)

export { SignIn }
