import _ from 'lodash'
import React from 'react'
import { Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { formValueSelector, reset } from 'redux-form'

import { Button } from '../components/button'
import { EmployeesForm } from '../components/employees-form'
import { Modal } from '../components/modal'
import { COLORS } from '../constants/colors'

const selector = formValueSelector('employees-form')

const styles = EStyleSheet.create({
  view: {
    marginBottom: 10,
  },
  viewClassification: {
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: 1,
    marginBottom: 10,
    paddingBottom: 10,
  },
  textClassification: {
    color: COLORS.grayDark,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 20,
  },
  viewName: {
    marginBottom: 10,
  },
  textName: {
    color: COLORS.grayDark,
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
  },
  viewControls: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  viewControlLeft: {
    flex: 1,
    marginRight: 10,
  },
  viewControlRight: {
    flex: 1,
    marginLeft: 10,
  },
  textControl: {
    color: COLORS.grayDark,
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 10,
  },
})

class EmployeesB extends React.Component {
  render = () => {
    if (!this.props.logs.length) {
      return null
    }
    const sortBy = _.sortBy(this.props.logs, (log) => `${log.classification}-${log.employee.name}`)
    const groupBy = _.groupBy(sortBy, (item) => item.classification)
    const pairs = _.toPairs(groupBy)
    const logs = _.sortBy(pairs, (pair) => pair[0])

    const types = this.props.rateTypes.data.map((type) => ({
      label: type.name,
      key: type.name,
      value: type.slug,
    }))

    const rows = _.map(this.props.logs, (value, key) => {
      const entries = selector(this.props, `entries-${value.classification}-${value.employee.id}`)
        ? selector(this.props, `entries-${value.classification}-${value.employee.id}`)
        : null
      return entries ? entries.every((item) => item.hours && item.rate_slug) : false
    })

    const isDisabled = !rows.every((item) => item !== false)

    return (
      <Modal isVisible={this.props.isVisible} reactModal={Platform.OS === 'web'}>
        <View
          style={{
            flex: 1,
            backgroundColor: COLORS.white,
            borderRadius: 5,
          }}
        >
          <ScrollView
            style={{
              flexGrow: 1,
              padding: 20,
            }}
          >
            {logs.map((value, key) => {
              const isLast = logs.length === key + 1
              return (
                <View key={key} style={styles.view}>
                  <View style={styles.viewClassification}>
                    <Text style={styles.textClassification}>{value[0]}</Text>
                  </View>
                  {value[1].map((v, k) => {
                    const initialValues = {}
                    v.entries.forEach((item) => {
                      initialValues[`entries-${v.classification}-${v.employee.id}-hours`] = item.hours
                      initialValues[`entries-${v.classification}-${v.employee.id}-rate_slug`] = item.rate_slug
                    })
                    return (
                      <View key={k}>
                        <View style={styles.viewName}>
                          <Text style={styles.textName}>{v.employee.name}</Text>
                        </View>
                        <EmployeesForm initialValues={initialValues} item={v} types={types} />
                      </View>
                    )
                  })}
                  {isLast && <View style={{ paddingBottom: 200, paddingTop: 0 }} />}
                </View>
              )
            })}
          </ScrollView>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 10,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.onPressCancel()
                }}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 10,
                paddingRight: 20,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.onPressConfirm(this.getData())
                  this.props.dispatch(reset('employees-b'))
                }}
                disabled={isDisabled}
              >
                <Button primary disabled={isDisabled}>
                  Done
                </Button>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  getData = () => {
    return _.map(this.props.logs, (value, key) => {
      return {
        ...value,
        entries: selector(this.props, `entries-${value.classification}-${value.employee.id}`),
      }
    })
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

EmployeesB = connect(mapStateToProps, mapDispatchToProps)(EmployeesB)

export { EmployeesB }
