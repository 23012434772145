import _ from 'lodash'
import React from 'react'
import { ScrollView, Text, View, ActivityIndicator } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'

import { selectRequest as ticketHoursSelectRequest } from '../actions/tickets-hours'
import { selectRequest as ticketMaterialsSelectRequest } from '../actions/tickets-materials'
import { AddToTicket } from '../components/add-to-ticket'
import { Notice } from '../components/notice'
import { Right } from '../components/right'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'

class TicketsDetailExpanded extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const right = (
      <View
        style={{
          paddingRight: 20,
        }}
      >
        <Right
          icon={buildIcon('solid', COLORS.grayDark, 'plus-square', 30)}
          onPress={() => {
            const onPress = navigation.getParam('onPress', null)
            if (onPress) {
              onPress()
            }
          }}
        />
      </View>
    )
    const target = {
      headerRight: () => right,
      headerTitle: `TICKET #${navigation.state.params.title}`,
    }
    return buildNavigationOptions(target)
  }

  state = {
    isVisibleAddToTicket: false,
  }

  componentDidMount = () => {
    this.props.navigation.setParams({
      onPress: () => {
        this.setState({
          isVisibleAddToTicket: true,
        })
      },
    })
    const data = {
      ticket: {
        id: this.props.navigation.getParam('id', ''),
      },
    }
    this.props.dispatch(ticketHoursSelectRequest(data))
    this.props.dispatch(ticketMaterialsSelectRequest(data))
  }

  render = () => {
    const { tickets, ticketsHours, ticketsMaterials } = this.props
    if (tickets.loading || ticketsHours.loading || ticketsMaterials.loading) {
      return this.renderLoading()
    }
    if (tickets.exception !== null || ticketsHours.exception !== null || ticketsMaterials.exception !== null) {
      return this.renderException()
    }
    const ticket = tickets.data.data.find((ticket) => ticket.id === this.props.navigation.getParam('id'))
    if (!ticket) {
      return this.renderLoading()
    }
    return this.renderItem(ticket)
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderException = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: 'center',
          padding: 20,
        }}
      >
        <Notice failure>Invalid Ticket</Notice>
      </ScrollView>
    )
  }

  renderItem = (ticket) => {
    return (
      <ScrollView
        style={{
          backgroundColor: COLORS.grayUltraLight,
          flex: 1,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: COLORS.white,
            paddingBottom: 10,
            paddingLeft: 20,
            paddingRight: 10,
            paddingTop: 10,
          }}
        >
          <View
            style={{
              marginRight: 10,
            }}
          >
            {buildIcon(
              'solid',
              this.isBookmarked(ticket.id) ? COLORS.orange : COLORS.grayDark,
              'bookmark',
              EStyleSheet.value('20rem'),
            )}
          </View>
          <View
            style={{
              marginRight: 10,
            }}
          >
            <Text
              style={{
                color: COLORS.grayDark,
              }}
            >
              TICKET
            </Text>
          </View>
          <View>
            <Text
              style={{
                color: COLORS.redLight,
              }}
            >
              #{ticket.number}
            </Text>
          </View>
          <View style={{ flex: 1 }}>{this.renderAuthorization(ticket)}</View>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'column',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontWeight: 'bold',
                }}
              >
                Project
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                }}
              >
                {ticket.project.name}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'column',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View>
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontWeight: 'bold',
                }}
              >
                Scope of Work
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                }}
              >
                {ticket.pricing.scope ? ticket.pricing.scope : 'N/A'}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'column',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontWeight: 'bold',
                }}
              >
                Location
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                }}
              >
                {ticket.location_tag ? ticket.location_tag : 'N/A'}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'column',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontWeight: 'bold',
                }}
              >
                Additional Notes
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                }}
              >
                {ticket.pricing.notes ? ticket.pricing.notes : 'N/A'}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontWeight: 'bold',
                }}
              >
                Hours
              </Text>
            </View>
            <View
              elevation={1}
              style={{
                backgroundColor: COLORS.white,
                elevation: 1,
                marginTop: 10,
                shadowColor: COLORS.black,
                shadowOffset: {
                  height: 1,
                  width: 1,
                },
                shadowOpacity: 0.1,
                shadowRadius: 1,
              }}
            >
              <View
                style={{
                  borderColor: COLORS.grayLight,
                  borderWidth: 1,
                }}
              >
                {this.renderHours()}
              </View>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontWeight: 'bold',
                }}
              >
                Materials and Equipment
              </Text>
            </View>
            <View
              elevation={1}
              style={{
                backgroundColor: COLORS.white,
                elevation: 1,
                marginTop: 10,
                shadowColor: COLORS.black,
                shadowOffset: {
                  height: 1,
                  width: 1,
                },
                shadowOpacity: 0.1,
                shadowRadius: 1,
              }}
            >
              <View
                style={{
                  borderColor: COLORS.grayLight,
                  borderWidth: 1,
                }}
              >
                {this.renderMaterialsAndEquipment()}
              </View>
            </View>
          </View>
        </View>
        <AddToTicket
          isVisible={this.state.isVisibleAddToTicket}
          onPressClose={() => this.setState({ isVisibleAddToTicket: false })}
          onPressHours={() => {
            this.setState({ isVisibleAddToTicket: false })
            this.props.navigation.navigate('TicketsHoursAddForm', {
              id: ticket.id,
            })
          }}
          onPressMaterials={() => {
            this.setState({ isVisibleAddToTicket: false })
            this.props.navigation.navigate('TicketsMaterialsAddForm', {
              id: ticket.id,
            })
          }}
          onPressPhotos={() => {
            this.setState({ isVisibleAddToTicket: false })
            this.props.navigation.navigate('TicketsPhotos', {
              id: ticket.id,
            })
          }}
          ticket={ticket}
        />
      </ScrollView>
    )
  }

  renderAuthorization = (ticket) => {
    if (ticket.requires_auth) {
      if (ticket.authorization === null) {
        return <Text style={{ color: COLORS.redDark, textAlign: 'right' }}>Awaiting Authorization</Text>
      }
    }
    const date = ticket.authorization !== null ? ticket.authorization_created_at : ticket.created_at
    return <Text style={{ color: COLORS.greenDark, textAlign: 'right' }}>Authorized on {date}</Text>
  }

  isBookmarked = (id) => {
    return _.filter(this.props.bookmarks.data.data, (item) => item.cor.id === id).length > 0
  }

  renderHours = () => {
    if (this.props.ticketsHours.data && this.props.ticketsHours.data.length) {
      return <ScrollView>{this.renderHoursDates()}</ScrollView>
    }
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Notice>No hours logged for this ticket.</Notice>
      </ScrollView>
    )
  }

  renderHoursDates = () => {
    const groupBy = _.groupBy(this.props.ticketsHours.data, (item) => item.created_at)
    const pairs = _.toPairs(groupBy)
    const sortBy = _.sortBy(pairs, (pair) => pair[0])
    return sortBy.map((pair) => {
      return (
        <View key={pair[0]}>
          <View
            style={{
              backgroundColor: COLORS.grayLight,
              padding: 10,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  flexGrow: 1,
                  paddingRight: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                    paddingRight: 10,
                  }}
                >
                  {pair[0]}
                </Text>
              </View>
            </View>
          </View>
          {this.renderHoursEntries(pair[1])}
        </View>
      )
    })
  }

  renderHoursEntries = (items) => {
    const styles = EStyleSheet.create({
      viewItem: {
        borderBottomColor: COLORS.grayLight,
        borderBottomWidth: '1rem',
        flexDirection: 'row',
        padding: '10rem',
      },
      viewRight: {
        flex: 1,
      },
      viewOne: {
        flexDirection: 'row',
        paddingBottom: '5rem',
      },
      viewName: {
        flexDirection: 'row',
        flexGrow: 1,
      },
      textName: {
        color: COLORS.grayDark,
        paddingRight: 5,
      },
      textClassification: {
        color: COLORS.grayMedium,
        fontSize: '12rem',
      },
      textHours: {
        color: COLORS.grayDark,
        fontSize: '13rem',
        paddingRight: '10rem',
      },
      textHoursTotal: {
        color: COLORS.grayDark,
        fontSize: '15rem',
        fontWeight: 'bold',
        padding: '10rem',
      },
    })
    const hoursTotal = items.reduce((a, b) => a + parseFloat(b.data.hours), 0)
    return (
      <View>
        {_.sortBy(items, (item) => item.data.employee.name).map((item) => {
          return (
            <View key={`${item.id}`} style={styles.viewItem}>
              <View style={styles.viewLeft} />
              <View style={styles.viewRight}>
                <View style={styles.viewOne}>
                  <View style={styles.viewName}>
                    <Text style={styles.textName}>{item.data.employee.name}</Text>
                  </View>
                </View>
                <View style={styles.viewTwo}>
                  <Text style={styles.textHours}>Hours: {parseFloat(item.data.hours).toFixed(2)}</Text>
                </View>
              </View>
            </View>
          )
        })}
        <View>
          <Text style={styles.textHoursTotal}>Total Hours: {parseFloat(hoursTotal).toFixed(2)}</Text>
        </View>
      </View>
    )
  }

  renderMaterialsAndEquipment = () => {
    if (this.props.ticketsMaterials.data && this.props.ticketsMaterials.data.length) {
      const styles = EStyleSheet.create({
        scrollView: {},
      })
      return (
        <ScrollView contentContainerStyle={styles.scrollView}>{this.renderMaterialsAndEquipmentDates()}</ScrollView>
      )
    }
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Notice>No materials and equipment for this ticket.</Notice>
      </ScrollView>
    )
  }

  renderMaterialsAndEquipmentDates = () => {
    const groupBy = _.groupBy(this.props.ticketsMaterials.data, (item) => item.created_at)
    const pairs = _.toPairs(groupBy)
    const sortBy = _.sortBy(pairs, (pair) => pair[0])
    return sortBy.map((pair) => {
      return (
        <View key={pair[0]}>
          <View
            style={{
              backgroundColor: COLORS.grayLight,
              marginBottom: 1,
              padding: 10,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  {pair[0]}
                </Text>
              </View>
            </View>
          </View>
          {this.renderMaterialsAndEquipmentEntries(pair[1])}
        </View>
      )
    })
  }

  renderMaterialsAndEquipmentEntries = (items) => {
    const styles = EStyleSheet.create({
      viewItem: {
        flexDirection: 'row',
        padding: '10rem',
        borderBottomColor: COLORS.grayLight,
        borderBottomWidth: '1rem',
      },
      viewRight: {
        flex: 1,
      },
      viewOne: {
        flexDirection: 'row',
        paddingBottom: '5rem',
      },
      viewName: {
        flex: 1,
      },
      textName: {
        color: COLORS.grayDark,
      },
      textCategory: {
        color: COLORS.grayMedium,
      },
      viewTwo: {
        flexDirection: 'row',
      },
      viewTwoInner: {
        flex: 1,
      },
      textQuantity: {
        color: COLORS.grayDark,
        fontSize: '13rem',
        paddingRight: '10rem',
      },
      textUnit: {
        color: COLORS.grayDark,
        fontSize: '13rem',
        paddingRight: '10rem',
      },
      textSize: {
        color: COLORS.grayDark,
        fontSize: '13rem',
      },
    })
    return (
      <View>
        {_.sortBy(items, (item) => item.data.material.name).map((item) => {
          return (
            <View key={`${item.id}`} style={styles.viewItem}>
              <View style={styles.viewLeft} />
              <View style={styles.viewRight}>
                <View style={styles.viewOne}>
                  <View style={styles.viewName}>
                    <Text style={styles.textName}>{item.data.material.name}</Text>
                  </View>
                </View>
                <View style={styles.viewTwo}>
                  <Text style={styles.textQuantity}>Quantity: {parseFloat(item.data.quantity).toFixed(2)}</Text>
                  <Text style={styles.textUnit}>Unit: {item.data.material.order_unit}</Text>
                  <Text style={styles.textSize}>Size: {parseFloat(item.data.size).toFixed(2)}</Text>
                </View>
              </View>
            </View>
          )
        })}
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsDetailExpanded = connect(mapStateToProps, mapDispatchToProps)(TicketsDetailExpanded)

export { TicketsDetailExpanded }
