import { Image, View } from 'react-native'
import Swiper from 'react-native-web-swiper'

import { buildPhoto } from '../modules/builders'

export const Carousel = ({ data }) => {
  if (!data || !data.length) {
    data = [buildPhoto()]
  }
  return (
    <View
      key={data.join('-')}
      style={{
        flex: 1,
        height: 150,
      }}
    >
      <Swiper
        from={0}
        loop
        controlsProps={{
          dotActiveStyle: {
            backgroundColor: '#fff',
            borderColor: '#000',
          },
          dotProps: {
            badgeStyle: {
              backgroundColor: '#000',
              borderColor: '#fff',
            },
            theme: {
              colors: {
                primary: '#ffffff',
                grey3: '#000000',
              },
            },
          },
          nextTitleStyle: {
            backgroundColor: '#fff',
            borderRadius: 5,
            color: '#000',
            marginBottom: 25,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
          },
          prevTitleStyle: {
            backgroundColor: '#fff',
            borderRadius: 5,
            color: '#000',
            marginBottom: 25,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
          },
        }}
      >
        {data.map((uri) => {
          return (
            <View
              key={uri}
              style={{
                alignItems: 'center',
                flex: 1,
                justifyContent: 'center',
                height: 150,
                width: '100%',
              }}
            >
              <Image
                resizeMode="cover"
                source={{
                  uri,
                }}
                style={{
                  flex: 1,
                  height: 150,
                  width: '100%',
                }}
              />
            </View>
          )
        })}
      </Swiper>
    </View>
  )
}
