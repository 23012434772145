import _ from 'lodash'
import React from 'react'
import { Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm, reset } from 'redux-form'

import { Button } from '../components/button'
import { DateTimePicker } from '../components/date-time-picker'
import { Input } from '../components/input'
import { Modal } from '../components/modal'
import { Select } from '../components/select'
import { COLORS } from '../constants/colors'
import { sanitizeNumber } from '../modules/helpers'

const selector = formValueSelector('materials-c')

const styles = EStyleSheet.create({
  view: {
    marginBottom: 10,
  },
  viewCategory: {
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: 1,
    marginBottom: 10,
    paddingBottom: 10,
  },
  textCategory: {
    color: COLORS.grayDark,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 20,
  },
  viewNameAndNotes: {
    flexDirection: 'row',
  },
  viewName: {
    flex: 1,
    marginBottom: 10,
  },
  textName: {
    color: COLORS.grayDark,
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
  },
  viewControls: {
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 20,
  },
  viewControlLeft: {
    flex: 1,
    marginRight: 10,
  },
  viewControlRight: {
    flex: 1,
    marginLeft: 10,
  },
  textControl: {
    color: COLORS.grayDark,
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 10,
  },
})

class MaterialsC extends React.Component {
  state = {
    notes: {},
  }

  handleSize = (v) => {
    const options = v.data.material.options ? v.data.material.options : ''

    if (!options) {
      return (
        <Field
          component={Input}
          keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
          name={`size-${v.data.category.id}-${v.data.material.id}-${v.id}`}
          normalize={(val) => sanitizeNumber(val)}
        />
      )
    } else {
      if (options.indexOf(',') === -1) {
        if (options.indexOf('!') === -1) {
          return (
            <Field
              component={Input}
              keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
              name={`size-${v.data.category.id}-${v.data.material.id}-${v.id}`}
              normalize={(val) => sanitizeNumber(val)}
            />
          )
        } else {
          return (
            <Field
              component={Input}
              disabled
              keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
              name={`size-${v.data.category.id}-${v.data.material.id}-${v.id}`}
              normalize={(val) => sanitizeNumber(val)}
            />
          )
        }
      } else {
        return (
          <Field
            component={Select}
            gray
            items={options.split(',').map((option) => {
              if (option.indexOf('~') !== -1) {
                return { label: option.substring(1), value: option.substring(1) }
              }
              return { label: option, value: option }
            })}
            name={`size-${v.data.category.id}-${v.data.material.id}-${v.id}`}
          />
        )
      }
    }
  }

  render = () => {
    if (!this.props.logs.length) {
      return null
    }
    const sortBy = _.sortBy(this.props.logs, (log) => `${log.data.category.name}-${log.data.material.name}`)
    const groupBy = _.groupBy(sortBy, (item) => item.data.category.name)
    const pairs = _.toPairs(groupBy)
    const logs = _.sortBy(pairs, (pair) => pair[0])
    return (
      <Modal isVisible={this.props.isVisible}>
        <View
          style={{
            flex: 1,
            backgroundColor: COLORS.white,
            borderRadius: 5,
          }}
        >
          <ScrollView
            style={{
              flexGrow: 1,
              padding: 20,
            }}
          >
            {logs.map((value, key) => {
              return (
                <View key={key} style={styles.view}>
                  <View style={styles.viewCategory}>
                    <Text style={styles.textCategory}>{value[0] !== 'null' ? value[0] : ''}</Text>
                  </View>
                  {value[1].map((v, k) => {
                    return (
                      <View key={k}>
                        <View style={styles.viewNameAndNotes}>
                          <View style={styles.viewName}>
                            <Text style={styles.textName}>{v.data.material.name}</Text>
                          </View>
                        </View>
                        {this.renderNotes(v.data.material.notes)}
                        <Text style={styles.textControl}>Date</Text>
                        <Field
                          component={DateTimePicker}
                          mode="date"
                          name={`date-${v.data.category.id}-${v.data.material.id}-${v.id}`}
                          type="text"
                        />
                        <View style={styles.viewControls}>
                          <View style={styles.viewControlLeft}>
                            <Text style={styles.textControl}>Quantity</Text>
                            <Field
                              component={Input}
                              keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
                              name={`quantity-${v.data.category.id}-${v.data.material.id}-${v.id}`}
                              normalize={(val) => sanitizeNumber(val)}
                            />
                          </View>
                          <View style={styles.viewControlRight}>
                            <Text style={styles.textControl}>Size</Text>
                            {this.handleSize(v)}
                          </View>
                        </View>
                      </View>
                    )
                  })}
                </View>
              )
            })}
            <View style={{ paddingBottom: 500, paddingTop: 500 }} />
          </ScrollView>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 10,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.onPressCancel()
                }}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 10,
                paddingRight: 20,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.onPressConfirm(this.getData())
                  this.props.dispatch(reset('materials-c'))
                }}
              >
                <Button primary>Done</Button>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  renderNotes = (notes) => {
    return (
      <View
        style={{
          paddingBottom: 10,
        }}
      >
        <Text
          style={{
            color: COLORS.grayDark,
            fontSize: 13,
          }}
        >
          {notes ? notes : null}
        </Text>
      </View>
    )
  }

  getData = () => {
    return _.map(this.props.logs, (value, key) => {
      return {
        ...value,
        date: selector(this.props.state, `date-${value.data.category.id}-${value.data.material.id}-${value.id}`),
        data: {
          ...value.data,
          quantity: selector(
            this.props.state,
            `quantity-${value.data.category.id}-${value.data.material.id}-${value.id}`,
          ),
          size: selector(this.props.state, `size-${value.data.category.id}-${value.data.material.id}-${value.id}`),
        },
      }
    })
  }
}

MaterialsC = reduxForm({
  enableReinitialize: true,
  form: 'materials-c',
})(MaterialsC)

const mapStateToProps = (state, ownProps) => {
  const initialValues = {}
  _.forEach(ownProps.logs, (value, key) => {
    initialValues[`quantity-${value.data.category.id}-${value.data.material.id}-${value.id}`] = '' + value.data.quantity
    initialValues[`size-${value.data.category.id}-${value.data.material.id}-${value.id}`] = '' + value.data.size
    initialValues[`date-${value.data.category.id}-${value.data.material.id}-${value.id}`] = value.created_at_small
  })
  return {
    initialValues,
    state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

MaterialsC = connect(mapStateToProps, mapDispatchToProps)(MaterialsC)

export { MaterialsC }
