import {
  SELECT_REQUEST,
  SELECT_SUCCESS,
  SELECT_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
} from '../types/projects'
import { SIGN_OUT } from '../types/users'

const initialState = {
  data: null,
  exception: null,
  loading: false,
}

const projects = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case SELECT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        exception: null,
        loading: false,
      }
    case SELECT_FAILURE:
      return {
        ...state,
        data: null,
        exception: action.payload.exception,
        loading: false,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.map((project) => {
            if (action.payload.data.id === project.id) {
              return action.payload.data
            }
            return project
          }),
        },
        exception: null,
        loading: false,
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        exception: action.payload.exception,
        loading: false,
      }
    case SIGN_OUT:
      return initialState
    default:
      return state
  }
}

export { projects }
