import React from 'react'
import { Text, View } from 'react-native'

import { COLORS } from '../constants/colors'

export const SimpleButton = ({ children, primary }) => {
  return (
    <View
      style={{
        textAlign: 'center',
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 8,
        paddingBottom: 8,
        margin: 6,
        width: 120,
        borderRadius: 4,
        backgroundColor: primary ? COLORS.orange : '#eee',
      }}
    >
      <Text
        style={{
          color: primary ? 'white' : '#333',
          fontSize: 20,
          textAlign: 'center',
        }}
      >
        {children}
      </Text>
    </View>
  )
}
