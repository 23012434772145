import React from 'react'
import { Text } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import { COLORS } from '../constants/colors'

const styles = EStyleSheet.create({
  text: {
    backgroundColor: COLORS.white,
    color: COLORS.grayDark,
    fontSize: '15rem',
    lineHeight: '20rem',
    marginBottom: '10rem',
  },
})

class Label extends React.Component {
  render = () => {
    return <Text style={styles.text}>{this.props.children}</Text>
  }
}

export { Label }
