import * as ImageManipulator from 'expo-image-manipulator'
import { Easing, Platform } from 'react-native'
import { RNS3 } from 'react-native-aws3'
import Constants from 'expo-constants'

const AWS_ACCESS_KEY_ID = Constants.expoConfig.extra.AWS_ACCESS_KEY //process.env.AWS_ACCESS_KEY
const AWS_SECRET_ACCESS_KEY = Constants.expoConfig.extra.AWS_SECRET //process.env.AWS_SECRET
const AWS_REGION = 'us-west-1'

const canShowFieldwireField = (project) => {
  if (!project.has_fieldwire) {
    return false
  }
  if (!project.has_fieldwire_projects) {
    return false
  }
  if (project.fieldwire_projects === null) {
    return false
  }
  if (!project.fieldwire_projects.length) {
    return false
  }
  return true
}

const canShowFieldwireLabel = (project) => {
  if (!project.has_fieldwire) {
    return false
  }
  if (!project.has_fieldwire_projects) {
    return false
  }
  if (project.fieldwire_projects === null) {
    return false
  }
  if (!project.fieldwire_projects.length) {
    return false
  }
  return true
}

const canShowPlanGridField = (project) => {
  if (!project.has_plan_grid) {
    return false
  }
  if (!project.has_plan_grid_projects) {
    return false
  }
  if (project.plan_grid_projects === null) {
    return false
  }
  if (!project.plan_grid_projects.length) {
    return false
  }
  return true
}

const canShowPlanGridLabel = (project) => {
  if (!project.has_plan_grid) {
    return false
  }
  if (!project.has_plan_grid_projects) {
    return false
  }
  if (project.plan_grid_projects === null) {
    return false
  }
  if (!project.plan_grid_projects.length) {
    return false
  }
  return true
}

const findProject = (projects, id) => {
  return projects.data.data.find((project) => project.id === id)
}

const isAuthorizedCOR = (item) => {
  if (!item.requires_auth) {
    return true
  }
  if (item.authorization !== null) {
    return true
  }
  return false
}

const isAuthorizedCORLog = (item) => {
  if (!item.requires_auth) {
    return true
  }
  if (item.authorization !== null) {
    return true
  }
  return false
}

const screenInterpolator = (props) => {
  return {
    opacity: props.position.interpolate({
      inputRange: [props.scene.index - 1, props.scene.index],
      outputRange: [1, 1],
    }),
  }
}

const transitionConfig = () => {
  return {
    screenInterpolator,
    transitionSpec: {
      duration: 500,
      easing: Easing.inOut(Easing.ease),
    },
  }
}

const photoUriToBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const uploadPhoto = async (photo) => {
  let file = ''
  if (Platform.OS === 'web') {
    const contentType = 'image/jpg/png/'
    const extension = photo.assets[0].uri.includes('data:image')
      ? /data:image\/(.*);base64,(.*)/.exec(photo.assets[0].uri)
      : ''

    if (extension && extension[1].match(/(jpg|jpeg|png)$/)) {
      const blob = photoUriToBlob(photo.assets[0].uri.replace(/^data:image\/\w+;base64,/, ''), contentType)
      const blobUrl = URL.createObjectURL(blob)
      file = new File([blob], blobUrl.substr(blobUrl.lastIndexOf('/') + 1) + '.' + extension[1])
    } else {
      alert('You can only upload JPG/PNG file!')
    }
  } else {
    const photoResized = await ImageManipulator.manipulateAsync(
      photo.assets[0].uri,
      [
        {
          resize: {
            width: 600,
            height: 600,
          },
        },
      ],
      {
        format: 'jpeg',
      },
    )
    const name = photoResized.uri.substr(photoResized.uri.lastIndexOf('/') + 1)
    const extension = name.substr(name.lastIndexOf('.') + 1)
    file = {
      name,
      type: `image/${extension}`,
      uri: photoResized.uri,
    }
  }
  const options = {
    accessKey: AWS_ACCESS_KEY_ID,
    bucket: 'datastreet-photos',
    keyPrefix: 'tickets/',
    region: AWS_REGION,
    secretKey: AWS_SECRET_ACCESS_KEY,
    successActionStatus: 201,
  }
  if (file) {
    return await RNS3.put(file, options).then((response) => {
      if (response.status !== 201) {
        throw new Error('Invalid Photo')
      }

      return response.body.postResponse.location
    })
  } else {
    throw new Error('Invalid Photo')
  }
}

const sanitizeNumber = (value) => {
  if (!value) {
    return value
  }
  if (value.includes('.')) {
    if (!isNaN(Number(value))) {
      return value
    }
    return '0.0'
  }
  if (!isNaN(Number(value))) {
    return value
  }
  return '0'
}

export {
  canShowFieldwireField,
  canShowFieldwireLabel,
  canShowPlanGridField,
  canShowPlanGridLabel,
  findProject,
  isAuthorizedCOR,
  isAuthorizedCORLog,
  sanitizeNumber,
  screenInterpolator,
  transitionConfig,
}
