import { put, takeLatest } from 'redux-saga/effects'

import { selectSuccess, selectFailure, updateSuccess, updateFailure } from '../actions/projects'
import { api } from '../api'
import { SELECT_REQUEST, UPDATE_REQUEST } from '../types/projects'

function* select(action) {
  try {
    const response = yield api({
      method: 'GET',
      params: action.payload.data,
      url: '/projects',
    })
    yield put(selectSuccess(response.data))
  } catch (error) {
    yield put(selectFailure(error.response.data))
  }
}

function* update(action) {
  if (!action.payload.data) {
    return
  }
  try {
    const response = yield api({
      data: action.payload.data,
      method: 'PATCH',
      url: `/projects/${action.payload.data.id}`,
    })
    yield put(updateSuccess(response.data))
  } catch (error) {
    yield put(updateFailure(error.response.data))
  }
}

function* projects() {
  yield takeLatest(SELECT_REQUEST, select)
  yield takeLatest(UPDATE_REQUEST, update)
}

export { projects }
