const COLORS = {
  //
  black: '#000000',
  //
  blue: '#2f95dc',
  //
  grayDark: '#303745',
  grayLight: '#dddddd',
  grayUltraLight: '#efefef',
  grayMedium: '#9b9b9b',
  //
  greenDark: '#4fb44f',
  greenLight: '#65be0c',
  //
  orange: '#e88e2e',
  //
  redDark: '#ff0000',
  redLight: '#dd4b39',
  //
  white: '#ffffff',
  //
  yellow: '#eaeb5e',
}

export { COLORS }
