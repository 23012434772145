import _ from 'lodash'
import React from 'react'
import {
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { change, Field, formValueSelector, reduxForm } from 'redux-form'

import { Button } from '../components/button'
import { FieldwireTasks } from '../components/fieldwire-tasks'
import { Input } from '../components/input'
import { Notice } from '../components/notice'
import { PlanGridIssues } from '../components/plan-grid-issues'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'
import { canShowFieldwireField, canShowPlanGridField, findProject } from '../modules/helpers'

const initialValues = {
  description: '',
  location: '',
  scope: '',
  notes: '',
  fieldwire_tasks: [],
  plan_grid_issues: [],
  reference: '',
}

const validate = (values) => {
  const errors = {}
  if (!values.description) {
    errors.description = 'Invalid Description'
  }
  if (!values.scope) {
    errors.scope = 'Invalid Scope'
  }
  return errors
}

const selector = formValueSelector('tickets-add')

class TicketsAddForm extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: navigation.state.params.title ? navigation.state.params.title : '',
    }
    return buildNavigationOptions(target)
  }

  state = {
    isVisibleFieldwireTasks: false,
    isVisiblePlanGridIssues: false,
  }

  componentDidMount = () => {
    const project = _.find(
      this.props.projects.data.data,
      (item) => item.id === this.props.navigation.getParam('id', ''),
    )
    if (project) {
      this.props.navigation.setParams({
        title: `PROJECT ${project.number}`,
      })
      this.props.dispatch(change('tickets-add', 'description', project.descriptions[0]))
    }
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  render = () => {
    if (!this.props.projects.data) {
      return null
    }
    const project = _.find(this.props.projects.data.data, this.predicate)
    if (!project) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Project</Notice>
        </ScrollView>
      )
    }
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === 'android' ? undefined : 'padding'}
        style={{
          flex: 1,
        }}
      >
        <ScrollView
          style={{
            flexGrow: 1,
          }}
        >
          {this.renderError()}
          <View
            style={{
              marginBottom: 10,
              paddingBottom: 20,
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                }}
              >
                Description
              </Text>
            </View>
            {_.chunk(this.props.projects.data.data[0].descriptions, 2).map((description) => {
              return (
                <View
                  key={description}
                  style={{
                    flexDirection: 'row',
                    marginBottom: 10,
                  }}
                >
                  <View
                    style={{
                      backgroundColor:
                        selector(this.props, 'description') === description[0] ? COLORS.orange : COLORS.white,
                      borderColor:
                        selector(this.props, 'description') === description[0] ? COLORS.orange : COLORS.grayMedium,
                      borderRadius: 5,
                      borderWidth: 1,
                      flex: 1,
                      marginRight: 5,
                      paddingBottom: 7.5,
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingTop: 7.5,
                      textAlign: 'center',
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => this.props.dispatch(change('tickets-add', 'description', description[0]))}
                    >
                      <Text
                        style={{
                          color:
                            selector(this.props, 'description') === description[0] ? COLORS.white : COLORS.grayMedium,
                          fontSize: 13,
                          lineHeight: 15,
                          textAlign: 'center',
                        }}
                      >
                        {description[0]}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  {description[1] ? (
                    <View
                      style={{
                        backgroundColor:
                          selector(this.props, 'description') === description[1] ? COLORS.orange : COLORS.white,
                        borderColor:
                          selector(this.props, 'description') === description[1] ? COLORS.orange : COLORS.grayMedium,
                        borderRadius: 5,
                        borderWidth: 1,
                        flex: 1,
                        marginLeft: 5,
                        paddingBottom: 7.5,
                        paddingLeft: 15,
                        paddingRight: 15,
                        paddingTop: 7.5,
                        textAlign: 'center',
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => this.props.dispatch(change('tickets-add', 'description', description[1]))}
                      >
                        <Text
                          style={{
                            color:
                              selector(this.props, 'description') === description[1] ? COLORS.white : COLORS.grayMedium,
                            fontSize: 13,
                            lineHeight: 15,
                            textAlign: 'center',
                          }}
                        >
                          {description[1]}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : null}
                </View>
              )
            })}
          </View>
          <View
            style={{
              marginBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                {this.props.projects.data.data[0].cor_log_labels['labels']['Reference #']}
              </Text>
              <Text
                style={{
                  color: COLORS.grayLight,
                  fontSize: 13,
                  fontWeight: 'bold',
                  lineHeight: 20,
                }}
              >
                Optional
              </Text>
            </View>
            <Field
              autoCorrect={false}
              autoCapitalize="none"
              autoGrow
              component={Input}
              style={{
                backgroundColor: COLORS.white,
                borderColor: COLORS.grayLight,
                borderRadius: 5,
                borderWidth: 1,
                color: COLORS.grayDark,
                fontSize: 15,
                lineHeight: 20,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
              keyboardType="default"
              multiline
              name="reference"
              numberOfLines={1}
              textAlignVertical="top"
              type="text"
            />
          </View>
          <View
            style={{
              marginBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                {this.props.projects.data.data[0].cor_log_labels['labels']['Location']}
              </Text>
              <Text
                style={{
                  color: COLORS.grayLight,
                  fontSize: 13,
                  fontWeight: 'bold',
                  lineHeight: 20,
                }}
              >
                Optional
              </Text>
            </View>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <Field component={Input} keyboardType="default" name="location" type="text" />
              </View>
            </View>
          </View>
          <View
            style={{
              marginBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                Scope of Work
              </Text>
            </View>
            <Field
              autoGrow={false}
              component={Input}
              keyboardType="default"
              multiline
              name="scope"
              numberOfLines={3}
              textAlignVertical="top"
              type="text"
            />
          </View>
          <View
            style={{
              marginBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                Additional Notes
              </Text>
              <Text
                style={{
                  color: COLORS.grayLight,
                  fontSize: 13,
                  fontWeight: 'bold',
                  lineHeight: 20,
                }}
              >
                Optional
              </Text>
            </View>
            <Field
              autoGrow
              component={Input}
              keyboardType="default"
              multiline
              name="notes"
              numberOfLines={1}
              textAlignVertical="top"
              type="text"
            />
          </View>
          {canShowFieldwireField(findProject(this.props.projects, this.props.navigation.getParam('id', ''))) ? (
            <View
              style={{
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Fieldwire Tasks
                </Text>
                <Text
                  style={{
                    color: COLORS.grayLight,
                    fontSize: 13,
                    fontWeight: 'bold',
                    lineHeight: 20,
                  }}
                >
                  Optional
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <Text
                    numberOfLines={1}
                    style={{
                      color: COLORS.grayDark,
                    }}
                  >
                    {selector(this.props, 'fieldwire_tasks') ? selector(this.props, 'fieldwire_tasks').length : 0} Tasks
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ isVisibleFieldwireTasks: true })
                  }}
                >
                  {buildIcon('regular', COLORS.grayDark, 'pen-square', 20)}
                </TouchableOpacity>
              </View>
            </View>
          ) : null}
          {canShowPlanGridField(findProject(this.props.projects, this.props.navigation.getParam('id', ''))) ? (
            <View
              style={{
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  PlanGrid Tasks
                </Text>
                <Text
                  style={{
                    color: COLORS.grayLight,
                    fontSize: 13,
                    fontWeight: 'bold',
                    lineHeight: 20,
                  }}
                >
                  Optional
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <Text
                    numberOfLines={1}
                    style={{
                      color: COLORS.grayDark,
                    }}
                  >
                    {selector(this.props, 'plan_grid_issues') ? selector(this.props, 'plan_grid_issues').length : 0}{' '}
                    Tasks
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    this.setState({ isVisiblePlanGridIssues: true })
                  }}
                >
                  {buildIcon('regular', COLORS.grayDark, 'pen-square', 20)}
                </TouchableOpacity>
              </View>
            </View>
          ) : null}
        </ScrollView>
        <View
          style={{
            padding: 20,
            paddingTop: 20,
          }}
        >
          <TouchableOpacity onPress={this.props.handleSubmit(this.handleSubmit)}>
            <Button primary>Continue</Button>
          </TouchableOpacity>
        </View>
        <FieldwireTasks
          isVisible={this.state.isVisibleFieldwireTasks}
          onPressCancel={() => {
            this.setState({ isVisibleFieldwireTasks: false })
          }}
          onPressConfirm={(fieldwire_tasks) => {
            this.setState({ isVisibleFieldwireTasks: false })
            this.props.dispatch(change('tickets-add', 'fieldwire_tasks', fieldwire_tasks))
          }}
          project={project}
          value={[]}
        />
        <PlanGridIssues
          isVisible={this.state.isVisiblePlanGridIssues}
          onPressCancel={() => {
            this.setState({ isVisiblePlanGridIssues: false })
          }}
          onPressConfirm={(plan_grid_issues) => {
            this.setState({ isVisiblePlanGridIssues: false })
            this.props.dispatch(change('tickets-add', 'plan_grid_issues', plan_grid_issues))
          }}
          project={project}
          value={[]}
        />
      </KeyboardAvoidingView>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginLeft: '20rem',
        marginRight: '20rem',
        marginTop: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  handleSubmit = (values) => {
    this.props.navigation.navigate('TicketsAddPreview', {
      id: this.props.navigation.getParam('id', ''),
    })
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsAddForm = connect(mapStateToProps, mapDispatchToProps)(TicketsAddForm)

TicketsAddForm = reduxForm({
  destroyOnUnmount: false,
  form: 'tickets-add',
  initialValues,
  validate,
})(TicketsAddForm)

export { TicketsAddForm }
