import { Camera } from 'expo-camera'
import * as Sentry from 'sentry-expo'
import Checkbox from 'expo-checkbox'
import * as ImagePicker from 'expo-image-picker'
import React from 'react'
import {
  Button as ReactNativeButton,
  Image,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { withNavigationFocus } from 'react-navigation'
import { connect } from 'react-redux'

import { Button } from '../components/button'
import { Input } from '../components/input'
import { Left } from '../components/left'
import { Right } from '../components/right'
import { Spinner } from '../components/spinner'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'
import { uploadPhoto } from '../modules/helpers'

class DailyReportsFormNotesForm extends React.Component {
  state = {
    report: null,
    visible: false,
  }

  static navigationOptions = ({ navigation }) => {
    const left = (
      <View
        style={{
          paddingLeft: 20,
        }}
      >
        <Left
          icon={buildIcon('regular', COLORS.grayDark, 'arrow-left', 20)}
          onPress={() => {
            const onPressLeft = navigation.getParam('onPressLeft', null)
            if (onPressLeft) {
              onPressLeft()
            }
          }}
        />
      </View>
    )
    const right =
      navigation.getParam('report').status === 'submitted' ? null : (
        <View
          style={{
            paddingRight: 20,
          }}
        >
          <Right
            icon={buildIcon('solid', COLORS.redDark, 'minus-square', 30)}
            onPress={() => {
              const onPressRight = navigation.getParam('onPressRight', null)
              if (onPressRight) {
                onPressRight()
              }
            }}
          />
        </View>
      )
    const target = {
      headerLeft: () => left,
      headerRight: () => right,
      headerTitle: `Note #${navigation.getParam('key') + 1}`,
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.component()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.isFocused !== prevProps.isFocused) {
      this.component()
    }
  }

  component = () => {
    this.project = this.props.navigation.getParam('project')
    this.key = this.props.navigation.getParam('key')
    this.value = this.props.navigation.getParam('value')
    this.buildState()
    this.props.navigation.setParams({
      onPressLeft: () => {
        this.props.navigation.navigate('DailyReportsFormNotesList', {
          project: this.project,
          report: this.state.report,
        })
      },
      onPressRight: () => {
        this.handleMinus()
      },
    })
  }

  render = () => {
    if (!this.state.report) {
      return null
    }
    if (!this.state.report.data.notes.rows[this.key]) {
      return null
    }
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <Spinner visible={this.state.visible} />
        <ScrollView
          style={{
            flexGrow: 1,
            padding: 20,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          {this.renderItem()}
        </ScrollView>
        {this.state.report.status === 'submitted' ? null : (
          <View
            style={{
              flexDirection: 'row',
              padding: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingRight: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsFormNotesList', {
                    project: this.project,
                    report: this.props.navigation.getParam('report'),
                  })
                }}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
              }}
            >
              <TouchableOpacity
                disabled={!this.value.text.value}
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsFormNotesList', {
                    project: this.project,
                    report: this.state.report,
                  })
                }}
              >
                <Button primary disabled={!this.value.text.value}>
                  Continue
                </Button>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </KeyboardAvoidingView>
    )
  }

  renderItem = () => {
    const text = {
      input: {
        onChange: (text) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  notes: {
                    ...prevState.report.data.notes,
                    rows: prevState.report.data.notes.rows.map((v, k) => {
                      if (k === this.key) {
                        v.text.value = text || ''
                      }
                      return v
                    }),
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: this.value.text.value || '',
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    const issue = {
      isChecked: this.value.issue !== undefined ? this.value.issue.value : false,
      onClick: () => {
        this.setState((prevState) => {
          return {
            ...prevState,
            report: {
              ...prevState.report,
              data: {
                ...prevState.report.data,
                notes: {
                  ...prevState.report.data.notes,
                  rows: prevState.report.data.notes.rows.map((v, k) => {
                    if (k === this.key) {
                      v.issue.value = !v.issue.value
                    }
                    return v
                  }),
                },
                isDirty: true,
              },
            },
          }
        })
      },
      value: this.value.issue !== undefined ? this.value.issue.value : false,
    }
    return (
      <View key={this.key}>
        <View
          style={{
            marginTop: 20,
          }}
        >
          {this.state.report.status === 'submitted' ? (
            <Text
              style={{
                color: COLORS.grayMedium,
                fontSize: 15,
                lineHeight: 20,
              }}
            >
              {text.input.value}
            </Text>
          ) : (
            <Input autoGrow multiline numberOfLines={3} textAlignVertical="top" type="textarea" {...text} />
          )}
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingTop: 20,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            {this.state.report.status === 'submitted' ? (
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Is this an issue?{' '}
                {issue.isChecked ? (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {' '}
                    Yes{' '}
                  </Text>
                ) : (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    No
                  </Text>
                )}
              </Text>
            ) : (
              <>
                <Checkbox
                  color={issue.isChecked === true ? COLORS.orange : COLORS.grayDark}
                  onValueChange={issue.onClick}
                  value={issue.isChecked === true}
                />
                <TouchableOpacity
                  onPress={() => {
                    issue.onClick()
                  }}
                >
                  <Text
                    allowFontScaling={false}
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      fontWeight: 'bold',
                      marginLeft: 10,
                    }}
                  >
                    Is this an issue?
                  </Text>
                </TouchableOpacity>
              </>
            )}
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {this.renderPhotos()}
        </View>
        {this.state.report.status === 'submitted' ? null : (
          <View
            style={{
              flexDirection: 'row',
              paddingTop: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                marginRight: 10,
              }}
            >
              <ReactNativeButton
                color="#666666"
                onPress={() => {
                  this.handleCamera()
                }}
                title="Camera"
              />
            </View>
            <View
              style={{
                flex: 1,
                marginLeft: 10,
              }}
            >
              <ReactNativeButton
                color="#666666"
                onPress={() => {
                  this.handleCameraRoll()
                }}
                title="Library"
              />
            </View>
          </View>
        )}
      </View>
    )
  }

  renderPhotos = () => {
    return this.state.report.data.notes.rows[this.key].photos.map((value, key) => {
      return (
        <View
          key={key}
          style={{
            flexDirection: 'column',
            paddingRight: 20,
            paddingTop: 20,
            width: 150,
          }}
        >
          <Image
            resizeMode="cover"
            source={{
              uri: value,
            }}
            style={{
              height: 130,
              width: 130,
              borderRadius: 5,
            }}
          />
          <TouchableOpacity
            onPress={() => {
              this.setState((prevState) => {
                return {
                  ...prevState,
                  report: {
                    ...prevState.report,
                    data: {
                      ...prevState.report.data,
                      notes: {
                        ...prevState.report.data.notes,
                        rows: prevState.report.data.notes.rows.map((v, k) => {
                          if (k === this.key) {
                            v.photos.splice(key, 1)
                          }
                          return v
                        }),
                      },
                      isDirty: true,
                    },
                  },
                }
              })
            }}
            style={{
              marginTop: 10,
            }}
          >
            {this.state.report.status === 'submitted' ? null : (
              <Button delete small>
                Remove
              </Button>
            )}
          </TouchableOpacity>
        </View>
      )
    })
  }

  buildState = () => {
    const report = this.props.navigation.getParam('report')
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...report,
        },
      }
    })
  }

  handleMinus = (key) => {
    const rows = this.state.report.data.notes.rows
    rows.splice(key, 1)
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          report: {
            ...prevState.report,
            data: {
              ...prevState.report.data,
              notes: {
                ...prevState.report.data.notes,
                rows,
              },
            },
          },
        }
      },
      () => {
        this.props.navigation.navigate('DailyReportsForm', {
          project: this.project,
          report: this.state.report,
        })
      },
    )
  }

  handleCamera = async (index) => {
    try {
      const { status } = await Camera.requestCameraPermissionsAsync()

      if (status !== 'granted') {
        alert('You need to grant this app permission to upload photos.')
        return
      }
      const width = 600
      const height = 600
      const photo = await ImagePicker.launchCameraAsync({
        allowsEditing: true,
        aspect: [width, height],
        base64: false,
        exif: false,
        quality: 1,
      })
      if (!photo.canceled) {
        this.setState((prevState) => {
          return {
            ...prevState,
            visible: true,
          }
        })

        try {
          const location = await uploadPhoto(photo)
          this.addPhotoToState(location)
        } catch {
          this.setState((prevState) => {
            return {
              ...prevState,
              visible: false,
            }
          })
        }
      }
    } catch (e) {
      console.log(e)
      Sentry.Native.captureException(e.message)
    }
  }
  addPhotoToState = (url) => {
    this.setState((state) => {
      const notes = state.report.data.notes
      const row = notes.rows[this.key]
      if (!row.photos) {
        row.photos = [url]
      } else {
        row.photos.push(url)
      }
      notes.rows[this.key] = row
      return {
        ...state,
        visible: false,
        report: {
          ...state.report,
          data: {
            ...state.report.data,
            notes,
            isDirty: true,
          },
        },
      }
    })
  }

  handleCameraRoll = async (index) => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    try {
      if (status !== 'granted') {
        alert('You need to grant this app permission to upload photos.')
      } else {
        const width = 600
        const height = 600
        const photo = await ImagePicker.launchImageLibraryAsync({
          allowsEditing: true,
          aspect: [width, height],
          base64: false,
          exif: false,
          quality: 1,
        })
        if (!photo.canceled) {
          this.setState((prevState) => {
            return {
              ...prevState,
              visible: true,
            }
          })
          try {
            const location = await uploadPhoto(photo)
            this.addPhotoToState(location)
          } catch {
            this.setState((prevState) => {
              return {
                ...prevState,
                visible: false,
              }
            })
          }
        }
      }
    } catch (e) {
      console.log(e)
      Sentry.Native.captureException(e.message)
    }
  }
}

const mapStateToProps = (props, ownProps) => {
  return {
    ...props,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

DailyReportsFormNotesForm = connect(mapStateToProps, mapDispatchToProps)(DailyReportsFormNotesForm)

DailyReportsFormNotesForm = withNavigationFocus(DailyReportsFormNotesForm)

export { DailyReportsFormNotesForm }
