import Checkbox from 'expo-checkbox'
import _ from 'lodash'
import React from 'react'
import { KeyboardAvoidingView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm, reset, SubmissionError } from 'redux-form'

import { insert } from '../actions/tickets'
import { Button } from '../components/button'
import { Notice } from '../components/notice'
import { Spinner } from '../components/spinner'
import { COLORS } from '../constants/colors'
import { buildError, buildNavigationOptions } from '../modules/builders'
import { canShowFieldwireField, canShowPlanGridField, findProject } from '../modules/helpers'

const selector = formValueSelector('tickets-add')

class TicketsAddPreview extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: navigation.state.params.title ? navigation.state.params.title : '',
    }
    return buildNavigationOptions(target)
  }

  state = {
    requiresSignature: true,
  }

  componentDidMount = () => {
    const project = _.find(
      this.props.projects.data.data,
      (item) => item.id === this.props.navigation.getParam('id', ''),
    )
    if (project) {
      this.props.navigation.setParams({
        title: `PROJECT ${project.number}`,
      })
    }
  }

  render = () => {
    if (!this.props.projects.data) {
      return null
    }
    const project = _.find(this.props.projects.data.data, this.predicate)
    if (!project) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Project</Notice>
        </ScrollView>
      )
    }
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <Spinner visible={this.props.tickets.loading} />
        <ScrollView
          style={{
            flexGrow: 1,
          }}
        >
          {this.renderError()}
          <View
            style={{
              marginBottom: 10,
              paddingBottom: 20,
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                }}
              >
                Description
              </Text>
            </View>
            <View style={{}}>
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {selector(this.props, 'description')}
              </Text>
            </View>
          </View>
          <View
            style={{
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                {this.props.projects.data.data[0].cor_log_labels['labels']['Reference #']}
              </Text>
            </View>
            <View style={{}}>
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {selector(this.props, 'reference')}
              </Text>
            </View>
          </View>
          <View
            style={{
              marginBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                {this.props.projects.data.data[0].cor_log_labels['labels']['Location']}
              </Text>
            </View>
            <View style={{}}>
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {selector(this.props, 'location')}
              </Text>
            </View>
          </View>
          <View
            style={{
              marginBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                Scope of Work
              </Text>
            </View>
            <View style={{}}>
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {selector(this.props, 'scope')}
              </Text>
            </View>
          </View>
          <View
            style={{
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                Additional Notes
              </Text>
            </View>
            <View style={{}}>
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {selector(this.props, 'notes')}
              </Text>
            </View>
          </View>
          {canShowFieldwireField(findProject(this.props.projects, this.props.navigation.getParam('id', ''))) ? (
            <View
              style={{
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Fieldwire Task
                </Text>
              </View>
              <View style={{}}>
                <Text
                  style={{
                    color: COLORS.grayMedium,
                    fontSize: 15,
                    lineHeight: 20,
                  }}
                >
                  {selector(this.props, 'fieldwire_tasks') ? selector(this.props, 'fieldwire_tasks').length : 0} Tasks
                </Text>
              </View>
            </View>
          ) : null}
          {canShowPlanGridField(findProject(this.props.projects, this.props.navigation.getParam('id', ''))) ? (
            <View
              style={{
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  PlanGrid Task
                </Text>
              </View>
              <View style={{}}>
                <Text
                  style={{
                    color: COLORS.grayMedium,
                    fontSize: 15,
                    lineHeight: 20,
                  }}
                >
                  {selector(this.props, 'plan_grid_issues') ? selector(this.props, 'plan_grid_issues').length : 0} Tasks
                </Text>
              </View>
            </View>
          ) : null}
          <View
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate('TicketsAddForm', {
                  id: this.props.navigation.getParam('id', ''),
                })
              }}
            >
              <Button secondary>Edit</Button>
            </TouchableOpacity>
          </View>
        </ScrollView>
        <View
          style={{
            borderTopColor: COLORS.grayLight,
            borderTopWidth: 1,
            marginTop: 20,
            padding: 20,
          }}
        >
          {this.renderButtons1(project)}
          {this.renderButtons2(project)}
        </View>
      </KeyboardAvoidingView>
    )
  }

  renderButtons1 = (project) => {
    if (!project.t_and_m_authorization_signature_required) {
      return null
    }
    return (
      <View
        style={{
          paddingBottom: 20,
        }}
      >
        {/* */}
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View>
            <Checkbox
              color={this.state.requiresSignature === true ? COLORS.orange : COLORS.grayDark}
              onValueChange={() => {
                this.setState((prevState) => {
                  return {
                    requiresSignature: !prevState.requiresSignature,
                  }
                })
              }}
              value={this.state.requiresSignature === true}
            />
          </View>
          <View
            style={{
              flex: 1,
              paddingLeft: 10,
            }}
          >
            <Text
              style={{
                color: COLORS.grayDark,
                fontSize: 15,
                fontWeight: 'bold',
                lineHeight: 20,
              }}
            >
              Requires Signature
            </Text>
          </View>
        </View>
        {/* */}
      </View>
    )
  }

  renderButtons2 = (project) => {
    if (project.t_and_m_authorization_signature_required && this.state.requiresSignature) {
      return (
        <TouchableOpacity
          onPress={() => {
            this.props.navigation.navigate('TicketsAddAuthorization', {
              id: this.props.navigation.getParam('id', ''),
            })
          }}
        >
          <Button primary>Create and Get Authorization</Button>
        </TouchableOpacity>
      )
    }
    return (
      <TouchableOpacity onPress={this.props.handleSubmit(this.handleSubmit)}>
        <Button primary>Create</Button>
      </TouchableOpacity>
    )
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginLeft: '20rem',
        marginRight: '20rem',
        marginTop: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  handleSubmit = () => {
    const values = {
      project_id: this.props.navigation.getParam('id', ''),
      description: selector(this.props, 'description'),
      location: selector(this.props, 'location'),
      scope: selector(this.props, 'scope'),
      notes: selector(this.props, 'notes'),
      fieldwire_tasks: selector(this.props, 'fieldwire_tasks'),
      plan_grid_issues: selector(this.props, 'plan_grid_issues'),
      requires_auth: false,
      authorization: null,
      reference: selector(this.props, 'reference'),
    }
    const success = (data) => {
      this.props.dispatch(reset('tickets-add'))
      this.props.navigation.navigate('TicketsAddSuccess', {
        id: this.props.navigation.getParam('id', ''),
        data,
      })
    }
    const failure = (data) => {
      throw new SubmissionError({
        _error: buildError(data),
      })
    }
    return insert(values, this.props.dispatch).then(success).catch(failure)
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsAddPreview = connect(mapStateToProps, mapDispatchToProps)(TicketsAddPreview)

TicketsAddPreview = reduxForm({
  destroyOnUnmount: false,
  form: 'tickets-add',
})(TicketsAddPreview)

export { TicketsAddPreview }
