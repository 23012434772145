import { PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_FAILURE, SIGN_IN, SIGN_OUT } from '../types/users'

const initialState = {
  profile: {
    data: null,
    exception: null,
    loading: false,
  },
  signIn: {
    data: null,
    exception: null,
    loading: false,
  },
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_REQUEST:
      return {
        ...state,
        profile: {
          data: null,
          exception: null,
          loading: true,
        },
      }
    case PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          data: action.payload.data,
          exception: null,
          loading: false,
        },
      }
    case PROFILE_FAILURE:
      return {
        ...state,
        profile: {
          data: null,
          exception: action.payload.exception,
          loading: false,
        },
      }
    case SIGN_IN.REQUEST:
      return {
        ...state,
        signIn: {
          data: null,
          exception: null,
          loading: true,
        },
      }
    case SIGN_IN.SUCCESS:
      return {
        ...state,
        signIn: {
          data: action.payload,
          exception: null,
          loading: false,
        },
      }
    case SIGN_IN.FAILURE:
      return {
        ...state,
        signIn: {
          data: null,
          exception: action.payload,
          loading: false,
        },
      }
    case SIGN_OUT:
      return initialState
    default:
      return state
  }
}

export { users }
