import { createFormAction } from 'redux-form-saga'

const SELECT_REQUEST = 'daily-reports/select/request'
const SELECT_SUCCESS = 'daily-reports/select/success'
const SELECT_FAILURE = 'daily-reports/select/failure'

const INSERT = createFormAction('DAILY_REPORTS_INSERT')
const UPDATE = createFormAction('DAILY_REPORTS_UPDATE')

export { SELECT_REQUEST, SELECT_SUCCESS, SELECT_FAILURE, INSERT, UPDATE }
