import { put, takeLatest } from 'redux-saga/effects'

import { selectSuccess, selectFailure } from '../actions/report-templates'
import { api } from '../api'
import { SELECT_REQUEST } from '../types/report-templates'

function* select(action) {
  try {
    const response = yield api({
      method: 'GET',
      params: {},
      url: '/v2/report-template',
    })
    yield put(selectSuccess(response.data))
  } catch (error) {
    yield put(selectFailure(error.response.data))
  }
}

function* reportTemplates() {
  yield takeLatest(SELECT_REQUEST, select)
}

export { reportTemplates }
