import React from 'react'
import { ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import { selectFilters } from '../actions/tickets'
import { profileRequest } from '../actions/users'
import { Checkbox } from '../components/checkbox'
import { Notice } from '../components/notice'
import { Select } from '../components/select'
import { COLORS } from '../constants/colors'
import { buildNavigationOptions } from '../modules/builders'

class TicketsFilters extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerStyle: {
        backgroundColor: COLORS.grayDark,
        borderBottomColor: COLORS.grayDark,
      },
      headerTintColor: COLORS.white,
      headerTitle: 'Filter Search',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.props.dispatch(profileRequest())
  }

  render = () => {
    const descriptions = this.props.users.profile.data ? this.props.users.profile.data.account.descriptions : []
    return (
      <ScrollView
        contentContainerStyle={{
          flex: 1,
        }}
      >
        <View
          style={{
            backgroundColor: COLORS.grayDark,
            flexGrow: 1,
            padding: 20,
          }}
        >
          <View
            style={{
              marginBottom: 20,
            }}
          >
            <Text
              style={{
                backgroundColor: COLORS.grayDark,
                color: COLORS.white,
                fontSize: 15,
                lineHeight: 20,
                marginBottom: 10,
              }}
            >
              Project
            </Text>
            <Field
              component={Select}
              items={
                this.props.projects.data
                  ? this.props.projects.data.data.map((item) => {
                      return { label: `${item.number} ${item.name}`, key: item.number, value: item.number }
                    })
                  : []
              }
              name="project"
              placeholder={{ label: 'Select a project...', key: '', value: '' }}
              white
            />
          </View>
          <View
            style={{
              marginBottom: 20,
            }}
          >
            <Text
              style={{
                backgroundColor: COLORS.grayDark,
                color: COLORS.white,
                fontSize: 15,
                lineHeight: 20,
                marginBottom: 10,
              }}
            >
              Description
            </Text>
            <Field
              component={Select}
              items={descriptions.map((key) => {
                return {
                  label: key,
                  key,
                  value: key,
                }
              })}
              name="description"
              placeholder={{ label: 'Select a description...', key: '', value: '' }}
              white
            />
          </View>
          <View
            style={{
              marginBottom: 20,
            }}
          >
            <Text
              style={{
                backgroundColor: COLORS.grayDark,
                color: COLORS.white,
                fontSize: 15,
                lineHeight: 20,
                marginBottom: 10,
              }}
            >
              Created By
            </Text>
            <Field
              component={Select}
              items={[]}
              name="createdBy"
              placeholder={{ label: 'Select a user...', key: '', value: '' }}
              white
            />
          </View>
          <View
            style={{
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <View style={{}}>
              <Field
                checked={this.props.tickets.filters.awaitingSignature}
                component={Checkbox}
                name="awaitingSignature"
                type="checkbox"
              />
            </View>
            <View
              style={{
                flexGrow: 1,
                paddingLeft: 20,
              }}
            >
              <Text
                style={{
                  color: COLORS.white,
                  fontSize: 15,
                }}
              >
                Awaiting Signature
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            backgroundColor: COLORS.orange,
            flexDirection: 'row',
            padding: 20,
          }}
        >
          <View
            style={{
              flex: 1,
              marginRight: 10,
            }}
          >
            <TouchableOpacity onPress={this.props.handleSubmit(this.handleSubmit)}>
              <View
                style={{
                  backgroundColor: COLORS.white,
                  borderRadius: 5,
                  paddingBottom: 15,
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 15,
                  textAlign: 'center',
                }}
              >
                <Text
                  style={{
                    color: COLORS.orange,
                    fontSize: 15,
                    lineHeight: 15,
                    textAlign: 'center',
                  }}
                >
                  Search
                </Text>
              </View>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flex: 1,
              marginLeft: 10,
            }}
          >
            <TouchableOpacity onPress={() => this.props.navigation.navigate('TicketsList')}>
              <View
                style={{
                  backgroundColor: COLORS.orange,
                  borderRadius: 5,
                  paddingBottom: 15,
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 15,
                  textAlign: 'center',
                }}
              >
                <Text
                  style={{
                    color: COLORS.white,
                    fontSize: 15,
                    lineHeight: 15,
                    textAlign: 'center',
                  }}
                >
                  Cancel
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    )
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    return (
      <View
        style={{
          marginBottom: 20,
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  handleSubmit = (values) => {
    this.props.dispatch(selectFilters('project', values.project))
    this.props.dispatch(selectFilters('description', values.description))
    this.props.dispatch(selectFilters('createdBy', values.createdBy))
    this.props.dispatch(selectFilters('awaitingSignature', values.awaitingSignature))
    this.props.navigation.navigate('TicketsList')
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    initialValues: state.tickets.filters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsFilters = connect(mapStateToProps, mapDispatchToProps)(TicketsFilters)

TicketsFilters = reduxForm({
  destroyOnUnmount: true,
  enableReinitialize: false,
  form: 'tickets-filters',
})(TicketsFilters)

export { TicketsFilters }
