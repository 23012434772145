import Checkbox from 'expo-checkbox'
import React from 'react'
import { KeyboardAvoidingView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { withNavigationFocus } from 'react-navigation'
import { connect } from 'react-redux'

import { Button } from '../components/button'
import { Input } from '../components/input'
import { Left } from '../components/left'
import { Right } from '../components/right'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'

class DailyReportsFormTradeInspections extends React.Component {
  state = {
    report: null,
  }

  static navigationOptions = ({ navigation }) => {
    const left = (
      <View
        style={{
          paddingLeft: 20,
        }}
      >
        <Left
          icon={buildIcon('regular', COLORS.grayDark, 'arrow-left', 20)}
          onPress={() => {
            const onPressLeft = navigation.getParam('onPressLeft', null)
            if (onPressLeft) {
              onPressLeft()
            }
          }}
        />
      </View>
    )
    const right =
      navigation.getParam('report').status === 'submitted' ? null : (
        <View
          style={{
            paddingRight: 20,
          }}
        >
          <Right
            icon={buildIcon('solid', COLORS.grayDark, 'plus-square', 30)}
            onPress={() => {
              const onPressRight = navigation.getParam('onPressRight', null)
              if (onPressRight) {
                onPressRight()
              }
            }}
          />
        </View>
      )
    const target = {
      headerLeft: () => left,
      headerRight: () => right,
      headerTitle: 'Trade Inspections',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.component()
  }

  component = () => {
    this.project = this.props.navigation.getParam('project')
    this.buildState()
    this.props.navigation.setParams({
      onPressLeft: () => {
        this.props.navigation.navigate('DailyReportsForm', {
          project: this.project,
          report: this.state.report,
        })
      },
      onPressRight: () => {
        this.handlePlus()
      },
    })
  }

  render = () => {
    if (!this.state.report) {
      return null
    }
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <ScrollView
          style={{
            flexGrow: 1,
            padding: 20,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          {this.state.report.data.trade_inspections.rows.map(this.renderItem)}
        </ScrollView>
        {this.state.report.status === 'submitted' ? null : (
          <View
            style={{
              flexDirection: 'row',
              padding: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingRight: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.props.navigation.getParam('report'),
                  })
                }}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.state.report,
                  })
                }}
              >
                <Button primary>Continue</Button>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </KeyboardAvoidingView>
    )
  }

  renderItem = (row, key) => {
    const description = {
      input: {
        onChange: (description) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  trade_inspections: {
                    ...prevState.report.data.trade_inspections,
                    rows: prevState.report.data.trade_inspections.rows.map((v, k) => {
                      if (k === key) {
                        v.description.value = description
                      }
                      return v
                    }),
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: row.description.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    const requested = {
      isChecked: !!row.requested.value,
      onClick: () => {
        this.setState((prevState) => {
          return {
            ...prevState,
            report: {
              ...prevState.report,
              data: {
                ...prevState.report.data,
                trade_inspections: {
                  ...prevState.report.data.trade_inspections,
                  rows: prevState.report.data.trade_inspections.rows.map((v, k) => {
                    if (k === key) {
                      v.requested.value = !v.requested.value
                    }
                    return v
                  }),
                },
                isDirty: true,
              },
            },
          }
        })
      },
      value: !!row.requested.value,
    }
    const pass = {
      isChecked: !!row.pass.value,
      onClick: () => {
        this.setState((prevState) => {
          return {
            ...prevState,
            report: {
              ...prevState.report,
              data: {
                ...prevState.report.data,
                trade_inspections: {
                  ...prevState.report.data.trade_inspections,
                  rows: prevState.report.data.trade_inspections.rows.map((v, k) => {
                    if (k === key) {
                      v.pass.value = !v.pass.value
                    }
                    return v
                  }),
                },
                isDirty: true,
              },
            },
          }
        })
      },
      value: !!row.pass.value,
    }
    const fail = {
      isChecked: !!row.fail.value,
      onClick: () => {
        this.setState((prevState) => {
          return {
            ...prevState,
            report: {
              ...prevState.report,
              data: {
                ...prevState.report.data,
                trade_inspections: {
                  ...prevState.report.data.trade_inspections,
                  rows: prevState.report.data.trade_inspections.rows.map((v, k) => {
                    if (k === key) {
                      v.fail.value = !v.fail.value
                    }
                    return v
                  }),
                },
                isDirty: true,
              },
            },
          }
        })
      },
      value: !!row.fail.value,
    }
    return (
      <View
        key={key}
        style={{
          borderTopColor: COLORS.grayLight,
          borderTopWidth: key === 0 ? 0 : 1,
          marginTop: key === 0 ? 0 : 20,
        }}
      >
        <View
          style={{
            marginTop: 20,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Description
              </Text>
            </View>
            {this.state.report.status === 'submitted' ? null : (
              <TouchableOpacity
                onPress={() => {
                  this.handleMinus(key)
                }}
              >
                {buildIcon('regular', COLORS.redDark, 'minus-square', 15)}
              </TouchableOpacity>
            )}
          </View>
          {this.state.report.status === 'submitted' ? (
            <Text
              style={{
                color: COLORS.grayMedium,
                fontSize: 15,
                lineHeight: 20,
              }}
            >
              {description.input.value}
            </Text>
          ) : (
            <Input type="text" {...description} />
          )}
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingTop: 20,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            {this.state.report.status === 'submitted' ? (
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Requested{' '}
                {!description.input.value ? (
                  ''
                ) : requested.isChecked ? (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    Yes
                  </Text>
                ) : (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    No
                  </Text>
                )}
              </Text>
            ) : (
              <>
                <Checkbox
                  color={requested.isChecked === true ? COLORS.orange : COLORS.grayDark}
                  onValueChange={requested.onClick}
                  value={requested.isChecked === true}
                />
                <TouchableOpacity
                  onPress={() => {
                    requested.onClick()
                  }}
                >
                  <Text
                    allowFontScaling={false}
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      fontWeight: 'bold',
                      marginLeft: 10,
                    }}
                  >
                    Requested
                  </Text>
                </TouchableOpacity>
              </>
            )}
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            {this.state.report.status === 'submitted' ? (
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Pass{' '}
                {!description.input.value ? (
                  ''
                ) : pass.isChecked ? (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    Yes
                  </Text>
                ) : (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    No
                  </Text>
                )}
              </Text>
            ) : (
              <>
                <Checkbox
                  color={pass.isChecked === true ? COLORS.orange : COLORS.grayDark}
                  onValueChange={pass.onClick}
                  value={pass.isChecked === true}
                />
                <TouchableOpacity
                  onPress={() => {
                    pass.onClick()
                  }}
                >
                  <Text
                    allowFontScaling={false}
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      fontWeight: 'bold',
                      marginLeft: 10,
                    }}
                  >
                    Pass
                  </Text>
                </TouchableOpacity>
              </>
            )}
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            {this.state.report.status === 'submitted' ? (
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Fail{' '}
                {!description.input.value ? (
                  ''
                ) : fail.isChecked ? (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    Yes
                  </Text>
                ) : (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    No
                  </Text>
                )}
              </Text>
            ) : (
              <>
                <Checkbox
                  color={fail.isChecked === true ? COLORS.orange : COLORS.grayDark}
                  onValueChange={fail.onClick}
                  value={fail.isChecked === true}
                />
                <TouchableOpacity
                  onPress={() => {
                    fail.onClick()
                  }}
                >
                  <Text
                    allowFontScaling={false}
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      fontWeight: 'bold',
                      marginLeft: 10,
                    }}
                  >
                    Fail
                  </Text>
                </TouchableOpacity>
              </>
            )}
          </View>
        </View>
      </View>
    )
  }

  buildState = () => {
    const report = this.props.navigation.getParam('report')
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          report: {
            ...report,
          },
        }
      },
      () => {
        if (this.state.report.data.trade_inspections.rows.length === 0) {
          this.handlePlus()
        }
      },
    )
  }

  handlePlus = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...prevState.report,
          data: {
            ...prevState.report.data,
            trade_inspections: {
              ...prevState.report.data.trade_inspections,
              rows: [
                ...prevState.report.data.trade_inspections.rows,
                {
                  description: {
                    value: '',
                  },
                  requested: {
                    value: false,
                  },
                  pass: {
                    value: false,
                  },
                  fail: {
                    value: false,
                  },
                },
              ],
            },
          },
        },
      }
    })
  }

  handleMinus = (key) => {
    const rows = this.state.report.data.trade_inspections.rows
    rows.splice(key, 1)
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...prevState.report,
          data: {
            ...prevState.report.data,
            trade_inspections: {
              ...prevState.report.data.trade_inspections,
              rows,
            },
          },
        },
      }
    })
  }
}

const mapStateToProps = (props, ownProps) => {
  return {
    ...props,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

DailyReportsFormTradeInspections = connect(mapStateToProps, mapDispatchToProps)(DailyReportsFormTradeInspections)

DailyReportsFormTradeInspections = withNavigationFocus(DailyReportsFormTradeInspections)

export { DailyReportsFormTradeInspections }
