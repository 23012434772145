import { Camera } from 'expo-camera'
import * as ImagePicker from 'expo-image-picker'
import * as Sentry from 'sentry-expo'
import _ from 'lodash'
import React from 'react'
import { Button as ReactNativeButton, Image, Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import openMap from 'react-native-open-maps'
import { connect } from 'react-redux'

import { updateRequest } from '../actions/projects'
import { selectFilters } from '../actions/tickets'
import { AddToProject } from '../components/add-to-project'
import { Left } from '../components/left'
import { Notice } from '../components/notice'
import { Right } from '../components/right'
import { Spinner } from '../components/spinner'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions, buildPhoto } from '../modules/builders'
import { uploadPhoto } from '../modules/helpers'

class ProjectsDetail extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const left = (
      <View
        style={{
          paddingLeft: 20,
        }}
      >
        <Left
          icon={buildIcon('regular', COLORS.grayDark, 'arrow-left', 20)}
          onPress={() => {
            const override = navigation.getParam('override', false)
            if (override !== false) {
              navigation.navigate('ProjectsList')
            } else {
              navigation.goBack()
            }
          }}
        />
      </View>
    )
    const right = (
      <View
        style={{
          paddingRight: 20,
        }}
      >
        <Right
          icon={buildIcon('solid', COLORS.grayDark, 'plus-square', 30)}
          onPress={() => {
            const onPress = navigation.getParam('onPress', null)
            if (onPress) {
              onPress()
            }
          }}
        />
      </View>
    )
    const target = {
      headerLeft: () => left,
      headerRight: () => right,
      headerTitle: 'Project Detail',
    }
    return buildNavigationOptions(target)
  }

  state = {
    isVisibleAddToProject: false,
    isVisiblePhotos: false,
  }

  componentDidMount = () => {
    this.props.navigation.setParams({
      onPress: () => {
        this.setState({ isVisibleAddToProject: true })
      },
    })
  }

  render = () => {
    if (this.props.projects.data === null) {
      return this.renderException()
    }
    const project = _.find(this.props.projects.data.data, this.predicate)
    if (!project) {
      return this.renderException()
    }
    return this.renderItem(project)
  }

  renderException = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: 'center',
          padding: 20,
        }}
      >
        <Notice failure>Invalid Project</Notice>
      </ScrollView>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }

  renderItem = (project) => {
    return (
      <ScrollView
        style={{
          paddingBottom: 0,
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 20,
          backgroundColor: 'white',
        }}
      >
        <Spinner visible={this.props.projects.loading} />
        <View
          style={{
            flexDirection: 'row',
            paddingBottom: 20,
          }}
        >
          <Image
            resizeMode="cover"
            source={{
              uri: project.photo ? project.photo : buildPhoto(),
            }}
            style={{
              height: 100,
              width: 100,
              borderRadius: 5,
            }}
          />
          <View
            style={{
              flex: 1,
              paddingLeft: 20,
              paddingRight: 0,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                paddingBottom: 5,
              }}
            >
              <View
                style={{
                  paddingRight: 20,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                  }}
                >
                  PROJECT
                </Text>
              </View>
              <View style={{}}>
                <Text
                  style={{
                    color: COLORS.redLight,
                  }}
                >
                  {project.number}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexGrow: 1,
              }}
            >
              <Text
                numberOfLines={1}
                style={{
                  color: COLORS.grayDark,
                }}
              >
                {project.name}
              </Text>
            </View>
          </View>
        </View>
        <View
          elevation={2}
          style={{
            backgroundColor: COLORS.white,
            elevation: 2,
            marginBottom: 20,
            shadowColor: COLORS.black,
            shadowOffset: {
              height: 2,
              width: 2,
            },
            shadowOpacity: 0.1,
            shadowRadius: 2,
          }}
        >
          <View
            style={{
              borderColor: COLORS.grayLight,
              borderWidth: 1,
            }}
          >
            <TouchableOpacity onPress={() => this.handlePressMap(project)}>
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  padding: 10,
                }}
              >
                {buildIcon('solid', COLORS.grayDark, 'map-marker-alt', 20)}
                <View
                  style={{
                    paddingLeft: 10,
                  }}
                >
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                    }}
                  >
                    {project.location ? project.location.street : 'N/A'}
                  </Text>
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                    }}
                  >
                    {project.location ? project.location.city : 'N/A'},{' '}
                    {project.location ? project.location.state : 'N/A'}{' '}
                    {project.location ? project.location.zip : 'N/A'}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View
          elevation={2}
          style={{
            backgroundColor: COLORS.white,
            elevation: 2,
            marginBottom: 20,
            shadowColor: COLORS.black,
            shadowOffset: {
              height: 2,
              width: 2,
            },
            shadowOpacity: 0.1,
            shadowRadius: 2,
          }}
        >
          <View
            style={{
              borderColor: COLORS.grayLight,
              borderWidth: 1,
            }}
          >
            <View style={{}}>
              <TouchableOpacity
                onPress={() => {
                  this.props.dispatch(selectFilters('project', project.number))
                  this.props.dispatch(selectFilters('description', ''))
                  this.props.dispatch(selectFilters('createdBy', ''))
                  this.props.dispatch(selectFilters('awaitingSignature', true))
                  this.props.navigation.navigate('TicketsList')
                }}
              >
                <View
                  style={{
                    alignItems: 'center',
                    borderBottomColor: COLORS.grayLight,
                    borderBottomWidth: 1,
                    flexDirection: 'row',
                    padding: 10,
                  }}
                >
                  <View
                    style={{
                      paddingRight: 10,
                    }}
                  >
                    {buildIcon('solid', COLORS.redDark, 'exclamation-triangle', 20)}
                  </View>
                  <View
                    style={{
                      flexGrow: 1,
                      paddingRight: 10,
                    }}
                  >
                    <Text
                      style={{
                        color: COLORS.grayDark,
                      }}
                    >
                      Tickets Needing Signatures
                    </Text>
                  </View>
                  <Text
                    style={{
                      color: COLORS.orange,
                    }}
                  >
                    {project.count_tickets_needing_signatures}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  this.props.dispatch(selectFilters('project', project.number))
                  this.props.dispatch(selectFilters('description', ''))
                  this.props.dispatch(selectFilters('createdBy', ''))
                  this.props.dispatch(selectFilters('awaitingSignature', false))
                  this.props.navigation.navigate('TicketsList')
                }}
              >
                <View
                  style={{
                    alignItems: 'center',
                    borderBottomColor: COLORS.grayLight,
                    borderBottomWidth: 1,
                    flexDirection: 'row',
                    padding: 10,
                  }}
                >
                  <View
                    style={{
                      paddingRight: 10,
                    }}
                  >
                    {buildIcon('solid', COLORS.grayDark, 'tasks', 20)}
                  </View>
                  <View
                    style={{
                      flexGrow: 1,
                      paddingRight: 10,
                    }}
                  >
                    <Text
                      style={{
                        color: COLORS.grayDark,
                      }}
                    >
                      All Tickets
                    </Text>
                  </View>
                  <Text
                    style={{
                      color: COLORS.orange,
                    }}
                  >
                    {project.count_tickets_all}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  this.props.navigation.navigate('ScheduleDelaysList', {
                    id: project.id,
                  })
                }
              >
                <View
                  style={{
                    alignItems: 'center',
                    borderBottomColor: COLORS.grayLight,
                    borderBottomWidth: 1,
                    flexDirection: 'row',
                    padding: 10,
                  }}
                >
                  <View
                    style={{
                      paddingRight: 10,
                    }}
                  >
                    {buildIcon('solid', COLORS.grayDark, 'clock', 20)}
                  </View>
                  <View
                    style={{
                      flexGrow: 1,
                      paddingRight: 10,
                    }}
                  >
                    <Text
                      style={{
                        color: COLORS.grayDark,
                      }}
                    >
                      Schedule Delays
                    </Text>
                  </View>
                  <Text
                    style={{
                      color: COLORS.orange,
                    }}
                  >
                    {project.delays.length}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() =>
                  this.props.navigation.navigate('DailyReportsList', {
                    project,
                  })
                }
              >
                <View
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    padding: 10,
                  }}
                >
                  <View
                    style={{
                      paddingRight: 10,
                    }}
                  >
                    {buildIcon('solid', COLORS.grayDark, 'calendar-check', 20)}
                  </View>
                  <View
                    style={{
                      flexGrow: 1,
                      paddingRight: 10,
                    }}
                  >
                    <Text
                      style={{
                        color: COLORS.grayDark,
                      }}
                    >
                      Daily Reports
                    </Text>
                  </View>
                  <Text
                    style={{
                      color: COLORS.orange,
                    }}
                  >
                    {project.total_daily_report_all}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>

        <View style={{ marginTop: 5 }}>
          <Text
            style={{
              color: '#aaa',
              textAlign: 'left',
            }}
          >
            Change project photo
          </Text>
        </View>
        <View style={{ padding: 20 }}>
          <View
            style={{
              borderColor: COLORS.grayDark,
              borderRadius: 5,
              borderWidth: 1,
              marginBottom: 4,
            }}
          >
            <ReactNativeButton
              color="#666666"
              onPress={() => this.handleCameraRoll(project)}
              title="Choose from Library"
            />
          </View>
          <View
            style={{
              borderColor: COLORS.grayDark,
              borderRadius: 5,
              borderWidth: 1,
              marginBottom: 4,
            }}
          >
            <ReactNativeButton color="#666666" onPress={() => this.handleCamera(project)} title="Take a picture" />
          </View>
        </View>

        <AddToProject
          isVisible={this.state.isVisibleAddToProject}
          onPressClose={() => this.setState({ isVisibleAddToProject: false })}
          onPressTicket={() => {
            this.setState({ isVisibleAddToProject: false })
            this.props.navigation.navigate('TicketsAddForm', {
              id: project.id,
            })
          }}
          onPressScheduleDelay={() => {
            this.setState({ isVisibleAddToProject: false })
            this.props.navigation.navigate('ScheduleDelaysAddForm', {
              id: project.id,
            })
          }}
          onPressDailyReport={() => {
            this.setState({ isVisibleAddToProject: false })
            this.props.navigation.navigate('DailyReportsForm', {
              project,
              report: undefined,
            })
          }}
          project={project}
        />
      </ScrollView>
    )
  }

  handleCamera = async (project) => {
    const { status } = await Camera.requestCameraPermissionsAsync()
    if (status !== 'granted') {
      alert('You need to grant this app permission to upload photos.')
    } else {
      const width = 600
      const height = 600
      const photo = await ImagePicker.launchCameraAsync({
        allowsEditing: false,
        aspect: [width, height],
        base64: false,
        exif: false,
        quality: 1,
      })
      if (!photo.canceled) {
        try {
          const location = await uploadPhoto(photo)
          const data = {
            id: project.id,
            photo: location,
          }
          this.props.dispatch(updateRequest(data))
        } catch (e) {
          console.log(e)
          Sentry.Native.captureException(e.message)
        }
      }
    }
  }

  handleCameraRoll = async (project) => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    if (status !== 'granted') {
      alert('You need to grant this app permission to upload photos.')
    } else {
      const width = 600
      const height = 600
      const photo = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: false,
        aspect: [width, height],
        base64: false,
        exif: false,
        quality: 1,
      })
      if (!photo.canceled) {
        try {
          const location = await uploadPhoto(photo)
          const data = {
            id: project.id,
            photo: location,
          }
          this.props.dispatch(updateRequest(data))
        } catch (e) {
          console.log(e)
          Sentry.Native.captureException(e.message)
        }
      }
    }
  }

  handlePressMap = (project) => {
    if (project.location && project.location.geo) {
      openMap({
        latitude: project.location.geo.lat,
        longitude: project.location.geo.lgn,
        provider: Platform.OS === 'ios' ? 'apple' : 'google',
        query: `${project.location.street}, ${project.location.city}, ${project.location.state} ${project.location.zip}`,
      })
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

ProjectsDetail = connect(mapStateToProps, mapDispatchToProps)(ProjectsDetail)

export { ProjectsDetail }
