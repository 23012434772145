import axios from 'axios'
import config from './config'

import { CONDITIONS_WEATHER } from './constants/conditions'

const api = axios.create({
  crossDomain: true,
  headers: {
    mobile: 1,
  },
  baseURL: config.api.baseUrl,
  timeout: parseInt(config.api.timeout, 10),
  withCredentials: true,
})

export const fetchTicket = (id) => {
  return api({
    method: 'GET',
    url: `/cors/${id}`,
  })
}

export const updateTicket = (id, data) => {
  return api({
    data,
    method: 'PATCH',
    url: `/cors/${id}`,
  })
}

export const sendPasswordResetLink = (data) => {
  return api({
    data,
    method: 'POST',
    url: `/password/email`,
  })
}

export const fetchClassifications = () => {
  return api({
    method: 'GET',
    url: `/classifications`,
  })
}

export const fetchOpenWeatherMap = (zip) => {
  return axios({
    params: {
      APPID: process.env.OPENWEATHER_API_KEY,
      zip,
      units: 'imperial',
    },
    method: 'GET',
    url: 'http://api.openweathermap.org/data/2.5/weather',
  }).then(
    (response) => {
      const dictionary = {
        weather_condition: '',
        weather_temperature_high: Math.trunc(response.data.main.temp_max).toString(),
        weather_temperature_low: Math.trunc(response.data.main.temp_min).toString(),
        ground_condition: '',
      }
      if (response.data.weather.length) {
        if (CONDITIONS_WEATHER.indexOf(response.data.weather[0].main) !== -1) {
          dictionary.weather_condition = response.data.weather[0].main
        }
      }
      return dictionary
    },
    () => {
      return {
        weather_condition: '',
        weather_temperature_high: '',
        weather_temperature_low: '',
        ground_condition: '',
      }
    },
  )
}

export const fetchPlanGridIssues = (project_id, search) => {
  return api({
    data: {
      project_id,
      search,
    },
    method: 'POST',
    url: `/v2/plan-grid/issues`,
  })
}

export const fetchFieldwireTasks = (project_id, search) => {
  return api({
    data: {
      project_id,
      search,
    },
    method: 'POST',
    url: `/v2/fieldwire/tasks`,
  })
}

export { api }
