import _ from 'lodash'
import React from 'react'
import { Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm, reset } from 'redux-form'

import { Button } from '../components/button'
import { DateTimePicker } from '../components/date-time-picker'
import { Input } from '../components/input'
import { Modal } from '../components/modal'
import { Select } from '../components/select'
import { COLORS } from '../constants/colors'
import { sanitizeNumber } from '../modules/helpers'

const selector = formValueSelector('employees-c')

const styles = EStyleSheet.create({
  view: {
    marginBottom: 10,
  },
  viewClassification: {
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: 1,
    marginBottom: 10,
    paddingBottom: 10,
  },
  textClassification: {
    color: COLORS.grayDark,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 20,
  },
  viewName: {
    marginBottom: 10,
  },
  textName: {
    color: COLORS.grayDark,
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
  },
  viewControls: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  viewControlLeft: {
    flex: 1,
    marginRight: 10,
  },
  viewControlCenter: {
    flex: 1,
    marginRight: 10,
  },
  viewControlRight: {
    flex: 1,
    marginLeft: 10,
  },
  textControl: {
    color: COLORS.grayDark,
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 10,
    marginTop: 10,
  },
})

class EmployeesC extends React.Component {
  render = () => {
    if (!this.props.logs.length) {
      return null
    }
    const sortBy = _.sortBy(this.props.logs, (log) => `${log.data.classification}-${log.data.employee.name}`)
    const groupBy = _.groupBy(sortBy, (item) => item.data.classification)
    const pairs = _.toPairs(groupBy)
    const logs = _.sortBy(pairs, (pair) => pair[0])

    const types = this.props.rateTypes.data.map((type) => ({
      label: type.name,
      key: type.name,
      value: type.slug,
    }))

    const isDisabled =
      _.filter(this.props.logs, (value, key) => {
        return (
          selector(this.props.state, `hours-${value.data.classification}-${value.data.employee.id}-${value.id}`) === ''
        )
      }).length !== 0

    return (
      <Modal isVisible={this.props.isVisible}>
        <View
          style={{
            flex: 1,
            backgroundColor: COLORS.white,
            borderRadius: 5,
          }}
        >
          <ScrollView
            style={{
              flexGrow: 1,
              padding: 20,
            }}
          >
            {logs.map((value, key) => {
              return (
                <View key={key} style={styles.view}>
                  <View style={styles.viewClassification}>
                    <Text style={styles.textClassification}>{value[0]}</Text>
                  </View>
                  {value[1].map((v, k) => {
                    return (
                      <View key={k}>
                        <View style={styles.viewName}>
                          <Text style={styles.textName}>{v.data.employee.name}</Text>
                        </View>
                        <View style={styles.viewControlCenter}>
                          <Text style={styles.textControl}>Date</Text>
                        </View>
                        <Field
                          component={DateTimePicker}
                          mode="date"
                          name={`date-${v.data.classification}-${v.data.employee.id}-${v.id}`}
                          type="text"
                        />
                        <View style={styles.viewControls}>
                          <View style={styles.viewControlLeft}>
                            <Text style={styles.textControl}>Hours</Text>
                            <Field
                              component={Input}
                              keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
                              name={`hours-${v.data.classification}-${v.data.employee.id}-${v.id}`}
                              normalize={(val) => sanitizeNumber(val)}
                            />
                          </View>
                          <View style={styles.viewControlRight}>
                            <Text style={styles.textControl}>Rate Type</Text>
                            <Field
                              component={Select}
                              gray
                              items={types}
                              name={`rate_slug-${v.data.classification}-${v.data.employee.id}-${v.id}`}
                              placeholder={{ label: 'Select a type...', key: '', value: '' }}
                            />
                          </View>
                        </View>
                      </View>
                    )
                  })}
                </View>
              )
            })}
            <View style={{ paddingBottom: 500, paddingTop: 500 }} />
          </ScrollView>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 10,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.onPressCancel()
                }}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 10,
                paddingRight: 20,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity
                disabled={isDisabled}
                onPress={() => {
                  this.props.onPressConfirm(this.getData())
                  this.props.dispatch(reset('employees-c'))
                }}
              >
                {isDisabled ? (
                  <Button primary disabled>
                    Done
                  </Button>
                ) : (
                  <Button primary>Done</Button>
                )}
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  getData = () => {
    return _.map(this.props.logs, (value, key) => {
      return {
        ...value,
        date: selector(this.props.state, `date-${value.data.classification}-${value.data.employee.id}-${value.id}`),
        data: {
          ...value.data,
          hours: parseFloat(
            selector(this.props.state, `hours-${value.data.classification}-${value.data.employee.id}-${value.id}`),
          ),
          rate_slug: selector(
            this.props.state,
            `rate_slug-${value.data.classification}-${value.data.employee.id}-${value.id}`,
          ),
        },
      }
    })
  }
}

EmployeesC = reduxForm({
  enableReinitialize: true,
  form: 'employees-c',
})(EmployeesC)

const mapStateToProps = (state, ownProps) => {
  const initialValues = {}
  _.forEach(ownProps.logs, (value, key) => {
    initialValues[`hours-${value.data.classification}-${value.data.employee.id}-${value.id}`] =
      value.data.hours.toString()
    initialValues[`rate_slug-${value.data.classification}-${value.data.employee.id}-${value.id}`] = value.data.rate_slug
    initialValues[`date-${value.data.classification}-${value.data.employee.id}-${value.id}`] = value.created_at_small
  })
  return {
    initialValues,
    state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

EmployeesC = connect(mapStateToProps, mapDispatchToProps)(EmployeesC)

export { EmployeesC }
