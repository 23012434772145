import { format } from 'date-fns'
import _ from 'lodash'
import React from 'react'
import { ActivityIndicator, Linking, Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'

import { profileRequest, signOut } from '../actions/users'
import MetaData from '../components/MetaData'
import { Button } from '../components/button'
import { Notice } from '../components/notice'
import { COLORS } from '../constants/colors'
import { buildError, buildNavigationOptions } from '../modules/builders'
import { persistor } from '../state'

class Account extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: 'Account',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.props.dispatch(profileRequest())
  }

  render = () => {
    if (this.props.users.profile.loading) {
      return this.renderLoading()
    }
    if (this.props.users.profile.data !== null) {
      return this.renderData()
    }
    if (this.props.users.profile.exception !== null) {
      return this.renderException()
    }
    return null
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderData = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        backgroundColor: '#efefef',
        flexGrow: 1,
        justifyContent: 'space-between',
        padding: '20rem',
        display: 'flex',
        alignItems: 'center',
      },
      viewSignInAs: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textSignInAs: {
        color: COLORS.grayDark,
        fontSize: '15rem',
        lineHeight: '20rem',
      },
      viewName: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textName: {
        color: COLORS.orange,
        fontSize: '15rem',
        fontWeight: 'bold',
        lineHeight: '20rem',
      },
      viewEmail: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textEmail: {
        fontSize: '15rem',
        lineHeight: '20rem',
      },
      viewAccount: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textAccount: {
        color: COLORS.grayDark,
        fontSize: '25rem',
        fontWeight: 'bold',
      },
      viewButton: {
        flexDirection: 'row',
        padding: 5,
        justifyContent: 'center',
      },
      accountCard: {
        borderRadius: 4,
        borderColor: '#eeeeee',
        borderWidth: '1rem',
        padding: '10rem',
        width: '75%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    })
    const account = _.find(
      this.props.users.profile.data.accounts,
      (account) => account.id === this.props.users.profile.data.account.id,
    )
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <MetaData />
        <View style={styles.viewAccount}>
          <Text style={styles.textAccount}>{account ? account.name : 'N/A'}</Text>
        </View>
        <View style={{ flex: 1, width: '100%' }}>
          <View style={styles.accountCard}>
            <View style={styles.viewEmail}>
              <Text style={styles.textEmail}>
                {this.props.users.profile.data.name} &lt;{this.props.users.profile.data.email}&gt;
              </Text>
            </View>

            <View style={styles.viewButton}>
              <TouchableOpacity onPress={() => this.handlePress()}>
                <Button primary>Sign Out</Button>
              </TouchableOpacity>
            </View>
            {Platform.OS === 'web' ? (
              <View style={styles.viewButton}>
                <Text onPress={() => Linking.openURL(`/index.html?date=${format(Date.now(), 'MM/dd/yyyy')}`)}>
                  <Button primary>Clear Cache</Button>
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </ScrollView>
    )
  }

  renderException = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Notice failure>{buildError(this.props.users.profile.exception)}</Notice>
      </ScrollView>
    )
  }

  handlePress = () => {
    this.props.dispatch(signOut())
    persistor.purge().then(
      () => this.props.navigation.navigate('Bootstrap'),
      () => this.props.navigation.navigate('Bootstrap'),
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

Account = connect(mapStateToProps, mapDispatchToProps)(Account)

export { Account }
