import {
  SELECT_REQUEST,
  SELECT_SUCCESS,
  SELECT_FAILURE,
  INSERT_REQUEST,
  INSERT_SUCCESS,
  INSERT_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from '../types/bookmarks'

const selectRequest = () => {
  return {
    payload: {},
    type: SELECT_REQUEST,
  }
}

const selectSuccess = (data) => {
  return {
    payload: {
      data,
    },
    type: SELECT_SUCCESS,
  }
}

const selectFailure = (exception) => {
  return {
    payload: {
      exception,
    },
    type: SELECT_FAILURE,
  }
}

const insertRequest = (data) => {
  return {
    payload: {
      data,
    },
    type: INSERT_REQUEST,
  }
}

const insertSuccess = (data) => {
  return {
    payload: {
      data,
    },
    type: INSERT_SUCCESS,
  }
}

const insertFailure = (exception) => {
  return {
    payload: {
      exception,
    },
    type: INSERT_FAILURE,
  }
}

const deleteRequest = (data) => {
  return {
    payload: {
      data,
    },
    type: DELETE_REQUEST,
  }
}

const deleteSuccess = (data) => {
  return {
    payload: {
      data,
    },
    type: DELETE_SUCCESS,
  }
}

const deleteFailure = (exception) => {
  return {
    payload: {
      exception,
    },
    type: DELETE_FAILURE,
  }
}

export {
  selectRequest,
  selectSuccess,
  selectFailure,
  insertRequest,
  insertSuccess,
  insertFailure,
  deleteRequest,
  deleteSuccess,
  deleteFailure,
}
