import _ from 'lodash'
import React from 'react'
import { ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'

import { selectRequest as selectRequestTickets } from '../actions/tickets'
import { selectRequest as selectRequestTicketsMaterials } from '../actions/tickets-materials'
import { Button } from '../components/button'
import { Notice } from '../components/notice'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'

class TicketsMaterialsAddSuccess extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerShown: false,
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.props.dispatch(
      selectRequestTickets({
        status: 'wip',
        type: 'ticket',
      }),
    )
    this.props.dispatch(
      selectRequestTicketsMaterials({
        ticket: {
          id: this.props.navigation.getParam('id', ''),
        },
      }),
    )
    if (this.props.tickets.data) {
      const ticket = _.find(this.props.tickets.data.data, this.predicate)
      if (ticket) {
        this.props.navigation.setParams({
          title: `TICKET ${ticket.number}`,
        })
      }
    }
  }

  render = () => {
    if (!this.props.tickets.data) {
      return null
    }
    const ticket = _.find(this.props.tickets.data.data, this.predicate)
    if (!ticket) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Ticket</Notice>
        </ScrollView>
      )
    }
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
      viewSuccess: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textSuccess: {
        backgroundColor: COLORS.white,
        color: COLORS.greenDark,
        fontSize: '20rem',
        fontWeight: 'bold',
        lineHeight: '20rem',
      },
      viewIcon: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      viewMessage: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textMessage: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '15rem',
        lineHeight: '20rem',
      },
      viewButton: {
        flexDirection: 'row',
        justifyContent: 'center',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.viewIcon}>{buildIcon('solid', COLORS.greenDark, 'check', 30)}</View>
        <View style={styles.viewSuccess}>
          <Text style={styles.textSuccess}>Success</Text>
        </View>
        <View style={styles.viewMessage}>
          <Text style={styles.textMessage}>Your materials and equipment have been logged.</Text>
        </View>
        <View style={styles.viewButton}>
          <TouchableOpacity onPress={this.handlePress}>
            <Button primary>OK</Button>
          </TouchableOpacity>
        </View>
      </ScrollView>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }

  handlePress = () => {
    const ticket = _.find(this.props.tickets.data.data, this.predicate)
    this.props.navigation.navigate('TicketsDetail', {
      id: this.props.navigation.getParam('id', ''),
      override: ticket.project.id,
    })
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsMaterialsAddSuccess = connect(mapStateToProps, mapDispatchToProps)(TicketsMaterialsAddSuccess)

export { TicketsMaterialsAddSuccess }
