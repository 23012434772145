import { SELECT_REQUEST, SELECT_SUCCESS, SELECT_FAILURE } from '../types/rate-types'

const initialState = {
  data: [],
}

const rateTypes = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case SELECT_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        exception: null,
        loading: false,
      }
    case SELECT_FAILURE:
      return {
        ...state,
        data: null,
        exception: action.payload.exception,
        loading: false,
      }

    default:
      return state
  }
}

export { rateTypes }
