import React from 'react'
import { Platform, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { Input } from '../components/input'
import { Select } from '../components/select'
import { COLORS } from '../constants/colors'
import { buildIcon } from '../modules/builders'
import { sanitizeNumber } from '../modules/helpers'

const styles = EStyleSheet.create({
  view: {
    marginBottom: '10rem',
  },
  viewControls: {
    flexDirection: 'row',
    marginBottom: '10rem',
  },
  viewControlsLeft: {
    flex: 1,
    marginRight: '10rem',
  },
  viewControlsMiddle: {
    width: '75rem',
  },
  viewControlsRight: {
    flex: 1,
    marginLeft: '10rem',
    marginRight: '10rem',
  },
  viewControlsPlus: {
    paddingTop: '6rem',
    paddingBottom: '6rem',
  },
  viewControlsMinus: {
    paddingTop: '11rem',
    paddingBottom: '11rem',
  },
  textLabel: {
    color: COLORS.grayDark,
    fontSize: '13rem',
    fontWeight: 'bold',
    lineHeight: '20rem',
    marginBottom: '10rem',
  },
  viewField: {
    marginBottom: '5rem',
  },
  textField: {
    color: COLORS.grayDark,
    fontSize: '13rem',
    fontWeight: 'normal',
    lineHeight: '40rem',
    marginBottom: '10rem',
  },
})

class Component extends React.Component {
  componentDidMount = () => {
    this.props.item.entries.forEach((entry) => {
      this.props.fields.push({
        quantity: entry.quantity,
        size: entry.size ? entry.size : this.findSize(),
      })
    })
  }

  findSize = () => {
    let options = this.props.item.material.options
    let size = ''
    if (options) {
      if (options.indexOf(',') === -1) {
        if (options.indexOf('!') === -1) {
          size = options
        } else {
          size = options.substring(1)
        }
      } else {
        options = options.split(',')
        let pos = 0
        pos = options.find((option) => option.indexOf('~') !== -1)
        if (!pos) {
          size = options[pos]
        } else {
          size = pos.substring(1)
        }
      }
    }
    return size
  }

  handleSize = (value) => {
    const options = this.props.item.material.options
    if (!options) {
      return (
        <Field
          component={Input}
          keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
          name={`${value}.size`}
          normalize={(val) => sanitizeNumber(val)}
        />
      )
    } else {
      if (options.indexOf(',') === -1) {
        if (options.indexOf('!') === -1) {
          return (
            <Field
              component={Input}
              keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
              name={`${value}.size`}
              normalize={(val) => sanitizeNumber(val)}
            />
          )
        } else {
          return (
            <Field
              component={Input}
              disabled
              keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
              name={`${value}.size`}
              normalize={(val) => sanitizeNumber(val)}
              options={options.substring(1)}
            />
          )
        }
      } else {
        const optionsList = options.split(',')
        const index = optionsList.findIndex((option) => option.indexOf('~') !== -1)
        const size = index !== -1 ? optionsList[index].substring(1) : optionsList[0]
        return (
          <Field
            component={Select}
            items={options.split(',').map((option) => {
              if (option.indexOf('~') !== -1) {
                return { label: option.substring(1), value: option.substring(1) }
              }
              return { label: option, value: option }
            })}
            options={size}
            name={`${value}.size`}
            gray
          />
        )
      }
    }
  }

  render = () => {
    return (
      <View>
        <View style={styles.viewControls}>
          <View style={styles.viewControlsLeft}>
            <Text style={styles.textLabel}>Quantity</Text>
          </View>
          <View style={styles.viewControlsMiddle}>
            <Text style={styles.textLabel}>Unit</Text>
          </View>
          <View style={styles.viewControlsRight}>
            <Text style={styles.textLabel}>Size</Text>
          </View>
          <View style={styles.viewControlsPlus}>
            <TouchableOpacity
              onPress={() => {
                this.props.fields.push({
                  quantity: '',
                  size: this.findSize() ? this.findSize() : '',
                })
              }}
            >
              {buildIcon('regular', COLORS.grayDark, 'plus-square', 15)}
            </TouchableOpacity>
          </View>
        </View>
        {this.props.fields.map((value, key) => {
          return (
            <View key={key} style={styles.viewControls}>
              <View style={styles.viewControlsLeft}>
                <Field
                  component={Input}
                  keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
                  name={`${value}.quantity`}
                  normalize={(val) => sanitizeNumber(val)}
                />
              </View>
              <View style={styles.viewControlsMiddle}>
                <Text style={styles.textField}>{this.props.unit}</Text>
              </View>
              <View style={styles.viewControlsRight}>{this.handleSize(value)}</View>
              <View style={styles.viewControlsMinus}>
                <TouchableOpacity
                  onPress={() => {
                    if (key <= this.props.fields.length - 1) {
                      this.props.fields.remove(key)
                    }
                  }}
                >
                  {buildIcon(
                    'regular',
                    this.props.fields.length === 1 ? COLORS.white : COLORS.redDark,
                    'minus-square',
                    15,
                  )}
                </TouchableOpacity>
              </View>
            </View>
          )
        })}
      </View>
    )
  }
}
class MaterialsForm extends React.Component {
  render = () => {
    return (
      <View style={styles.view}>
        <FieldArray
          component={Component}
          item={this.props.item}
          name={`entries-${this.props.item.category.id}-${this.props.item.material.id}`}
          unit={this.props.item.material.order_unit}
        />
      </View>
    )
  }
}

MaterialsForm = reduxForm({
  enableReinitialize: true,
  form: 'materials-form',
})(MaterialsForm)

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: ownProps.initialValues,
    state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

MaterialsForm = connect(mapStateToProps, mapDispatchToProps)(MaterialsForm)

export { MaterialsForm }
