import { createFormAction } from 'redux-form-saga'

const PROFILE_FAILURE = 'users/profile/failure'
const PROFILE_REQUEST = 'users/profile/request'
const PROFILE_SUCCESS = 'users/profile/success'

const SIGN_IN = createFormAction('USERS_SIGN_IN')

const SIGN_OUT = 'users/sign-out'

export { PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_FAILURE, SIGN_IN, SIGN_OUT }
