import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

const styles = EStyleSheet.create({
  view: {},
})

class Right extends React.Component {
  render = () => {
    return (
      <TouchableOpacity onPress={this.props.onPress}>
        <View style={styles.view}>{this.props.icon}</View>
      </TouchableOpacity>
    )
  }
}

export { Right }
