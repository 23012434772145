import React from 'react'
import { ActivityIndicator } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import Overlay from 'react-native-modal-overlay'

import { COLORS } from '../constants/colors'

const styles = EStyleSheet.create({
  childrenWrapperStyle: {
    backgroundColor: COLORS.white,
    borderRadius: '5rem',
    padding: '20rem',
  },
  containerStyle: {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    flex: 1,
    justifyContent: 'center',
  },
})

class Spinner extends React.Component {
  render = () => {
    return (
      <Overlay
        visible={this.props.visible}
        childrenWrapperStyle={styles.childrenWrapperStyle}
        containerStyle={styles.containerStyle}
      >
        <ActivityIndicator color={COLORS.orange} size="large" />
      </Overlay>
    )
  }
}

export { Spinner }
