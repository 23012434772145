import EStyleSheet from 'react-native-extended-stylesheet'
import { createAppContainer, createSwitchNavigator } from 'react-navigation'
import { createStackNavigator, TransitionPresets } from 'react-navigation-stack'
import { createBottomTabNavigator } from 'react-navigation-tabs'

import { COLORS } from './constants/colors'
import { buildIcon } from './modules/builders'
import { Account } from './screens/account'
import { Bookmarks } from './screens/bookmarks'
import { Bootstrap } from './screens/bootstrap'
import { DailyReportsForm } from './screens/daily-reports-form'
import { DailyReportsFormAreasNotReadyForWorkForm } from './screens/daily-reports-form-areas-not-ready-for-work-form'
import { DailyReportsFormAreasNotReadyForWorkList } from './screens/daily-reports-form-areas-not-ready-for-work-list'
import { DailyReportsFormEnvironment } from './screens/daily-reports-form-environment'
import { DailyReportsFormEquipment } from './screens/daily-reports-form-equipment'
import { DailyReportsFormLabor } from './screens/daily-reports-form-labor'
import { DailyReportsFormNotesForm } from './screens/daily-reports-form-notes-form'
import { DailyReportsFormNotesList } from './screens/daily-reports-form-notes-list'
import { DailyReportsFormSubcontractors } from './screens/daily-reports-form-subcontractors'
import { DailyReportsFormSurvey } from './screens/daily-reports-form-survey'
import { DailyReportsFormTradeInspections } from './screens/daily-reports-form-trade-inspections'
import { DailyReportsFormVendorDeliveries } from './screens/daily-reports-form-vendor-deliveries'
import { DailyReportsFormWorkPerformedTodayForm } from './screens/daily-reports-form-work-performed-today-form'
import { DailyReportsFormWorkPerformedTodayList } from './screens/daily-reports-form-work-performed-today-list'
import { DailyReportsList } from './screens/daily-reports-list'
import { DailyReportsSuccess } from './screens/daily-reports-success'
import { ForgotPassword } from './screens/forgot-password'
import { ProjectsDetail } from './screens/projects-detail'
import { ProjectsList } from './screens/projects-list'
import { RequestSignature } from './screens/request-signature'
import { ScheduleDelaysAddForm } from './screens/schedule-delays-add-form'
import { ScheduleDelaysAddPreview } from './screens/schedule-delays-add-preview'
import { ScheduleDelaysAddSuccess } from './screens/schedule-delays-add-success'
import { ScheduleDelaysList } from './screens/schedule-delays-list'
import { SignIn } from './screens/sign-in'
import { TicketsAddAuthorization } from './screens/tickets-add-authorization'
import { TicketsAddForm } from './screens/tickets-add-form'
import { TicketsAddPreview } from './screens/tickets-add-preview'
import { TicketsAddSuccess } from './screens/tickets-add-success'
import { TicketsDetail } from './screens/tickets-detail'
import { TicketsDetailAuthorization } from './screens/tickets-detail-authorization'
import { TicketsDetailExpanded } from './screens/tickets-detail-expanded'
import { TicketsFilters } from './screens/tickets-filters'
import { TicketsHoursAddAuthorization } from './screens/tickets-hours-add-authorization'
import { TicketsHoursAddForm } from './screens/tickets-hours-add-form'
import { TicketsHoursAddSuccess } from './screens/tickets-hours-add-success'
import { TicketsHoursAuthorization } from './screens/tickets-hours-authorization'
import { TicketsHoursList } from './screens/tickets-hours-list'
import { TicketsList } from './screens/tickets-list'
import { TicketsMaterialsAddForm } from './screens/tickets-materials-add-form'
import { TicketsMaterialsAddSuccess } from './screens/tickets-materials-add-success'
import { TicketsMaterialsList } from './screens/tickets-materials-list'
import { TicketsPhotos } from './screens/tickets-photos'

const config = createSwitchNavigator(
  {
    Primary: createStackNavigator(
      {
        Bootstrap,
      },
      {
        defaultNavigationOptions: {
          ...TransitionPresets.SlideFromRightIOS,
          cardStyle: { backgroundColor: '#FFFFFF' },
        },
        initialRouteName: 'Bootstrap',
        headerMode: 'none',
        mode: 'card',
      },
    ),
    Secondary: createBottomTabNavigator(
      {
        Projects: {
          navigationOptions: {
            cardStyle: {
              backgroundColor: COLORS.white,
            },
            headerTitleAlign: 'center',
            tabBarIcon: ({ focused }) => {
              return buildIcon('solid', focused ? COLORS.orange : COLORS.grayDark, 'home', EStyleSheet.value('25rem'))
            },
          },
          screen: createStackNavigator(
            {
              ProjectsList,
              ProjectsDetail,
              DailyReportsList,
              DailyReportsForm,
              DailyReportsFormEnvironment,
              DailyReportsFormNotesList,
              DailyReportsFormNotesForm,
              DailyReportsFormSurvey,
              DailyReportsFormLabor,
              DailyReportsFormEquipment,
              DailyReportsFormTradeInspections,
              DailyReportsFormVendorDeliveries,
              DailyReportsFormSubcontractors,
              DailyReportsFormWorkPerformedTodayList,
              DailyReportsFormWorkPerformedTodayForm,
              DailyReportsFormAreasNotReadyForWorkList,
              DailyReportsFormAreasNotReadyForWorkForm,
              DailyReportsSuccess,
              ScheduleDelaysList,
              ScheduleDelaysAddForm,
              ScheduleDelaysAddPreview,
              ScheduleDelaysAddSuccess,
              RequestSignature,
              TicketsList,
              TicketsFilters,
              TicketsDetail,
              TicketsDetailExpanded,
              TicketsDetailAuthorization,
              TicketsAddAuthorization,
              TicketsAddForm,
              TicketsAddPreview,
              TicketsAddSuccess,
              TicketsHoursAuthorization,
              TicketsHoursList,
              TicketsHoursAddAuthorization,
              TicketsHoursAddForm,
              TicketsHoursAddSuccess,
              TicketsMaterialsList,
              TicketsMaterialsAddForm,
              TicketsMaterialsAddSuccess,
              TicketsPhotos,
            },
            {
              defaultNavigationOptions: {
                ...TransitionPresets.SlideFromRightIOS,
                cardStyle: { backgroundColor: '#FFFFFF' },
              },
              headerMode: 'screen',
              initialRouteName: 'ProjectsList',
              mode: 'card',
            },
          ),
        },
        Tickets: {
          navigationOptions: {
            cardStyle: {
              backgroundColor: COLORS.white,
            },
            headerTitleAlign: 'center',
            tabBarIcon: ({ focused }) => {
              return buildIcon('solid', focused ? COLORS.orange : COLORS.grayDark, 'search', EStyleSheet.value('25rem'))
            },
          },
          screen: createStackNavigator(
            {
              RequestSignature,
              TicketsFilters,
              TicketsList,
              TicketsDetail,
              TicketsDetailExpanded,
              TicketsDetailAuthorization,
              TicketsAddAuthorization,
              TicketsAddForm,
              TicketsAddPreview,
              TicketsAddSuccess,
              TicketsHoursAuthorization,
              TicketsHoursList,
              TicketsHoursAddAuthorization,
              TicketsHoursAddForm,
              TicketsHoursAddSuccess,
              TicketsMaterialsList,
              TicketsMaterialsAddForm,
              TicketsMaterialsAddSuccess,
              TicketsPhotos,
            },
            {
              defaultNavigationOptions: {
                ...TransitionPresets.SlideFromRightIOS,
                cardStyle: { backgroundColor: '#FFFFFF' },
              },
              headerMode: 'screen',
              initialRouteName: 'TicketsFilters',
              mode: 'card',
            },
          ),
        },
        Bookmarks: {
          navigationOptions: {
            cardStyle: {
              backgroundColor: COLORS.white,
            },
            headerTitleAlign: 'center',
            tabBarIcon: ({ focused }) => {
              return buildIcon(
                'solid',
                focused ? COLORS.orange : COLORS.grayDark,
                'bookmark',
                EStyleSheet.value('25rem'),
              )
            },
          },
          screen: createStackNavigator(
            {
              Bookmarks,
              TicketsDetail,
              TicketsAddForm,
            },
            {
              defaultNavigationOptions: {
                ...TransitionPresets.SlideFromRightIOS,
                cardStyle: { backgroundColor: '#FFFFFF' },
              },
              headerMode: 'screen',
              initialRouteName: 'Bookmarks',
              mode: 'card',
            },
          ),
        },
        Account: {
          navigationOptions: {
            cardStyle: {
              backgroundColor: COLORS.white,
            },
            headerTitleAlign: 'center',
            tabBarIcon: ({ focused }) => {
              return buildIcon('solid', focused ? COLORS.orange : COLORS.grayDark, 'user', EStyleSheet.value('25rem'))
            },
          },
          screen: createStackNavigator(
            {
              Account,
            },
            {
              defaultNavigationOptions: {
                ...TransitionPresets.SlideFromRightIOS,
                cardStyle: { backgroundColor: '#FFFFFF' },
              },
              headerMode: 'screen',
              initialRouteName: 'Account',
              mode: 'card',
            },
          ),
        },
      },
      {
        initialRouteName: 'Projects',
        resetOnBlur: true,
        tabBarOptions: {
          activeBackgroundColor: COLORS.white,
          activeTintColor: COLORS.orange,
          inactiveBackgroundColor: COLORS.white,
          inactiveTintColor: COLORS.grayDark,
          showLabel: false,
          style: {
            backgroundColor: COLORS.white,
            borderBottomColor: COLORS.grayLight,
          },
        },
      },
    ),
    Tertiary: createStackNavigator(
      {
        SignIn,
        ForgotPassword,
      },
      {
        defaultNavigationOptions: {
          ...TransitionPresets.SlideFromRightIOS,
          cardStyle: { backgroundColor: '#FFFFFF' },
        },
        initialRouteName: 'SignIn',
        headerMode: 'none',
        mode: 'card',
      },
    ),
  },
  {
    initialRouteName: 'Primary',
  },
)

const Navigator = createAppContainer(config)

export { Navigator }
