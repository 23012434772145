import React from 'react'
import { Text, TouchableOpacity, ScrollView, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'

import { selectRequest as projectsSelectRequest } from '../actions/projects'
import { selectRequest as scheduleDelaysSelectRequest } from '../actions/schedule-delays'
import { Button } from '../components/button'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'

class ScheduleDelaysAddSuccess extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerShown: false,
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.props.dispatch(
      projectsSelectRequest({
        status: 'active',
      }),
    )
    const data = {
      project: {
        id: this.props.navigation.getParam('id', ''),
      },
    }
    this.props.dispatch(scheduleDelaysSelectRequest(data))
  }

  render = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
      viewSuccess: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textSuccess: {
        backgroundColor: COLORS.white,
        color: COLORS.greenDark,
        fontSize: '20rem',
        fontWeight: 'bold',
        lineHeight: '20rem',
      },
      viewIcon: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      viewMessage: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textMessage: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '15rem',
        lineHeight: '20rem',
      },
      viewButton: {
        flexDirection: 'row',
        justifyContent: 'center',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.viewIcon}>{buildIcon('solid', COLORS.greenDark, 'check', 30)}</View>
        <View style={styles.viewSuccess}>
          <Text style={styles.textSuccess}>Success</Text>
        </View>
        <View style={styles.viewMessage}>
          <Text style={styles.textMessage}>Your delay has been logged.</Text>
        </View>
        <View style={styles.viewButton}>
          <TouchableOpacity onPress={this.handlePress}>
            <Button primary>OK</Button>
          </TouchableOpacity>
        </View>
      </ScrollView>
    )
  }

  handlePress = () => {
    this.props.navigation.navigate('ProjectsDetail', {
      id: this.props.navigation.getParam('id', ''),
      override: true,
    })
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

ScheduleDelaysAddSuccess = connect(mapStateToProps, mapDispatchToProps)(ScheduleDelaysAddSuccess)

export { ScheduleDelaysAddSuccess }
