import React from 'react'
import { ActivityIndicator, KeyboardAvoidingView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import { sendPasswordResetLink } from '../api'
import { Button } from '../components/button'
import { Input } from '../components/input'
import { Label } from '../components/label'
import { Notice } from '../components/notice'
import { COLORS } from '../constants/colors'

const initialValues = {
  email: '',
}

const validate = (values) => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Invalid Email'
  }

  return errors
}

class ForgotPassword extends React.Component {
  state = {
    sending: false,
    error: null,
    success: false,
  }

  renderSuccess = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View>
          <Text>Password reset instructions have been sent!</Text>
        </View>
        <View>
          <TouchableOpacity onPress={() => this.props.navigation.navigate('SignIn')}>
            <Button primary>OK</Button>
          </TouchableOpacity>
        </View>
      </ScrollView>
    )
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  render = () => {
    if (this.state.sending) {
      return this.renderLoading()
    }

    if (this.state.success) {
      return this.renderSuccess()
    }

    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
      viewLogo: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      viewForgotPassword: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textForgotPassword: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '15rem',
        fontWeight: 'bold',
        lineHeight: '20rem',
      },
      viewControl: {
        marginBottom: '20rem',
        marginLeft: '20rem',
        marginRight: '20rem',
      },
      viewSignIn: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '20rem',
      },
      textSignIn: {
        backgroundColor: COLORS.white,
        color: 'blue',
        fontSize: '15rem',
        lineHeight: '20rem',
      },
      viewButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 8,
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <KeyboardAvoidingView
          behavior="padding"
          style={{
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <View style={styles.viewForgotPassword}>
            <Text style={styles.textForgotPassword}>Forgot Password</Text>
          </View>
          {this.renderError()}
          <View style={styles.viewControl}>
            <Label>Email</Label>
            <Field
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect={false}
              autoFocus={false} // TODO
              component={Input}
              keyboardType="email-address"
              name="email"
              placeholder="Enter your email address"
              returnKeyType="done"
              textContentType="emailAddress"
              type="text"
            />

            <View>
              <Text style={{ fontSize: 11, color: '#aaa' }}>
                We will send you an email with further instructions to reset your password.
              </Text>
            </View>
          </View>

          <View style={styles.viewButton}>
            <TouchableOpacity onPress={this.props.handleSubmit(this.handleSubmit)}>
              <Button primary>Send</Button>
            </TouchableOpacity>
          </View>
          <View style={styles.viewSignIn}>
            <TouchableOpacity onPress={() => this.props.navigation.navigate('SignIn')}>
              <Text style={styles.textSignIn}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    )
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginBottom: '20rem',
        marginLeft: '20rem',
        marginRight: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  handleSubmit = (data) => {
    this.setState({ sending: true })
    sendPasswordResetLink(data)
      .then((resp) => {
        this.setState({ sending: false, success: true })
      })
      .catch((err) => this.setState({ sending: false, error: err.message }))
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

ForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)

ForgotPassword = reduxForm({
  destroyOnUnmount: false,
  form: 'forgot-password',
  initialValues,
  validate,
})(ForgotPassword)

export { ForgotPassword }
