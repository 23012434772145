import React from 'react'
import { Text, View } from 'react-native'
import createSelector from 'react-native-conditional-stylesheet'
import EStyleSheet from 'react-native-extended-stylesheet'

import { COLORS } from '../constants/colors'

const styles = EStyleSheet.create({
  view: {
    borderRadius: '5rem',
    textAlign: 'center',
  },
  viewPrimary: {
    backgroundColor: COLORS.grayDark,
    borderColor: COLORS.grayDark,
    borderWidth: '0rem',
    paddingBottom: '16rem',
    paddingLeft: '16rem',
    paddingRight: '16rem',
    paddingTop: '16rem',
  },
  viewSecondary: {
    backgroundColor: COLORS.white,
    borderColor: COLORS.grayDark,
    borderWidth: '1rem',
    paddingBottom: '15rem',
    paddingLeft: '30rem',
    paddingRight: '30rem',
    paddingTop: '15rem',
  },
  viewLeft: {
    borderBottomRightRadius: '0rem',
    borderTopRightRadius: '0rem',
  },
  viewRight: {
    borderBottomLeftRadius: '0rem',
    borderTopLeftRadius: '0rem',
  },
  viewEdit: {
    backgroundColor: COLORS.white,
    borderColor: COLORS.grayDark,
    borderWidth: '1rem',
    paddingBottom: '10rem',
    paddingLeft: '30rem',
    paddingRight: '30rem',
    paddingTop: '10rem',
  },
  viewDelete: {
    backgroundColor: COLORS.white,
    borderColor: COLORS.redLight,
    borderWidth: '1rem',
    paddingBottom: '10rem',
    paddingLeft: '30rem',
    paddingRight: '30rem',
    paddingTop: '10rem',
  },
  viewSmall: {
    paddingBottom: '10rem',
    paddingLeft: '30rem',
    paddingRight: '30rem',
    paddingTop: '10rem',
  },
  viewDisabled: {
    backgroundColor: COLORS.grayLight,
    borderColor: COLORS.grayLight,
  },
  text: {
    color: COLORS.white,
    fontSize: '13rem',
    lineHeight: '15rem',
    textAlign: 'center',
  },
  textPrimary: {
    color: COLORS.white,
  },
  textSecondary: {
    color: COLORS.grayDark,
  },
  textEdit: {
    color: COLORS.grayDark,
  },
  textDelete: {
    color: COLORS.redLight,
  },
  textDisabled: {
    color: COLORS.grayMedium,
  },
  textSmall: {},
})

class Button extends React.Component {
  render = () => {
    const selector = createSelector(styles)
    const view = selector('view', {
      viewPrimary: this.props.primary,
      viewSecondary: this.props.secondary,
      viewLeft: this.props.left,
      viewRight: this.props.right,
      viewEdit: this.props.edit,
      viewDelete: this.props.delete,
      viewSmall: this.props.small,
      viewDisabled: this.props.disabled,
    })
    const text = selector('text', {
      textPrimary: this.props.primary,
      textSecondary: this.props.secondary,
      textEdit: this.props.edit,
      textDelete: this.props.delete,
      textSmall: this.props.small,
      textDisabled: this.props.disabled,
    })
    return (
      <View style={view}>
        <Text allowFontScaling={false} style={text} numberOfLines={1}>
          {this.props.children}
        </Text>
      </View>
    )
  }
}

export { Button }
