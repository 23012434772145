const SELECT_REQUEST = 'bookmarks/select/request'
const SELECT_SUCCESS = 'bookmarks/select/success'
const SELECT_FAILURE = 'bookmarks/select/failure'

const INSERT_REQUEST = 'bookmarks/insert/request'
const INSERT_SUCCESS = 'bookmarks/insert/success'
const INSERT_FAILURE = 'bookmarks/insert/failure'

const DELETE_REQUEST = 'bookmarks/delete/request'
const DELETE_SUCCESS = 'bookmarks/delete/success'
const DELETE_FAILURE = 'bookmarks/delete/failure'

export {
  SELECT_REQUEST,
  SELECT_SUCCESS,
  SELECT_FAILURE,
  INSERT_REQUEST,
  INSERT_SUCCESS,
  INSERT_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
}
