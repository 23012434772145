import { Camera } from 'expo-camera'
import * as Sentry from 'sentry-expo'
import * as ImagePicker from 'expo-image-picker'
import _ from 'lodash'
import React from 'react'
import { Button as ReactNativeButton, Image, Platform, ScrollView, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'

import { updateRequest } from '../actions/tickets'
import { Button } from '../components/button'
import { Notice } from '../components/notice'
import { Photos } from '../components/photos'
import { Spinner } from '../components/spinner'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'
import { uploadPhoto } from '../modules/helpers'

class TicketsPhotos extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: navigation.state.params.title ? navigation.state.params.title : '',
    }
    return buildNavigationOptions(target)
  }

  state = {
    photos: [],
    isVisible: false,
    loading: false,
  }

  componentDidMount = () => {
    if (this.props.tickets.data) {
      const ticket = _.find(this.props.tickets.data.data, this.predicate)
      if (ticket) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
          photos: ticket.photos && ticket.photos.length ? ticket.photos : [],
        })
        this.props.navigation.setParams({
          title: `TICKET ${ticket.number}`,
        })
      }
    }
    this.props.navigation.setParams({
      onPress: async () => {
        this.setState({
          isVisible: true,
        })
      },
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.isFocused !== prevProps.isFocused) {
      this.component()
    }
  }

  render = () => {
    if (!this.props.tickets.data) {
      return null
    }
    this.ticket = _.find(this.props.tickets.data.data, this.predicate)
    if (!this.ticket) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Ticket</Notice>
        </ScrollView>
      )
    }
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <Spinner visible={this.state.loading} />
        {/* */}
        {this.renderItems()}
        {/* */}
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              flex: 1,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 10,
              paddingTop: 0,
            }}
          >
            <TouchableOpacity onPress={() => this.handlePressCancel()}>
              <Button secondary>Cancel</Button>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flex: 1,
              paddingBottom: 20,
              paddingLeft: 10,
              paddingRight: 20,
              paddingTop: 0,
            }}
          >
            <TouchableOpacity onPress={() => this.handlePressOK()}>
              <Button primary>OK</Button>
            </TouchableOpacity>
          </View>
        </View>
        <Photos
          isVisible={this.state.isVisible}
          onPressClose={() => this.setState({ isVisible: false })}
          onPressCamera={() => {
            this.setState({ isVisible: false })
            this.handleCamera()
          }}
          onPressCameraRoll={() => {
            this.setState({ isVisible: false })
            this.handleCameraRoll()
          }}
        />
      </View>
    )
  }

  handleDeletePhoto = (key) => {
    return () => {
      this.setState((prevState) => {
        return {
          photos: prevState.photos.filter((_, k) => k !== key),
        }
      })
    }
  }

  renderItems = () => {
    if (!this.state.photos.length) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <Notice>There are no photos in this ticket.</Notice>
            <View
              style={{
                borderColor: COLORS.grayDark,
                borderRadius: 5,
                borderWidth: 1,
                marginBottom: 4,
              }}
            >
              <ReactNativeButton color="#666666" onPress={this.handleCameraRoll} title="Choose from Library" />
            </View>
            <View
              style={{
                borderColor: COLORS.grayDark,
                borderRadius: 5,
                borderWidth: 1,
                marginBottom: 4,
              }}
            >
              <ReactNativeButton color="#666666" onPress={this.handleCamera} title="Take a picture" />
            </View>
          </View>
        </ScrollView>
      )
    }
    return (
      <ScrollView
        style={{
          flexGrow: 1,
        }}
      >
        <View style={{ padding: 20 }}>
          <View
            style={{
              borderColor: COLORS.grayDark,
              borderRadius: 5,
              borderWidth: 1,
              marginBottom: 4,
            }}
          >
            <ReactNativeButton color="#666666" onPress={this.handleCameraRoll} title="Choose from Library" />
          </View>
          <View
            style={{
              borderColor: COLORS.grayDark,
              borderRadius: 5,
              borderWidth: 1,
              marginBottom: 4,
            }}
          >
            <ReactNativeButton color="#666666" onPress={this.handleCamera} title="Take a picture" />
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            paddingTop: 20,
          }}
        >
          {this.state.photos.map((value, key) => {
            return (
              <View
                key={key}
                style={{
                  paddingBottom: 20,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <Image
                  resizeMode="cover"
                  source={{
                    uri: typeof value === 'string' ? value : value.uri,
                  }}
                  style={{
                    height: 150,
                    width: 150,
                    borderRadius: 5,
                  }}
                />
                <View
                  style={{
                    backgroundColor: COLORS.white,
                    borderRadius: 10,
                    position: 'absolute',
                    right: 20,
                    top: 10,
                  }}
                >
                  <TouchableOpacity onPress={this.handleDeletePhoto(key)}>
                    {buildIcon('solid', COLORS.redDark, 'times-circle', 20)}
                  </TouchableOpacity>
                </View>
              </View>
            )
          })}
        </View>
      </ScrollView>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }

  handlePressOK = () => {
    const ticket = _.find(this.props.tickets.data.data, this.predicate)
    this.props.dispatch(updateRequest({ id: ticket.id, photos: this.state.photos }))
    this.props.navigation.navigate('TicketsDetail', {
      id: ticket.id,
      override: ticket.project.id,
    })
  }

  handlePressCancel = () => {
    const ticket = _.find(this.props.tickets.data.data, this.predicate)
    this.props.navigation.navigate('TicketsDetail', {
      id: ticket.id,
      override: ticket.project.id,
    })
  }

  handleCamera = async () => {
    try {
      const { status } = await Camera.requestCameraPermissionsAsync()
      if (status !== 'granted') {
        alert('You need to grant this app permission to upload photos.')
        return
      }
      const width = 600
      const height = 600
      const photo = await ImagePicker.launchCameraAsync({
        allowsEditing: true,
        aspect: [width, height],
        base64: false,
        exif: false,
        quality: 1,
      })
      if (!photo.canceled) {
        this.setState((prevState) => {
          return {
            ...prevState,
            loading: true,
          }
        })

        try {
          const location = await uploadPhoto(photo)
          this.setState((state) => {
            const photos = state.photos.concat(location)
            return {
              ...state,
              photos,
              loading: false,
            }
          })
          this.props.dispatch(updateRequest({ id: this.ticket.id, photos: this.state.photos }))
        } catch {
          this.setState((prevState) => {
            return {
              ...prevState,
              loading: false,
            }
          })
        }
      }
    } catch (e) {
      Sentry.Native.captureException(e.message)
      console.log(e)
    }
  }

  handleCameraRoll = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    try {
      if (status !== 'granted') {
        alert('You need to grant this app permission to upload photos.')
      } else {
        const width = 600
        const height = 600
        const photo = await ImagePicker.launchImageLibraryAsync({
          allowsEditing: true,
          aspect: [width, height],
          base64: false,
          exif: false,
          quality: 1,
        })

        if (!photo.canceled) {
          this.setState((prevState) => {
            return {
              ...prevState,
              loading: true,
            }
          })

          try {
            const location = await uploadPhoto(photo)
            this.setState((state) => {
              const photos = state.photos.concat(location)
              return {
                ...state,
                photos,
                loading: false,
              }
            })
            this.props.dispatch(updateRequest({ id: this.ticket.id, photos: this.state.photos }))
          } catch {
            this.setState((prevState) => {
              return {
                ...prevState,
                loading: false,
              }
            })
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsPhotos = connect(mapStateToProps, mapDispatchToProps)(TicketsPhotos)

export { TicketsPhotos }
