import _ from 'lodash'
import React from 'react'
import {
  ActivityIndicator,
  Image,
  Platform,
  RefreshControl,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { RefreshControl as RefreshControlWeb } from 'react-native-web-refresh-control'
import { connect } from 'react-redux'

import {
  selectRequest as bookmarksSelectRequest,
  insertRequest as insertRequest_,
  deleteRequest as deleteRequest_,
} from '../actions/bookmarks'
import { selectRequest as selectRequestEmployees } from '../actions/employees'
import { selectRequest as projectsSelectRequest } from '../actions/projects'
import { selectRequest as ticketsSelectRequest, selectFilters } from '../actions/tickets'
import { AddToTicket } from '../components/add-to-ticket'
import { Card } from '../components/card'
import { Line } from '../components/line'
import { Notice } from '../components/notice'
import { COLORS } from '../constants/colors'
import { buildError, buildIcon, buildNavigationOptions, buildPhoto } from '../modules/builders'
import { isAuthorizedCOR } from '../modules/helpers'

class TicketsList extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: 'Tickets',
    }
    return buildNavigationOptions(target)
  }

  state = {
    ticket: null,
    isVisible: false,
  }

  componentDidMount = () => {
    this.props.dispatch(
      ticketsSelectRequest({
        status: 'wip',
        type: 'ticket',
      }),
    )
    this.props.dispatch(bookmarksSelectRequest())
    this.props.dispatch(
      projectsSelectRequest({
        status: 'active',
      }),
    )
    this.props.dispatch(selectRequestEmployees())
  }

  render = () => {
    if (this.props.tickets.loading) {
      return this.renderLoading()
    }
    if (this.props.tickets.data && this.props.tickets.data.data) {
      return this.renderData()
    }
    if (this.props.tickets.exception !== null) {
      return this.renderException()
    }
    return null
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderData = () => {
    if (!this.props.tickets.data.data.length) {
      const styles = EStyleSheet.create({
        scrollView: {
          flexGrow: 1,
          justifyContent: 'center',
          padding: '20rem',
        },
      })
      return (
        <ScrollView
          contentContainerStyle={styles.scrollView}
          refreshControl={
            Platform.OS === 'web' ? (
              <RefreshControlWeb refreshing={this.props.projects.loading} onRefresh={this.handleRefresh} />
            ) : (
              <RefreshControl refreshing={this.props.projects.loading} onRefresh={this.handleRefresh} />
            )
          }
        >
          <Notice>There are no tickets in your account.</Notice>
        </ScrollView>
      )
    }
    const styles = EStyleSheet.create({
      scrollView: {
        backgroundColor: COLORS.white,
        paddingBottom: '0rem',
        paddingLeft: '20rem',
        paddingRight: '20rem',
        paddingTop: '20rem',
      },
    })
    return (
      <ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={
          Platform.OS === 'web' ? (
            <RefreshControlWeb refreshing={this.props.projects.loading} onRefresh={this.handleRefresh} />
          ) : (
            <RefreshControl refreshing={this.props.projects.loading} onRefresh={this.handleRefresh} />
          )
        }
      >
        {this.renderFilters()}
        {this.renderItems()}
        <AddToTicket
          isVisible={this.state.isVisible}
          onPressClose={() => this.setState({ isVisible: false })}
          onPressHours={() => {
            this.setState({ isVisible: false })
            this.props.navigation.navigate('TicketsHoursAddForm', {
              id: this.state.ticket.id,
            })
          }}
          onPressMaterials={() => {
            this.setState({ isVisible: false })
            this.props.navigation.navigate('TicketsMaterialsAddForm', {
              id: this.state.ticket.id,
            })
          }}
          onPressPhotos={() => {
            this.setState({ isVisible: false })
            this.props.navigation.navigate('TicketsPhotos', {
              id: this.state.ticket.id,
            })
          }}
          ticket={this.state.ticket}
        />
      </ScrollView>
    )
  }

  renderException = (exception) => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Notice failure>{buildError(this.props.tickets.exception)}</Notice>
      </ScrollView>
    )
  }

  renderFilters = () => {
    const styles = EStyleSheet.create({
      view: {
        marginBottom: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        {this.renderButton()}
        {this.renderSummary()}
      </View>
    )
  }

  renderButton = () => {
    const styles = EStyleSheet.create({
      viewOuter: {
        backgroundColor: COLORS.grayDark,
        borderRadius: '5rem',
        flex: 1,
        flexDirection: 'row',
        padding: '15rem',
      },
      viewInner: {
        flex: 1,
      },
      text: {
        color: COLORS.white,
        fontSize: '18rem',
        lineHeight: '24rem',
      },
    })
    return (
      <TouchableOpacity
        onPress={() => {
          this.props.navigation.navigate('TicketsFilters')
        }}
      >
        <View style={styles.viewOuter}>
          <View style={styles.viewInner}>
            <Text allowFontScaling={false} style={styles.text}>
              Search Tickets
            </Text>
          </View>
          {buildIcon('solid', COLORS.white, 'sliders-h', EStyleSheet.value('20rem'))}
        </View>
      </TouchableOpacity>
    )
  }

  renderSummary = () => {
    const items = [
      this.props.tickets.filters.project ? `Project ${this.props.tickets.filters.project}` : '',
      this.props.tickets.filters.description,
      this.props.tickets.filters.createdBy,
      this.props.tickets.filters.awaitingSignature ? 'Awaiting Signature' : '',
    ]
    const filter = _.filter(items, (item) => item)
    const join = _.join(filter, ', ')
    if (!join) {
      return null
    }
    const styles = EStyleSheet.create({
      viewOuter: {
        flexDirection: 'row',
        marginTop: '10rem',
      },
      viewInner: {
        flex: 1,
      },
      textLeft: {
        backgroundColor: COLORS.white,
        color: COLORS.grayMedium,
        fontSize: '15rem',
        lineHeight: '20rem',
      },
      textRight: {
        backgroundColor: COLORS.white,
        color: COLORS.redLight,
        fontSize: '15rem',
        lineHeight: '20rem',
      },
    })
    return (
      <View style={styles.viewOuter}>
        <View style={styles.viewInner}>
          <Text allowFontScaling={false} numberOfLines={1} style={styles.textLeft}>
            {join}
          </Text>
        </View>
        <View>
          <TouchableOpacity onPress={() => this.handlePress()}>
            <Text allowFontScaling={false} style={styles.textRight}>
              CLEAR
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  renderItems = () => {
    const filter = _.filter(this.props.tickets.data.data, this.predicate)
    const groupBy = _.groupBy(filter, (ticket) => `${ticket.project.number} ${ticket.project.name}`)
    const pairs = _.toPairs(groupBy)
    const sortBy = _.sortBy(pairs, (pair) => pair[0])
    if (!sortBy.length) {
      return <Notice failure>There are no tickets matching your search criteria.</Notice>
    }
    return sortBy.map((value, key) => this.renderSection(value, key))
  }

  predicate = (item) => {
    if (this.props.tickets.filters.project) {
      if (item.project.number !== this.props.tickets.filters.project) {
        return false
      }
    }
    if (this.props.tickets.filters.description) {
      if (item.description !== this.props.tickets.filters.description) {
        return false
      }
    }
    if (this.props.tickets.filters.createdBy) {
      if (item.created_by.name !== this.props.tickets.filters.createdBy) {
        return false
      }
    }
    if (this.props.tickets.filters.awaitingSignature) {
      if (item.requires_auth === false) {
        return false
      }
      if (item.requires_auth === true && item.authorization !== null) {
        return false
      }
    }

    return true
  }

  renderSection = (value, key) => {
    return (
      <View key={key}>
        {this.renderTitle(value)}
        {value[1].map((value, key) => this.renderItem(value, key))}
      </View>
    )
  }

  renderTitle = (value) => {
    const styles = EStyleSheet.create({
      view: {
        marginBottom: '20rem',
      },
      text: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '18rem',
        lineHeight: '24rem',
      },
    })
    return (
      <View key={value[0]}>
        <View style={styles.view}>
          <Text style={styles.text}>{value[0]}</Text>
        </View>
      </View>
    )
  }

  renderItem = (value, key) => {
    const styles = EStyleSheet.create({
      viewGrid: {
        flexDirection: 'row',
        padding: '10rem',
      },
      viewImage: {
        height: '75rem',
        width: '75rem',
        borderRadius: '5rem',
      },
      viewRow: {
        flex: 1,
        paddingLeft: '10rem',
        paddingRight: '10rem',
      },
      viewColumn: {
        flexDirection: 'row',
        paddingBottom: '2.5rem',
      },
      textTicket: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '15rem',
        lineHeight: '15rem',
        paddingRight: '10rem',
      },
      textNumber: {
        backgroundColor: COLORS.white,
        color: COLORS.redLight,
        fontSize: '15rem',
        lineHeight: '15rem',
        paddingRight: '10rem',
      },
      textScope: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '12rem',
        lineHeight: '18rem',
      },
      textLocation: {
        backgroundColor: COLORS.white,
        color: COLORS.grayMedium,
        fontSize: '12rem',
        lineHeight: '18rem',
      },
      viewButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      viewButtonLeft: {
        flex: 1,
        paddingBottom: '10rem',
        paddingLeft: '10rem',
        paddingRight: '10rem',
        paddingTop: '10rem',
      },
      viewButtonRight: {
        borderLeftColor: COLORS.grayLight,
        borderLeftWidth: '1rem',
        flex: 1,
        paddingBottom: '10rem',
        paddingLeft: '10rem',
        paddingRight: '10rem',
        paddingTop: '10rem',
      },
      textButton: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '12rem',
        fontWeight: 'bold',
        lineHeight: '18rem',
        textAlign: 'center',
      },
    })
    return (
      <Card orange key={key}>
        <View style={styles.viewGrid}>
          <Image
            resizeMode="cover"
            source={{
              uri: value.photos && value.photos.length ? value.photos[0] : buildPhoto(),
            }}
            style={styles.viewImage}
          />
          <View style={styles.viewRow}>
            <View style={styles.viewColumn}>
              <Text style={styles.textTicket}>TICKET</Text>
              <Text style={styles.textNumber}>{value.number}</Text>
              {!isAuthorizedCOR(value)
                ? buildIcon('solid', COLORS.redDark, 'exclamation-triangle', EStyleSheet.value('12rem'))
                : null}
            </View>
            <View style={styles.viewColumn}>
              <Text numberOfLines={2} style={styles.textScope}>
                {value.pricing.scope ? value.pricing.scope : 'N/A'}
              </Text>
            </View>
            <View style={styles.viewColumn}>
              <Text numberOfLines={1} style={styles.textLocation}>
                {value.location_tag ? value.location_tag : 'N/A'}
              </Text>
            </View>
          </View>
          <TouchableOpacity
            onPress={() => {
              if (!this.props.bookmarks.data) {
                return
              }
              const item = _.find(this.props.bookmarks.data.data, (item) => item.cor.id === value.id)
              if (!item) {
                this.props.dispatch(insertRequest_({ cor_id: value.id }))
              } else {
                this.props.dispatch(deleteRequest_({ id: item.id }))
              }
            }}
          >
            {buildIcon(
              'solid',
              this.isBookmarked(value.id) ? COLORS.orange : COLORS.grayDark,
              'bookmark',
              EStyleSheet.value('20rem'),
            )}
          </TouchableOpacity>
        </View>
        <Line />
        <View style={styles.viewButtons}>
          <View style={styles.viewButtonLeft}>
            <TouchableOpacity
              onPress={() =>
                this.props.navigation.navigate('TicketsDetail', {
                  id: value.id,
                })
              }
            >
              <Text allowFontScaling={false} style={styles.textButton}>
                DETAIL
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.viewButtonRight}>
            <TouchableOpacity
              onPress={() => {
                this.setState({
                  ticket: value,
                })
                this.setState({
                  isVisible: true,
                })
              }}
            >
              <Text allowFontScaling={false} style={styles.textButton}>
                ADD
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.viewIcon} />
        </View>
      </Card>
    )
  }

  handlePress = () => {
    this.props.dispatch(selectFilters('project', null))
    this.props.dispatch(selectFilters('description', null))
    this.props.dispatch(selectFilters('createdBy', null))
    this.props.dispatch(selectFilters('awaitingSignature', false))
  }

  isBookmarked = (id) => {
    if (!this.props.bookmarks.data) {
      return false
    }
    return _.filter(this.props.bookmarks.data.data, (item) => item.cor.id === id).length > 0
  }

  handleRefresh = () => {
    this.props.dispatch(
      ticketsSelectRequest({
        status: 'wip',
        type: 'ticket',
      }),
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsList = connect(mapStateToProps, mapDispatchToProps)(TicketsList)

export { TicketsList }
