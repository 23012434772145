import _ from 'lodash'
import React from 'react'
import {
  ActivityIndicator,
  Image,
  Platform,
  RefreshControl,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { RefreshControl as RefreshControlWeb } from 'react-native-web-refresh-control'
import { connect } from 'react-redux'

import { selectRequest } from '../actions/projects'
import { selectFilters } from '../actions/tickets'
import { AddToProject } from '../components/add-to-project'
import { Card } from '../components/card'
import { Line } from '../components/line'
import { Notice } from '../components/notice'
import { COLORS } from '../constants/colors'
import { buildError, buildNavigationOptions, buildPhoto } from '../modules/builders'

class ProjectsList extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: 'Projects',
    }
    return buildNavigationOptions(target)
  }

  state = {
    project: null,
    isVisible: false,
  }

  componentDidMount = () => {
    if (Platform.OS === 'web') {
      history.pushState(null, document.title, location.href)
      window.addEventListener('popstate', function (event) {
        history.pushState(null, document.title, location.href)
      })
    }
    this.props.dispatch(
      selectRequest({
        status: 'active',
      }),
    )
  }

  render = () => {
    if (this.props.projects.loading) {
      return this.renderLoading()
    }
    if (this.props.projects.data && this.props.projects.data.data) {
      return this.renderData()
    }
    if (this.props.projects.exception !== null) {
      return this.renderException()
    }
    return null
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderData = () => {
    if (!this.props.projects.data.data.length) {
      const styles = EStyleSheet.create({
        scrollView: {
          flexGrow: 1,
          justifyContent: 'center',
          padding: '20rem',
        },
      })
      return (
        <ScrollView
          contentContainerStyle={styles.scrollView}
          refreshControl={
            Platform.OS === 'web' ? (
              <RefreshControlWeb refreshing={this.props.projects.loading} onRefresh={this.handleRefresh} />
            ) : (
              <RefreshControl refreshing={this.props.projects.loading} onRefresh={this.handleRefresh} />
            )
          }
        >
          <Notice>There are no projects in your account.</Notice>
        </ScrollView>
      )
    }
    const styles = EStyleSheet.create({
      scrollView: {
        paddingBottom: '0rem',
        paddingLeft: '20rem',
        paddingRight: '20rem',
        paddingTop: '20rem',
        backgroundColor: 'white',
      },
    })
    return (
      <ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={
          Platform.OS === 'web' ? (
            <RefreshControlWeb refreshing={this.props.projects.loading} onRefresh={this.handleRefresh} />
          ) : (
            <RefreshControl refreshing={this.props.projects.loading} onRefresh={this.handleRefresh} />
          )
        }
      >
        {this.renderItems()}
        <AddToProject
          isVisible={this.state.isVisible}
          onPressClose={() => this.setState({ isVisible: false })}
          onPressTicket={() => {
            this.setState({ isVisible: false })
            this.props.navigation.navigate('TicketsAddForm', {
              id: this.state.project.id,
            })
          }}
          onPressScheduleDelay={() => {
            this.setState({ isVisible: false })
            this.props.navigation.navigate('ScheduleDelaysAddForm', {
              id: this.state.project.id,
            })
          }}
          onPressDailyReport={() => {
            this.setState({ isVisible: false })
            this.props.navigation.navigate('DailyReportsForm', {
              project: this.state.project,
              report: undefined,
            })
          }}
          project={this.state.project}
        />
      </ScrollView>
    )
  }

  renderException = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Notice failure>{buildError(this.props.projects.exception)}</Notice>
      </ScrollView>
    )
  }

  renderItems = () => {
    return _.map(this.props.projects.data.data, (value, key) => this.renderItem(value, key))
  }

  renderItem = (value, key) => {
    const styles = EStyleSheet.create({
      viewGrid: {
        flexDirection: 'row',
        padding: '10rem',
      },
      viewImage: {
        height: '50rem',
        width: '50rem',
        borderRadius: '5rem',
      },
      viewRow: {
        flex: 1,
        paddingLeft: '10rem',
        paddingRight: '10rem',
      },
      viewColumn: {
        flexDirection: 'row',
        paddingBottom: '0rem',
      },
      textNumber: {
        backgroundColor: COLORS.white,
        color: COLORS.redLight,
        fontSize: '15rem',
        lineHeight: '15rem',
      },
      textName: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '12rem',
        fontWeight: 'bold',
        lineHeight: '18rem',
      },
      textLocation: {
        backgroundColor: COLORS.white,
        color: COLORS.grayMedium,
        fontSize: '12rem',
        lineHeight: '18rem',
      },
      viewButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      viewButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        flexGrow: 1,
        height: '35rem',
        paddingBottom: '3rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
        paddingTop: '3rem',
      },
      viewButtonCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderLeftColor: COLORS.grayLight,
        borderLeftWidth: '1rem',
        borderRightColor: COLORS.grayLight,
        borderRightWidth: '1rem',
        flex: 1,
        flexGrow: 1,
        height: '35rem',
        paddingBottom: '3rem',
        paddingLeft: '3rem',
        paddingRight: '3rem',
        paddingTop: '3rem',
      },
      textButton: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '10rem',
        fontWeight: 'bold',
        lineHeight: '18rem',
        textAlign: 'center',
      },
    })
    return (
      <Card grayDark key={key}>
        <View style={styles.viewGrid}>
          <Image
            resizeMode="cover"
            source={{
              uri: value.photo ? value.photo : buildPhoto(),
            }}
            style={styles.viewImage}
          />
          <View style={styles.viewRow}>
            <View style={styles.viewColumn}>
              <Text allowFontScaling={false} style={styles.textNumber}>
                {value.number}
              </Text>
            </View>
            <View style={styles.viewColumn}>
              <Text numberOfLines={1} style={styles.textName}>
                {value.name}
              </Text>
            </View>
            <View style={styles.viewColumn}>
              <Text numberOfLines={1} style={styles.textLocation}>
                {value.location ? value.location.city : 'N/A'}, {value.location ? value.location.state : 'N/A'}
              </Text>
            </View>
          </View>
        </View>
        <Line />
        <View style={styles.viewButtons}>
          <View style={{ flex: 1, flexGrow: 1 }}>
            <TouchableOpacity onPress={() => this.handlePress(value.id)}>
              <View style={styles.viewButton}>
                <Text allowFontScaling={false} style={styles.textButton}>
                  DETAIL
                </Text>
              </View>
            </TouchableOpacity>
          </View>
          <View style={{ flex: 1, flexGrow: 1 }}>
            <TouchableOpacity
              onPress={() => {
                this.setState({ project: value })
                this.setState({ isVisible: true })
              }}
            >
              <View style={styles.viewButtonCenter}>
                <Text allowFontScaling={false} style={styles.textButton}>
                  ADD
                </Text>
              </View>
            </TouchableOpacity>
          </View>

          <View style={{ flex: 1, flexGrow: 1 }}>
            <TouchableOpacity
              onPress={() => {
                this.props.dispatch(selectFilters('project', value.number))
                this.props.dispatch(selectFilters('description', ''))
                this.props.dispatch(selectFilters('createdBy', ''))
                this.props.dispatch(selectFilters('awaitingSignature', false))
                this.props.navigation.navigate('TicketsList')
              }}
            >
              <View style={styles.viewButton}>
                <Text allowFontScaling={false} style={styles.textButton}>
                  TICKETS ({value.count_tickets_all})
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </Card>
    )
  }

  handleRefresh = () => {
    this.props.dispatch(
      selectRequest({
        status: 'active',
      }),
    )
  }

  handlePress = (id) => {
    this.props.navigation.navigate('ProjectsDetail', {
      id,
    })
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

ProjectsList = connect(mapStateToProps, mapDispatchToProps)(ProjectsList)

export { ProjectsList }
