import { put, takeLatest } from 'redux-saga/effects'

import { profileSuccess, profileFailure, signIn } from '../actions/users'
import { api } from '../api'
import { PROFILE_REQUEST, SIGN_IN } from '../types/users'

function* profile(action) {
  try {
    const response = yield api({
      method: 'GET',
      url: '/user',
    })
    yield put(profileSuccess(response.data))
  } catch (error) {
    yield put(profileFailure(error.response.data))
  }
}

function* signIn_(action) {
  try {
    const response = yield api({
      data: {
        email: action.payload.email,
        password: action.payload.password,
        meta: {
          device_id: '...', // TODO
          device_os: '...', // TODO
          ip_address: '...', // TODO
        },
      },
      method: 'POST',
      url: '/login',
    })
    yield put(signIn.success(response.data))
  } catch (error) {
    yield put(signIn.failure(error.response.data))
  }
}

function* users() {
  yield takeLatest(PROFILE_REQUEST, profile)
  yield takeLatest(SIGN_IN.REQUEST, signIn_)
}

export { users }
