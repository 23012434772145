import { parse } from 'date-fns'
import React from 'react'
import DatePicker from 'react-datepicker'
import { Platform, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import DateTimePickerModal from 'react-native-modal-datetime-picker'

import { COLORS } from '../constants/colors'
import { buildDate, buildDateTime, buildIcon, buildTime } from '../modules/builders'

const styles = EStyleSheet.create({
  control: {
    backgroundColor: COLORS.white,
    borderColor: COLORS.grayLight,
    borderRadius: '5rem',
    borderWidth: '1rem',
    color: COLORS.grayDark,
    fontSize: '15rem',
    lineHeight: '20rem',
    paddingBottom: '10rem',
    paddingLeft: '10rem',
    paddingRight: '10rem',
    paddingTop: '10rem',
  },
  view: {
    marginTop: '10rem',
  },
  text: {
    backgroundColor: COLORS.white,
    color: COLORS.redLight,
    fontSize: '15rem',
    fontWeight: 'bold',
    lineHeight: '20rem',
  },
  datePickerClose: {
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '5rem',
  },
  datePickerCalender: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: '5rem',
  },
})

class DateTimePicker extends React.Component {
  state = {
    isVisible: false,
  }

  encode = (date) => {
    if (this.props.mode === 'datetime') {
      return parse(date, 'MM/dd/yyyy h:mm a', new Date())
    }
    if (this.props.mode === 'date') {
      return parse(date, 'MM/dd/yyyy', new Date())
    }
    if (this.props.mode === 'time') {
      return parse(`01/02/2001 ${date}`, 'MM/dd/yyyy h:mm a', new Date())
    }
    return parse(date, 'MM/dd/yyyy h:mm a', new Date())
  }

  decode = (date) => {
    if (this.props.mode === 'datetime') {
      return buildDateTime(date)
    }
    if (this.props.mode === 'date') {
      return buildDate(date)
    }
    if (this.props.mode === 'time') {
      return buildTime(date)
    }
    return buildDateTime(date)
  }

  render = () => {
    return (
      <View>
        <TouchableOpacity onPress={() => this.setState({ isVisible: true })}>
          <Text style={styles.control}>{this.props.input.value}</Text>
        </TouchableOpacity>
        {this.renderWidget()}
        {this.renderError()}
      </View>
    )
  }

  calendar = ({ children }) => {
    return (
      <div className="react-datepicker">
        <View style={styles.datePickerClose}>
          <TouchableOpacity onPress={() => this.setState({ isVisible: false })}>
            {buildIcon('regular', COLORS.grayDark, 'times-circle', EStyleSheet.value(20))}
          </TouchableOpacity>
        </View>
        <View style={styles.datePickerCalender}>
          <View>{children}</View>
        </View>
      </div>
    )
  }

  renderWidget = () => {
    if (!this.state.isVisible) {
      return null
    }
    return Platform.OS !== 'web' ? (
      <DateTimePickerModal
        date={this.encode(this.props.input.value)}
        isVisible
        mode={this.props.mode}
        onCancel={() => {
          this.setState({ isVisible: false })
        }}
        onConfirm={(date) => {
          this.setState({ isVisible: false })
          this.props.input.onChange(this.decode(date))
        }}
      />
    ) : (
      <View>
        <DatePicker
          calendarContainer={this.calendar}
          inline
          selected={this.encode(this.props.input.value)}
          showTimeInput={this.props.mode === 'datetime' || this.props.mode === 'time'}
          showTimeSelectOnly={this.props.mode === 'time'}
          style={{ width: '100%' }}
          onChange={(date) => {
            this.setState({ isVisible: false })
            this.props.input.onChange(this.decode(date))
          }}
        />
      </View>
    )
  }

  renderError = () => {
    if (!this.props.meta.touched) {
      return null
    }
    if (!this.props.meta.error) {
      return null
    }
    return (
      <View style={styles.view}>
        <Text style={styles.text}>{this.props.meta.error}</Text>
      </View>
    )
  }
}

export { DateTimePicker }
