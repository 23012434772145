import Checkbox from 'expo-checkbox'
import React from 'react'
import { KeyboardAvoidingView, Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { withNavigationFocus } from 'react-navigation'
import { connect } from 'react-redux'

import { Button } from '../components/button'
import { Input } from '../components/input'
import { Left } from '../components/left'
import { Right } from '../components/right'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'

class DailyReportsFormEquipment extends React.Component {
  state = {
    report: null,
  }

  static navigationOptions = ({ navigation }) => {
    const left = (
      <View
        style={{
          paddingLeft: 20,
        }}
      >
        <Left
          icon={buildIcon('regular', COLORS.grayDark, 'arrow-left', 20)}
          onPress={() => {
            const onPressLeft = navigation.getParam('onPressLeft', null)
            if (onPressLeft) {
              onPressLeft()
            }
          }}
        />
      </View>
    )
    const right =
      navigation.getParam('report').status === 'submitted' ? null : (
        <View
          style={{
            paddingRight: 20,
          }}
        >
          <Right
            icon={buildIcon('solid', COLORS.grayDark, 'plus-square', 30)}
            onPress={() => {
              const onPressRight = navigation.getParam('onPressRight', null)
              if (onPressRight) {
                onPressRight()
              }
            }}
          />
        </View>
      )
    const target = {
      headerLeft: () => left,
      headerRight: () => right,
      headerTitle: 'Equipment',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.component()
  }

  component = () => {
    this.project = this.props.navigation.getParam('project')
    this.buildState()
    this.props.navigation.setParams({
      onPressLeft: () => {
        this.props.navigation.navigate('DailyReportsForm', {
          project: this.project,
          report: this.state.report,
        })
      },
      onPressRight: () => {
        this.handlePlus()
      },
    })
  }

  render = () => {
    if (!this.state.report) {
      return null
    }
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <ScrollView
          style={{
            flexGrow: 1,
            padding: 20,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          {this.state.report.data.equipment.rows.map(this.renderItem)}
        </ScrollView>
        {this.state.report.status === 'submitted' ? null : (
          <View
            style={{
              flexDirection: 'row',
              padding: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingRight: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.props.navigation.getParam('report'),
                  })
                }}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.state.report,
                  })
                }}
              >
                <Button primary>Continue</Button>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </KeyboardAvoidingView>
    )
  }

  renderItem = (row, key) => {
    const description = {
      input: {
        onChange: (description) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  equipment: {
                    ...prevState.report.data.equipment,
                    rows: prevState.report.data.equipment.rows.map((v, k) => {
                      if (k === key) {
                        v.description.value = description
                      }
                      return v
                    }),
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: '' + row.description.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    const qty = {
      input: {
        onChange: (qty) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  equipment: {
                    ...prevState.report.data.equipment,
                    rows: prevState.report.data.equipment.rows.map((v, k) => {
                      if (k === key) {
                        qty = parseInt(qty, 10)
                        v.qty.value = !isNaN(qty) ? qty : 0
                      }
                      return v
                    }),
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: '' + row.qty.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    const service = {
      isChecked: !!row.service.value,
      onClick: () => {
        this.setState((prevState) => {
          return {
            ...prevState,
            report: {
              ...prevState.report,
              data: {
                ...prevState.report.data,
                equipment: {
                  ...prevState.report.data.equipment,
                  rows: prevState.report.data.equipment.rows.map((v, k) => {
                    if (k === key) {
                      v.service.value = !v.service.value
                    }
                    return v
                  }),
                },
                isDirty: true,
              },
            },
          }
        })
      },
      value: !!row.service.value,
    }
    const call_off = {
      isChecked: !!row.call_off.value,
      onClick: () => {
        this.setState((prevState) => {
          return {
            ...prevState,
            report: {
              ...prevState.report,
              data: {
                ...prevState.report.data,
                equipment: {
                  ...prevState.report.data.equipment,
                  rows: prevState.report.data.equipment.rows.map((v, k) => {
                    if (k === key) {
                      v.call_off.value = !v.call_off.value
                    }
                    return v
                  }),
                },
                isDirty: true,
              },
            },
          }
        })
      },
      value: !!row.call_off.value,
    }
    return (
      <View
        key={key}
        style={{
          borderTopColor: COLORS.grayLight,
          borderTopWidth: key === 0 ? 0 : 1,
          marginTop: key === 0 ? 0 : 20,
        }}
      >
        <View
          style={{
            marginTop: 20,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Description
              </Text>
            </View>
            {this.state.report.status === 'submitted' ? null : (
              <TouchableOpacity
                onPress={() => {
                  this.handleMinus(key)
                }}
              >
                {buildIcon('regular', COLORS.redDark, 'minus-square', 15)}
              </TouchableOpacity>
            )}
          </View>
          {this.state.report.status === 'submitted' ? (
            <Text
              style={{
                color: COLORS.grayMedium,
                fontSize: 15,
                lineHeight: 20,
              }}
            >
              {description.input.value}
            </Text>
          ) : (
            <Input type="text" {...description} />
          )}
        </View>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            paddingTop: 20,
          }}
        >
          <Text
            allowFontScaling={false}
            style={{
              color: COLORS.grayDark,
              fontSize: 15,
              fontWeight: 'bold',
              marginRight: 10,
            }}
          >
            Qty
          </Text>
          <View
            style={{
              flex: 1,
            }}
          >
            {this.state.report.status === 'submitted' ? (
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {qty.input.value}
              </Text>
            ) : (
              <Input keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'} placeholder="Qty" {...qty} />
            )}
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            paddingTop: 20,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            {this.state.report.status === 'submitted' ? (
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Service{' '}
                {!description.input.value ? (
                  ''
                ) : service.isChecked ? (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {' '}
                    Yes
                  </Text>
                ) : (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {' '}
                    No
                  </Text>
                )}
              </Text>
            ) : (
              <>
                <Checkbox
                  color={service.isChecked === true ? COLORS.orange : COLORS.grayDark}
                  onValueChange={service.onClick}
                  value={service.isChecked === true}
                />
                <TouchableOpacity
                  onPress={() => {
                    service.onClick()
                  }}
                >
                  <Text
                    allowFontScaling={false}
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      fontWeight: 'bold',
                      marginLeft: 10,
                    }}
                  >
                    Service
                  </Text>
                </TouchableOpacity>
              </>
            )}
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}
          >
            {this.state.report.status === 'submitted' ? (
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Call Off{' '}
                {!description.input.value ? (
                  ''
                ) : call_off.isChecked ? (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {' '}
                    Yes
                  </Text>
                ) : (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {' '}
                    No{' '}
                  </Text>
                )}
              </Text>
            ) : (
              <>
                <Checkbox
                  disabled={this.state.report.status === 'submitted'}
                  color={call_off.isChecked === true ? COLORS.orange : COLORS.grayDark}
                  onValueChange={call_off.onClick}
                  value={call_off.isChecked === true}
                />
                <TouchableOpacity
                  disabled={this.state.report.status === 'submitted'}
                  onPress={() => {
                    call_off.onClick()
                  }}
                >
                  <Text
                    allowFontScaling={false}
                    style={{
                      color: COLORS.grayDark,
                      fontSize: 15,
                      fontWeight: 'bold',
                      marginLeft: 10,
                    }}
                  >
                    Call Off
                  </Text>
                </TouchableOpacity>
              </>
            )}
          </View>
        </View>
      </View>
    )
  }

  buildState = () => {
    const report = this.props.navigation.getParam('report')
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          report: {
            ...report,
          },
        }
      },
      () => {
        if (this.state.report.data.equipment.rows.length === 0) {
          this.handlePlus()
        }
      },
    )
  }

  handlePlus = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...prevState.report,
          data: {
            ...prevState.report.data,
            equipment: {
              ...prevState.report.data.equipment,
              rows: [
                ...prevState.report.data.equipment.rows,
                {
                  description: {
                    value: '',
                  },
                  qty: {
                    value: '',
                  },
                  service: {
                    value: false,
                  },
                  call_off: {
                    value: false,
                  },
                },
              ],
            },
          },
        },
      }
    })
  }

  handleMinus = (key) => {
    const rows = this.state.report.data.equipment.rows
    rows.splice(key, 1)
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...prevState.report,
          data: {
            ...prevState.report.data,
            equipment: {
              ...prevState.report.data.equipment,
              rows,
            },
          },
        },
      }
    })
  }
}

const mapStateToProps = (props, ownProps) => {
  return {
    ...props,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

DailyReportsFormEquipment = connect(mapStateToProps, mapDispatchToProps)(DailyReportsFormEquipment)

DailyReportsFormEquipment = withNavigationFocus(DailyReportsFormEquipment)

export { DailyReportsFormEquipment }
