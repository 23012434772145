import React from 'react'
import { View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import { COLORS } from '../constants/colors'

const styles = EStyleSheet.create({
  view: {
    backgroundColor: COLORS.grayLight,
    height: '1rem',
  },
})

class Line extends React.Component {
  render = () => {
    return <View style={styles.view}>{this.props.children}</View>
  }
}

export { Line }
