import React from 'react'
import { Platform, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm } from 'redux-form'

import { Input } from '../components/input'
import { Select } from '../components/select'
import { COLORS } from '../constants/colors'
import { buildIcon } from '../modules/builders'
import { sanitizeNumber } from '../modules/helpers'

const styles = EStyleSheet.create({
  view: {
    marginBottom: '10rem',
  },
  viewControls: {
    flexDirection: 'row',
    marginBottom: '10rem',
  },
  viewControlsLeft: {
    flex: 1,
    marginRight: '10rem',
  },
  viewControlsRight: {
    flex: 1,
    marginLeft: '10rem',
    marginRight: '10rem',
  },
  viewControlsPlus: {
    paddingTop: '6rem',
    paddingBottom: '6rem',
  },
  viewControlsMinus: {
    paddingTop: '11rem',
    paddingBottom: '11rem',
  },
  textLabel: {
    color: COLORS.grayDark,
    fontSize: '13rem',
    fontWeight: 'bold',
    lineHeight: '20rem',
    marginBottom: '10rem',
  },
})

class Component extends React.Component {
  componentDidMount = () => {
    this.props.item.entries.forEach((entry) => {
      this.props.fields.push({
        hours: entry.hours,
        rate_slug: entry.rate_slug,
      })
    })
  }

  render = () => {
    return (
      <View>
        <View style={styles.viewControls}>
          <View style={styles.viewControlsLeft}>
            <Text style={styles.textLabel}>Hours</Text>
          </View>
          <View style={styles.viewControlsRight}>
            <Text style={styles.textLabel}>Rate Types</Text>
          </View>
          <View style={styles.viewControlsPlus}>
            <TouchableOpacity
              onPress={() => {
                this.props.fields.push({
                  hours: '',
                  rate_slug: 'regular_time',
                })
              }}
            >
              {buildIcon('regular', COLORS.grayDark, 'plus-square', 15)}
            </TouchableOpacity>
          </View>
        </View>
        {this.props.fields.map((value, key) => {
          return (
            <View key={key} style={styles.viewControls}>
              <View style={styles.viewControlsLeft}>
                <Field
                  component={Input}
                  keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
                  name={`${value}.hours`}
                  normalize={(val) => sanitizeNumber(val)}
                />
              </View>
              <View style={styles.viewControlsRight}>
                <Field
                  component={Select}
                  gray
                  items={this.props.types}
                  name={`${value}.rate_slug`}
                  placeholder={{ label: 'Select a type...', key: '', value: '' }}
                />
              </View>
              <View style={styles.viewControlsMinus}>
                <TouchableOpacity
                  onPress={() => {
                    if (key <= this.props.fields.length - 1) {
                      this.props.fields.remove(key)
                    }
                  }}
                >
                  {buildIcon(
                    'regular',
                    this.props.fields.length === 1 ? COLORS.white : COLORS.redDark,
                    'minus-square',
                    15,
                  )}
                </TouchableOpacity>
              </View>
            </View>
          )
        })}
      </View>
    )
  }
}
class EmployeesForm extends React.Component {
  render = () => {
    return (
      <View style={styles.view}>
        <FieldArray
          component={Component}
          item={this.props.item}
          name={`entries-${this.props.item.classification}-${this.props.item.employee.id}`}
          types={this.props.types}
        />
      </View>
    )
  }
}

EmployeesForm = reduxForm({
  enableReinitialize: true,
  form: 'employees-form',
})(EmployeesForm)

const mapStateToProps = (state, ownProps) => {
  return {
    initialValues: ownProps.initialValues,
    state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

EmployeesForm = connect(mapStateToProps, mapDispatchToProps)(EmployeesForm)

export { EmployeesForm }
