import { createFormAction } from 'redux-form-saga'

import { SELECT_REQUEST, SELECT_SUCCESS, SELECT_FAILURE } from '../types/materials'

const selectRequest = () => {
  return {
    payload: {},
    type: SELECT_REQUEST,
  }
}

const selectSuccess = (data) => {
  return {
    payload: {
      data,
    },
    type: SELECT_SUCCESS,
  }
}

const selectFailure = (exception) => {
  return {
    payload: {
      exception,
    },
    type: SELECT_FAILURE,
  }
}

const insert = createFormAction('MATERIALS_INSERT')

export { selectRequest, selectSuccess, selectFailure, insert }
