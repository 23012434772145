import { put, takeLatest } from 'redux-saga/effects'

import { selectSuccess, selectFailure, insert, update, updateSuccess, updateFailure } from '../actions/tickets'
import { api } from '../api'
import { SELECT_REQUEST, INSERT, UPDATE, UPDATE_REQUEST } from '../types/tickets'

function* select(action) {
  try {
    const params = {
      ...action.payload.data,
      perPage: 999,
    }
    const response = yield api({
      method: 'GET',
      params,
      url: '/cors',
    })
    yield put(selectSuccess(response.data))
  } catch (error) {
    yield put(selectFailure(error.response.data))
  }
}

function* inser_(action) {
  try {
    const response = yield api({
      data: {
        project_id: action.payload.project_id,
        description: action.payload.description,
        location_tag: action.payload.location,
        scope: action.payload.scope,
        notes: action.payload.notes,
        fieldwire_tasks: action.payload.fieldwire_tasks,
        plan_grid_issues: action.payload.plan_grid_issues,
        requires_auth: action.payload.requires_auth,
        authorization: action.payload.authorization,
        reference: action.payload.reference,
      },
      method: 'POST',
      url: '/cors',
    })
    yield put(insert.success(response.data))
  } catch (error) {
    yield put(insert.failure(error.response.data))
  }
}

function* update1(action) {
  try {
    const response = yield api({
      data: action.payload,
      method: 'PATCH',
      url: `/cors/${action.payload.id}`,
    })
    yield put(update.success(response.data))
  } catch (error) {
    yield put(update.failure(error.response.data))
  }
}

function* update2(action) {
  if (!action.payload.data) {
    return
  }
  try {
    const response = yield api({
      data: action.payload.data,
      method: 'PATCH',
      url: `/cors/${action.payload.data.id}`,
    })
    yield put(updateSuccess(response.data))
  } catch (error) {
    yield put(updateFailure(error.response.data))
  }
}

function* tickets() {
  yield takeLatest(SELECT_REQUEST, select)
  yield takeLatest(INSERT.REQUEST, inser_)
  yield takeLatest(UPDATE.REQUEST, update1)
  yield takeLatest(UPDATE_REQUEST, update2)
}

export { tickets }
