import { default as Checkbox_ } from 'expo-checkbox'
import React from 'react'
import { Text, View } from 'react-native'

import { COLORS } from '../constants/colors'

class Checkbox extends React.Component {
  render = () => {
    return (
      <View>
        <Checkbox_
          color={this.props.input.checked === true ? COLORS.orange : COLORS.white}
          onChange={this.handleClick}
          onValueChange={this.handleClick}
          value={this.props.input.checked === true}
          {...this.props}
        />
        {this.renderError()}
      </View>
    )
  }

  renderError = () => {
    if (!this.props.meta.touched) {
      return null
    }
    if (!this.props.meta.error) {
      return null
    }
    return (
      <View
        style={{
          marginTop: 10,
        }}
      >
        <Text
          style={{
            backgroundColor: COLORS.white,
            color: COLORS.redLight,
            fontSize: 15,
            lineHeight: 20,
          }}
        >
          {this.props.meta.error}
        </Text>
      </View>
    )
  }

  handleClick = () => {
    this.props.input.onChange(!this.props.input.checked)
  }
}

export { Checkbox }
