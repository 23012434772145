import Checkbox from 'expo-checkbox'
import _ from 'lodash'
import React from 'react'
import { ActivityIndicator, Alert, Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'

import { selectRequest as selectRequestMaterials } from '../actions/materials'
import { selectRequest as selectRequestTickets } from '../actions/tickets'
import { selectRequest, update, delet_ } from '../actions/tickets-materials'
import { Button } from '../components/button'
import { MaterialsC } from '../components/materials-c'
import { Notice } from '../components/notice'
import { Right } from '../components/right'
import { COLORS } from '../constants/colors'
import { buildError, buildIcon, buildNavigationOptions } from '../modules/builders'

class TicketsMaterialsList extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const right = (
      <View
        style={{
          paddingRight: 20,
        }}
      >
        <Right
          icon={buildIcon('solid', COLORS.grayDark, 'plus-square', 30)}
          onPress={() => {
            const onPress = navigation.getParam('onPress', null)
            if (onPress) {
              onPress()
            }
          }}
        />
      </View>
    )
    const target = {
      headerRight: () => right,
      headerTitle: navigation.state.params.title ? navigation.state.params.title : '',
    }
    return buildNavigationOptions(target)
  }

  state = {
    checkboxes: {},
    statuses: {},
    isVisible: false,
  }

  componentDidMount = () => {
    this.props.navigation.setParams({
      onPress: () => {
        this.props.navigation.navigate('TicketsMaterialsAddForm', {
          id: this.props.navigation.getParam('id', ''),
        })
      },
    })
    const data = {
      ticket: {
        id: this.props.navigation.getParam('id', ''),
      },
    }
    this.props.dispatch(selectRequest(data))
    this.props.dispatch(selectRequestMaterials())
    if (this.props.tickets.data) {
      const ticket = _.find(this.props.tickets.data.data, this.predicate)
      if (ticket) {
        this.props.navigation.setParams({
          title: `TICKET ${ticket.number}`,
        })
      }
    }
  }

  handleDelete = () => {
    Promise.all([
      _.map(
        _.filter(this.props.ticketsMaterials.data, (item) => this.state.checkboxes[item.id]),
        (log) => {
          const values = {
            id: log.id,
            ticket: {
              id: this.props.navigation.getParam('id', ''),
            },
          }
          return delet_(values, this.props.dispatch)
        },
      ),
    ]).then(() => {
      const data = {
        status: 'wip',
        type: 'ticket',
      }
      this.props.dispatch(selectRequestTickets(data))
      this.props.navigation.navigate('TicketsDetail', {
        id: this.props.navigation.getParam('id', ''),
      })
    })
  }

  render = () => {
    if (!this.props.tickets.data) {
      return null
    }
    this.ticket = _.find(this.props.tickets.data.data, this.predicate)
    if (!this.ticket) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Ticket</Notice>
        </ScrollView>
      )
    }
    if (this.props.ticketsMaterials.loading) {
      return this.renderLoading()
    }
    if (this.props.ticketsMaterials.data !== null) {
      return this.renderData()
    }
    if (this.props.ticketsMaterials.exception !== null) {
      return this.renderException()
    }
    return null
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderData = () => {
    if (!this.props.ticketsMaterials.data.length) {
      const styles = EStyleSheet.create({
        scrollView: {
          flexGrow: 1,
          justifyContent: 'center',
          padding: '20rem',
        },
      })
      return (
        <ScrollView contentContainerStyle={styles.scrollView}>
          <Notice>No materials and equipment for this ticket.</Notice>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('TicketsMaterialsAddForm', {
                id: this.props.navigation.getParam('id', ''),
              })
            }}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <View>
              <Button primary small>
                Add
              </Button>
            </View>
          </TouchableOpacity>
        </ScrollView>
      )
    }
    const styles = EStyleSheet.create({
      scrollView: {},
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        {this.renderDates()}
        <MaterialsC
          logs={this.buildItems()}
          isVisible={this.state.isVisible}
          onPressCancel={() => {
            this.setState({ checkboxes: {}, isVisible: false })
          }}
          onPressConfirm={(logs) => {
            Promise.all([
              _.map(logs, (log) => {
                const values = {
                  ...log,
                  ticket: {
                    id: this.props.navigation.getParam('id', ''),
                  },
                }
                return update(values, this.props.dispatch)
              }),
            ]).then(() => {
              const data = {
                status: 'wip',
                type: 'ticket',
              }
              this.props.dispatch(selectRequestTickets(data))
              this.props.navigation.navigate('TicketsDetail', {
                id: this.props.navigation.getParam('id', ''),
              })
            })
            this.setState({ checkboxes: {}, isVisible: false })
          }}
        />
      </ScrollView>
    )
  }

  buildItems = () => {
    const filter = _.filter(this.props.ticketsMaterials.data, (item) => this.state.checkboxes[item.id])
    const sortBy = _.sortBy(filter, (item) => `${item.data.category.name}-${item.data.material.name}`)
    return sortBy
  }

  renderDates = () => {
    const groupBy = _.groupBy(this.props.ticketsMaterials.data, (item) => item.created_at)
    const pairs = _.toPairs(groupBy)
    const sortBy = _.sortBy(pairs, (pair) => pair[0])
    return sortBy.map((pair) => {
      return (
        <View key={pair[0]} style={{}}>
          <View
            style={{
              backgroundColor: COLORS.grayLight,
              marginBottom: 1,
              padding: 10,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.setState((prevState) => {
                  return {
                    statuses: {
                      ...prevState.statuses,
                      [pair[0]]: !prevState.statuses[pair[0]],
                    },
                  }
                })
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      color: COLORS.grayDark,
                      fontWeight: 'bold',
                    }}
                  >
                    {pair[0]}
                  </Text>
                </View>
                {buildIcon(
                  'solid',
                  COLORS.grayDark,
                  this.state.statuses[pair[0]] === true ? 'chevron-up' : 'chevron-down',
                  20,
                )}
              </View>
            </TouchableOpacity>
          </View>
          {this.renderItems(pair[0], pair[1])}
        </View>
      )
    })
  }

  renderItems = (date, items) => {
    if (this.state.statuses[date] !== true) {
      return null
    }
    const styles = EStyleSheet.create({
      viewItem: {
        flexDirection: 'row',
        padding: '10rem',
        borderBottomColor: COLORS.grayLight,
        borderBottomWidth: '1rem',
      },
      viewLeft: {
        paddingRight: '5rem',
      },
      viewRight: {
        flex: 1,
      },
      viewOne: {
        flexDirection: 'row',
        paddingBottom: '5rem',
      },
      viewName: {
        flex: 1,
      },
      textName: {
        color: COLORS.grayDark,
      },
      textCategory: {
        color: COLORS.grayMedium,
      },
      viewTwo: {
        flexDirection: 'row',
      },
      viewTwoInner: {
        flex: 1,
      },
      textQuantity: {
        color: COLORS.grayDark,
        fontSize: '13rem',
        paddingRight: '10rem',
      },
      textUnit: {
        color: COLORS.grayDark,
        fontSize: '13rem',
        paddingRight: '10rem',
      },
      textSize: {
        color: COLORS.grayDark,
        fontSize: '13rem',
      },
      viewButtons: {
        flexDirection: 'row',
        padding: '10rem',
      },
      viewButtonsEdit: {
        paddingRight: '5rem',
      },
      viewButtonsDelete: {
        paddingLeft: '5rem',
      },
    })
    return (
      <View>
        {_.sortBy(items, (item) => item.data.material.name).map((item) => {
          return (
            <View key={`${item.id}`} style={styles.viewItem}>
              <View style={styles.viewLeft}>
                <Checkbox
                  color={this.state.checkboxes[`${item.id}`] === true ? COLORS.orange : COLORS.grayDark}
                  onValueChange={() => {
                    this.setState((prevState) => {
                      return {
                        checkboxes: {
                          ...prevState.checkboxes,
                          [`${item.id}`]: !prevState.checkboxes[`${item.id}`],
                        },
                      }
                    })
                  }}
                  value={this.state.checkboxes[`${item.id}`] === true}
                />
              </View>
              <View style={styles.viewRight}>
                <View style={styles.viewOne}>
                  <View style={styles.viewName}>
                    <Text style={styles.textName}>{item.data.material.name}</Text>
                  </View>
                  <Text style={styles.textCategory}>{item.data.category.name}</Text>
                </View>
                <View key={`${item.id}-${item.data.quantity}-${item.data.size}`} style={styles.viewTwo}>
                  <Text style={styles.textQuantity}>Quantity: {parseFloat(item.data.quantity).toFixed(2)}</Text>
                  <Text style={styles.textUnit}>Unit: {item.data.material.order_unit}</Text>
                  <Text style={styles.textSize}>Size: {parseFloat(item.data.size).toFixed(2)}</Text>
                </View>
              </View>
            </View>
          )
        })}
        <View style={styles.viewButtons}>
          <View style={styles.viewButtonsEdit}>
            <TouchableOpacity
              onPress={() => {
                this.setState({ isVisible: true })
              }}
            >
              <Button edit>Edit</Button>
            </TouchableOpacity>
          </View>
          <View style={styles.viewButtonsDelete}>
            <TouchableOpacity
              onPress={() => {
                if (Platform.OS === 'web') {
                  if (window.confirm('Are you sure you want to delete the selected items?')) {
                    this.handleDelete()
                  }
                } else {
                  Alert.alert('Delete', 'Are you sure you want to delete the selected items?', [
                    {
                      text: 'No',
                      style: 'cancel',
                    },
                    {
                      text: 'Yes',
                      onPress: () => {
                        this.handleDelete()
                      },
                    },
                  ])
                }
              }}
            >
              <Button delete>Delete</Button>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  renderException = (exception) => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Notice failure>{buildError(this.props.ticketsMaterials.exception)}</Notice>
      </ScrollView>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsMaterialsList = connect(mapStateToProps, mapDispatchToProps)(TicketsMaterialsList)

export { TicketsMaterialsList }
