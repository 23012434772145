import { format } from 'date-fns'
import Checkbox from 'expo-checkbox'
import _ from 'lodash'
import React from 'react'
import { ActivityIndicator, KeyboardAvoidingView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { change, formValueSelector, Field, reduxForm, reset, SubmissionError } from 'redux-form'

import { selectRequest } from '../actions/materials'
import { insert } from '../actions/tickets-materials'
import { Button } from '../components/button'
import { DateTimePicker } from '../components/date-time-picker'
import { MaterialsA } from '../components/materials-a'
import { MaterialsAdd } from '../components/materials-add'
import { MaterialsB } from '../components/materials-b'
import { Notice } from '../components/notice'
import { Spinner } from '../components/spinner'
import { COLORS } from '../constants/colors'
import { buildError, buildIcon, buildNavigationOptions } from '../modules/builders'

const selector = formValueSelector('tickets-materials-add')
const today = format(new Date(), 'MM/dd/yyyy')

const initialValues = {
  date: today,
}

const validate = (values) => {
  const errors = {}
  if (!values.date) {
    errors.date = 'Invalid Date'
  }
  return errors
}

class TicketsMaterialsAddForm extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: navigation.state.params.title ? navigation.state.params.title : '',
    }
    return buildNavigationOptions(target)
  }

  state = {
    logsA: [],
    logsB: [],
    isAVisible: false,
    isBVisible: false,
    isAddVisible: false,
  }

  componentDidMount = () => {
    const today = format(new Date(), 'MM/dd/yyyy')

    this.props.dispatch(selectRequest())
    this.props.dispatch(change('tickets-materials-add', 'date', today))
    if (this.props.tickets.data) {
      const ticket = _.find(this.props.tickets.data.data, this.predicate)
      if (ticket) {
        this.props.navigation.setParams({
          title: `TICKET ${ticket.number}`,
        })
      }
    }
  }

  render = () => {
    if (!this.props.tickets.data) {
      return null
    }
    this.ticket = _.find(this.props.tickets.data.data, this.predicate)
    if (!this.ticket) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Ticket</Notice>
        </ScrollView>
      )
    }
    if (!this.state.isAddVisible) {
      if (this.props.materials.loading) {
        return this.renderLoading()
      }
    }
    if (this.props.materials.data !== null) {
      return this.renderData()
    }
    if (this.props.materials.exception !== null) {
      return this.renderException()
    }
    return null
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderData = () => {
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <Spinner visible={this.props.ticketsMaterials.loading} />
        <ScrollView
          style={{
            flexGrow: 1,
          }}
        >
          {this.renderError()}
          {this.renderDate()}
          {this.renderButtons1()}
          {this.renderItems()}
        </ScrollView>
        {this.renderButtons2()}
        {this.renderButtons3()}
        <MaterialsA
          date={selector(this.props, 'date')}
          materials={this.props.materials.data.data}
          isVisible={this.state.isAVisible}
          onPressCancel={() => {
            this.setState({ isAVisible: false })
          }}
          onPressConfirm={(logs) => {
            if (logs.length) {
              this.setState({ logsA: logs })
              this.setState({ isAVisible: false })
              this.setState({ isBVisible: true })
            }
          }}
        />
        <MaterialsB
          logs={this.state.logsA}
          isVisible={this.state.isBVisible}
          onPressCancel={() => {
            this.setState({ isBVisible: false })
          }}
          onPressConfirm={(logs) => {
            const union = _.unionBy(logs, this.state.logsB, (log) => `${log.category.name}-${log.material.id}`)
            if (union.length) {
              this.setState({ logsB: union })
              this.setState({ isBVisible: false })
            }
          }}
        />
        <MaterialsAdd
          isVisible={this.state.isAddVisible}
          onSuccess={(logs) => {
            const union = _.unionBy(logs, this.state.logsB, (log) => `${log.category.name}-${log.material.id}`)
            if (union.length) {
              this.setState({ logsB: union })
            }
          }}
          onPressClose={() => {
            this.setState({ isAddVisible: false })
          }}
        />
      </KeyboardAvoidingView>
    )
  }

  renderException = (exception) => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Notice failure>{buildError(this.props.materials.exception)}</Notice>
      </ScrollView>
    )
  }

  renderItems = () => {
    if (!this.state.logsB.length) {
      return null
    }
    const sortBy = _.sortBy(this.state.logsB, (log) => `${log.category.name}-${log.material.name}`)
    const groupBy = _.groupBy(sortBy, (item) => item.category.name)
    const pairs = _.toPairs(groupBy)
    const logs = _.sortBy(pairs, (pair) => pair[0])
    return _.map(logs, (value, key) => {
      return (
        <View key={value[0]}>
          <View
            style={{
              backgroundColor: COLORS.grayLight,
              marginBottom: 1,
              padding: 10,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  {value[0]}
                </Text>
              </View>
            </View>
          </View>
          {this.renderChildren(value[1])}
        </View>
      )
    })
  }

  renderChildren = (items) => {
    const styles = EStyleSheet.create({
      view: {
        borderBottomColor: COLORS.grayLight,
        borderBottomWidth: 1,
        flexDirection: 'row',
        padding: '10rem',
      },
      viewLeft: {
        paddingRight: '5rem',
      },
      viewMiddle: {
        flex: 1,
      },
      viewRight: {
        paddingLeft: '5rem',
      },
      viewOne: {
        flexDirection: 'row',
        paddingBottom: '5rem',
      },
      viewCategory: {
        flex: 1,
      },
      textCategory: {
        color: COLORS.grayDark,
        fontWeight: 'bold',
      },
      viewDelete: {
        paddingTop: '5rem',
      },
      viewTwo: {
        flexDirection: 'row',
      },
      textQuantity: {
        color: COLORS.grayDark,
        fontSize: '13rem',
        paddingRight: '10rem',
      },
      textUnit: {
        color: COLORS.grayDark,
        fontSize: '13rem',
        paddingRight: '10rem',
      },
      textSize: {
        color: COLORS.grayDark,
        fontSize: '13rem',
      },
      viewButtons: {
        flexDirection: 'row',
        padding: '10rem',
      },
      viewButtonsEdit: {
        paddingRight: '5rem',
      },
      viewButtonsDelete: {
        paddingLeft: '5rem',
      },
      viewButtonsGetAuthorization: {
        padding: '10rem',
        paddingTop: '0rem',
      },
    })
    return (
      <View>
        {items.map((item) => {
          return (
            <View key={item.material.id} style={styles.view}>
              <View style={styles.viewLeft}>
                <Checkbox
                  color={item.checked === true ? COLORS.orange : COLORS.grayDark}
                  onValueChange={() => {
                    this.setState((prevState) => {
                      return {
                        logsB: prevState.logsB.map((log) => {
                          if (log.category.name !== item.category.name) {
                            return log
                          }
                          if (log.material.id !== item.material.id) {
                            return log
                          }
                          log.checked = !log.checked
                          return log
                        }),
                      }
                    })
                  }}
                  value={item.checked === true}
                />
              </View>
              <View style={styles.viewMiddle}>
                <View style={styles.viewOne}>
                  <View style={styles.viewCategory}>
                    <Text style={styles.textCategory}>{item.material.name}</Text>
                  </View>
                </View>
                {item.entries.map((v, k) => {
                  return (
                    <View key={k} style={styles.viewTwo}>
                      <Text style={styles.textQuantity}>Quantity: {v.quantity}</Text>
                      <Text style={styles.textUnit}>Unit: {item.material.order_unit}</Text>
                      <Text style={styles.textSize}>Size: {v.size}</Text>
                    </View>
                  )
                })}
              </View>
              <View style={styles.viewRight}>
                <TouchableOpacity
                  onPress={() => {
                    this.setState((prevState) => {
                      return {
                        logsB: prevState.logsB.filter((log) => {
                          if (log.category.name !== item.category.name) {
                            return true
                          }
                          if (log.material.id !== item.material.id) {
                            return true
                          }
                          return false
                        }),
                      }
                    })
                  }}
                >
                  <View style={styles.viewDelete}>{buildIcon('solid', COLORS.redDark, 'times-circle', 30)}</View>
                </TouchableOpacity>
              </View>
            </View>
          )
        })}
      </View>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }

  handleSubmit = () => {
    const logs = this.state.logsB.map((log) => {
      delete log.checked
      return log
    })
    const values = {
      ticket: this.ticket,
      type: 'material',
      date: selector(this.props, 'date'),
      data: this.buildData(logs),
      requires_auth: false,
    }
    const success = () => {
      this.setState({
        logsA: [],
        logsB: [],
        isAVisible: false,
        isBVisible: false,
      })
      this.props.dispatch(reset('tickets-materials-add'))
      this.props.navigation.navigate('TicketsMaterialsAddSuccess', {
        id: this.props.navigation.getParam('id', ''),
      })
    }
    const failure = (data) => {
      throw new SubmissionError({
        _error: buildError(data),
      })
    }
    return insert(values, this.props.dispatch).then(success).catch(failure)
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginLeft: '20rem',
        marginRight: '20rem',
        marginTop: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  renderDate = () => {
    if (this.state.logsB.length === 0) {
      return (
        <View
          style={{
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
          }}
        >
          <View
            style={{
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                color: COLORS.grayDark,
                fontSize: 15,
                fontWeight: 'bold',
                lineHeight: 20,
              }}
            >
              Date
            </Text>
          </View>
          <Field component={DateTimePicker} mode="date" name="date" type="text" />
        </View>
      )
    }
    return null
  }

  renderButtons1 = () => {
    if (this.state.logsB.length === 0) {
      return (
        <View
          style={{
            flexDirection: 'row',
            padding: 20,
            paddingTop: 0,
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.setState({ isAVisible: true })
              }}
            >
              <Button secondary>Add</Button>
            </TouchableOpacity>
          </View>
        </View>
      )
    }
    return (
      <View
        style={{
          flexDirection: 'row',
          padding: 20,
          paddingTop: 20,
        }}
      >
        <View
          style={{
            flex: 1,
            paddingRight: 10,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              const logs = this.state.logsB.filter((log) => log.checked)
              if (logs.length) {
                this.setState({
                  logsA: logs,
                  isBVisible: true,
                })
              }
            }}
          >
            <Button secondary>Edit</Button>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flex: 1,
            paddingLeft: 10,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.setState({ isAVisible: true })
            }}
          >
            <Button secondary>Add</Button>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  renderButtons2 = () => {
    return (
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: 20,
        }}
      >
        <View
          style={{
            paddingRight: 20,
          }}
        >
          <Text
            style={{
              color: COLORS.redLight,
            }}
          >
            Can't find materials or equipment?
          </Text>
        </View>
        <View style={{}}>
          <TouchableOpacity
            onPress={() => {
              this.setState({ isAddVisible: true })
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.redLight,
                borderColor: COLORS.redLight,
                borderRadius: 5,
                borderWidth: 1,
                paddingBottom: 3,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 3,
                textAlign: 'center',
              }}
            >
              <Text
                style={{
                  color: COLORS.white,
                }}
              >
                + Add
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  renderButtons3 = () => {
    if (this.state.logsB.length === 0) {
      return null
    }
    return (
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            flex: 1,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 10,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.setState({
                logsA: [],
                logsB: [],
                isAVisible: false,
                isBVisible: false,
              })
              this.props.navigation.goBack()
            }}
          >
            <Button secondary>Cancel</Button>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flex: 1,
            paddingBottom: 20,
            paddingLeft: 10,
            paddingRight: 20,
          }}
        >
          <TouchableOpacity onPress={this.props.handleSubmit(this.handleSubmit)}>
            <Button primary>Submit</Button>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  buildData = (logs) => {
    const data = _.flatten(
      _.map(logs, (log) => {
        return _.map(log.entries, (entry) => {
          return {
            category: log.category,
            material: log.material,
            quantity: entry.quantity,
            size: entry.size,
          }
        })
      }),
    )
    return data
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsMaterialsAddForm = connect(mapStateToProps, mapDispatchToProps)(TicketsMaterialsAddForm)

TicketsMaterialsAddForm = reduxForm({
  form: 'tickets-materials-add',
  initialValues,
  validate,
})(TicketsMaterialsAddForm)

export { TicketsMaterialsAddForm }
