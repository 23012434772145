import React from 'react'
import { View } from 'react-native'
import createSelector from 'react-native-conditional-stylesheet'
import EStyleSheet from 'react-native-extended-stylesheet'

import { COLORS } from '../constants/colors'

const styles = EStyleSheet.create({
  viewOuter: {
    backgroundColor: COLORS.white,
    elevation: '2rem',
    marginBottom: '20rem',
    shadowColor: COLORS.black,
    shadowOffset: {
      height: '2rem',
      width: '2rem',
    },
    shadowOpacity: 0.1,
    shadowRadius: '2rem',
  },
  viewInnerTop: {
    height: '5rem',
  },
  viewInnerTopGrayDark: {
    backgroundColor: COLORS.grayDark,
  },
  viewInnerTopOrange: {
    backgroundColor: COLORS.orange,
  },
  viewInnerBottom: {
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    borderLeftColor: COLORS.grayLight,
    borderLeftWidth: '1rem',
    borderRightColor: COLORS.grayLight,
    borderRightWidth: '1rem',
  },
})

class Card extends React.Component {
  render = () => {
    const selector = createSelector(styles)
    const viewOuter = selector('viewOuter', {})
    const viewInnerTop = selector('viewInnerTop', {
      viewInnerTopGrayDark: this.props.grayDark,
      viewInnerTopOrange: this.props.orange,
    })
    const viewInnerBottom = selector('viewInnerBottom', {})
    return (
      <View elevation={EStyleSheet.value('2rem')} style={viewOuter} {...this.props}>
        <View style={viewInnerTop} />
        <View style={viewInnerBottom}>{this.props.children}</View>
      </View>
    )
  }
}

export { Card }
