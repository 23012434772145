import React from 'react'
import { KeyboardAvoidingView, Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { withNavigationFocus } from 'react-navigation'
import { connect } from 'react-redux'

import { fetchClassifications } from '../api'
import { Button } from '../components/button'
import { Input } from '../components/input'
import { Left } from '../components/left'
import { Right } from '../components/right'
import { Select } from '../components/select'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'

class DailyReportsFormLabor extends React.Component {
  state = {
    classifications: [],
    report: null,
  }

  static navigationOptions = ({ navigation }) => {
    const left = (
      <View
        style={{
          paddingLeft: 20,
        }}
      >
        <Left
          icon={buildIcon('regular', COLORS.grayDark, 'arrow-left', 20)}
          onPress={() => {
            const onPressLeft = navigation.getParam('onPressLeft', null)
            if (onPressLeft) {
              onPressLeft()
            }
          }}
        />
      </View>
    )
    const right =
      navigation.getParam('report').status === 'submitted' ? null : (
        <View
          style={{
            paddingRight: 20,
          }}
        >
          <Right
            icon={buildIcon('solid', COLORS.grayDark, 'plus-square', 30)}
            onPress={() => {
              const onPressRight = navigation.getParam('onPressRight', null)
              if (onPressRight) {
                onPressRight()
              }
            }}
          />
        </View>
      )
    const target = {
      headerLeft: () => left,
      headerRight: () => right,
      headerTitle: 'Labor',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    fetchClassifications().then((response) => {
      this.setState((prevState) => {
        return {
          ...prevState,
          classifications: response.data['data'].map((item) => {
            return {
              key: item.name,
              label: item.name,
              value: item.name,
            }
          }),
        }
      })
    })
    this.component()
  }

  component = () => {
    this.project = this.props.navigation.getParam('project')
    this.buildState()
    this.props.navigation.setParams({
      onPressLeft: () => {
        this.props.navigation.navigate('DailyReportsForm', {
          project: this.project,
          report: this.state.report,
        })
      },
      onPressRight: () => {
        this.handlePlus()
      },
    })
  }

  render = () => {
    if (!this.state.report) {
      return null
    }
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <ScrollView
          style={{
            flexGrow: 1,
            padding: 20,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          {this.state.report.data.labor.rows.map(this.renderItem)}
        </ScrollView>
        {this.state.report.status === 'submitted' ? null : (
          <View
            style={{
              flexDirection: 'row',
              padding: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingRight: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.props.navigation.getParam('report'),
                  })
                }}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.state.report,
                  })
                }}
              >
                <Button primary>Continue</Button>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </KeyboardAvoidingView>
    )
  }

  renderItem = (row, key) => {
    const isLast = this.state.report.data.labor.rows.length === key + 1
    const classification = {
      input: {
        onChange: (value) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  labor: {
                    ...prevState.report.data.labor,
                    rows: prevState.report.data.labor.rows.map((v, k) => {
                      if (k === key) {
                        v.classification.value = value
                      }
                      return v
                    }),
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: row.classification.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    const employees = {
      input: {
        onChange: (value) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  labor: {
                    ...prevState.report.data.labor,
                    rows: prevState.report.data.labor.rows.map((v, k) => {
                      if (k === key) {
                        v.employees.value = value
                      }
                      return v
                    }),
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: '' + row.employees.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    const hours = {
      input: {
        onChange: (value) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  labor: {
                    ...prevState.report.data.labor,
                    rows: prevState.report.data.labor.rows.map((v, k) => {
                      if (k === key) {
                        v.hours.value = value
                      }
                      return v
                    }),
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: '' + row.hours.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    return (
      <View
        key={key}
        style={{
          borderTopColor: COLORS.grayLight,
          borderTopWidth: key === 0 ? 0 : 1,
          marginTop: key === 0 ? 0 : 20,
        }}
      >
        <View
          style={{
            marginTop: 20,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Classification
              </Text>
            </View>
            {this.state.report.status === 'submitted' ? null : (
              <TouchableOpacity
                onPress={() => {
                  this.handleMinus(key)
                }}
              >
                {buildIcon('regular', COLORS.redDark, 'minus-square', 15)}
              </TouchableOpacity>
            )}
          </View>
          {this.state.report.status === 'submitted' ? (
            <Text
              style={{
                color: COLORS.grayMedium,
                fontSize: 15,
                lineHeight: 20,
              }}
            >
              {classification.input.value}
            </Text>
          ) : (
            <Select
              gray
              items={this.state.classifications}
              placeholder={{
                key: '',
                label: 'Select a classification...',
                value: '',
              }}
              {...classification}
            />
          )}
        </View>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            paddingTop: 20,
          }}
        >
          <Text
            allowFontScaling={false}
            style={{
              color: COLORS.grayDark,
              fontSize: 15,
              fontWeight: 'bold',
              marginRight: 10,
              width: 100,
            }}
          >
            Employees
          </Text>
          <View
            style={{
              flex: 1,
            }}
          >
            {this.state.report.status === 'submitted' ? (
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {employees.input.value}
              </Text>
            ) : (
              <Input
                keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
                placeholder="# of Employees"
                {...employees}
              />
            )}
          </View>
        </View>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            paddingTop: 20,
            paddingBottom: isLast ? 120 : 15,
          }}
        >
          <Text
            allowFontScaling={false}
            style={{
              color: COLORS.grayDark,
              fontSize: 15,
              fontWeight: 'bold',
              marginRight: 10,
              width: 100,
            }}
          >
            Hours
          </Text>
          <View
            style={{
              flex: 1,
            }}
          >
            {this.state.report.status === 'submitted' ? (
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {hours.input.value}
              </Text>
            ) : (
              <Input
                disabled={this.state.report.status === 'submitted'}
                keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
                placeholder="# of Hours"
                {...hours}
              />
            )}
          </View>
        </View>
      </View>
    )
  }

  buildState = () => {
    const report = this.props.navigation.getParam('report')
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          report: {
            ...report,
          },
        }
      },
      () => {
        if (this.state.report.data.labor.rows.length === 0) {
          this.handlePlus()
        }
      },
    )
  }

  handlePlus = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...prevState.report,
          data: {
            ...prevState.report.data,
            labor: {
              ...prevState.report.data.labor,
              rows: [
                ...prevState.report.data.labor.rows,
                {
                  classification: {
                    value: '',
                  },
                  employees: {
                    value: '',
                  },
                  hours: {
                    value: '',
                  },
                },
              ],
            },
          },
        },
      }
    })
  }

  handleMinus = (key) => {
    const rows = this.state.report.data.labor.rows
    rows.splice(key, 1)
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...prevState.report,
          data: {
            ...prevState.report.data,
            labor: {
              ...prevState.report.data.labor,
              rows,
            },
          },
        },
      }
    })
  }
}

const mapStateToProps = (props, ownProps) => {
  return {
    ...props,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

DailyReportsFormLabor = connect(mapStateToProps, mapDispatchToProps)(DailyReportsFormLabor)

DailyReportsFormLabor = withNavigationFocus(DailyReportsFormLabor)

export { DailyReportsFormLabor }
