import { createFormAction } from 'redux-form-saga'

const SELECT_REQUEST = 'tickets-materials/select/request'
const SELECT_SUCCESS = 'tickets-materials/select/success'
const SELECT_FAILURE = 'tickets-materials/select/failure'

const INSERT = createFormAction('TICKETS_MATERIALS_INSERT')
const UPDATE = createFormAction('TICKETS_HOURS_UPDATE')
const DELETE = createFormAction('TICKETS_HOURS_DELETE')

export { SELECT_REQUEST, SELECT_SUCCESS, SELECT_FAILURE, INSERT, UPDATE, DELETE }
