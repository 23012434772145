import React from 'react'
import { ActivityIndicator, ScrollView } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'

import { COLORS } from '../constants/colors'

class Bootstrap extends React.Component {
  constructor(props) {
    super(props)
    props.navigation.navigate(props.users.signIn.data !== null ? 'Secondary' : 'Tertiary')
  }

  render = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

Bootstrap = connect(mapStateToProps, mapDispatchToProps)(Bootstrap)

export { Bootstrap }
