import { put, takeLatest } from 'redux-saga/effects'

import { selectSuccess, selectFailure, insert } from '../actions/materials'
import { api } from '../api'
import { SELECT_REQUEST, INSERT } from '../types/materials'

function* select(action) {
  try {
    const response = yield api({
      method: 'GET',
      params: {
        pending: false,
      },
      url: '/materials',
    })
    yield put(selectSuccess(response.data))
  } catch (error) {
    yield put(selectFailure(error.response.data))
  }
}

function* inser_(action) {
  try {
    const response = yield api({
      data: action.payload,
      method: 'POST',
      url: '/materials',
    })
    yield put(insert.success(response.data))
  } catch (error) {
    yield put(insert.failure(error.response.data))
  }
}

function* materials() {
  yield takeLatest(SELECT_REQUEST, select)
  yield takeLatest(INSERT.REQUEST, inser_)
}

export { materials }
