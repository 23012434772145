import { put, takeLatest } from 'redux-saga/effects'

import {
  selectSuccess,
  selectFailure,
  insertSuccess,
  insertFailure,
  deleteSuccess,
  deleteFailure,
} from '../actions/bookmarks'
import { api } from '../api'
import { SELECT_REQUEST, INSERT_REQUEST, DELETE_REQUEST } from '../types/bookmarks'

function* select(action) {
  try {
    const response = yield api({
      method: 'GET',
      params: {},
      url: '/bookmarks',
    })
    yield put(selectSuccess(response.data))
  } catch (error) {
    yield put(selectFailure(error.response.data))
  }
}

function* insert(action) {
  try {
    const response = yield api({
      data: action.payload.data,
      method: 'POST',
      url: '/bookmarks',
    })
    yield put(insertSuccess(response.data))
  } catch (error) {
    yield put(insertFailure(error.response.data))
  }
}

function* delet_(action) {
  try {
    yield api({
      method: 'DELETE',
      url: `/bookmarks/${action.payload.data.id}`,
    })
    yield put(deleteSuccess(action.payload.data))
  } catch (error) {
    yield put(deleteFailure(error.response.data))
  }
}

function* bookmarks() {
  yield takeLatest(SELECT_REQUEST, select)
  yield takeLatest(INSERT_REQUEST, insert)
  yield takeLatest(DELETE_REQUEST, delet_)
}

export { bookmarks }
