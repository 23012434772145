import {
  SELECT_REQUEST,
  SELECT_SUCCESS,
  SELECT_FAILURE,
  SELECT_FILTERS,
  INSERT,
  UPDATE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
} from '../types/tickets'
import { SIGN_OUT } from '../types/users'

const initialState = {
  filters: {
    project: '',
    description: '',
    createdBy: '',
    awaitingSignature: false,
  },
  data: null,
  exception: null,
  loading: false,
}

const tickets = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case SELECT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        exception: null,
        loading: false,
      }
    case SELECT_FAILURE:
      return {
        ...state,
        data: null,
        exception: action.payload.exception,
        loading: false,
      }
    case SELECT_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.value,
        },
      }
    case INSERT.REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case INSERT.SUCCESS:
      return {
        ...state,
        exception: null,
        loading: false,
      }
    case INSERT.FAILURE:
      return {
        ...state,
        exception: action.payload.exception,
        loading: false,
      }
    case UPDATE.REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case UPDATE.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.map((ticket) => {
            if (action.payload.id === ticket.id) {
              ticket.pricing.notes = action.payload.pricing.notes
            }
            return ticket
          }),
        },
        exception: null,
        loading: false,
      }
    case UPDATE.FAILURE:
      return {
        ...state,
        exception: action.payload.exception,
        loading: false,
      }
    case UPDATE_REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case UPDATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.map((ticket) => {
            if (action.payload.data.id === ticket.id) {
              return action.payload.data
            }
            return ticket
          }),
        },
        exception: null,
        loading: false,
      }
    case UPDATE_FAILURE:
      return {
        ...state,
        exception: action.payload.exception,
        loading: false,
      }
    case SIGN_OUT:
      return initialState
    default:
      return state
  }
}

export { tickets }
