import {
  SELECT_REQUEST,
  SELECT_SUCCESS,
  SELECT_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
} from '../types/projects'

const selectRequest = (data) => {
  return {
    payload: {
      data,
    },
    type: SELECT_REQUEST,
  }
}

const selectSuccess = (data) => {
  return {
    payload: {
      data,
    },
    type: SELECT_SUCCESS,
  }
}

const selectFailure = (exception) => {
  return {
    payload: {
      exception,
    },
    type: SELECT_FAILURE,
  }
}

const updateRequest = (data) => {
  return {
    payload: {
      data,
    },
    type: UPDATE_REQUEST,
  }
}

const updateSuccess = (data) => {
  return {
    payload: {
      data,
    },
    type: UPDATE_SUCCESS,
  }
}

const updateFailure = (exception) => {
  return {
    payload: {
      exception,
    },
    type: UPDATE_FAILURE,
  }
}

export { selectRequest, selectSuccess, selectFailure, updateRequest, updateSuccess, updateFailure }
