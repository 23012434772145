import React from 'react'
import { Text, TouchableOpacity, ScrollView, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'

import { Button } from '../components/button'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'

const styles = EStyleSheet.create({
  scrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    padding: '20rem',
  },
  viewSuccess: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '20rem',
  },
  textSuccess: {
    backgroundColor: COLORS.white,
    color: COLORS.greenDark,
    fontSize: '20rem',
    fontWeight: 'bold',
    lineHeight: '20rem',
  },
  viewIcon: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '20rem',
  },
  viewMessage: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '20rem',
  },
  textMessage: {
    backgroundColor: COLORS.white,
    color: COLORS.grayDark,
    fontSize: '15rem',
    lineHeight: '20rem',
    textAlign: 'center',
  },
  viewButton: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

class DailyReportsSuccess extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerShown: false,
    }
    return buildNavigationOptions(target)
  }

  render = () => {
    const status = this.props.navigation.getParam('status', 'draft')
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <View style={styles.viewIcon}>
          {buildIcon('solid', COLORS.greenDark, status === 'draft' ? 'check' : 'envelope', 30)}
        </View>
        <View style={styles.viewSuccess}>
          <Text style={styles.textSuccess}>Success</Text>
        </View>
        <View style={styles.viewMessage}>
          <Text style={styles.textMessage}>
            {status === 'draft'
              ? 'Your daily report has been saved.'
              : 'Your daily report has been submitted and sent.'}
          </Text>
        </View>
        <View style={styles.viewButton}>
          <TouchableOpacity onPress={this.handlePress}>
            <Button primary>OK</Button>
          </TouchableOpacity>
        </View>
      </ScrollView>
    )
  }

  handlePress = () => {
    this.props.navigation.navigate('DailyReportsList', {
      project: this.props.navigation.getParam('project', ''),
    })
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

DailyReportsSuccess = connect(mapStateToProps, mapDispatchToProps)(DailyReportsSuccess)

export { DailyReportsSuccess }
