import _ from 'lodash'
import React from 'react'
import {
  ActivityIndicator,
  Alert,
  Image,
  Platform,
  RefreshControl,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { RefreshControl as RefreshControlWeb } from 'react-native-web-refresh-control'
import { connect } from 'react-redux'

import { selectRequest, deleteRequest } from '../actions/bookmarks'
import { selectRequest as ticketsSelectRequest } from '../actions/tickets'
import { AddToTicket } from '../components/add-to-ticket'
import { Card } from '../components/card'
import { Line } from '../components/line'
import { Notice } from '../components/notice'
import { COLORS } from '../constants/colors'
import { buildError, buildIcon, buildNavigationOptions, buildPhoto } from '../modules/builders'
import { isAuthorizedCOR } from '../modules/helpers'

class Bookmarks extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: 'Bookmarks',
    }
    return buildNavigationOptions(target)
  }

  state = {
    ticket: null,
    isVisible: false,
  }

  componentDidMount = () => {
    this.props.dispatch(selectRequest())
    this.props.dispatch(
      ticketsSelectRequest({
        status: 'wip',
        type: 'ticket',
      }),
    )
  }

  render = () => {
    if (this.props.bookmarks.data !== null) {
      return this.renderData()
    }
    if (this.props.bookmarks.loading) {
      return this.renderLoading()
    }
    if (this.props.bookmarks.exception !== null) {
      return this.renderException()
    }
    return null
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderData = () => {
    if (!this.props.bookmarks.data.data.length) {
      const styles = EStyleSheet.create({
        scrollView: {
          flexGrow: 1,
          justifyContent: 'center',
          padding: '20rem',
        },
      })
      return (
        <ScrollView
          contentContainerStyle={styles.scrollView}
          refreshControl={
            Platform.OS === 'web' ? (
              <RefreshControlWeb refreshing={this.props.bookmarks.loading} onRefresh={this.handleRefresh} />
            ) : (
              <RefreshControl refreshing={this.props.bookmarks.loading} onRefresh={this.handleRefresh} />
            )
          }
        >
          <Notice>No tickets bookmarked.</Notice>
        </ScrollView>
      )
    }

    const styles = EStyleSheet.create({
      scrollView: {
        backgroundColor: COLORS.white,
        paddingBottom: '0rem',
        paddingLeft: '20rem',
        paddingRight: '20rem',
        paddingTop: '20rem',
      },
    })
    return (
      <ScrollView
        contentContainerStyle={styles.scrollView}
        refreshControl={
          Platform.OS === 'web' ? (
            <RefreshControlWeb refreshing={this.props.bookmarks.loading} onRefresh={this.handleRefresh} />
          ) : (
            <RefreshControl refreshing={this.props.bookmarks.loading} onRefresh={this.handleRefresh} />
          )
        }
      >
        {this.renderItems()}
      </ScrollView>
    )
  }

  renderException = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Notice failure>{buildError(this.props.bookmarks.exception)}</Notice>
      </ScrollView>
    )
  }

  renderItems = () => {
    const groupBy = _.groupBy(
      this.props.bookmarks.data.data,
      (item) => `${item.cor.project.number} ${item.cor.project.name}`,
    )
    const pairs = _.toPairs(groupBy)
    const sortBy = _.sortBy(pairs, (pair) => pair[0])
    return (
      <View>
        <AddToTicket
          isVisible={this.state.isVisible}
          onPressClose={() => this.setState({ isVisible: false })}
          onPressHours={() => {
            this.setState({ isVisible: false })
            this.props.navigation.navigate('TicketsHoursAddForm', {
              id: this.state.ticket.id,
            })
          }}
          onPressMaterials={() => {
            this.setState({ isVisible: false })
            this.props.navigation.navigate('TicketsMaterialsAddForm', {
              id: this.state.ticket.id,
            })
          }}
          onPressPhotos={() => {
            this.setState({ isVisible: false })
            this.props.navigation.navigate('TicketsPhotos', {
              id: this.state.ticket.id,
            })
          }}
          ticket={this.state.ticket}
        />
        {sortBy.map((value, key) => this.renderSection(value, key))}
      </View>
    )
  }

  renderSection = (value, key) => {
    return (
      <View key={key}>
        {this.renderTitle(value)}
        {value[1].map((value, key) => this.renderItem(value, key))}
      </View>
    )
  }

  renderTitle = (value) => {
    const styles = EStyleSheet.create({
      view: {
        marginBottom: '20rem',
      },
      text: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '18rem',
        lineHeight: '24rem',
      },
    })
    return (
      <View key={value[0]}>
        <View style={styles.view}>
          <Text style={styles.text}>{value[0]}</Text>
        </View>
      </View>
    )
  }

  renderItem = (value, key) => {
    const styles = EStyleSheet.create({
      viewGrid: {
        flexDirection: 'row',
        padding: '10rem',
      },
      viewImage: {
        height: '50rem',
        width: '50rem',
        borderRadius: '5rem',
      },
      viewRow: {
        flex: 1,
        paddingLeft: '10rem',
        paddingRight: '10rem',
      },
      viewColumn: {
        flexDirection: 'row',
        paddingBottom: '2.5rem',
      },
      textTicket: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '15rem',
        lineHeight: '15rem',
        paddingRight: '10rem',
      },
      textNumber: {
        backgroundColor: COLORS.white,
        color: COLORS.redLight,
        fontSize: '15rem',
        lineHeight: '15rem',
        paddingRight: '10rem',
      },
      textScope: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '12rem',
        lineHeight: '18rem',
      },
      viewButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      viewButtonLeft: {
        flex: 1,
        paddingBottom: '10rem',
        paddingLeft: '10rem',
        paddingRight: '10rem',
        paddingTop: '10rem',
      },
      viewButtonRight: {
        borderLeftColor: COLORS.grayLight,
        borderLeftWidth: '1rem',
        flex: 1,
        paddingBottom: '10rem',
        paddingLeft: '10rem',
        paddingRight: '10rem',
        paddingTop: '10rem',
      },
      textButton: {
        backgroundColor: COLORS.white,
        color: COLORS.grayDark,
        fontSize: '12rem',
        fontWeight: 'bold',
        lineHeight: '18rem',
        textAlign: 'center',
      },
    })
    return (
      <Card orange key={key}>
        <View style={styles.viewGrid}>
          <Image
            resizeMode="cover"
            source={{
              uri: value.cor.photos && value.cor.photos.length ? value.cor.photos[0] : buildPhoto(),
            }}
            style={styles.viewImage}
          />
          <View style={styles.viewRow}>
            <View style={styles.viewColumn}>
              <Text style={styles.textTicket}>TICKET</Text>
              <Text style={styles.textNumber}>{value.cor.number}</Text>
              {!isAuthorizedCOR(value)
                ? buildIcon('solid', COLORS.redDark, 'exclamation-triangle', EStyleSheet.value('12rem'))
                : null}
            </View>
            <View style={styles.viewColumn}>
              <Text numberOfLines={2} style={styles.textScope}>
                {value.cor && value.cor.pricing && value.cor.pricing.scope ? value.cor.pricing.scope : 'N/A'}
              </Text>
            </View>
          </View>
          <TouchableOpacity
            onPress={() => {
              if (Platform.OS === 'web') {
                if (window.confirm('Are you sure you want to delete this bookmark?')) {
                  this.props.dispatch(deleteRequest({ id: value.id }))
                }
              } else {
                Alert.alert('Delete', 'Are you sure you want to delete this bookmark?', [
                  { text: 'No', style: 'cancel' },
                  {
                    text: 'Yes',
                    onPress: () => {
                      this.props.dispatch(deleteRequest({ id: value.id }))
                    },
                  },
                ])
              }
            }}
          >
            {buildIcon('solid', COLORS.orange, 'bookmark', EStyleSheet.value('20rem'))}
          </TouchableOpacity>
        </View>
        <Line />
        <View style={styles.viewButtons}>
          <View style={styles.viewButtonLeft}>
            <TouchableOpacity
              onPress={() =>
                this.props.navigation.navigate('TicketsDetail', {
                  id: value.cor.id,
                })
              }
            >
              <Text style={styles.textButton}>DETAIL</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.viewButtonRight}>
            <TouchableOpacity
              onPress={() => {
                this.setState({
                  ticket: value.cor,
                })
                this.setState({
                  isVisible: true,
                })
              }}
            >
              <Text style={styles.textButton}>ADD</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Card>
    )
  }

  handleRefresh = () => {
    this.props.dispatch(selectRequest())
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

Bookmarks = connect(mapStateToProps, mapDispatchToProps)(Bookmarks)

export { Bookmarks }
