import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import { Modal } from '../components/modal'
import { COLORS } from '../constants/colors'
import { buildIcon } from '../modules/builders'

const styles = EStyleSheet.create({
  viewcontainer: {
    backgroundColor: COLORS.white,
    borderRadius: '5rem',
  },
  viewTitleOuter: {
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    flexDirection: 'row',
    padding: '15rem',
  },
  viewTitleInner: {
    flex: 1,
  },
  textTitle: {
    color: COLORS.grayDark,
    fontSize: '18rem',
    fontWeight: 'bold',
  },
  viewCamera: {
    alignItems: 'center',
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    flexDirection: 'row',
    padding: '15rem',
  },
  viewCameraLeft: {
    paddingRight: '15rem',
  },
  viewCameraRight: {
    flexGrow: 1,
  },
  textCamera: {
    color: COLORS.grayDark,
    fontSize: '15rem',
  },
  viewCameraRoll: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: '15rem',
  },
  viewCameraRollLeft: {
    paddingRight: '15rem',
  },
  viewCameraRollRight: {
    flexGrow: 1,
  },
  textCameraRoll: {
    color: COLORS.grayDark,
    fontSize: '15rem',
  },
})

class Photos extends React.Component {
  render = () => {
    return (
      <Modal isVisible={this.props.isVisible}>
        <View style={styles.viewcontainer}>
          <View style={styles.viewTitleOuter}>
            <View style={styles.viewTitleInner}>
              <Text style={styles.textTitle}>Photos</Text>
            </View>
            <TouchableOpacity onPress={this.props.onPressClose}>
              {buildIcon('regular', COLORS.grayDark, 'times-circle', EStyleSheet.value(20))}
            </TouchableOpacity>
          </View>
          <TouchableOpacity onPress={this.props.onPressCamera}>
            <View style={styles.viewCamera}>
              <View style={styles.viewCameraLeft}>{buildIcon('solid', COLORS.grayDark, 'camera-alt', 20)}</View>
              <View style={styles.viewCameraRight}>
                <Text style={styles.textCamera}>Camera</Text>
              </View>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={this.props.onPressCameraRoll}>
            <View style={styles.viewCameraRoll}>
              <View style={styles.viewCameraRollLeft}>{buildIcon('solid', COLORS.grayDark, 'image', 20)}</View>
              <View style={styles.viewCameraRollRight}>
                <Text style={styles.textMaterviewCameraRoll}>Camera Roll</Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </Modal>
    )
  }
}

export { Photos }
