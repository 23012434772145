import { parse } from 'date-fns'
import _ from 'lodash'
import React from 'react'
import { ActivityIndicator, Platform, RefreshControl, ScrollView, Text, View, TouchableOpacity } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { RefreshControl as RefreshControlWeb } from 'react-native-web-refresh-control'
import { withNavigationFocus } from 'react-navigation'
import { connect } from 'react-redux'

import { selectRequest } from '../actions/daily-reports'
import { Notice } from '../components/notice'
import { Right } from '../components/right'
import { COLORS } from '../constants/colors'
import { buildError, buildIcon, buildNavigationOptions } from '../modules/builders'

const styles = EStyleSheet.create({
  navigationOptionsRight: {
    paddingRight: '20rem',
  },
  loadingScrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    padding: '20rem',
  },
  exceptionScrollView: {
    flexGrow: 1,
    justifyContent: 'center',
    padding: '20rem',
  },
  dataScrollView: {
    backgroundColor: COLORS.grayUltraLight,
    flexGrow: 1,
    padding: '20rem',
  },
  dataOneView: {
    flexDirection: 'row',
    paddingBottom: '10rem',
  },
  dataOneTextProject: {
    color: COLORS.grayDark,
    fontSize: '15rem',
    paddingRight: '10rem',
  },
  dataOneTextNumber: {
    color: COLORS.redLight,
    fontWeight: 'bold',
    fontSize: '15rem',
  },
  dataTwoView: {
    flexDirection: 'row',
    fontSize: '15rem',
    paddingBottom: '20rem',
  },
  dataTwoText: {
    color: COLORS.grayDark,
    fontWeight: 'bold',
  },
  dataItemView: {
    alignItems: 'center',
    backgroundColor: COLORS.white,
    display: 'flex',
    elevation: 2,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '10rem',
    shadowColor: COLORS.black,
    shadowOffset: {
      height: '2rem',
      width: '2rem',
    },
    shadowOpacity: 0.1,
    shadowRadius: '2rem',
  },
  dataItemViewOuter: {
    alignItems: 'center',
    backgroundColor: COLORS.white,
    display: 'flex',
    elevation: 2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10rem',
    padding: '10rem',
    shadowColor: COLORS.black,
    shadowOffset: {
      height: '2rem',
      width: '2rem',
    },
    shadowOpacity: 0.1,
    shadowRadius: '2rem',
  },
  dataItemViewInnerOne: {
    flexDirection: 'row',
    marginRight: '5rem',
  },
  dataItemText: {
    fontSize: '15rem',
    marginLeft: '10rem',
  },
  dataItemViewInnerTwo: {
    marginLeft: '5rem',
  },
})

class DailyReportsList extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const right = (
      <View style={styles.navigationOptionsRight}>
        <Right
          icon={buildIcon('solid', COLORS.grayDark, 'plus-square', 30)}
          onPress={() => {
            navigation.navigate('DailyReportsForm', {
              project: navigation.getParam('project', ''),
              report: undefined,
            })
          }}
        />
      </View>
    )
    const target = {
      headerRight: () => right,
      headerTitle: 'Daily Reports',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.project = this.props.navigation.getParam('project')
    this.props.dispatch(
      selectRequest({
        project_id: this.project.id,
      }),
    )
  }

  componentDidUpdate(prevProps) {
    this.project = this.props.navigation.getParam('project')
    if (prevProps.isFocused !== this.props.isFocused) {
      this.props.dispatch(
        selectRequest({
          project_id: this.project.id,
        }),
      )
    }
  }

  render = () => {
    if (!this.project) {
      return this.renderLoading()
    }
    if (this.props.dailyReports.loading) {
      return this.renderLoading()
    }
    if (this.props.dailyReports.exception !== null) {
      return this.renderException()
    }
    if (this.props.dailyReports.data !== null) {
      return this.renderData()
    }
    return null
  }

  renderLoading = () => {
    return (
      <ScrollView contentContainerStyle={styles.loadingScrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderException = (exception) => {
    return (
      <ScrollView contentContainerStyle={styles.exceptionScrollView}>
        <Notice failure>{buildError(this.props.dailyReports.exception)}</Notice>
      </ScrollView>
    )
  }

  renderData = () => {
    return (
      <ScrollView
        contentContainerStyle={styles.dataScrollView}
        refreshControl={
          Platform.OS === 'web' ? (
            <RefreshControlWeb refreshing={this.props.dailyReports.loading} onRefresh={this.handleRefresh} />
          ) : (
            <RefreshControl refreshing={this.props.dailyReports.loading} onRefresh={this.handleRefresh} />
          )
        }
      >
        <View style={styles.dataOneView}>
          <Text style={styles.dataOneTextProject}>PROJECT</Text>
          <Text style={styles.dataOneTextNumber}>{this.props.navigation.getParam('project').number}</Text>
        </View>
        <View style={styles.dataTwoView}>
          <Text style={styles.dataTwoText}>{this.project.name}</Text>
        </View>
        {this.renderDailyReports()}
      </ScrollView>
    )
  }

  renderDailyReports = () => {
    if (!this.props.dailyReports.data.data.length) {
      return (
        <View style={styles.dataItemView}>
          <Notice warning>There are no daily reports in this project.</Notice>
        </View>
      )
    }
    const groupBy = _.groupBy(this.props.dailyReports.data.data, (item) => item.status)
    return Object.entries(_(groupBy).toPairs().sortBy(0).fromPairs().value()).map(([key, value]) => {
      return (
        <View key={key}>
          <Text
            style={{
              color: COLORS.grayDark,
              fontSize: 15,
              fontWeight: 'bold',
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            {_.capitalize(key)}
          </Text>
          <ScrollView key={key}>{value.map((report) => this.renderDailyReport(report))}</ScrollView>
        </View>
      )
    })
  }

  renderDailyReport = (report) => {
    return (
      <TouchableOpacity
        key={`report-${report.id}`}
        onPress={() => {
          this.handlePress(report)
        }}
      >
        <View style={styles.dataItemViewOuter}>
          <View style={styles.dataItemViewInnerOne}>
            <Text allowFontScaling={false}>
              {buildIcon('solid', COLORS.grayLight, report.status === 'draft' ? 'check' : 'envelope', 20)}
            </Text>
            <View style={{ flexDirection: 'column' }}>
              <Text allowFontScaling={false} style={styles.dataItemText}>
                {report.date_formatted}
              </Text>
              <Text allowFontScaling={false} style={styles.dataItemText}>
                Created by: {report.created_by.name}
              </Text>
            </View>
          </View>
          <View style={styles.dataItemViewInnerTwo}>
            <Text allowFontScaling={false}>{buildIcon('solid', COLORS.grayDark, 'chevron-right', 20)}</Text>
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  handlePress = (report) => {
    report.date = parse(report.date, 'MM/dd/yyyy', new Date())
    delete report.data.isDirty
    this.props.navigation.navigate('DailyReportsForm', {
      project: this.project,
      report,
    })
  }

  handleRefresh = () => {
    this.props.dispatch(
      selectRequest({
        project_id: this.project.id,
      }),
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

DailyReportsList = connect(mapStateToProps, mapDispatchToProps)(DailyReportsList)

DailyReportsList = withNavigationFocus(DailyReportsList)

export { DailyReportsList }
