import { format } from 'date-fns'
import Checkbox from 'expo-checkbox'
import * as ImageManipulator from 'expo-image-manipulator'
import _ from 'lodash'
import React from 'react'
import { Image, Platform, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm, reset, SubmissionError } from 'redux-form'

import { insert } from '../actions/tickets'
import { Button } from '../components/button'
import { Notice } from '../components/notice'
import Dropdown from '../components/Dropdown'
import { SignatureModal } from '../components/signature-modal'
import { Spinner } from '../components/spinner'
import { TermsAndConditions } from '../components/terms-and-conditions'
import { COLORS } from '../constants/colors'
import { buildError, buildNavigationOptions } from '../modules/builders'

const selector = formValueSelector('tickets-add')

class TicketsAddAuthorization extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerShown: false,
    }
    return buildNavigationOptions(target)
  }

  state = {
    isVisible: false,
    i_agree: false,
    authorize: '',
    scrollEnabled: false,
    signatureModalVisible: false,
    notes: '',
  }

  componentDidMount = () => {
    const project = _.find(
      this.props.projects.data.data,
      (item) => item.id === this.props.navigation.getParam('id', ''),
    )
    if (project) {
      this.props.navigation.setParams({
        title: `PROJECT ${project.number}`,
      })
    }
  }

  saveCanvas = async () => {
    if (!this.state.signatureUri) {
      return null
    }
    return this.state.signatureUri
  }

  openSignatureModal = () => {
    this.setState({ signatureModalVisible: true })
  }

  handleSubmitSignature = (signatureUri) => {
    this.setState({ signatureUri, signatureModalVisible: false })
  }

  renderRequestSignature = (project) => {
    if (project.mobile_request_for_signatures) {
      const recipients = project.team.gc_team.filter((value) => value.authorized_signer).length
      return (
        <View
          style={{
            flexDirection: 'row',
            padding: 20,
            paddingBottom: 0,
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            {recipients ? (
              <TouchableOpacity onPress={() => this.handlePress()}>
                <Button primary>Request Signature</Button>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity disabled>
                <Button primary disabled>
                  Request Signature
                </Button>
              </TouchableOpacity>
            )}
          </View>
        </View>
      )
    } else {
      return null
    }
  }

  rotate90 = async () => {
    const result = await ImageManipulator.manipulateAsync(this.state.signatureUri, [{ rotate: 90 }], {
      base64: true,
      format: ImageManipulator.SaveFormat.PNG,
    })
    this.setState(() => {
      return {
        signatureUri: 'data:image/png;base64,' + result.base64,
      }
    })
  }

  render = () => {
    console.log(this.state)
    if (!this.props.projects.data) {
      return null
    }
    const project = _.find(this.props.projects.data.data, this.predicate)
    if (!project) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Project</Notice>
        </ScrollView>
      )
    }
    const authorizedBys = this.buildAuthorizedBys(project)

    return (
      <View
        style={{
          flex: 1,
          paddingTop: 20,
        }}
      >
        <Spinner visible={this.props.tickets.loading} />
        <ScrollView
          style={{
            flexGrow: 1,
          }}
          scrollEnabled={this.state.scrollEnabled}
        >
          {this.renderError()}
          <View
            style={{
              marginBottom: 5,
              paddingBottom: 20,
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                marginBottom: 10,
              }}
            >
              <View
                style={{
                  marginBottom: 10,
                }}
              >
                <Text style={{ fontWeight: 'bold', fontSize: 18, color: '#555' }}>T&M Authorization</Text>
              </View>
            </View>
            <View
              style={{
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                }}
              >
                Authorized By
              </Text>
            </View>

            <Field component={Dropdown} data={authorizedBys} name="authorizedBy" />
          </View>
          <View
            style={{
              marginBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                Signature
              </Text>
            </View>
            <SignatureModal
              visible={this.state.signatureModalVisible}
              onSubmit={this.handleSubmitSignature}
              onClose={() => this.setState({ signatureModalVisible: false })}
            />

            {this.state.signatureUri ? (
              <View>
                <View
                  style={{
                    backgroundColor: '#eee',
                    borderColor: COLORS.grayLight,
                    borderRadius: 5,
                    borderStyle: 'dashed',
                    borderWidth: 3,
                    height: 150,
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Image
                    source={{
                      uri: this.state.signatureUri,
                    }}
                    style={{
                      position: 'relative',
                      flex: 1,
                      width: '100%',
                      height: 150,
                      resizeMode: 'stretch',
                    }}
                  />
                </View>
                <TouchableOpacity onPress={() => this.setState({ signatureUri: null })}>
                  <Button secondary small>
                    Remove
                  </Button>
                </TouchableOpacity>
                <TouchableOpacity onPress={this.rotate90}>
                  <Button secondary small>
                    Rotate
                  </Button>
                </TouchableOpacity>
              </View>
            ) : (
              <View
                style={{
                  backgroundColor: '#eee',
                  borderColor: COLORS.grayLight,
                  borderRadius: 5,
                  borderStyle: 'dashed',
                  borderWidth: 3,
                  height: 150,
                  padding: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <TouchableOpacity onPress={this.openSignatureModal}>
                  <Text style={{ fontSize: 20, color: '#ccc', fontWeight: '800' }}>Tap to add signature</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
          {project.signature_notes_for_cors ? (
            <View
              style={{
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  GC Notes
                </Text>
              </View>
              <TextInput
                autoGrow
                multiline
                numberOfLines={3}
                onChangeText={(notes) => this.setState({ notes })}
                style={{
                  backgroundColor: COLORS.white,
                  borderColor: COLORS.grayLight,
                  borderRadius: 5,
                  borderWidth: 1,
                  color: COLORS.grayDark,
                  fontSize: 15,
                  lineHeight: 20,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 10,
                }}
                textAlignVertical="top"
                type="textarea"
                value={this.state.notes ? this.state.notes : ''}
              />
            </View>
          ) : null}
          <View
            style={{
              flex: 1,
              marginHorizontal: 20,
            }}
          />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              marginVertical: 15,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                paddingRight: 10,
              }}
            >
              <Checkbox
                color={this.state.i_agree === true ? COLORS.orange : COLORS.grayDark}
                onValueChange={() => {
                  this.setState((prevState) => {
                    return {
                      i_agree: !prevState.i_agree,
                    }
                  })
                }}
                value={this.state.i_agree === true}
              />
            </View>
            <TouchableOpacity onPress={() => this.setState({ isVisible: true })}>
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'normal',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                I agree to the{' '}
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  terms and conditions.
                </Text>
              </Text>
            </TouchableOpacity>
          </View>

          <TermsAndConditions
            body={project.terms.t_and_m || 'N/A'}
            isVisible={this.state.isVisible}
            onPressClose={() => this.setState({ isVisible: false })}
          />
        </ScrollView>
        {this.renderRequestSignature(project)}
        <View
          style={{
            flexDirection: 'row',
            padding: 20,
          }}
        >
          <View
            style={{
              flex: 1,
              paddingRight: 10,
            }}
          >
            <TouchableOpacity
              disabled={
                !selector(this.props, 'authorizedBy') || this.state.i_agree === false || !this.state.signatureUri
              }
              onPress={() => {
                this.setState(
                  {
                    authorize: 'now',
                  },
                  () => this.props.handleSubmit(this.handleSubmit)(),
                )
              }}
            >
              {!selector(this.props, 'authorizedBy') || this.state.i_agree === false || !this.state.signatureUri ? (
                <Button primary disabled>
                  Authorize Now
                </Button>
              ) : (
                <Button primary>Authorize Now</Button>
              )}
            </TouchableOpacity>
          </View>
          <View
            style={{
              flex: 1,
              paddingLeft: 10,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.setState(
                  {
                    authorize: 'later',
                  },
                  () => this.props.handleSubmit(this.handleSubmit)(),
                )
              }}
            >
              <Button primary>Authorize Later</Button>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginLeft: '20rem',
        marginRight: '20rem',
        marginTop: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  handlePress = () => {
    this.props.navigation.navigate('RequestSignature', {
      created: true,
      id: this.props.navigation.getParam('id', ''),
      ticket_id: null,
      type: 'ticket',
    })
  }

  handleSubmit = () => {
    const then = async (signature) => {
      const authorizedBy = selector(this.props, 'authorizedBy')
      const authorization = this.buildAuthorization(this.state.authorize, authorizedBy, signature)
      const values = {
        project_id: this.props.navigation.getParam('id', ''),
        description: selector(this.props, 'description'),
        location: selector(this.props, 'location'),
        scope: selector(this.props, 'scope'),
        notes: selector(this.props, 'notes'),
        fieldwire_tasks: selector(this.props, 'fieldwire_tasks'),
        plan_grid_issues: selector(this.props, 'plan_grid_issues'),
        requires_auth: true,
        reference: selector(this.props, 'reference'),
        authorization,
      }
      const success = (data) => {
        this.props.dispatch(reset('tickets-add'))
        this.props.navigation.navigate('TicketsAddSuccess', {
          id: this.props.navigation.getParam('id', ''),
          data,
        })
      }
      const failure = (data) => {
        throw new SubmissionError({
          _error: buildError(data),
        })
      }
      return insert(values, this.props.dispatch).then(success).catch(failure)
    }
    this.saveCanvas().then(then)
  }

  buildAuthorizedBys = (project) => {
    if (!project.team) {
      return []
    }
    if (!project.team.gc_team) {
      return []
    }
    return project.team.gc_team
      .filter((value) => {
        return value.authorized_signer === true
      })
      .map((value, key) => {
        const v = {
          classifications: value.classifications,
          company: project.contractor.name || '',
          name: value.contact,
        }
        return {
          label: value.contact,
          key,
          value: Platform.OS === 'web' ? JSON.stringify(v) : v,
        }
      })
  }

  buildAuthorization = (authorize, authorizedBy, signature) => {
    const todayStamp = format(new Date(), 'MM/dd/yyyy h:mm a')
    if (authorize === 'now' && authorizedBy && signature) {
      return {
        date: todayStamp,
        authorized_by: Platform.OS === 'web' ? JSON.parse(authorizedBy) : authorizedBy,
        signature_path: signature,
        notes: this.state.notes,
      }
    }
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsAddAuthorization = connect(mapStateToProps, mapDispatchToProps)(TicketsAddAuthorization)

TicketsAddAuthorization = reduxForm({
  destroyOnUnmount: false,
  form: 'tickets-add',
})(TicketsAddAuthorization)

export { TicketsAddAuthorization }
