import _ from 'lodash'
import React from 'react'
import { ActivityIndicator, Alert, Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'

import {
  selectRequest as bookmarksSelectRequest,
  insertRequest as insertRequest_,
  deleteRequest as deleteRequest_,
} from '../actions/bookmarks'
import { selectRequest as selectRequestEmployees } from '../actions/employees'
import { selectRequest, updateRequest } from '../actions/tickets'
import { api } from '../api'
import { AddReference } from '../components/add-reference'
import { AddToTicket } from '../components/add-to-ticket'
import { AdditionalNotes } from '../components/additional-notes'
import { Carousel } from '../components/carousel'
import { FieldwireTasks } from '../components/fieldwire-tasks'
import { Left } from '../components/left'
import { Notice } from '../components/notice'
import { PlanGridIssues } from '../components/plan-grid-issues'
import { Right } from '../components/right'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'
import { canShowFieldwireLabel, canShowPlanGridLabel, findProject, isAuthorizedCOR } from '../modules/helpers'

class TicketsDetail extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const left = (
      <View
        style={{
          paddingLeft: 20,
        }}
      >
        <Left
          icon={buildIcon('regular', COLORS.grayDark, 'arrow-left', 20)}
          onPress={() => {
            const override = navigation.getParam('override', false)
            if (override !== false) {
              navigation.navigate('ProjectsDetail', {
                id: override,
              })
            } else {
              navigation.goBack()
            }
          }}
        />
      </View>
    )
    const right = (
      <View
        style={{
          paddingRight: 20,
        }}
      >
        <Right
          icon={buildIcon('solid', COLORS.grayDark, 'plus-square', 30)}
          onPress={() => {
            const onPress = navigation.getParam('onPress', null)
            if (onPress) {
              onPress()
            }
          }}
        />
      </View>
    )
    const target = {
      headerLeft: () => left,
      headerRight: () => right,
      headerTitle: 'Ticket Detail',
    }
    return buildNavigationOptions(target)
  }

  state = {
    isVisibleAdditionalNotes: false,
    isVisibleAddToTicket: false,
    isVisibleScopeOfWork: false,
    isVisibleFieldwireTasks: false,
    isVisiblePlanGridIssues: false,
    done: 0,
    isVisibleAddReference: false,
  }

  componentDidMount = () => {
    this.props.navigation.setParams({
      onPress: () => {
        this.setState({
          isVisibleAddToTicket: true,
        })
      },
    })

    this.focusListener = this.props.navigation.addListener('didFocus', (payload) => {
      this.fetchTicketDetail()
    })

    this.props.dispatch(selectRequestEmployees())
  }

  fetchTicketDetail = () => {
    const data = {
      status: 'wip',
      type: 'ticket',
    }
    this.props.dispatch(selectRequest(data))
    this.props.dispatch(bookmarksSelectRequest())
  }

  render = () => {
    const { tickets } = this.props

    if (tickets.exception !== null) {
      return this.renderException()
    }

    const ticket =
      tickets.data &&
      tickets.data.data &&
      tickets.data.data.length &&
      tickets.data.data.find((ticket) => ticket.id === this.props.navigation.getParam('id'))
    if (!ticket || (ticket.authorization && !ticket.authorization_created_at)) {
      return this.renderLoading()
    }

    return this.renderItem(ticket)
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderException = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: 'center',
          padding: 20,
        }}
      >
        <Notice failure>Invalid Ticket</Notice>
      </ScrollView>
    )
  }

  renderTotalHours = (ticket) => {
    if (ticket.total_hours !== undefined) {
      return ticket.total_hours
    } else {
      return ticket.total_hours_authorized + ticket.total_hours_unauthorized
    }
  }

  renderTotalMaterials = (ticket) => {
    if (ticket.total_materials !== undefined) {
      return ticket.total_materials
    } else {
      return ticket.total_materials_and_equipment
    }
  }

  renderItem = (ticket) => {
    return (
      <ScrollView
        style={{
          backgroundColor: COLORS.grayUltraLight,
          flex: 1,
        }}
      >
        <Carousel data={ticket.photos} />
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: COLORS.white,
            paddingBottom: 10,
            paddingLeft: 20,
            paddingRight: 10,
            paddingTop: 10,
          }}
        >
          <View
            style={{
              marginRight: 10,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                if (!this.props.bookmarks.data) {
                  return
                }
                const item = _.find(this.props.bookmarks.data.data, (item) => item.cor.id === ticket.id)
                if (!item) {
                  this.props.dispatch(insertRequest_({ cor_id: ticket.id }))
                } else {
                  this.props.dispatch(deleteRequest_({ id: item.id }))
                }
              }}
            >
              {buildIcon(
                'solid',
                this.isBookmarked(ticket.id) ? COLORS.orange : COLORS.grayDark,
                'bookmark',
                EStyleSheet.value('20rem'),
              )}
            </TouchableOpacity>
          </View>
          <View
            style={{
              marginRight: 10,
            }}
          >
            <Text
              style={{
                color: COLORS.grayDark,
              }}
            >
              TICKET
            </Text>
          </View>
          <View>
            <Text
              style={{
                color: COLORS.redLight,
              }}
            >
              #{ticket.number}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              marginRight: 10,
            }}
          >
            {this.renderAuthorization(ticket)}
          </View>
          <View>
            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate('TicketsDetailExpanded', {
                  id: ticket.id,
                  title: ticket.number,
                })
              }}
            >
              {buildIcon('solid', COLORS.grayDark, 'info-circle', 20)}
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'row',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                }}
              >
                Project
              </Text>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                numberOfLines={1}
                style={{
                  alignSelf: 'flex-end',
                  color: COLORS.grayDark,
                }}
              >
                {ticket.project.name}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'row',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                }}
              >
                Date Created
              </Text>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                numberOfLines={1}
                style={{
                  alignSelf: 'flex-end',
                  color: COLORS.grayDark,
                }}
              >
                {ticket.created_at}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'row',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  paddingRight: 10,
                }}
              >
                Scope of Work
              </Text>
              <TouchableOpacity
                onPress={() => {
                  this.setState((prevState) => {
                    return {
                      isVisibleScopeOfWork: !prevState.isVisibleScopeOfWork,
                    }
                  })
                }}
              >
                {buildIcon(this.state.isVisibleScopeOfWork ? 'solid' : 'regular', COLORS.grayDark, 'sticky-note', 20)}
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                numberOfLines={this.state.isVisibleScopeOfWork ? 999 : 1}
                style={{
                  alignSelf: 'flex-end',
                  color: COLORS.grayDark,
                }}
              >
                {ticket.pricing.scope ? ticket.pricing.scope : 'N/A'}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'row',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                }}
              >
                Location
              </Text>
            </View>
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                numberOfLines={1}
                style={{
                  alignSelf: 'flex-end',
                  color: COLORS.grayDark,
                }}
              >
                {ticket.location_tag ? ticket.location_tag : 'N/A'}
              </Text>
            </View>
          </View>
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'row',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  paddingRight: 10,
                }}
              >
                Reference #
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                this.setState({ isVisibleAddReference: true })
              }}
            >
              {buildIcon('regular', COLORS.grayDark, 'pen-square', 20)}
            </TouchableOpacity>
          </View>
          <View
            style={{
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'row',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  paddingRight: 10,
                }}
              >
                Additional Notes
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                this.setState({ isVisibleAdditionalNotes: true })
              }}
            >
              {buildIcon('regular', COLORS.grayDark, 'pen-square', 20)}
            </TouchableOpacity>
          </View>
          {canShowFieldwireLabel(findProject(this.props.projects, ticket.project.id)) ? (
            <View
              style={{
                borderBottomColor: COLORS.grayLight,
                borderBottomWidth: 1,
                flexDirection: 'row',
                paddingBottom: 10,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                  }}
                >
                  Fieldwire Tasks
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  paddingRight: 20,
                }}
              >
                <Text
                  numberOfLines={1}
                  style={{
                    alignSelf: 'flex-end',
                    color: COLORS.grayDark,
                  }}
                >
                  {ticket.fieldwire_tasks ? ticket.fieldwire_tasks.length : 0} Tasks
                </Text>
              </View>
              <TouchableOpacity
                onPress={() => {
                  this.setState({ isVisibleFieldwireTasks: true })
                }}
              >
                {buildIcon('regular', COLORS.grayDark, 'pen-square', 20)}
              </TouchableOpacity>
            </View>
          ) : null}
          {canShowPlanGridLabel(findProject(this.props.projects, ticket.project.id)) ? (
            <View
              style={{
                borderBottomColor: COLORS.grayLight,
                borderBottomWidth: 1,
                flexDirection: 'row',
                paddingBottom: 10,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                  }}
                >
                  PlanGrid Tasks
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  paddingRight: 20,
                }}
              >
                <Text
                  numberOfLines={1}
                  style={{
                    alignSelf: 'flex-end',
                    color: COLORS.grayDark,
                  }}
                >
                  {ticket.plan_grid_issues ? ticket.plan_grid_issues.length : 0} Tasks
                </Text>
              </View>
              <TouchableOpacity
                onPress={() => {
                  this.setState({ isVisiblePlanGridIssues: true })
                }}
              >
                {buildIcon('regular', COLORS.grayDark, 'pen-square', 20)}
              </TouchableOpacity>
            </View>
          ) : null}
          <View
            elevation={2}
            style={{
              backgroundColor: COLORS.white,
              elevation: 2,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 20,
              shadowColor: COLORS.black,
              shadowOffset: {
                height: 2,
                width: 2,
              },
              shadowOpacity: 0.1,
              shadowRadius: 2,
            }}
          >
            <View
              style={{
                borderColor: COLORS.grayLight,
                borderWidth: 1,
              }}
            >
              <View style={{}}>
                <TouchableOpacity
                  onPress={() => {
                    this.props.navigation.navigate('TicketsHoursList', {
                      id: ticket.id,
                    })
                  }}
                >
                  <View
                    style={{
                      alignItems: 'center',
                      borderBottomColor: COLORS.grayLight,
                      borderBottomWidth: 1,
                      flexDirection: 'row',
                      padding: 10,
                    }}
                  >
                    <View
                      style={{
                        paddingRight: 10,
                      }}
                    >
                      {buildIcon('solid', COLORS.grayDark, 'tools', 20)}
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        flexGrow: 1,
                        paddingRight: 10,
                      }}
                    >
                      <Text
                        style={{
                          color: COLORS.grayDark,
                          paddingRight: 10,
                        }}
                      >
                        Total Hours
                      </Text>
                      {ticket.total_hours_unauthorized
                        ? buildIcon('solid', COLORS.redDark, 'exclamation-triangle', 15)
                        : null}
                    </View>
                    <Text
                      style={{
                        color: COLORS.orange,
                      }}
                    >
                      {this.renderTotalHours(ticket)}
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    this.props.navigation.navigate('TicketsMaterialsList', {
                      id: ticket.id,
                    })
                  }}
                >
                  <View
                    style={{
                      alignItems: 'center',
                      borderBottomColor: COLORS.grayLight,
                      borderBottomWidth: 1,
                      flexDirection: 'row',
                      padding: 10,
                    }}
                  >
                    <View
                      style={{
                        paddingRight: 10,
                      }}
                    >
                      {buildIcon('solid', COLORS.grayDark, 'ball-pile', 20)}
                    </View>
                    <View
                      style={{
                        flexGrow: 1,
                        paddingRight: 10,
                      }}
                    >
                      <Text
                        style={{
                          color: COLORS.grayDark,
                        }}
                      >
                        Total Materials and Equipment
                      </Text>
                    </View>
                    <Text
                      style={{
                        color: COLORS.orange,
                      }}
                    >
                      {this.renderTotalMaterials(ticket)}
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    this.props.navigation.navigate('TicketsPhotos', {
                      id: ticket.id,
                    })
                  }}
                >
                  <View
                    style={{
                      alignItems: 'center',
                      flexDirection: 'row',
                      padding: 10,
                    }}
                  >
                    <View
                      style={{
                        paddingRight: 10,
                      }}
                    >
                      {buildIcon('solid', COLORS.grayDark, 'images', 20)}
                    </View>
                    <View
                      style={{
                        flexGrow: 1,
                        paddingRight: 10,
                      }}
                    >
                      <Text
                        style={{
                          color: COLORS.grayDark,
                        }}
                      >
                        Total Photos
                      </Text>
                    </View>
                    <Text
                      style={{
                        color: COLORS.orange,
                      }}
                    >
                      {ticket.photos ? ticket.photos.length : 0}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          {this.renderMarkedForReviewBy(ticket)}
          {!isAuthorizedCOR(ticket) ? (
            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate('TicketsDetailAuthorization', {
                  id: ticket.id,
                })
              }}
            >
              <View
                style={{
                  backgroundColor: COLORS.grayDark,
                  borderRadius: 5,
                  margin: 20,
                  paddingBottom: 15,
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 15,
                }}
              >
                <Text
                  style={{
                    color: COLORS.white,
                    textAlign: 'center',
                  }}
                >
                  Get Ticket Authorization
                </Text>
              </View>
            </TouchableOpacity>
          ) : null}
        </View>
        <AddToTicket
          isVisible={this.state.isVisibleAddToTicket}
          onPressClose={() => this.setState({ isVisibleAddToTicket: false })}
          onPressHours={() => {
            this.setState({ isVisibleAddToTicket: false })
            this.props.navigation.navigate('TicketsHoursAddForm', {
              id: ticket.id,
            })
          }}
          onPressMaterials={() => {
            this.setState({ isVisibleAddToTicket: false })
            this.props.navigation.navigate('TicketsMaterialsAddForm', {
              id: ticket.id,
            })
          }}
          onPressPhotos={() => {
            this.setState({ isVisibleAddToTicket: false })
            this.props.navigation.navigate('TicketsPhotos', {
              id: ticket.id,
            })
          }}
          ticket={ticket}
        />
        <AdditionalNotes
          isVisible={this.state.isVisibleAdditionalNotes}
          onPressClose={() => this.setState({ isVisibleAdditionalNotes: false })}
          ticket={ticket}
        />
        <AddReference
          isVisible={this.state.isVisibleAddReference}
          onPressClose={() => this.setState({ isVisibleAddReference: false })}
          ticket={ticket}
        />
        <FieldwireTasks
          isVisible={this.state.isVisibleFieldwireTasks}
          onPressCancel={() => {
            this.setState({ isVisibleFieldwireTasks: false })
          }}
          onPressConfirm={(fieldwire_tasks) => {
            this.setState({ isVisibleFieldwireTasks: false })
            const values = {
              id: ticket.id,
              fieldwire_tasks,
            }
            this.props.dispatch(updateRequest(values))
          }}
          project={ticket.project}
          value={ticket.fieldwire_tasks}
        />
        <PlanGridIssues
          isVisible={this.state.isVisiblePlanGridIssues}
          onPressCancel={() => {
            this.setState({ isVisiblePlanGridIssues: false })
          }}
          onPressConfirm={(plan_grid_issues) => {
            this.setState({ isVisiblePlanGridIssues: false })
            const values = {
              id: ticket.id,
              plan_grid_issues,
            }
            this.props.dispatch(updateRequest(values))
          }}
          project={ticket.project}
          value={ticket.plan_grid_issues}
        />
      </ScrollView>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }

  handleMarkedForReviewBy = (ticket) => {
    this.setState({ updating: true })
    const request = api({
      data: { marked_for_review_by: true },
      method: 'PATCH',
      url: `/cors/${ticket.id}`,
    })

    request.then((resp) => {
      this.setState({ updating: false })
      this.fetchTicketDetail()
    })
  }

  renderMarkedForReviewBy = (ticket) => {
    if (ticket.marked_for_review_by) {
      return null
    }
    return (
      <TouchableOpacity
        onPress={() => {
          if (Platform.OS === 'web') {
            if (window.confirm('Are you sure you want to mark this for review?')) {
              this.handleMarkedForReviewBy(ticket)
            }
          } else {
            Alert.alert('Mark for Review', 'Are you sure you want to mark this for review?', [
              { text: 'No', style: 'cancel' },
              {
                text: 'Yes',
                onPress: () => {
                  this.handleMarkedForReviewBy(ticket)
                },
              },
            ])
          }
        }}
      >
        <View
          style={{
            backgroundColor: COLORS.redLight,
            borderRadius: 5,
            margin: 20,
            marginBottom: 0,
            paddingBottom: 15,
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 15,
          }}
        >
          <Text
            style={{
              color: COLORS.white,
              textAlign: 'center',
            }}
          >
            Mark for Review
          </Text>
        </View>
      </TouchableOpacity>
    )
  }

  renderAuthorization = (ticket) => {
    if (ticket.requires_auth) {
      if (ticket.authorization === null) {
        return <Text style={{ color: COLORS.redDark, textAlign: 'right' }}>Awaiting Authorization</Text>
      }
    }
    const date = ticket.authorization !== null ? ticket.authorization_created_at : ticket.created_at
    return <Text style={{ color: COLORS.greenDark, textAlign: 'right' }}>Authorized on {date}</Text>
  }

  isBookmarked = (id) => {
    if (!this.props.bookmarks.data) {
      return false
    }
    return _.filter(this.props.bookmarks.data.data, (item) => item.cor.id === id).length > 0
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsDetail = connect(mapStateToProps, mapDispatchToProps)(TicketsDetail)

export { TicketsDetail }
