import { createFormAction } from 'redux-form-saga'

import {
  SELECT_REQUEST,
  SELECT_SUCCESS,
  SELECT_FAILURE,
  SELECT_FILTERS,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
} from '../types/tickets'

const selectRequest = (data) => {
  return {
    payload: {
      data,
    },
    type: SELECT_REQUEST,
  }
}

const selectSuccess = (data) => {
  return {
    payload: {
      data,
    },
    type: SELECT_SUCCESS,
  }
}

const selectFailure = (exception) => {
  return {
    payload: {
      exception,
    },
    type: SELECT_FAILURE,
  }
}

const selectFilters = (key, value) => {
  return {
    payload: {
      key,
      value,
    },
    type: SELECT_FILTERS,
  }
}

const insert = createFormAction('TICKETS_INSERT')

const update = createFormAction('TICKETS_UPDATE')

const updateRequest = (data) => {
  return {
    payload: {
      data,
    },
    type: UPDATE_REQUEST,
  }
}

const updateSuccess = (data) => {
  return {
    payload: {
      data,
    },
    type: UPDATE_SUCCESS,
  }
}

const updateFailure = (exception) => {
  return {
    payload: {
      exception,
    },
    type: UPDATE_FAILURE,
  }
}

export {
  selectRequest,
  selectSuccess,
  selectFailure,
  selectFilters,
  insert,
  update,
  updateRequest,
  updateSuccess,
  updateFailure,
}
