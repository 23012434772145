import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'

import { update } from '../actions/tickets'
import { Button } from '../components/button'
import { Input } from '../components/input'
import { Modal } from '../components/modal'
import { Notice } from '../components/notice'
import { COLORS } from '../constants/colors'
import { buildError, buildIcon } from '../modules/builders'

const styles = EStyleSheet.create({
  viewcontainer: {
    backgroundColor: COLORS.white,
    borderRadius: '5rem',
  },
  viewTitle: {
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    flexDirection: 'row',
    padding: '15rem',
  },
  viewTitleLeft: {
    flex: 1,
  },
  textTitleLeft: {
    color: COLORS.grayDark,
    fontSize: '18rem',
    fontWeight: 'bold',
  },
  viewField: {
    padding: '15rem',
    paddingBottom: '0rem',
  },
  viewButton: {
    padding: '15rem',
  },
})

const selector = formValueSelector('additional-notes')

class AdditionalNotes extends React.Component {
  render = () => {
    return (
      <Modal isVisible={this.props.isVisible}>
        <View style={styles.viewcontainer}>
          <View style={styles.viewTitle}>
            <View style={styles.viewTitleLeft}>
              <Text style={styles.textTitleLeft}>Additional Notes</Text>
            </View>
            <TouchableOpacity onPress={this.props.onPressClose}>
              {buildIcon('regular', COLORS.grayDark, 'times-circle', EStyleSheet.value(20))}
            </TouchableOpacity>
          </View>
          <View style={styles.viewField}>
            <Field
              autoGrow
              component={Input}
              keyboardType="default"
              multiline
              name="notes"
              numberOfLines={10}
              textAlignVertical="top"
              type="text"
            />
          </View>
          {this.renderError()}
          <View style={styles.viewButton}>
            <TouchableOpacity onPress={this.props.handleSubmit(this.handleSubmit)}>
              <Button primary type="submit">
                Submit
              </Button>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    )
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginLeft: '15rem',
        marginRight: '15rem',
        marginTop: '15rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  handleSubmit = () => {
    const values = {
      id: this.props.ticket.id,
      notes: selector(this.props.state, 'notes'),
    }
    const success = (data) => {
      this.props.onPressClose()
    }
    const failure = (data) => {
      throw new SubmissionError({
        _error: buildError(data),
      })
    }
    return update(values, this.props.dispatch).then(success).catch(failure)
  }
}

AdditionalNotes = reduxForm({
  destroyOnUnmount: true,
  form: 'additional-notes',
})(AdditionalNotes)

const mapStateToProps = (state, ownProps) => {
  const initialValues = {
    notes: ownProps.ticket.pricing.notes,
  }
  return {
    initialValues,
    state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

AdditionalNotes = connect(mapStateToProps, mapDispatchToProps)(AdditionalNotes)

export { AdditionalNotes }
