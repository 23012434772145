import React from 'react'
import { KeyboardAvoidingView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { withNavigationFocus } from 'react-navigation'
import { connect } from 'react-redux'

import { Button } from '../components/button'
import { Left } from '../components/left'
import { Right } from '../components/right'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'

class DailyReportsFormWorkPerformedTodayList extends React.Component {
  state = {
    report: null,
  }

  static navigationOptions = ({ navigation }) => {
    const left = (
      <View
        style={{
          paddingLeft: 20,
        }}
      >
        <Left
          icon={buildIcon('regular', COLORS.grayDark, 'arrow-left', 20)}
          onPress={() => {
            const onPressLeft = navigation.getParam('onPressLeft', null)
            if (onPressLeft) {
              onPressLeft()
            }
          }}
        />
      </View>
    )
    const right =
      navigation.getParam('report').status === 'submitted' ? null : (
        <View
          style={{
            paddingRight: 20,
          }}
        >
          <Right
            icon={buildIcon('solid', COLORS.grayDark, 'plus-square', 30)}
            onPress={() => {
              const onPressRight = navigation.getParam('onPressRight', null)
              if (onPressRight) {
                onPressRight()
              }
            }}
          />
        </View>
      )
    const target = {
      headerLeft: () => left,
      headerRight: () => right,
      headerTitle: 'Work Performed Today',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.component()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.isFocused !== prevProps.isFocused) {
      this.component()
    }
  }

  component = () => {
    this.project = this.props.navigation.getParam('project')
    this.buildState()
    this.props.navigation.setParams({
      onPressLeft: () => {
        this.props.navigation.navigate('DailyReportsForm', {
          project: this.project,
          report: this.state.report,
        })
      },
      onPressRight: () => {
        this.handlePlus()
      },
    })
  }

  render = () => {
    if (!this.state.report) {
      return null
    }
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <ScrollView
          style={{
            flexGrow: 1,
            padding: 20,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          {this.state.report.data.areas_work_performed.rows.map(this.renderItem)}
        </ScrollView>
        <View
          style={{
            flexDirection: 'row',
            padding: 20,
          }}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: 10,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate('DailyReportsForm', {
                  project: this.project,
                  report: this.state.report,
                })
              }}
            >
              <Button primary>Back</Button>
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAvoidingView>
    )
  }

  renderItem = (value, key) => {
    return (
      <TouchableOpacity
        key={key}
        onPress={() => {
          this.props.navigation.navigate('DailyReportsFormWorkPerformedTodayForm', {
            project: this.project,
            report: this.state.report,
            key,
            value,
          })
        }}
        style={{
          paddingTop: 20,
        }}
      >
        <View
          style={{
            backgroundColor: COLORS.grayLight,
            flexDirection: 'row',
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
          }}
        >
          <View
            style={{
              flexGrow: 1,
            }}
          >
            <Text
              style={{
                color: COLORS.grayDark,
                fontWeight: 'bold',
              }}
            >
              Area #{key + 1}
            </Text>
          </View>
          {buildIcon('solid', COLORS.grayDark, 'chevron-right', 15)}
        </View>
      </TouchableOpacity>
    )
  }

  buildState = () => {
    const report = this.props.navigation.getParam('report')
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...report,
        },
      }
    })
  }

  handlePlus = () => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          report: {
            ...prevState.report,
            data: {
              ...prevState.report.data,
              areas_work_performed: {
                ...prevState.report.data.areas_work_performed,
                rows: [
                  ...prevState.report.data.areas_work_performed.rows,
                  {
                    location: {
                      value: '',
                    },
                    scope: {
                      value: '',
                    },
                    photos: [],
                  },
                ],
              },
              isDirty: true,
            },
          },
        }
      },
      () => {
        this.props.navigation.navigate('DailyReportsFormWorkPerformedTodayForm', {
          project: this.project,
          report: this.state.report,
          key: this.state.report.data.areas_work_performed.rows.length - 1,
          value:
            this.state.report.data.areas_work_performed.rows[
              this.state.report.data.areas_work_performed.rows.length - 1
            ],
        })
      },
    )
  }
}

const mapStateToProps = (props, ownProps) => {
  return {
    ...props,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

DailyReportsFormWorkPerformedTodayList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailyReportsFormWorkPerformedTodayList)

DailyReportsFormWorkPerformedTodayList = withNavigationFocus(DailyReportsFormWorkPerformedTodayList)

export { DailyReportsFormWorkPerformedTodayList }
