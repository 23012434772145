import { SELECT_REQUEST, SELECT_SUCCESS, SELECT_FAILURE, INSERT } from '../types/tickets-materials'
import { SIGN_OUT } from '../types/users'

const initialState = {
  data: null,
  exception: null,
  loading: false,
}

const ticketsMaterials = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case SELECT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        exception: null,
        loading: false,
      }
    case SELECT_FAILURE:
      return {
        ...state,
        data: null,
        exception: action.payload.exception,
        loading: false,
      }
    case INSERT.REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case INSERT.SUCCESS:
      return {
        ...state,
        exception: null,
        loading: false,
      }
    case INSERT.FAILURE:
      return {
        ...state,
        exception: action.payload.exception,
        loading: false,
      }
    case SIGN_OUT:
      return initialState
    default:
      return state
  }
}

export { ticketsMaterials }
