import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import { Modal } from '../components/modal'
import { COLORS } from '../constants/colors'
import { buildIcon } from '../modules/builders'

const styles = EStyleSheet.create({
  viewcontainer: {
    backgroundColor: COLORS.white,
    borderRadius: '5rem',
  },
  viewTitleOuter: {
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    flexDirection: 'row',
    padding: '15rem',
  },
  viewTitleInner: {
    flex: 1,
  },
  textTitle: {
    color: COLORS.grayDark,
    fontSize: '18rem',
    fontWeight: 'bold',
  },
  viewBody: {
    flexDirection: 'row',
    padding: '15rem',
  },
  textBody: {
    color: COLORS.grayDark,
    fontSize: '18rem',
    fontWeight: 'normal',
  },
})

class TermsAndConditions extends React.Component {
  render = () => {
    return (
      <Modal isVisible={this.props.isVisible}>
        <View style={styles.viewcontainer}>
          <View style={styles.viewTitleOuter}>
            <View style={styles.viewTitleInner}>
              <Text style={styles.textTitle}>Terms and Conditions</Text>
            </View>
            <TouchableOpacity onPress={this.props.onPressClose}>
              {buildIcon('regular', COLORS.grayDark, 'times-circle', EStyleSheet.value(20))}
            </TouchableOpacity>
          </View>
          <View style={styles.viewBody}>
            <Text style={styles.textBody}>{this.props.body}</Text>
          </View>
        </View>
      </Modal>
    )
  }
}

export { TermsAndConditions }
