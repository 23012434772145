import React from 'react'
import { Text, View } from 'react-native'
import createSelector from 'react-native-conditional-stylesheet'
import EStyleSheet from 'react-native-extended-stylesheet'
import Dropdown from './Dropdown'

import { COLORS } from '../constants/colors'

const styles = EStyleSheet.create({
  inputAndroid: {
    borderRadius: '5rem',
    borderWidth: '1rem',
    paddingBottom: '10rem',
    paddingLeft: '10rem',
    paddingRight: '10rem',
    paddingTop: '10rem',
  },
  inputAndroidGray: {
    backgroundColor: COLORS.white,
    borderColor: COLORS.grayLight,
    color: COLORS.grayDark,
  },
  inputAndroidWhite: {
    backgroundColor: COLORS.white,
    borderColor: COLORS.white,
    color: COLORS.grayDark,
  },
  inputIOS: {
    borderRadius: '5rem',
    borderWidth: '1rem',
    paddingBottom: '11rem',
    paddingLeft: '10rem',
    paddingRight: '10rem',
    paddingTop: '11rem',
  },
  inputIOSGray: {
    backgroundColor: COLORS.white,
    borderColor: COLORS.grayLight,
    color: COLORS.grayDark,
  },
  inputIOSWhite: {
    backgroundColor: COLORS.white,
    borderColor: COLORS.white,
    color: COLORS.grayDark,
  },
  inputWeb: {
    borderRadius: '5rem',
    borderWidth: '1rem',
    fontFamily: 'system-ui',
    paddingBottom: '10rem',
    paddingLeft: '10rem',
    paddingRight: '10rem',
    paddingTop: '10rem',
  },
})

class Select extends React.Component {
  render = () => {
    const selector = createSelector(styles)
    const inputAndroid = selector('inputAndroid', {
      inputAndroidGray: this.props.gray,
      inputAndroidWhite: this.props.white,
    })
    const inputIOS = selector('inputIOS', {
      inputIOSGray: this.props.gray,
      inputIOSWhite: this.props.white,
    })
    const inputWeb = selector('inputWeb', {
      inputAndroidGray: this.props.gray,
      inputAndroidWhite: this.props.white,
    })
    return (
      <View>
        <Dropdown
          data={this.props.items}
          onValueChange={this.handleOnValueChange}
          placeholder={this.props.placeholder}
          style={{
            inputAndroid,
            inputIOS,
            inputWeb,
          }}
          useNativeAndroidPickerStyle={false}
          value={this.props.options ? this.props.options : this.props.input.value}
          {...this.props.input}
          {...this.props}
        />
        {this.renderError()}
      </View>
    )
  }

  renderError = () => {
    if (!this.props.meta.touched) {
      return null
    }
    if (!this.props.meta.error) {
      return null
    }
    return (
      <View
        style={{
          marginTop: 10,
        }}
      >
        <Text
          style={{
            backgroundColor: COLORS.white,
            color: COLORS.redLight,
            fontSize: 15,
            lineHeight: 20,
          }}
        >
          {this.props.meta.error}
        </Text>
      </View>
    )
  }

  handleOnValueChange = (value) => {
    this.props.input.onChange(value)
  }
}

export { Select }
