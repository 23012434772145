import { format, parse } from 'date-fns'
import _ from 'lodash'
import React from 'react'
import { Image, KeyboardAvoidingView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm, reset, SubmissionError } from 'redux-form'

import { insert } from '../actions/schedule-delays'
import { Button } from '../components/button'
import { Notice } from '../components/notice'
import { Spinner } from '../components/spinner'
import { COLORS } from '../constants/colors'
import { buildError, buildNavigationOptions } from '../modules/builders'

const selector = formValueSelector('schedule-delays-add')

class ScheduleDelaysAddPreview extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: navigation.state.params.title ? navigation.state.params.title : '',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    const project = _.find(
      this.props.projects.data.data,
      (item) => item.id === this.props.navigation.getParam('id', ''),
    )
    if (project) {
      this.props.navigation.setParams({
        title: `PROJECT ${project.number}`,
      })
    }
  }

  render = () => {
    if (!this.props.projects.data) {
      return null
    }
    const project = _.find(this.props.projects.data.data, this.predicate)
    if (!project) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Project</Notice>
        </ScrollView>
      )
    }
    const photos = this.props.navigation.getParam('photos', [])
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <Spinner visible={this.props.scheduleDelays.loading} />
        <ScrollView
          style={{
            flexGrow: 1,
          }}
        >
          {this.renderError()}
          <View
            style={{
              flex: 1,
            }}
          >
            <View
              style={{
                marginTop: 20,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                  }}
                >
                  Date and Time
                </Text>
              </View>
              <View style={{}}>
                <Text
                  style={{
                    color: COLORS.grayMedium,
                    fontSize: 15,
                    lineHeight: 20,
                  }}
                >
                  {selector(this.props, 'date')}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Number of Hours Delayed
                </Text>
                <Text
                  style={{
                    color: COLORS.grayLight,
                    fontSize: 13,
                    fontWeight: 'bold',
                    lineHeight: 20,
                  }}
                >
                  Optional
                </Text>
              </View>
              <View style={{}}>
                <Text
                  style={{
                    color: COLORS.grayMedium,
                    fontSize: 15,
                    lineHeight: 20,
                  }}
                >
                  {selector(this.props, 'hours_delayed')}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Location
                </Text>
              </View>
              <View style={{}}>
                <Text
                  style={{
                    color: COLORS.grayMedium,
                    fontSize: 15,
                    lineHeight: 20,
                  }}
                >
                  {selector(this.props, 'location')}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Issue
                </Text>
              </View>
              <View style={{}}>
                <Text
                  style={{
                    color: COLORS.grayMedium,
                    fontSize: 15,
                    lineHeight: 20,
                  }}
                >
                  {selector(this.props, 'issue')}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Additional Notes
                </Text>
              </View>
              <View style={{}}>
                <Text
                  style={{
                    color: COLORS.grayMedium,
                    fontSize: 15,
                    lineHeight: 20,
                  }}
                >
                  {selector(this.props, 'notes')}
                </Text>
              </View>
            </View>
            <View
              style={{
                marginBottom: 0,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Photos
                </Text>
              </View>
            </View>
            <View
              style={{
                marginLeft: 20,
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
              }}
            >
              {photos.length === 0 ? (
                <View
                  style={{
                    flex: 1,
                    paddingRight: 20,
                    paddingTop: 10,
                  }}
                >
                  <Notice failure>There are no photos in this schedule delay.</Notice>
                </View>
              ) : null}
              {photos.map((value, key) => {
                return (
                  <View
                    key={key}
                    style={{
                      paddingTop: 10,
                      paddingRight: 10,
                    }}
                  >
                    <Image
                      resizeMode="cover"
                      source={{
                        uri: typeof value === 'string' ? value : value.uri,
                      }}
                      style={{
                        height: 150,
                        width: 150,
                        borderRadius: 5,
                      }}
                    />
                  </View>
                )
              })}
            </View>
          </View>
        </ScrollView>
        {/* */}
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              flex: 1,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 10,
              paddingTop: 20,
            }}
          >
            <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
              <Button secondary>Edit</Button>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flex: 1,
              paddingBottom: 20,
              paddingLeft: 10,
              paddingRight: 20,
              paddingTop: 20,
            }}
          >
            <TouchableOpacity onPress={this.props.handleSubmit(this.handleSubmit)}>
              <Button primary>Submit</Button>
            </TouchableOpacity>
          </View>
        </View>
        {/* */}
      </KeyboardAvoidingView>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginLeft: '20rem',
        marginRight: '20rem',
        marginTop: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  handleSubmit = () => {
    this.props.dispatch(insert.request(null))
    const photos = this.props.navigation.getParam('photos', [])
    const dateLocal = parse(selector(this.props, 'date'), 'MM/dd/yyyy h:mm a', new Date())
    const dateUTC = new Date(dateLocal.getTime() + dateLocal.getTimezoneOffset() * 60000)
    const values = {
      project: {
        id: this.props.navigation.getParam('id', ''),
      },
      date: format(dateUTC, 'MM/dd/yyyy h:mm a'),
      hours_delayed: selector(this.props, 'hours_delayed') || 0,
      location: selector(this.props, 'location'),
      issue: selector(this.props, 'issue'),
      notes: selector(this.props, 'notes'),
      photos,
    }
    const success = (data) => {
      this.props.dispatch(reset('schedule-delays-add'))
      this.props.navigation.navigate('ScheduleDelaysAddSuccess', {
        id: this.props.navigation.getParam('id', ''),
      })
    }
    const failure = (data) => {
      throw new SubmissionError({
        _error: buildError(data),
      })
    }
    return insert(values, this.props.dispatch).then(success).catch(failure)
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

ScheduleDelaysAddPreview = connect(mapStateToProps, mapDispatchToProps)(ScheduleDelaysAddPreview)

ScheduleDelaysAddPreview = reduxForm({
  destroyOnUnmount: false,
  form: 'schedule-delays-add',
})(ScheduleDelaysAddPreview)

export { ScheduleDelaysAddPreview }
