import { Asset } from 'expo-asset'
import * as Sentry from 'sentry-expo'
import * as Font from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'
import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'

import { Navigator } from './navigator'

const Portal = () => {
  const [appIsReady, setAppIsReady] = useState(false)

  useEffect(() => {
    async function prepare() {
      try {
        await SplashScreen.preventAutoHideAsync()
        Asset.loadAsync([require('./assets/images/icon.png'), require('./assets/images/logo.png')])
        Font.loadAsync({
          'font-awesome-brands': require('./assets/fonts/font-awesome-brands.ttf'),
          'font-awesome-light': require('./assets/fonts/font-awesome-light.ttf'),
          'font-awesome-regular': require('./assets/fonts/font-awesome-regular.ttf'),
          'font-awesome-solid': require('./assets/fonts/font-awesome-solid.ttf'),
          'open-sans-bold-italic': require('./assets/fonts/open-sans-bold-italic.ttf'),
          'open-sans-bold': require('./assets/fonts/open-sans-bold.ttf'),
          'open-sans-extra-bold-italic': require('./assets/fonts/open-sans-extra-bold-italic.ttf'),
          'open-sans-extra-bold': require('./assets/fonts/open-sans-extra-bold.ttf'),
          'open-sans-italic': require('./assets/fonts/open-sans-italic.ttf'),
          'open-sans-light-italic': require('./assets/fonts/open-sans-light-italic.ttf'),
          'open-sans-light': require('./assets/fonts/open-sans-light.ttf'),
          'open-sans-regular': require('./assets/fonts/open-sans-regular.ttf'),
          'open-sans-semi-bold-italic': require('./assets/fonts/open-sans-semi-bold-italic.ttf'),
          'open-sans-semi-bold': require('./assets/fonts/open-sans-semi-bold.ttf'),
        })
        await new Promise((resolve) => setTimeout(resolve, 2000))
      } catch (e) {
        console.log('PREPARE')
        console.log(e)
        Sentry.Native.captureException(e.message)
      } finally {
        setAppIsReady(true)
      }
    }

    prepare()
  }, [])

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      await SplashScreen.hideAsync()
    }
  }, [appIsReady])

  if (!appIsReady) {
    return null
  }
  if (appIsReady) {
    return (
      <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
        <Navigator />
      </View>
    )
  }
}

export { Portal }
