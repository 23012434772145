import * as Updates from 'expo-updates'
import React from 'react'
import { LogBox, Platform } from 'react-native'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { buildAPI } from './modules/builders'
import { Portal } from './portal'
import { persistor, store } from './state'

import * as Sentry from 'sentry-expo'

Sentry.init({
  dsn: 'https://4af9b8bc445143e9ac6b3c36f0a4bfa5@o264474.ingest.sentry.io/1494673',
  enableInExpoDevelopment: true,
  debug: Updates.channel !== 'production', // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
})

LogBox.ignoreLogs(['_reactNative.Dimensions.removeEventListener'])

buildAPI(store)

class App extends React.Component {
  render = () => {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Portal />
        </PersistGate>
      </Provider>
    )
  }
}

export { App }
