import { put, takeLatest } from 'redux-saga/effects'

import { selectSuccess, selectFailure, insert, update } from '../actions/daily-reports'
import { api } from '../api'
import { SELECT_REQUEST, INSERT, UPDATE } from '../types/daily-reports'

function* select(action) {
  try {
    const response = yield api({
      method: 'GET',
      params: {
        project: action.payload.data.project_id,
        status: action.payload.data.status,
      },
      url: '/v2/reports',
    })
    yield put(selectSuccess(response.data))
  } catch (error) {
    yield put(selectFailure(error.response.data))
  }
}

function* inser_(action) {
  if (!action.payload) {
    return
  }
  try {
    const response = yield api({
      data: {
        report: action.payload.report,
      },
      method: 'POST',
      url: '/v2/reports',
    })
    yield put(insert.success(response.data))
  } catch (error) {
    yield put(insert.failure(error.response.data))
  }
}

function* updat_(action) {
  if (!action.payload) {
    return
  }
  try {
    const response = yield api({
      data: {
        report: action.payload.report,
      },
      method: 'PATCH',
      url: `/v2/reports/${action.payload.id}`,
    })
    yield put(update.success(response.data))
  } catch (error) {
    yield put(update.failure(error.response.data))
  }
}

function* dailyReports() {
  yield takeLatest(SELECT_REQUEST, select)
  yield takeLatest(INSERT.REQUEST, inser_)
  yield takeLatest(UPDATE.REQUEST, updat_)
}

export { dailyReports }
