import { put, takeLatest } from 'redux-saga/effects'

import { selectSuccess, selectFailure } from '../actions/employees'
import { api } from '../api'
import { SELECT_REQUEST } from '../types/employees'

function* select(action) {
  try {
    const response = yield api({
      method: 'GET',
      params: {},
      url: '/employees',
    })
    yield put(selectSuccess(response.data))
  } catch (error) {
    yield put(selectFailure(error.response.data))
  }
}

function* employees() {
  yield takeLatest(SELECT_REQUEST, select)
}

export { employees }
