import _ from 'lodash'
import React from 'react'
import * as Sentry from 'sentry-expo'
import { KeyboardAvoidingView, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { formValueSelector, reset } from 'redux-form'

import { insert } from '../actions/tickets'
import { insert as insertA } from '../actions/tickets-hours'
import { api } from '../api'
import { Button } from '../components/button'
import { Notice } from '../components/notice'
import { Spinner } from '../components/spinner'
import { COLORS } from '../constants/colors'
import { buildError, buildIcon, buildNavigationOptions } from '../modules/builders'

const selector = formValueSelector('tickets-add')

class RequestSignature extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerLeft: () => null,
      headerRight: () => null,
      headerTitle: 'Request Signature',
    }
    return buildNavigationOptions(target)
  }

  state = {
    comments: '',
    users: [],
    recipients: [],
    submissionError: null,
  }

  componentDidMount = () => {
    this.component()
  }

  componentDidUpdate = () => {
    if (!this.state.users.length) {
      this.component()
    }
  }

  component = () => {
    if (!this.state.users.length && this.props.navigation.state.params.type !== null) {
      if (this.props.navigation.state.params.type === 'ticket') {
        const project = _.find(
          this.props.projects.data.data,
          (item) => item.id === this.props.navigation.getParam('id', ''),
        )
        const users = project.team.gc_team
          .filter((value) => value.authorized_signer)
          .map((value) => {
            return {
              email: value.email,
              name: value.contact,
            }
          })
        this.setState({
          users,
          recipients: users,
        })
      } else {
        const ticket = _.find(this.props.tickets.data.data, this.predicate)
        const users = ticket.recipients
          .filter((value) => value.authorized_signer)
          .map((value) => {
            return {
              email: value.email,
              name: value.contact,
            }
          })
        this.setState({
          users,
          recipients: users,
        })
      }
    }
  }

  handleHourLogSubmit = async (signature) => {
    if (!this.props.navigation.state.params.created) {
      return this.handleSendRequest()
    }
    const logs = this.props.navigation.getParam('logs', []).map((log) => {
      delete log.checked
      return log
    })
    const authorization = null
    const values = {
      ticket: _.find(this.props.tickets.data.data, (item) => item.id === this.props.navigation.getParam('id', '')),
      type: 'hour',
      date: this.props.navigation.getParam('date', []),
      data: logs,
      requires_auth: true,
      authorization,
    }
    const failure = (data) => {
      this.setState({
        submissionError: buildError(data),
      })
    }
    return insertA(values, this.props.dispatch).then(this.handleSendRequest).catch(failure)
  }

  handleSendRequest = async (data) => {
    try {
      const comments = this.state.comments
      api({
        data: {
          auth_type: this.props.navigation.state.params.type === 'ticket' ? 'ticket' : 'hours',
          comments,
          cor: this.props.navigation.state.params.ticket_id ? this.props.navigation.state.params.ticket_id : data.id,
          recipients: this.state.recipients,
        },
        method: 'POST',
        url: '/jobs',
      })
      this.setState({
        comments: '',
        users: [],
        recipients: [],
      })
      if (this.props.navigation.state.params.type === 'ticket') {
        this.props.dispatch(reset('tickets-add'))
      } else {
        this.props.dispatch(reset('tickets-hours-add-authorization'))
      }
      if (this.props.navigation.state.params.created && this.props.navigation.state.params.type === 'ticket') {
        this.props.navigation.navigate('TicketsAddSuccess', {
          id: data.id,
          data,
        })
      } else {
        this.props.navigation.navigate('TicketsDetail', {
          id: this.props.navigation.state.params.ticket_id,
        })
      }
      this.props.navigation.setParams({ type: null, created: false })
    } catch (error) {
      this.setState({
        submissionError: buildError(error),
      })
      Sentry.Native.captureException(e.message)
    }
  }

  handleTicketSubmit = async () => {
    if (!this.props.navigation.state.params.created) {
      return this.handleSendRequest()
    }
    const authorization = null
    const values = {
      project_id: this.props.navigation.getParam('id', ''),
      description: selector(this.props, 'description'),
      location: selector(this.props, 'location'),
      scope: selector(this.props, 'scope'),
      notes: selector(this.props, 'notes'),
      fieldwire_tasks: selector(this.props, 'fieldwire_tasks'),
      plan_grid_issues: selector(this.props, 'plan_grid_issues'),
      requires_auth: true,
      reference: selector(this.props, 'reference'),
      authorization,
    }
    const failure = (data) => {
      this.setState({
        submissionError: buildError(data),
      })
    }
    return insert(values, this.props.dispatch).then(this.handleSendRequest).catch(failure)
  }

  renderUsers = () => {
    return (
      <View
        style={{
          paddingBottom: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <View>
          <Text
            style={{
              color: COLORS.grayDark,
              fontSize: 15,
              fontWeight: 'bold',
              lineHeight: 20,
            }}
          >
            To
          </Text>
        </View>
        {this.state.users.map((user) => {
          return (
            <View key={user.email}>
              <TouchableOpacity
                onPress={() => {
                  if (this.state.recipients.filter((recipient) => recipient.email === user.email).length) {
                    this.setState((prevState) => {
                      return {
                        recipients: prevState.recipients.filter((recipient) => recipient.email !== user.email),
                      }
                    })
                  } else {
                    this.setState((prevState) => {
                      return {
                        recipients: [...prevState.recipients, user],
                      }
                    })
                  }
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingTop: 10,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <Text
                      style={{
                        color: COLORS.grayDark,
                        fontSize: 15,
                      }}
                    >
                      {user.name}
                    </Text>
                  </View>
                  {buildIcon(
                    'regular',
                    this.state.recipients.filter((recipient) => recipient.email === user.email).length
                      ? COLORS.grayDark
                      : COLORS.grayLight,
                    'check-circle',
                    15,
                  )}
                </View>
              </TouchableOpacity>
            </View>
          )
        })}
      </View>
    )
  }

  renderCancel = () => {
    if (this.props.navigation.state.params.type === 'ticket') {
      if (this.props.navigation.state.params.created) {
        return (
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('TicketsAddAuthorization', {
                id: this.props.navigation.getParam('id', ''),
              })
            }}
          >
            <Button primary>Cancel</Button>
          </TouchableOpacity>
        )
      } else {
        return (
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('TicketsDetailAuthorization', {
                id: this.props.navigation.state.params.ticket_id,
              })
            }}
          >
            <Button primary>Cancel</Button>
          </TouchableOpacity>
        )
      }
    } else {
      if (this.props.navigation.state.params.created) {
        return (
          <TouchableOpacity
            onPress={() => {
              const logs = this.props.navigation.getParam('logs', []).map((log) => {
                delete log.checked
                return log
              })
              this.props.navigation.navigate('TicketsHoursAddAuthorization', {
                id: this.props.navigation.getParam('id', ''),
                date: this.props.navigation.getParam('date', []),
                logs,
              })
            }}
          >
            <Button primary>Cancel</Button>
          </TouchableOpacity>
        )
      } else {
        return (
          <TouchableOpacity
            onPress={() => {
              const logs = this.props.navigation.getParam('logs', []).map((log) => {
                delete log.checked
                return log
              })
              this.props.navigation.navigate('TicketsHoursAuthorization', {
                id: this.props.navigation.state.params.ticket_id,
                logs,
              })
            }}
          >
            <Button primary>Cancel</Button>
          </TouchableOpacity>
        )
      }
    }
  }

  renderError = () => {
    if (!this.state.submissionError) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginLeft: '20rem',
        marginRight: '20rem',
        marginTop: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.state.submissionError}</Notice>
      </View>
    )
  }

  render = () => {
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <Spinner visible={this.props.tickets.loading} />
        <ScrollView
          style={{
            flexGrow: 1,
          }}
        >
          <View>
            <Text
              style={{
                color: COLORS.grayMedium,
                fontSize: 15,
                lineHeight: 20,
                marginBottom: 15,
                marginLeft: 20,
                marginRight: 20,
                marginTop: 15,
              }}
            >
              Ticket will be sent to the following recipients to request authorization.
            </Text>
          </View>
          {this.renderUsers()}
          <View
            style={{
              marginBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <TextInput
              autoGrow
              multiline
              numberOfLines={3}
              onChangeText={(comments) => this.setState({ comments })}
              placeholder="Comments"
              style={{
                backgroundColor: COLORS.white,
                borderColor: COLORS.grayLight,
                borderRadius: 5,
                borderWidth: 1,
                color: COLORS.grayDark,
                fontSize: 15,
                lineHeight: 20,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
              textAlignVertical="top"
              type="textarea"
              value={this.state.comments ? this.state.comments : ''}
            />
            <Text
              style={{
                paddingTop: 20,
              }}
            >
              Comments will appear in the email to the recipients above.
            </Text>
          </View>
        </ScrollView>
        {this.renderError()}
        <View
          style={{
            flexDirection: 'row',
            padding: 20,
          }}
        >
          <View
            style={{
              flex: 1,
              paddingRight: 10,
            }}
          >
            {this.renderCancel()}
          </View>
          <View
            style={{
              flex: 1,
              paddingLeft: 10,
            }}
          >
            {this.props.navigation.state.params.type === 'ticket' ? (
              <TouchableOpacity onPress={() => this.handleTicketSubmit()} disabled={!this.state.recipients.length}>
                {this.state.recipients.length ? (
                  <Button primary>Send Now</Button>
                ) : (
                  <Button primary disabled>
                    Send Now
                  </Button>
                )}
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={() => this.handleHourLogSubmit()} disabled={!this.state.recipients.length}>
                {this.state.recipients.length ? (
                  <Button primary>Send Now</Button>
                ) : (
                  <Button primary disabled>
                    Send Now
                  </Button>
                )}
              </TouchableOpacity>
            )}
          </View>
        </View>
      </KeyboardAvoidingView>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

RequestSignature = connect(mapStateToProps, mapDispatchToProps)(RequestSignature)

export { RequestSignature }
