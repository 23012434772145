import React from 'react'
import { Text, TextInput, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import { COLORS } from '../constants/colors'

const styles = EStyleSheet.create({
  view: {
    marginTop: '10rem',
  },
  text: {
    backgroundColor: COLORS.white,
    color: COLORS.redLight,
    fontSize: '15rem',
    fontWeight: 'bold',
    lineHeight: '20rem',
  },
})

class Input extends React.Component {
  render = () => {
    const textInput = {
      backgroundColor: !this.props.disabled ? COLORS.white : COLORS.grayLight,
      borderColor: COLORS.grayLight,
      borderRadius: 5,
      borderWidth: 1,
      color: COLORS.grayDark,
      fontSize: 15,
      lineHeight: 20,
      paddingBottom: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 10,
    }
    if (this.props.options) {
      return (
        <View>
          <TextInput
            autoCorrect={false}
            autoCapitalize="none"
            defaultValue={this.props.options}
            editable={!this.props.disabled}
            onChangeText={this.props.input.onChange}
            style={textInput}
            value={this.props.value}
            {...this.props.input}
            {...this.props}
          />
          {this.renderError()}
        </View>
      )
    }
    return (
      <View>
        <TextInput
          autoCorrect={false}
          autoCapitalize="none"
          editable={!this.props.disabled}
          onChangeText={this.props.input.onChange}
          style={textInput}
          {...this.props.input}
          {...this.props}
        />
        {this.renderError()}
      </View>
    )
  }

  renderError = () => {
    if (!this.props.meta.touched) {
      return null
    }
    if (!this.props.meta.error) {
      return null
    }
    return (
      <View style={styles.view}>
        <Text style={styles.text}>{this.props.meta.error}</Text>
      </View>
    )
  }
}

export { Input }
