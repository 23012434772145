import _ from 'lodash'

import {
  SELECT_REQUEST,
  SELECT_SUCCESS,
  SELECT_FAILURE,
  INSERT_REQUEST,
  INSERT_SUCCESS,
  INSERT_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from '../types/bookmarks'
import { SIGN_OUT } from '../types/users'

const initialState = {
  data: null,
  exception: null,
  loading: false,
}

const bookmarks = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case SELECT_SUCCESS:
      return {
        data: action.payload.data,
        exception: null,
        loading: false,
      }
    case SELECT_FAILURE:
      return {
        data: null,
        exception: action.payload.exception,
        loading: false,
      }
    case INSERT_REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case INSERT_SUCCESS:
      if (state.data) {
        return {
          data: {
            ...state.data,
            data: [...state.data.data, action.payload.data],
          },
          exception: null,
          loading: false,
        }
      }
      return {
        data: {
          data: [action.payload.data],
        },
        exception: null,
        loading: false,
      }
    case INSERT_FAILURE:
      return {
        ...state,
        exception: action.payload.exception,
        loading: false,
      }
    case DELETE_REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case DELETE_SUCCESS:
      if (state.data) {
        return {
          data: {
            ...state.data,
            data: _.filter(state.data.data, (item) => item.id !== action.payload.data.id),
          },
          exception: null,
          loading: false,
        }
      }
      return {
        data: {
          data: [],
        },
        exception: null,
        loading: false,
      }
    case DELETE_FAILURE:
      return {
        ...state,
        exception: action.payload.exception,
        loading: false,
      }
    case SIGN_OUT:
      return initialState
    default:
      return state
  }
}

export { bookmarks }
