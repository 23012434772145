import { put, takeLatest } from 'redux-saga/effects'

import { selectSuccess, selectFailure } from '../actions/rate-types'
import { api } from '../api'
import { SELECT_REQUEST } from '../types/rate-types'

function* select(action) {
  try {
    const response = yield api({
      method: 'GET',
      params: {},
      url: `/ratetypes`,
    })
    yield put(selectSuccess(response.data))
  } catch (error) {
    yield put(selectFailure(error.response.data))
  }
}

function* rateTypes() {
  yield takeLatest(SELECT_REQUEST, select)
}

export { rateTypes }
