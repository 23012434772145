import { format } from 'date-fns'
import React from 'react'
import { KeyboardAvoidingView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { withNavigationFocus } from 'react-navigation'
import { connect } from 'react-redux'

import { Button } from '../components/button'
import { DateTimePicker } from '../components/date-time-picker'
import { Input } from '../components/input'
import { Left } from '../components/left'
import { Right } from '../components/right'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions, buildTime } from '../modules/builders'

class DailyReportsFormVendorDeliveries extends React.Component {
  state = {
    report: null,
  }

  static navigationOptions = ({ navigation }) => {
    const left = (
      <View
        style={{
          paddingLeft: 20,
        }}
      >
        <Left
          icon={buildIcon('regular', COLORS.grayDark, 'arrow-left', 20)}
          onPress={() => {
            const onPressLeft = navigation.getParam('onPressLeft', null)
            if (onPressLeft) {
              onPressLeft()
            }
          }}
        />
      </View>
    )
    const right =
      navigation.getParam('report').status === 'submitted' ? null : (
        <View
          style={{
            paddingRight: 20,
          }}
        >
          <Right
            icon={buildIcon('solid', COLORS.grayDark, 'plus-square', 30)}
            onPress={() => {
              const onPressRight = navigation.getParam('onPressRight', null)
              if (onPressRight) {
                onPressRight()
              }
            }}
          />
        </View>
      )
    const target = {
      headerLeft: () => left,
      headerRight: () => right,
      headerTitle: 'Vendor Deliveries',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.component()
  }

  component = () => {
    this.project = this.props.navigation.getParam('project')
    this.buildState()
    this.props.navigation.setParams({
      onPressLeft: () => {
        this.props.navigation.navigate('DailyReportsForm', {
          project: this.project,
          report: this.state.report,
        })
      },
      onPressRight: () => {
        this.handlePlus()
      },
    })
  }

  render = () => {
    if (!this.state.report) {
      return null
    }
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <ScrollView
          style={{
            flexGrow: 1,
            padding: 20,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          {this.state.report.data.vendor_deliveries.rows.map(this.renderItem)}
        </ScrollView>
        {this.state.report.status === 'submitted' ? null : (
          <View
            style={{
              flexDirection: 'row',
              padding: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingRight: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.props.navigation.getParam('report'),
                  })
                }}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.state.report,
                  })
                }}
              >
                <Button primary>Continue</Button>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </KeyboardAvoidingView>
    )
  }

  renderItem = (value, key) => {
    const vendor_name = {
      input: {
        onChange: (vendor_name) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  vendor_deliveries: {
                    ...prevState.report.data.vendor_deliveries,
                    rows: prevState.report.data.vendor_deliveries.rows.map((v, k) => {
                      if (k === key) {
                        v.vendor_name.value = vendor_name
                      }
                      return v
                    }),
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: value.vendor_name.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    const time = {
      input: {
        onChange: (time) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  vendor_deliveries: {
                    ...prevState.report.data.vendor_deliveries,
                    rows: prevState.report.data.vendor_deliveries.rows.map((v, k) => {
                      if (k === key) {
                        v.time.value = time
                      }
                      return v
                    }),
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: value.time.value || buildTime(new Date()),
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    return (
      <View
        key={key}
        style={{
          borderTopColor: COLORS.grayLight,
          borderTopWidth: key === 0 ? 0 : 1,
          marginTop: key === 0 ? 0 : 20,
        }}
      >
        <View
          style={{
            marginTop: 20,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              marginBottom: 10,
            }}
          >
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                allowFontScaling={false}
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Company Name
              </Text>
            </View>
            {this.state.report.status === 'submitted' ? null : (
              <TouchableOpacity
                onPress={() => {
                  this.handleMinus(key)
                }}
              >
                {buildIcon('regular', COLORS.redDark, 'minus-square', 15)}
              </TouchableOpacity>
            )}
          </View>
          {this.state.report.status === 'submitted' ? (
            <Text
              style={{
                color: COLORS.grayMedium,
                fontSize: 15,
                lineHeight: 20,
              }}
            >
              {vendor_name.input.value}
            </Text>
          ) : (
            <Input type="text" {...vendor_name} />
          )}
        </View>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            paddingTop: 20,
          }}
        >
          <Text
            allowFontScaling={false}
            style={{
              color: COLORS.grayDark,
              fontSize: 15,
              fontWeight: 'bold',
              marginRight: 10,
            }}
          >
            Time
          </Text>
          <View
            style={{
              flex: 1,
            }}
          >
            {this.state.report.status === 'submitted' ? (
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {time.input.value}
              </Text>
            ) : (
              <DateTimePicker mode="time" {...time} />
            )}
          </View>
        </View>
      </View>
    )
  }

  buildState = () => {
    const report = this.props.navigation.getParam('report')
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          report: {
            ...report,
          },
        }
      },
      () => {
        if (this.state.report.data.vendor_deliveries.rows.length === 0) {
          this.handlePlus()
        }
      },
    )
  }

  handlePlus = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...prevState.report,
          data: {
            ...prevState.report.data,
            vendor_deliveries: {
              ...prevState.report.data.vendor_deliveries,
              rows: [
                ...prevState.report.data.vendor_deliveries.rows,
                {
                  vendor_name: {
                    value: '',
                  },
                  time: {
                    value: format(new Date(), 'h:mm a'),
                  },
                },
              ],
            },
          },
        },
      }
    })
  }

  handleMinus = (key) => {
    const rows = this.state.report.data.vendor_deliveries.rows
    rows.splice(key, 1)
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...prevState.report,
          data: {
            ...prevState.report.data,
            vendor_deliveries: {
              ...prevState.report.data.vendor_deliveries,
              rows,
            },
          },
        },
      }
    })
  }
}

const mapStateToProps = (props, ownProps) => {
  return {
    ...props,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

DailyReportsFormVendorDeliveries = connect(mapStateToProps, mapDispatchToProps)(DailyReportsFormVendorDeliveries)

DailyReportsFormVendorDeliveries = withNavigationFocus(DailyReportsFormVendorDeliveries)

export { DailyReportsFormVendorDeliveries }
