import _ from 'lodash'
import React from 'react'
import { Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

import { Button } from '../components/button'
import { MaterialsForm } from '../components/materials-form'
import { Modal } from '../components/modal'
import { COLORS } from '../constants/colors'

const selector = formValueSelector('materials-form')

const styles = EStyleSheet.create({
  view: {
    marginBottom: 10,
  },
  viewCategory: {
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: 1,
    marginBottom: 10,
    paddingBottom: 10,
  },
  textCategory: {
    color: COLORS.grayDark,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 20,
  },
  viewNameAndNotes: {
    flexDirection: 'row',
  },
  viewName: {
    flex: 1,
    marginBottom: 10,
  },
  textName: {
    color: COLORS.grayDark,
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 20,
  },
  viewControls: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  viewControlLeft: {
    flex: 1,
    marginRight: 10,
  },
  viewControlRight: {
    flex: 1,
    marginLeft: 10,
  },
  textControl: {
    color: COLORS.grayDark,
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: 20,
    marginBottom: 10,
  },
})

class MaterialsB extends React.Component {
  state = {
    notes: {},
  }

  render = () => {
    if (!this.props.logs.length) {
      return null
    }
    const sortBy = _.sortBy(this.props.logs, (log) => `${log.category.name}-${log.material.name}`)
    const groupBy = _.groupBy(sortBy, (item) => item.category.name)
    const pairs = _.toPairs(groupBy)
    const logs = _.sortBy(pairs, (pair) => pair[0])
    const rows = _.map(this.props.logs, (value, key) => {
      const entries = selector(this.props, `entries-${value.category.id}-${value.material.id}`)
        ? selector(this.props, `entries-${value.category.id}-${value.material.id}`)
        : null
      return entries ? entries.every((item) => item.quantity && item.size) : false
    })
    const isDisabled = !rows.every((item) => item !== false)

    return (
      <Modal isVisible={this.props.isVisible} reactModal={Platform.OS === 'web'}>
        <View
          style={{
            flex: 1,
            backgroundColor: COLORS.white,
            borderRadius: 5,
          }}
        >
          <ScrollView
            style={{
              flexGrow: 1,
              padding: 20,
            }}
          >
            {logs.map((value, key) => {
              return (
                <View key={key} style={styles.view}>
                  <View style={styles.viewCategory}>
                    <Text style={styles.textCategory}>{value[0]}</Text>
                  </View>
                  {value[1].map((v, k) => {
                    const initialValues = {}
                    v.entries.forEach((item) => {
                      initialValues[`entries-${v.category.id}-${v.material.id}-quantity`] = item.quantity
                      initialValues[`entries-${v.category.id}-${v.material.id}-size`] = item.size
                    })
                    return (
                      <View key={k}>
                        <View style={styles.viewNameAndNotes}>
                          <View style={styles.viewName}>
                            <Text style={styles.textName}>{v.material.name}</Text>
                          </View>
                        </View>
                        {this.renderNotes(v.material.notes)}
                        <MaterialsForm initialValues={initialValues} item={v} />
                      </View>
                    )
                  })}
                </View>
              )
            })}
            <View style={{ paddingBottom: 500, paddingTop: 500 }} />
          </ScrollView>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 10,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.onPressCancel()
                }}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 10,
                paddingRight: 20,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.onPressConfirm(this.getData())
                }}
                disabled={isDisabled}
              >
                {isDisabled ? (
                  <Button primary disabled>
                    Done
                  </Button>
                ) : (
                  <Button primary>Done</Button>
                )}
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  renderNotes = (notes) => {
    return (
      <View
        style={{
          paddingBottom: 10,
        }}
      >
        <Text
          style={{
            color: COLORS.grayDark,
            fontSize: 13,
          }}
        >
          {notes ? notes : null}
        </Text>
      </View>
    )
  }

  getData = () => {
    return _.map(this.props.logs, (value, key) => {
      return {
        ...value,
        entries: selector(this.props, `entries-${value.category.id}-${value.material.id}`),
      }
    })
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

MaterialsB = connect(mapStateToProps, mapDispatchToProps)(MaterialsB)

export { MaterialsB }
