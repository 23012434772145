import { format, parse } from 'date-fns'
import Checkbox from 'expo-checkbox'
import _ from 'lodash'
import React from 'react'
import { ActivityIndicator, KeyboardAvoidingView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { change, formValueSelector, Field, reduxForm, reset, SubmissionError } from 'redux-form'

import { selectRequest as selectRequestEmployees } from '../actions/employees'
import { selectRequest as selectRateTypes } from '../actions/rate-types'
import { selectRequest, insert } from '../actions/tickets-hours'
import { Button } from '../components/button'
import { DateTimePicker } from '../components/date-time-picker'
import { EmployeesA } from '../components/employees-a'
import { EmployeesB } from '../components/employees-b'
import { Notice } from '../components/notice'
import { Spinner } from '../components/spinner'
import { COLORS } from '../constants/colors'
import { buildError, buildIcon, buildNavigationOptions } from '../modules/builders'

const selector = formValueSelector('tickets-hours-add')

const today = format(Date.now(), 'MM/dd/yyyy')

const initialValues = {
  date: today,
}

const validate = (values) => {
  const errors = {}
  if (!values.date) {
    errors.date = 'Invalid Date'
  }
  return errors
}

class TicketsHoursAddForm extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: navigation.state.params.title ? navigation.state.params.title : '',
    }
    return buildNavigationOptions(target)
  }

  state = {
    logsA: [],
    logsB: [],
    isAVisible: false,
    isBVisible: false,
    requiresSignature: true,
  }

  componentDidMount = () => {
    const today = format(Date.now(), 'MM/dd/yyyy')
    const data = {
      ticket: {
        id: this.props.navigation.getParam('id', ''),
      },
    }
    this.props.dispatch(selectRequest(data))
    this.props.dispatch(change('tickets-hours-add', 'date', today))
    if (this.props.tickets.data) {
      const ticket = _.find(this.props.tickets.data.data, this.predicate)
      if (ticket) {
        this.props.navigation.setParams({
          title: `TICKET ${ticket.number}`,
        })
      }

      this.props.dispatch(selectRateTypes())
    }
    this.props.dispatch(selectRequestEmployees())
  }

  render = () => {
    if (!this.props.tickets.data) {
      return null
    }
    this.ticket = _.find(this.props.tickets.data.data, this.predicate)
    if (!this.ticket) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Ticket</Notice>
        </ScrollView>
      )
    }
    if (this.props.employees.loading) {
      return this.renderLoading()
    }
    if (this.props.employees.data !== null) {
      return this.renderData()
    }
    if (this.props.employees.exception !== null) {
      return this.renderException()
    }
    return null
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderData = () => {
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <Spinner visible={this.props.ticketsHours.loading} />
        {this.renderReuseLastLog()}
        <ScrollView
          style={{
            flexGrow: 1,
          }}
        >
          {this.renderError()}
          {this.renderDate()}
          {this.renderButtons1()}
          {this.renderItems()}
        </ScrollView>
        {this.renderButtons2()}
        {this.renderButtons3()}
        <EmployeesA
          date={selector(this.props, 'date')}
          employees={this.props.employees.data.data}
          isVisible={this.state.isAVisible}
          onPressCancel={() => {
            this.setState({ isAVisible: false })
          }}
          onPressConfirm={(logs) => {
            if (logs.length) {
              this.setState({ logsA: logs })
              this.setState({ isAVisible: false })
              this.setState({ isBVisible: true })
            }
          }}
          rates={this.ticket.project.rates}
        />
        <EmployeesB
          logs={this.state.logsA}
          isVisible={this.state.isBVisible}
          onPressCancel={() => {
            this.setState({ isBVisible: false })
          }}
          onPressConfirm={(logs) => {
            const union = _.unionBy(logs, this.state.logsB, (log) => `${log.classification}-${log.employee.id}`)
            if (union.length) {
              this.setState({ logsB: union })
              this.setState({ isBVisible: false })
            }
          }}
          rateTypes={this.props.rateTypes}
        />
      </KeyboardAvoidingView>
    )
  }

  renderException = (exception) => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Notice failure>{buildError(this.props.employees.exception)}</Notice>
      </ScrollView>
    )
  }

  renderItems = () => {
    if (!this.state.logsB.length) {
      return null
    }
    const sortBy = _.sortBy(this.state.logsB, (log) => `${log.classification}-${log.employee.name}`)
    const groupBy = _.groupBy(sortBy, (item) => item.classification)
    const pairs = _.toPairs(groupBy)
    const logs = _.sortBy(pairs, (pair) => pair[0])
    return _.map(logs, (value, key) => {
      return (
        <View key={value[0]}>
          <View
            style={{
              backgroundColor: COLORS.grayLight,
              marginBottom: 1,
              padding: 10,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  {value[0]}
                </Text>
              </View>
            </View>
          </View>
          {this.renderChildren(value[1])}
        </View>
      )
    })
  }

  renderChildren = (items) => {
    const styles = EStyleSheet.create({
      view: {
        borderBottomColor: COLORS.grayLight,
        borderBottomWidth: 1,
        flexDirection: 'row',
        padding: '10rem',
      },
      viewLeft: {
        paddingRight: '5rem',
      },
      viewMiddle: {
        flex: 1,
      },
      viewRight: {
        paddingLeft: '5rem',
      },
      viewOne: {
        flexDirection: 'row',
        paddingBottom: '5rem',
      },
      viewClassification: {
        flex: 1,
      },
      textClassification: {
        color: COLORS.grayDark,
        fontWeight: 'bold',
      },
      viewDelete: {
        paddingTop: '5rem',
      },
      viewTwo: {
        flexDirection: 'row',
      },
      textHours: {
        color: COLORS.grayDark,
        fontSize: '13rem',
        paddingRight: '10rem',
      },
      textRateType: {
        color: COLORS.grayDark,
        fontSize: '13rem',
      },
      viewButtons: {
        flexDirection: 'row',
        padding: '10rem',
      },
      viewButtonsEdit: {
        paddingRight: '5rem',
      },
      viewButtonsDelete: {
        paddingLeft: '5rem',
      },
      viewButtonsGetAuthorization: {
        padding: '10rem',
        paddingTop: '0rem',
      },
      textHoursTotal: {
        color: COLORS.grayDark,
        fontSize: '15rem',
        paddingLeft: '10rem',
        paddingTop: '3rem',
        paddingBottom: '5rem',
        fontWeight: 'bold',
      },
    })

    const totalHours = items.reduce((a, b) => {
      return (
        a +
        parseFloat(
          b.entries.reduce((c, d) => {
            return c + parseFloat(d.hours)
          }, 0),
        )
      )
    }, 0)

    return (
      <View>
        {items.map((item, index) => {
          return (
            <View key={`${item.employee.id}-${index}`} style={styles.view}>
              <View style={styles.viewLeft}>
                <Checkbox
                  color={item.checked === true ? COLORS.orange : COLORS.grayDark}
                  onValueChange={() => {
                    this.setState((prevState) => {
                      return {
                        logsB: prevState.logsB.map((log) => {
                          if (log.classification !== item.classification) {
                            return log
                          }
                          if (log.employee.id !== item.employee.id) {
                            return log
                          }
                          if (
                            log.entries[0].rate_slug !== item.entries[0].rate_slug ||
                            log.entries[0].hours !== item.entries[0].hours
                          ) {
                            return log
                          }
                          log.checked = !log.checked
                          return log
                        }),
                      }
                    })
                  }}
                  value={item.checked === true}
                />
              </View>
              <View style={styles.viewMiddle}>
                <View style={styles.viewOne}>
                  <View style={styles.viewClassification}>
                    <Text style={styles.textClassification}>{item.employee.name}</Text>
                  </View>
                </View>
                {item.entries.map((v, k) => {
                  return (
                    <View key={k} style={styles.viewTwo}>
                      <Text style={styles.textHours}>Hours: {v.hours}</Text>
                      <Text style={styles.textRateType}>{_.startCase(v.rate_slug)}</Text>
                    </View>
                  )
                })}
              </View>
              <View style={styles.viewRight}>
                <TouchableOpacity
                  onPress={() => {
                    this.setState((prevState) => {
                      return {
                        logsB: prevState.logsB.filter((log) => {
                          if (log.classification !== item.classification) {
                            return true
                          }
                          if (log.employee.id !== item.employee.id) {
                            return true
                          }
                          if (
                            log.entries[0].rate_slug !== item.entries[0].rate_slug ||
                            log.entries[0].hours !== item.entries[0].hours
                          ) {
                            return log
                          }
                          return false
                        }),
                      }
                    })
                  }}
                >
                  <View style={styles.viewDelete}>{buildIcon('solid', COLORS.redDark, 'times-circle', 30)}</View>
                </TouchableOpacity>
              </View>
            </View>
          )
        })}

        <View>
          <Text style={styles.textHoursTotal}>Total Hours: {totalHours}</Text>
        </View>
      </View>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }

  handleSubmit = () => {
    const logs = this.state.logsB.map((log) => {
      delete log.checked
      return log
    })
    const values = {
      ticket: this.ticket,
      type: 'hour',
      date: selector(this.props, 'date'),
      data: this.buildData(logs),
      requires_auth: false,
      authorization: null,
    }
    const success = () => {
      this.setState({
        logsA: [],
        logsB: [],
        isAVisible: false,
        isBVisible: false,
        requiresSignature: true,
      })
      this.props.dispatch(reset('tickets-hours-add'))
      this.props.navigation.navigate('TicketsHoursAddSuccess', {
        id: this.props.navigation.getParam('id', ''),
      })
    }
    const failure = (data) => {
      throw new SubmissionError({
        _error: buildError(data),
      })
    }
    return insert(values, this.props.dispatch).then(success).catch(failure)
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginLeft: '20rem',
        marginRight: '20rem',
        marginTop: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  renderReuseLastLog = () => {
    if (this.state.logsB.length === 0) {
      return (
        <View
          style={{
            padding: 20,
            paddingTop: 20,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              const logs = _.filter(this.props.ticketsHours.data, (log) => log.type === 'hour')
              const updatedLogs = logs.reduce((log, value) => {
                const found = log.find(
                  (match) =>
                    match.data.employee.id === value.data.employee.id && match.data.rate_slug === value.data.rate_slug,
                )
                if (found) {
                  found.data.hours = (Number(found.data.hours) + Number(value.data.hours)).toString()
                } else log.push(value)
                return log
              }, [])

              if (updatedLogs.length) {
                const filteredLogs = _.chain(updatedLogs)
                  .groupBy((log) => format(parse(log.created_at_small, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd'))
                  .toPairs()
                  .sortBy((pair) => pair[0])
                  .reverse()
                  .value()[0][1]
                this.setState({
                  logsB: filteredLogs.map((filteredLog) => {
                    const { rate_slug, hours, ...rest } = filteredLog.data

                    const updatedLog = {
                      ...rest,
                      entries: [{ rate_slug, hours }],
                    }

                    return updatedLog
                  }),
                })
              }
            }}
          >
            <View
              style={{
                alignContent: 'center',
                alignItems: 'center',
                backgroundColor: COLORS.white,
                borderColor: COLORS.grayDark,
                borderRadius: 5,
                borderWidth: 1,
                flexDirection: 'row',
                justifyContent: 'center',
                paddingBottom: 15,
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 15,
                textAlign: 'center',
              }}
            >
              <View
                style={{
                  paddingRight: 10,
                }}
              >
                {buildIcon('solid', COLORS.grayDark, 'recycle', 15)}
              </View>
              <Text
                style={{
                  fontSize: 15,
                  lineHeight: 15,
                  textAlign: 'center',
                  color: COLORS.grayDark,
                }}
              >
                Reuse Last Log
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      )
    }
    return null
  }
  renderDate = () => {
    if (this.state.logsB.length === 0) {
      return (
        <View
          style={{
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <View
            style={{
              marginBottom: 10,
            }}
          >
            <Text
              style={{
                color: COLORS.grayDark,
                fontSize: 15,
                fontWeight: 'bold',
                lineHeight: 20,
              }}
            >
              Date
            </Text>
          </View>
          <Field component={DateTimePicker} mode="date" name="date" type="text" />
        </View>
      )
    }
    return null
  }

  renderButtons1 = () => {
    if (this.state.logsB.length === 0) {
      return (
        <View
          style={{
            flexDirection: 'row',
            padding: 20,
            paddingTop: 0,
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.setState({ isAVisible: true })
              }}
            >
              <Button secondary>Add</Button>
            </TouchableOpacity>
          </View>
        </View>
      )
    }
    return (
      <View
        style={{
          flexDirection: 'row',
          padding: 20,
          paddingTop: 20,
        }}
      >
        <View
          style={{
            flex: 1,
            paddingRight: 10,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              const logs = this.state.logsB.filter((log) => log.checked)
              if (logs.length) {
                this.setState({
                  logsA: logs,
                  isBVisible: true,
                })
              }
            }}
          >
            <Button secondary>Edit</Button>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flex: 1,
            paddingLeft: 10,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.setState({ isAVisible: true })
            }}
          >
            <Button secondary>Add</Button>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  renderButtons2 = () => {
    if (this.state.logsB.length === 0) {
      return null
    }
    if (!this.ticket.project.hour_log_signature_required) {
      return null
    }
    return (
      <View
        style={{
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 20,
        }}
      >
        {/* */}
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View>
            <Checkbox
              color={this.state.requiresSignature === true ? COLORS.orange : COLORS.grayDark}
              onValueChange={() => {
                this.setState((prevState) => {
                  return {
                    requiresSignature: !prevState.requiresSignature,
                  }
                })
              }}
              value={this.state.requiresSignature === true}
            />
          </View>
          <View
            style={{
              flex: 1,
              paddingLeft: 10,
            }}
          >
            <Text
              style={{
                color: COLORS.grayDark,
                fontSize: 15,
                fontWeight: 'bold',
                lineHeight: 20,
              }}
            >
              Requires Signature
            </Text>
          </View>
        </View>
        {/* */}
      </View>
    )
  }

  renderButtons3 = () => {
    if (this.state.logsB.length === 0) {
      return null
    }
    if (this.ticket.project.hour_log_signature_required && this.state.requiresSignature) {
      return (
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              flex: 1,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 20,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                const logs = this.buildData(this.state.logsB)
                this.setState({
                  logsA: [],
                  logsB: [],
                  isAVisible: false,
                  isBVisible: false,
                  requiresSignature: true,
                })
                this.props.navigation.navigate('TicketsHoursAddAuthorization', {
                  id: this.props.navigation.getParam('id', ''),
                  date: selector(this.props, 'date'),
                  logs,
                })
              }}
            >
              <Button primary>Create and Get Authorization</Button>
            </TouchableOpacity>
          </View>
        </View>
      )
    }
    return (
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            flex: 1,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
          }}
        >
          <TouchableOpacity onPress={this.props.handleSubmit(this.handleSubmit)}>
            <Button primary>Create</Button>
          </TouchableOpacity>
        </View>
      </View>
    )
  }

  buildData = (logs) => {
    const data = _.flatten(
      _.map(logs, (log) => {
        return _.map(log.entries, (entry) => {
          return {
            classification: log.classification,
            employee: log.employee,
            hours: entry.hours,
            rate_slug: entry.rate_slug,
          }
        })
      }),
    )
    return data
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    rateTypes: state.rateTypes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsHoursAddForm = connect(mapStateToProps, mapDispatchToProps)(TicketsHoursAddForm)

TicketsHoursAddForm = reduxForm({
  form: 'tickets-hours-add',
  initialValues,
  validate,
})(TicketsHoursAddForm)

export { TicketsHoursAddForm }
