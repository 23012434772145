import React from 'react'
import RadioForm, { RadioButtonInput, RadioButtonLabel } from 'react-native-simple-radio-button'

import { COLORS } from '../constants/colors'

class Radio extends React.Component {
  render = () => {
    return (
      <RadioForm formHorizontal onChangeText={this.props.input.onChange} {...this.props.input} {...this.props}>
        {this.props.options.map((value, key) => {
          return (
            <React.Fragment key={key}>
              <RadioButtonInput
                borderWidth={1}
                buttonInnerColor={this.props.input.value === value.value ? COLORS.orange : COLORS.white}
                buttonOuterColor={COLORS.orange}
                buttonOuterSize={20}
                buttonSize={15}
                buttonStyle={{
                  color: COLORS.grayDark,
                }}
                index={key}
                isSelected={this.props.input.value === value.value}
                obj={value}
                onPress={() => {
                  this.props.input.onChange(value.value)
                }}
              />
              <RadioButtonLabel
                index={key}
                labelHorizontal
                labelStyle={{
                  fontSize: 15,
                  color: COLORS.grayDark,
                  marginRight: 10,
                }}
                obj={value}
                onPress={() => {
                  this.props.input.onChange(value.value)
                }}
              />
            </React.Fragment>
          )
        })}
      </RadioForm>
    )
  }
}

export { Radio }
