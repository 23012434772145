import { createFormAction } from 'redux-form-saga'

const SELECT_REQUEST = 'tickets/select/request'
const SELECT_SUCCESS = 'tickets/select/success'
const SELECT_FAILURE = 'tickets/select/failure'
const SELECT_FILTERS = 'tickets/select/filters'

const INSERT = createFormAction('TICKETS_INSERT')

const UPDATE = createFormAction('TICKETS_UPDATE')

const UPDATE_REQUEST = 'tickets/update/request'
const UPDATE_SUCCESS = 'tickets/update/success'
const UPDATE_FAILURE = 'tickets/update/failure'

export {
  SELECT_REQUEST,
  SELECT_SUCCESS,
  SELECT_FAILURE,
  SELECT_FILTERS,
  INSERT,
  UPDATE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
}
