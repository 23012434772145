import { createFormAction } from 'redux-form-saga'

const SELECT_REQUEST = 'tickets-hours/select/request'
const SELECT_SUCCESS = 'tickets-hours/select/success'
const SELECT_FAILURE = 'tickets-hours/select/failure'

const INSERT = createFormAction('TICKETS_HOURS_INSERT')
const UPDATE = createFormAction('TICKETS_HOURS_UPDATE')
const DELETE = createFormAction('TICKETS_HOURS_DELETE')
const AUTHORIZE = createFormAction('TICKETS_HOURS_AUTH')

export { SELECT_REQUEST, SELECT_SUCCESS, SELECT_FAILURE, INSERT, UPDATE, DELETE, AUTHORIZE }
