import _ from 'lodash'
import React from 'react'
import { KeyboardAvoidingView, Platform, Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm, reset, SubmissionError } from 'redux-form'

import { insert } from '../actions/materials'
import { Button } from '../components/button'
import { Input } from '../components/input'
import { Modal } from '../components/modal'
import { Notice } from '../components/notice'
import { Spinner } from '../components/spinner'
import { COLORS } from '../constants/colors'
import { buildError, buildIcon } from '../modules/builders'
import { sanitizeNumber } from '../modules/helpers'

const initialValues = {
  name: '',
  quantity: '',
  size: '',
}

const validate = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Invalid Name'
  }
  if (!values.quantity) {
    errors.quantity = 'Invalid Quantity'
  }
  if (!values.size) {
    errors.size = 'Invalid Size'
  }
  return errors
}

const selector = formValueSelector('materials-add')

const styles = EStyleSheet.create({
  formView: {
    backgroundColor: COLORS.white,
    borderRadius: '5rem',
  },
  formViewTop: {
    alignItems: 'center',
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    padding: '20rem',
  },
  formTextTop: {
    color: COLORS.grayDark,
    fontSize: '20rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  formViewMiddle: {
    padding: '20rem',
    paddingBottom: '0rem',
    paddingTop: '0rem',
  },
  formViewGroup: {
    flexDirection: 'row',
  },
  formViewControl: {
    paddingTop: '20rem',
  },
  formViewControlLeft: {
    flex: 1,
    paddingRight: '10rem',
    paddingTop: '20rem',
  },
  formViewControlRight: {
    flex: 1,
    paddingLeft: '10rem',
    paddingTop: '20rem',
  },
  formTextLabel: {
    color: COLORS.grayDark,
    fontSize: '15rem',
    fontWeight: 'bold',
    lineHeight: '20rem',
    marginBottom: '10rem',
  },
  formViewBottom: {
    flexDirection: 'row',
    padding: '20rem',
  },
  formViewBottomLeft: {
    flex: 1,
    paddingRight: '10rem',
  },
  formViewBottomRight: {
    flex: 1,
    paddingLeft: '10rem',
  },
  formViewError: {
    marginTop: '20rem',
  },
  successView: {
    backgroundColor: COLORS.white,
    borderRadius: '5rem',
    padding: '20rem',
  },
  successViewIcon: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '20rem',
  },
  successViewTitle: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '20rem',
  },
  successTextTitle: {
    color: COLORS.greenDark,
    fontSize: '20rem',
    fontWeight: 'bold',
    lineHeight: '20rem',
  },
  successViewMessage: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '20rem',
  },
  successTextMessage: {
    color: COLORS.grayDark,
    fontSize: '15rem',
    lineHeight: '20rem',
  },
  successViewButton: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

class MaterialsAdd extends React.Component {
  state = {
    form: true,
    success: false,
  }

  render = () => {
    if (this.state.form) {
      return this.renderForm()
    }
    if (this.state.success) {
      return this.renderSuccess()
    }
    return null
  }

  renderForm = () => {
    return (
      <Modal isVisible={this.props.isVisible}>
        <Spinner visible={this.props.materials.loading} />
        <View style={styles.formView}>
          <View style={styles.formViewTop}>
            <Text style={styles.formTextTop}>Add materials or equipment</Text>
          </View>
          <View style={styles.formViewMiddle}>
            <KeyboardAvoidingView behavior="padding">
              <View style={styles.formViewControl}>
                <Text style={styles.formTextLabel}>Name</Text>
                <Field component={Input} keyboardType="default" name="name" type="text" />
              </View>
              <View style={styles.formViewGroup}>
                <View style={styles.formViewControlLeft}>
                  <Text style={styles.formTextLabel}>Quantity</Text>
                  <Field
                    component={Input}
                    keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
                    name="quantity"
                    normalize={(val) => sanitizeNumber(val)}
                    type="text"
                  />
                </View>
                <View style={styles.formViewControlRight}>
                  <Text style={styles.formTextLabel}>Size</Text>
                  <Field
                    component={Input}
                    keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
                    name="size"
                    normalize={(val) => sanitizeNumber(val)}
                    type="text"
                  />
                </View>
              </View>
              {this.renderFormError()}
            </KeyboardAvoidingView>
          </View>
          <View style={styles.formViewBottom}>
            <View style={styles.formViewBottomLeft}>
              <TouchableOpacity
                onPress={() => {
                  this.handleCancel()
                }}
                onMouseDown={(e) => e.preventDefault()}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View style={styles.formViewBottomRight}>
              <TouchableOpacity onPress={this.props.handleSubmit(this.handleSubmit)}>
                <Button primary>Submit</Button>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  handleCancel = () => {
    this.props.initialize(initialValues, true)
    return this.props.onPressClose()
  }

  renderFormError = () => {
    if (!this.props.error) {
      return null
    }
    return (
      <View style={styles.formViewError}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  renderSuccess = () => {
    return (
      <Modal isVisible={this.props.isVisible}>
        <View style={styles.successView}>
          <View style={styles.successViewIcon}>{buildIcon('solid', COLORS.greenDark, 'check', 30)}</View>
          <View style={styles.successViewTitle}>
            <Text style={styles.successTextTitle}>Success</Text>
          </View>
          <View style={styles.successViewMessage}>
            <Text style={styles.successTextMessage}>Your materials and equipment have been logged.</Text>
          </View>
          <View style={styles.successViewButton}>
            <TouchableOpacity onPress={this.handleClose}>
              <Button primary>OK</Button>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    )
  }

  handleSubmit = () => {
    const quantity = selector(this.props, 'quantity')
    const size = selector(this.props, 'size')
    const values = {
      name: selector(this.props, 'name'),
      pending: true,
    }
    const success = (data) => {
      const logs = [
        {
          category: {
            id: 0,
            name: '',
          },
          material: {
            id: data.id,
            name: data.name,
            pending: data.pending,
            order_unit: data.order_unit,
            price_unit: data.price_unit,
            price: 0,
            is_equipment: false,
          },
          entries: [
            {
              quantity,
              size,
            },
          ],
          checked: false,
        },
      ]
      this.props.onSuccess(logs)
      this.props.dispatch(reset('materials-add'))
      this.setState({
        form: false,
        success: true,
      })
    }
    const failure = (data) => {
      throw new SubmissionError({
        _error: buildError(data),
      })
    }
    return insert(values, this.props.dispatch).then(success).catch(failure)
  }

  handleClose = () => {
    this.setState({
      form: true,
      success: false,
    })
    this.props.onPressClose()
  }

  buildCategories = () => {
    if (!this.props.materials.data) {
      return []
    }
    const map = _.map(this.props.materials.data.data, (material) => {
      return {
        label: material.category.name,
        key: material.category.id,
        value: material.category.id,
      }
    })
    const uniqBy = _.uniqBy(map, (item) => item.key)
    return uniqBy
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

MaterialsAdd = connect(mapStateToProps, mapDispatchToProps)(MaterialsAdd)

MaterialsAdd = reduxForm({
  destroyOnUnmount: false,
  form: 'materials-add',
  initialValues,
  validate,
})(MaterialsAdd)

export { MaterialsAdd }
