import AsyncStorage from '@react-native-async-storage/async-storage'
import { applyMiddleware, createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import formActionSaga from 'redux-form-saga'
import { persistReducer, persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'

import { bookmarks as bookmarksReducer } from './reducers/bookmarks'
import { dailyReports as dailyReportsReducer } from './reducers/daily-reports'
import { employees as employeesReducer } from './reducers/employees'
import { locations as locationsReducer } from './reducers/locations'
import { materials as materialsReducer } from './reducers/materials'
import { projects as projectsReducer } from './reducers/projects'
import { rateTypes as rateTypesReducer } from './reducers/rate-types'
import { reportTemplates as reportTemplatesReducer } from './reducers/report-templates'
import { scheduleDelays as scheduleDelaysReducer } from './reducers/schedule-delays'
import { tickets as ticketsReducer } from './reducers/tickets'
import { ticketsHours as ticketsHoursReducer } from './reducers/tickets-hours'
import { ticketsMaterials as ticketsMaterialsReducer } from './reducers/tickets-materials'
import { users as usersReducer } from './reducers/users'
import { bookmarks as bookmarksSaga } from './sagas/bookmarks'
import { dailyReports as dailyReportsSaga } from './sagas/daily-reports'
import { employees as employeesSaga } from './sagas/employees'
import { locations as locationsSaga } from './sagas/locations'
import { materials as materialsSaga } from './sagas/materials'
import { projects as projectsSaga } from './sagas/projects'
import { rateTypes as rateTypesSaga } from './sagas/rate-types'
import { reportTemplates as reportTemplatesSaga } from './sagas/report-templates'
import { scheduleDelays as scheduleDelaysSaga } from './sagas/schedule-delays'
import { tickets as ticketsSaga } from './sagas/tickets'
import { ticketsHours as ticketsHoursSaga } from './sagas/tickets-hours'
import { ticketsMaterials as ticketsMaterialsSaga } from './sagas/tickets-materials'
import { users as usersSaga } from './sagas/users'

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  persistReducer(
    {
      key: 'root',
      storage: AsyncStorage,
    },
    combineReducers({
      bookmarks: bookmarksReducer,
      dailyReports: dailyReportsReducer,
      employees: employeesReducer,
      form: formReducer,
      locations: locationsReducer,
      materials: materialsReducer,
      projects: projectsReducer,
      rateTypes: rateTypesReducer,
      reportTemplates: reportTemplatesReducer,
      scheduleDelays: scheduleDelaysReducer,
      tickets: ticketsReducer,
      ticketsHours: ticketsHoursReducer,
      ticketsMaterials: ticketsMaterialsReducer,
      users: usersReducer,
    }),
  ),
  applyMiddleware(sagaMiddleware),
)

sagaMiddleware.run(formActionSaga)

const sagas = function* sagas() {
  yield all([
    bookmarksSaga(),
    employeesSaga(),
    materialsSaga(),
    locationsSaga(),
    projectsSaga(),
    rateTypesSaga(),
    reportTemplatesSaga(),
    scheduleDelaysSaga(),
    ticketsSaga(),
    ticketsHoursSaga(),
    ticketsMaterialsSaga(),
    usersSaga(),
    dailyReportsSaga(),
  ])
}

sagaMiddleware.run(sagas)

const persistor = persistStore(store)

export { persistor, store }
