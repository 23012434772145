import React, { useRef } from 'react'
import { Dimensions, Modal, Platform, TouchableOpacity, View } from 'react-native'
import SignatureScreen from 'react-native-signature-canvas'
import SignatureCanvas from 'react-signature-canvas'

import { COLORS } from '../constants/colors'
import { SimpleButton } from './simple-button'

export const SignatureModal = ({ visible, onSubmit, onClose }) => {
  const state = {
    signature: null,
  }

  const refs = {
    rnsc: useRef(),
    rsc: useRef(),
  }

  const webStyle = `
  html {
    backgroundColor: #fff;
    border: 0;
    height: 100%;
    width: 100%;
  }
  body {
    backgroundColor: #fff;
    border: 0;
    height: 100%;
    width: 100%;
  }
  .m-signature-pad {
    border: 0;
    box-shadow: none;
  }
  .m-signature-pad--body {
    border: 0;
  }
  .m-signature-pad--body canvas {
    border: 0;
    box-shadow: none;
  }
  .m-signature-pad--footer .save {
      display: none;
  }
  .m-signature-pad--footer .clear {
      display: none;
  }
  `

  const handleSubmit = async () => {
    if (Platform.OS !== 'web') {
      onSubmit(state.signature)
    } else {
      onSubmit(refs.rsc.toDataURL('image/png'))
    }
  }

  const clearCanvas = () => {
    if (Platform.OS !== 'web') {
      // refs.rnsc.current.clearSignature()
    } else {
      refs.rsc.clear()
    }
  }

  const handleRNSCClear = () => {
    state.signature = null
  }

  const handleRNSCEmpty = () => {
    state.signature = null
  }

  const handleRNSCEnd = () => {
    refs.rnsc.current.readSignature()
  }

  const handleRNSCOK = (signature) => {
    state.signature = signature
  }

  return (
    <Modal ariaHideApp={false} onRequestClose={() => {}} onShow={clearCanvas} transparent visible={visible}>
      <View
        style={{
          flex: 1,
          backgroundColor: COLORS.white,
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 40,
          ...Platform.select({
            native: {
              width: '100%',
            },
          }),
        }}
      >
        <View
          style={{
            backgroundColor: COLORS.white,
            borderColor: COLORS.grayLight,
            borderRadius: 5,
            borderStyle: 'dashed',
            borderWidth: 2,
            ...Platform.select({
              native: {
                height: '75%',
                width: '100%',
                padding: 20,
              },
            }),
          }}
        >
          {Platform.OS !== 'web' ? (
            <SignatureScreen
              backgroundColor={COLORS.white}
              descriptionText=""
              onClear={handleRNSCClear}
              onEmpty={handleRNSCEmpty}
              onEnd={handleRNSCEnd}
              onOK={handleRNSCOK}
              ref={refs.rnsc}
              rotated
              webStyle={webStyle}
            />
          ) : (
            <SignatureCanvas
              canvasProps={{
                height: 250,
                width: Dimensions.get('window').width * 0.9,
              }}
              ref={(ref) => (refs.rsc = ref)}
            />
          )}
        </View>
        <View
          style={{
            ...Platform.select({
              native: {
                width: '25%',
                transform: [{ rotate: '90deg' }],
              },
              default: {
                flexDirection: 'row',
                marginTop: 20,
              },
            }),
          }}
        >
          <View style={{ marginBottom: 20 }}>
            <TouchableOpacity onPress={handleSubmit}>
              <SimpleButton primary>Submit</SimpleButton>
            </TouchableOpacity>
          </View>
          <View style={{ marginBottom: 20 }}>
            <TouchableOpacity onPress={clearCanvas}>
              <SimpleButton>Reset</SimpleButton>
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity onPress={onClose}>
              <SimpleButton>Close</SimpleButton>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  )
}
