import React, { useRef, useState } from 'react'
import { FlatList, StyleSheet, Text, TouchableOpacity, Modal, View } from 'react-native'

const Dropdown = ({ placeholder, data, input, ...rest }) => {
  const DropdownButton = useRef()
  const [visible, setVisible] = useState(false)
  const [selected, setSelected] = useState(undefined)
  const [dropdownTop, setDropdownTop] = useState(0)

  const selectedItem = data.find((d) => d.value === input?.value)

  const toggleDropdown = () => {
    visible ? setVisible(false) : openDropdown()
  }

  const openDropdown = () => {
    DropdownButton.current.measure((_fx, _fy, _w, h, _px, py) => {
      if (py > 500) {
        setDropdownTop(py - 350 + h)
      } else {
        setDropdownTop(py + h)
      }
    })
    setVisible(true)
  }

  const onItemPress = (item) => {
    setSelected(item)
    input.onChange(item.value)
    setVisible(false)
  }

  const renderItem = ({ item }) => {
    let selectedStyles = item.key === selected?.key ? styles.itemSelected : styles.item
    return (
      <TouchableOpacity style={selectedStyles} onPress={() => onItemPress(item)}>
        <Text style={styles.selected}>{item.label}</Text>
      </TouchableOpacity>
    )
  }

  const renderDropdown = () => {
    return (
      <Modal visible={visible} transparent animationType="none">
        <TouchableOpacity style={styles.overlay} onPress={() => setVisible(false)}>
          <View style={[styles.dropdown, { top: dropdownTop }]}>
            <FlatList data={data} renderItem={renderItem} keyExtractor={(item, index) => index.toString()} />
          </View>
        </TouchableOpacity>
      </Modal>
    )
  }

  const inputValue = parseInputValue(input)

  const selectedLabel = selectedItem?.label || selected?.label || inputValue || placeholder?.label || 'Select...'

  return (
    <TouchableOpacity ref={DropdownButton} style={styles.button} onPress={toggleDropdown}>
      {renderDropdown()}
      <Text style={styles.buttonText}>{selectedLabel}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 6,
    height: 50,
    zIndex: 1,
  },
  buttonText: {
    flex: 1,
    textAlign: 'left',
    paddingLeft: 16,
  },
  icon: {
    marginRight: 10,
  },
  dropdown: {
    position: 'absolute',
    backgroundColor: '#fff',
    width: '95%',
    shadowColor: '#000000',
    shadowRadius: 4,
    shadowOffset: { height: 4, width: 0 },
    shadowOpacity: 0.15,
    marginLeft: 12,
    maxHeight: 300,
    border: 'solid 1px #ccc',
    borderRadius: 4,
    overflow: 'hidden',
  },
  overlay: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.24)',
  },
  item: {
    paddingHorizontal: 12,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: '#DDD',
  },
  itemSelected: {
    paddingHorizontal: 12,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: '#DDD',
    backgroundColor: '#DFECE8',
  },
})

function parseInputValue(input) {
  if (typeof input?.value === 'string') return input?.value
  if (typeof input?.value?.name === 'string') return input?.value?.name
}

export default Dropdown
