import { createIconSet } from '@expo/vector-icons'
import { format } from 'date-fns'
import * as Updates from 'expo-updates'
import _ from 'lodash'
import React from 'react'
import EStyleSheet from 'react-native-extended-stylesheet'

import { signOut } from '../actions/users'
import { api } from '../api'
import { default as map } from '../assets/fonts/font-awesome.json'
import { COLORS } from '../constants/colors'
import { PHOTOS } from '../constants/photos'

EStyleSheet.build({
  $outline: 0,
  $rem: 1,
})

const Icons = {
  brands: createIconSet(map, 'font-awesome-brands'),
  light: createIconSet(map, 'font-awesome-light'),
  regular: createIconSet(map, 'font-awesome-regular'),
  solid: createIconSet(map, 'font-awesome-solid'),
}

const buildAPI = (store) => {
  const requestFulfilled = (config) => {
    config.headers.Accept = 'application/json'
    if (store.getState().users.signIn.data !== null) {
      config.headers.Authorization = `Bearer ${store.getState().users.signIn.data.token}`
    }
    return config
  }

  const requestRejected = (error) => Promise.reject(error)

  api.interceptors.request.use(requestFulfilled, requestRejected)

  const responseFulfilled = (response) => response

  const responseRejected = async (error) => {
    if (error.response && error.response.status === 401) {
      if (error.response.config.url.indexOf('/login') === -1) {
        store.dispatch(signOut())
        await Updates.reloadAsync()
      }
    }
    return Promise.reject(error)
  }

  api.interceptors.response.use(responseFulfilled, responseRejected)
}

const buildDateTime = (dateTime) => {
  if (typeof dateTime === 'string') {
    return dateTime
  }
  return format(dateTime, 'MM/dd/yyyy h:mm a')
}

const buildDate = (date) => {
  if (typeof date === 'string') {
    return date
  }
  return format(date, 'MM/dd/yyyy')
}

const buildTime = (time) => {
  if (typeof time === 'string') {
    return time
  }
  return format(time, 'h:mm a')
}

const buildError = (_) => {
  return 'An unknown error has occurred. Please try again later.'
}

const buildIcon = (type, color, name, size) => {
  const Icon = Icons[type]
  return <Icon color={color} name={name} size={size} />
}

const buildNavigationOptions = (target) => {
  const source = {
    headerRight: () => null,
    headerStyle: {
      backgroundColor: COLORS.white,
      borderBottomColor: COLORS.grayLight,
      borderBottomWidth: EStyleSheet.value('1rem'),
      elevation: EStyleSheet.value('0rem'),
      shadowOpacity: EStyleSheet.value('0rem'),
    },
    headerTintColor: COLORS.grayDark,
    headerTitleStyle: {
      alignSelf: 'center',
      flexGrow: 1,
      fontSize: EStyleSheet.value('20rem'),
      textAlign: 'center',
    },
    headerTitle: () => null,
  }
  return { ...source, ...target }
}

const buildPhoto = () => {
  return _.sample(PHOTOS)
}

const buildReport = (project, reportTemplates) => {
  return {
    project: project.id,

    date: format(new Date(), 'MM/dd/yyyy'),

    data: {
      start_time: format(new Date(), 'h:mm a'),

      weather: {
        condition: {
          icon: null,
          value: '',
        },
        temperature_high: {
          value: '',
        },
        temperature_low: {
          value: '',
        },
      },
      ground: {
        condition: {
          value: '',
        },
      },

      notes: {
        rows: [],
      },

      user_defined_input_sections: reportTemplates.data.user_defined_input_sections,
      user_defined_input: reportTemplates.data.user_defined_input,

      labor: {
        rows: [],
      },

      equipment: {
        rows: [],
      },
      trade_inspections: {
        rows: [],
      },

      vendor_deliveries: {
        rows: [],
      },

      subcontractors: {
        rows: [],
      },

      areas_work_performed: {
        rows: [],
      },

      areas_not_ready: {
        rows: [],
      },
    },

    status: 'draft',
  }
}

export {
  buildAPI,
  buildDateTime,
  buildDate,
  buildTime,
  buildError,
  buildIcon,
  buildNavigationOptions,
  buildPhoto,
  buildReport,
}
