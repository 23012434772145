import _ from 'lodash'
import React from 'react'
import { ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native'

import { Button } from '../components/button'
import { Modal } from '../components/modal'
import { COLORS } from '../constants/colors'
import { buildIcon } from '../modules/builders'

class EmployeesA extends React.Component {
  state = {
    keywords: '',
    classifications: {},
    employees: {},
  }

  render = () => {
    return (
      <Modal isVisible={this.props.isVisible}>
        <View
          style={{
            flex: 1,
            backgroundColor: COLORS.white,
            borderRadius: 5,
          }}
        >
          <View
            style={{
              alignItems: 'center',
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'row',
              padding: 20,
            }}
          >
            {buildIcon('solid', COLORS.grayDark, 'search', 20)}
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <TextInput
                keyboardType="default"
                onChangeText={(keywords) => this.setState({ keywords })}
                placeholder="Search employees..."
                style={{
                  backgroundColor: COLORS.white,
                  borderColor: COLORS.grayLight,
                  borderRadius: 5,
                  borderWidth: 1,
                  color: COLORS.grayDark,
                  fontSize: 20,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 10,
                }}
                value={this.state.keywords}
              />
            </View>
            <TouchableOpacity
              onPress={() => {
                this.setState({ keywords: '' })
              }}
            >
              {buildIcon('solid', COLORS.grayDark, 'times-circle', 20)}
            </TouchableOpacity>
          </View>
          <ScrollView
            style={{
              flexGrow: 1,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 0,
            }}
          >
            {_.map(this.getClassifications(), (classification) => {
              return (
                <View key={classification}>
                  <TouchableOpacity onPress={() => this.toggleClassifications(classification)}>
                    <View
                      style={{
                        borderBottomColor: COLORS.grayLight,
                        borderBottomWidth: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        paddingBottom: 10,
                        paddingTop: 10,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                        }}
                      >
                        <Text
                          style={{
                            color: COLORS.grayDark,
                            fontSize: 15,
                            fontWeight: 'bold',
                          }}
                        >
                          {classification}
                        </Text>
                      </View>
                      <View
                        style={{
                          paddingRight: 10,
                        }}
                      >
                        <Text
                          style={{
                            color: COLORS.grayMedium,
                            fontSize: 15,
                            fontWeight: 'normal',
                          }}
                        >
                          Selected: {this.getSelected(classification)}
                        </Text>
                      </View>
                      {buildIcon(
                        'solid',
                        COLORS.grayDark,
                        this.isOpen(classification) ? 'chevron-up' : 'chevron-down',
                        15,
                      )}
                    </View>
                  </TouchableOpacity>
                  {this.renderItems(classification)}
                </View>
              )
            })}
          </ScrollView>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 10,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity onPress={this.props.onPressCancel}>
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 10,
                paddingRight: 20,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity onPress={() => this.props.onPressConfirm(this.getData())}>
                <Button primary>Confirm</Button>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  renderItems = (classification) => {
    if (!this.isOpen(classification)) {
      return null
    }
    const employees = this.getEmployees()
    return _.map(employees, (employee) => {
      if (employee.classifications.indexOf(classification) === -1) {
        return null
      }
      const isSelected = this.isSelected(employee, classification)
      return (
        <View key={employee.name}>
          <TouchableOpacity onPress={() => this.toggleEmployees(employee, classification)}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                paddingBottom: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                  }}
                >
                  {employee.name}
                </Text>
              </View>
              {buildIcon('regular', isSelected ? COLORS.grayDark : COLORS.grayLight, 'check-circle', 15)}
            </View>
          </TouchableOpacity>
        </View>
      )
    })
  }

  getEmployees = () => {
    const filter = _.filter(this.props.employees, (employee) => this.isAMatch(employee))
    const sortBy = _.sortBy(filter, (employee) => employee.name)
    return sortBy
  }

  getClassifications = () => {
    const employees = this.getEmployees()
    const map = _.map(employees, (item) => item.classifications)
    const flatten = _.flatten(map)
    const uniq = _.uniq(flatten)
    uniq.sort()
    const availableClassifications = this.props.rates
      .filter((classification) => classification.include_in_project === true)
      .map((classification) => classification.classification_name)
    const updatedClassifications = uniq.filter((classification) => availableClassifications.includes(classification))

    return updatedClassifications
  }

  getData = () => {
    const employees = _.sortBy(this.props.employees, (employee) => employee.name)
    const map = _.map(employees, (employee) => {
      employee.classifications.sort()
      return _.map(employee.classifications, (classification) => {
        if (this.state.employees[employee.name]) {
          if (this.state.employees[employee.name].indexOf(classification) !== -1) {
            return {
              classification,
              employee: {
                id: employee.id,
                name: employee.name,
                email: employee.email,
                phone: employee.phone,
              },
              entries: [
                {
                  hours: '',
                  rate_slug: 'regular_time',
                },
              ],
              checked: false,
            }
          }
        }
      })
    })
    const flatten = _.flatten(map)
    const filter = _.filter(flatten, (item) => item !== undefined)
    this.setState({
      keywords: '',
      classifications: {},
      employees: {},
    })
    return filter
  }

  getSelected = (classification) => {
    return _.filter(this.state.employees, (value, _) => value.indexOf(classification) !== -1).length
  }

  toggleClassifications = (classification) => {
    this.setState((prevState) => {
      return {
        classifications: {
          ...prevState.classifications,
          [classification]: !prevState.classifications[classification],
        },
      }
    })
  }

  toggleEmployees = (employee, classification) => {
    this.setState((prevState) => {
      return {
        employees: {
          ...prevState.employees,
          [employee.name]: _.xor(prevState.employees[employee.name], [classification]),
        },
      }
    })
  }

  isAMatch = (employee) => {
    const keywords = this.state.keywords.toLowerCase()
    if (keywords === '') {
      return true
    }
    if (employee.email && employee.email.toLowerCase().indexOf(keywords) !== -1) {
      return true
    }
    if (employee.name.toLowerCase().indexOf(keywords) !== -1) {
      return true
    }
    if (
      _.filter(employee.classifications, (classification) => classification.toLowerCase().indexOf(keywords) !== -1)
        .length
    ) {
      return true
    }
    return false
  }

  isOpen = (classification) => {
    return this.state.classifications[classification] === true
  }

  isSelected = (employee, classification) => {
    if (!this.state.employees) {
      return false
    }
    if (!this.state.employees[employee.name]) {
      return false
    }
    if (this.state.employees[employee.name].indexOf(classification) === -1) {
      return false
    }
    return true
  }
}

export { EmployeesA }
