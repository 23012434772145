import { SELECT_REQUEST, SELECT_SUCCESS, SELECT_FAILURE, INSERT, UPDATE } from '../types/daily-reports'
import { SIGN_OUT } from '../types/users'

const initialState = {
  data: null,
  exception: null,
  loading: false,
}

const dailyReports = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_REQUEST:
      return {
        data: null,
        exception: null,
        loading: true,
      }
    case SELECT_SUCCESS:
      return {
        data: action.payload.data,
        exception: null,
        loading: false,
      }
    case SELECT_FAILURE:
      return {
        data: null,
        exception: action.payload.exception,
        loading: false,
      }
    case INSERT.REQUEST:
      return {
        ...state,
        exception: null,
        loading: true,
      }
    case INSERT.SUCCESS:
      return {
        ...state,
        exception: null,
        loading: false,
      }
    case INSERT.FAILURE:
      return {
        ...state,
        exception: action.payload.exception,
        loading: false,
      }
    case UPDATE.SUCCESS:
      return {
        ...state,
        exception: null,
        loading: false,
      }
    case UPDATE.FAILURE:
      return {
        ...state,
        exception: action.payload.exception,
        loading: false,
      }
    case SIGN_OUT:
      return initialState
    default:
      return state
  }
}

export { dailyReports }
