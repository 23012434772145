import React from 'react'
import { Text, View } from 'react-native'
import createSelector from 'react-native-conditional-stylesheet'
import EStyleSheet from 'react-native-extended-stylesheet'

import { COLORS } from '../constants/colors'

const styles = EStyleSheet.create({
  view: {
    borderRadius: '5rem',
    color: COLORS.white,
    paddingBottom: '15rem',
    paddingLeft: '30rem',
    paddingRight: '30rem',
    paddingTop: '15rem',
    textAlign: 'center',
  },
  viewSuccess: {
    color: COLORS.greenDark,
  },
  viewWarning: {
    color: COLORS.orange,
  },
  viewFailure: {
    color: COLORS.redLight,
  },
  text: {
    color: COLORS.black,
    fontSize: '15rem',
    lineHeight: '15rem',
    textAlign: 'center',
  },
  textSuccess: {
    color: COLORS.greenDark,
  },
  textWarning: {
    color: COLORS.orange,
  },
  textFailure: {
    color: COLORS.redLight,
  },
})

class Notice extends React.Component {
  render = () => {
    const selector = createSelector(styles)
    const view = selector('view', {
      viewSuccess: this.props.success,
      viewWarning: this.props.warning,
      viewFailure: this.props.failure,
    })
    const text = selector('text', {
      textSuccess: this.props.success,
      textWarning: this.props.warning,
      textFailure: this.props.failure,
    })
    return (
      <View style={view}>
        <Text style={text}>{this.props.children}</Text>
      </View>
    )
  }
}

export { Notice }
