import { Text, View } from 'react-native'

import config from '../config'

export default function MetaData() {
  return (
    <View style={{ flex: 1 }}>
      <Text style={{ color: '#666' }}>App environment {config.appEnv}</Text>
      <Text style={{ color: '#666' }}>App version {config.version}</Text>
      <Text style={{ color: '#666' }}>API {config.api.baseUrl}</Text>
    </View>
  )
}
