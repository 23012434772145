import { registerRootComponent } from 'expo'
import { activateKeepAwake } from 'expo-keep-awake'
import { Text } from 'react-native'

import { App } from './app'

Text.defaultProps = Text.defaultProps || {}
Text.defaultProps.allowFontScaling = false

if (__DEV__) {
  activateKeepAwake()
}

registerRootComponent(App)
