import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import { Modal } from '../components/modal'
import { COLORS } from '../constants/colors'
import { buildIcon } from '../modules/builders'

const styles = EStyleSheet.create({
  viewcontainer: {
    backgroundColor: COLORS.white,
    borderRadius: '5rem',
  },
  viewTitle: {
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    flexDirection: 'row',
    padding: '15rem',
  },
  textTitleLeft: {
    color: COLORS.grayDark,
    fontSize: '18rem',
    fontWeight: 'bold',
  },
  viewTitleRight: {
    flex: 1,
    paddingLeft: '15rem',
  },
  textTitleRight: {
    color: COLORS.redLight,
    fontSize: '18rem',
    fontWeight: 'bold',
  },
  viewHours: {
    alignItems: 'center',
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    flexDirection: 'row',
    padding: '15rem',
  },
  viewHoursLeft: {
    paddingRight: '15rem',
  },
  viewHoursRight: {
    flexGrow: 1,
  },
  textHours: {
    color: COLORS.grayDark,
    fontSize: '15rem',
  },
  viewMaterialsAndEquipment: {
    alignItems: 'center',
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    flexDirection: 'row',
    padding: '15rem',
  },
  viewMaterialsAndEquipmentLeft: {
    paddingRight: '15rem',
  },
  viewMaterialsAndEquipmentRight: {
    flexGrow: 1,
  },
  textMaterialsAndEquipment: {
    color: COLORS.grayDark,
    fontSize: '15rem',
  },
  viewPhotos: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: '15rem',
  },
  viewPhotosLeft: {
    paddingRight: '15rem',
  },
  viewPhotosRight: {
    flexGrow: 1,
  },
  textPhotos: {
    color: COLORS.grayDark,
    fontSize: '15rem',
  },
})

class AddToTicket extends React.Component {
  render = () => {
    return (
      <Modal isVisible={this.props.isVisible}>
        <View style={styles.viewcontainer}>
          <View style={styles.viewTitle}>
            <Text style={styles.textTitleLeft}>Add to Ticket</Text>
            <View style={styles.viewTitleRight}>
              <Text style={styles.textTitleRight}>#{this.props.ticket ? this.props.ticket.number : 'N/A'}</Text>
            </View>
            <TouchableOpacity onPress={this.props.onPressClose}>
              {buildIcon('regular', COLORS.grayDark, 'times-circle', EStyleSheet.value(20))}
            </TouchableOpacity>
          </View>
          <TouchableOpacity onPress={this.props.onPressHours}>
            <View style={styles.viewHours}>
              <View style={styles.viewHoursLeft}>{buildIcon('solid', COLORS.grayDark, 'tools', 20)}</View>
              <View style={styles.viewHoursRight}>
                <Text style={styles.textHours}>Add Hours</Text>
              </View>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={this.props.onPressMaterials}>
            <View style={styles.viewMaterialsAndEquipment}>
              <View style={styles.viewMaterialsAndEquipmentLeft}>
                {buildIcon('solid', COLORS.grayDark, 'ball-pile', 20)}
              </View>
              <View style={styles.viewMaterialsAndEquipmentRight}>
                <Text style={styles.textMaterialsAndEquipment}>Add Materials and Equipment</Text>
              </View>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={this.props.onPressPhotos}>
            <View style={styles.viewPhotos}>
              <View style={styles.viewPhotosLeft}>{buildIcon('solid', COLORS.grayDark, 'images', 20)}</View>
              <View style={styles.viewPhotosRight}>
                <Text style={styles.textPhotos}>Add Photos</Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </Modal>
    )
  }
}

export { AddToTicket }
