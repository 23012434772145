import _ from 'lodash'
import React from 'react'
import { ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { connect } from 'react-redux'

import { fetchFieldwireTasks } from '../api'
import { COLORS } from '../constants/colors'
import { buildIcon } from '../modules/builders'
import { Button } from './button'
import { Modal } from './modal'

class FieldwireTasks extends React.Component {
  state = {
    loading: false,
    keywords: '',
    options: [],
    value: this.props.value || [],
  }

  render = () => {
    return (
      <Modal isVisible={this.props.isVisible}>
        <View
          style={{
            flex: 1,
            backgroundColor: COLORS.white,
            borderRadius: 5,
          }}
        >
          <View
            style={{
              alignItems: 'center',
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'row',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            {buildIcon('solid', COLORS.grayDark, 'search', 20)}
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <TextInput
                keyboardType="default"
                onChangeText={(keywords) => {
                  this.setState({ keywords }, () => {
                    this.setState({ loading: true, options: [] })
                    fetchFieldwireTasks(this.props.project.id, this.state.keywords).then(
                      (response) => {
                        this.setState({ loading: false, options: this.buildOptions(response.data) })
                      },
                      () => {
                        this.setState({ loading: false, options: [] })
                      },
                    )
                  })
                }}
                placeholder="Search Fieldwire Tasks..."
                style={{
                  backgroundColor: COLORS.white,
                  borderColor: COLORS.grayLight,
                  borderRadius: 5,
                  borderWidth: 1,
                  color: COLORS.grayDark,
                  fontSize: 20,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 10,
                }}
                value={this.state.keywords}
              />
            </View>
          </View>
          <ScrollView
            style={{
              flexGrow: 1,
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            {this.renderLoading()}
            {this.renderSelected()}
            {this.renderOptions()}
          </ScrollView>
          <View
            style={{
              flexDirection: 'row',
              padding: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingRight: 10,
              }}
            >
              <TouchableOpacity onPress={this.props.onPressCancel}>
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
              }}
            >
              <TouchableOpacity onPress={() => this.props.onPressConfirm(this.state.value)}>
                <Button primary>Confirm</Button>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  renderLoading = () => {
    if (!this.state.loading) {
      return null
    }
    return (
      <View>
        <View
          style={{
            flexDirection: 'row',
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <Text
            style={{
              color: COLORS.grayDark,
              fontSize: 15,
              fontWeight: 'bold',
            }}
          >
            Loading...
          </Text>
        </View>
      </View>
    )
  }

  renderSelected = () => {
    if (!this.state.value.length) {
      return null
    }
    return (
      <View>
        <View
          style={{
            flexDirection: 'row',
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <Text
            style={{
              color: COLORS.grayDark,
              fontSize: 15,
              fontWeight: 'bold',
            }}
          >
            Selected Tasks
          </Text>
        </View>
        {_.map(this.state.value, (option) => {
          return (
            <View key={option.key}>
              <TouchableOpacity onPress={() => this.toggle(option)}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingBottom: 10,
                    paddingTop: 10,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <Text
                      numberOfLines={1}
                      style={{
                        color: COLORS.grayDark,
                        fontSize: 15,
                      }}
                    >
                      {option.label}
                    </Text>
                  </View>
                  {buildIcon(
                    'regular',
                    this.isSelected(option) ? COLORS.grayDark : COLORS.grayLight,
                    'check-circle',
                    15,
                  )}
                </View>
              </TouchableOpacity>
            </View>
          )
        })}
      </View>
    )
  }

  renderOptions = () => {
    const options = _.filter(
      this.state.options,
      (option) => _.find(this.state.value, (value) => _.isEqual(value, option)) === undefined,
    )
    if (!options.length) {
      return null
    }
    return (
      <View>
        <View
          style={{
            flexDirection: 'row',
            paddingBottom: 10,
            paddingTop: 10,
          }}
        >
          <Text
            style={{
              color: COLORS.grayDark,
              fontSize: 15,
              fontWeight: 'bold',
            }}
          >
            Available Tasks
          </Text>
        </View>
        {_.map(options, (option) => {
          return (
            <View key={option.key}>
              <TouchableOpacity onPress={() => this.toggle(option)}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingBottom: 10,
                    paddingTop: 10,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <Text
                      numberOfLines={1}
                      style={{
                        color: COLORS.grayDark,
                        fontSize: 15,
                      }}
                    >
                      {option.label}
                    </Text>
                  </View>
                  {buildIcon(
                    'regular',
                    this.isSelected(option) ? COLORS.grayDark : COLORS.grayLight,
                    'check-circle',
                    15,
                  )}
                </View>
              </TouchableOpacity>
            </View>
          )
        })}
      </View>
    )
  }

  buildOptions = (options) => {
    return _.map(options, (option) => {
      return {
        key: option.id,
        label: [
          `#${option.number}`,
          option.name,
          option.floor_plan_name,
          option.floor_plan_description,
          option.status,
          option.assignee,
          JSON.parse(option.tags).join(', '),
        ]
          .filter((option) => !!option)
          .join(' - '),
      }
    })
  }

  toggle = (option) => {
    if (this.isSelected(option)) {
      this.setState((prevState) => {
        return {
          value: _.filter(prevState.value, (v) => !_.isEqual(v, option)),
        }
      })
    } else {
      this.setState((prevState) => {
        return {
          value: [...prevState.value, option],
        }
      })
    }
  }

  isSelected = (option) => {
    return _.find(this.state.value, (value) => _.isEqual(value, option)) !== undefined
  }
}

const mapStateToProps = (state) => {
  return {
    state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

FieldwireTasks = connect(mapStateToProps, mapDispatchToProps)(FieldwireTasks)

export { FieldwireTasks }
