import { format } from 'date-fns'
import Checkbox from 'expo-checkbox'
import * as ImageManipulator from 'expo-image-manipulator'
import React from 'react'
import { ActivityIndicator, Image, Platform, ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm, reset } from 'redux-form'

import { selectRequest as selectRequestEmployees } from '../actions/employees'
import { selectRequest as selectRequestTickets } from '../actions/tickets'
import { authorize } from '../actions/tickets-hours'
import { fetchTicket } from '../api'
import { Button } from '../components/button'
import { Notice } from '../components/notice'
import Dropdown from '../components/Dropdown'
import { SignatureModal } from '../components/signature-modal'
import { Spinner } from '../components/spinner'
import { TermsAndConditions } from '../components/terms-and-conditions'
import { COLORS } from '../constants/colors'
import { buildNavigationOptions } from '../modules/builders'

const selector = formValueSelector('tickets-hours-authorization')

class TicketsHoursAuthorization extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerShown: false,
    }
    return buildNavigationOptions(target)
  }

  state = {
    checkboxes: {},
    statuses: {},
    isVisible: false,
    i_agree: false,
    ticket: null,
    fetchingTicket: true,
    scrollEnabled: false,
    signatureModalVisible: false,
    notes: '',
  }
  fetchTicketDetail = () => {
    this.setState({ fetchingTicket: true, ticket: null })
    const request = fetchTicket(this.props.navigation.getParam('id'))
    request.then((resp) => {
      this.setState({ ticket: resp.data, fetchingTicket: false })
    })
  }
  componentDidMount = () => {
    this.fetchTicketDetail()

    this.props.dispatch(selectRequestEmployees())
  }

  saveCanvas = async () => {
    if (!this.state.signatureUri) {
      return null
    }
    return this.state.signatureUri
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  openSignatureModal = () => {
    this.setState({ signatureModalVisible: true })
  }

  handleSubmitSignature = (signatureUri) => {
    this.setState({ signatureUri, signatureModalVisible: false })
  }

  handlePress = (ticket) => {
    this.props.navigation.navigate('RequestSignature', {
      created: false,
      date: this.props.navigation.getParam('date', []),
      id: this.props.navigation.getParam('id', ''),
      logs: this.props.navigation.getParam('logs', []),
      ticket,
      ticket_id: this.props.navigation.getParam('id', ''),
      type: 'hour_log',
    })
  }

  rotate90 = async () => {
    const result = await ImageManipulator.manipulateAsync(this.state.signatureUri, [{ rotate: 90 }], {
      base64: true,
      format: ImageManipulator.SaveFormat.PNG,
    })
    this.setState(() => {
      return {
        signatureUri: 'data:image/png;base64,' + result.base64,
      }
    })
  }

  renderRequestSignature = (ticket) => {
    if (ticket.mobile_request_for_signatures) {
      const recipients = ticket.share_with.filter((value) => value.authorized_signer).length
      return (
        <View
          style={{
            flexDirection: 'row',
            padding: 20,
            paddingBottom: 0,
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            {recipients ? (
              <TouchableOpacity onPress={() => this.handlePress(ticket)}>
                <Button primary>Request Signature</Button>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity disabled>
                <Button primary disabled>
                  Request Signature
                </Button>
              </TouchableOpacity>
            )}
          </View>
        </View>
      )
    } else {
      return null
    }
  }

  render = () => {
    if (this.state.fetchingTicket) {
      return this.renderLoading()
    }
    const ticket = this.state.ticket
    // const ticket = _.find(this.props.tickets.data.data, (item) => item.id === this.props.navigation.getParam('id', ''));
    if (!ticket) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Project</Notice>
        </ScrollView>
      )
    }
    const authorizedBys = this.buildAuthorizedBys(ticket.project)

    const logs = this.props.navigation.getParam('logs', [])

    const totalHours = logs.reduce((a, b) => a + parseFloat(b.data.hours), 0)
    return (
      <View
        style={{
          paddingTop: 20,
          flex: 1,
        }}
      >
        <Spinner visible={this.props.tickets.loading} />
        <ScrollView
          style={{
            flexGrow: 1,
          }}
          scrollEnabled={this.state.scrollEnabled}
        >
          {this.renderError()}
          <View
            style={{
              marginBottom: 10,
              paddingBottom: 20,
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                marginBottom: 10,
              }}
            >
              <View
                style={{
                  marginBottom: 10,
                }}
              >
                <Text style={{ fontWeight: 'bold', fontSize: 18, color: '#555' }}>Hour Log Authorization</Text>
              </View>

              <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 'bold', fontSize: 18 }}>
                  TICKET <Text style={{ color: COLORS.redLight, paddingLeft: 10 }}>#{ticket.number}</Text>
                </Text>
                <Text style={{ fontWeight: 'bold', fontSize: 18 }}>
                  Total Hours: <Text style={{ color: COLORS.orange }}>{totalHours}</Text>
                </Text>
              </View>
            </View>
            <View
              style={{
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                }}
              >
                Authorized By
              </Text>
            </View>
            <Field
              component={Dropdown}
              gray
              data={authorizedBys}
              name="authorizedBy"
              placeholder={{ label: 'Select a user...', key: '', value: '' }}
            />
          </View>
          <View
            style={{
              marginBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                Signature
              </Text>
            </View>
            <SignatureModal
              visible={this.state.signatureModalVisible}
              onSubmit={this.handleSubmitSignature}
              onClose={() => this.setState({ signatureModalVisible: false })}
            />
            {this.state.signatureUri ? (
              <View>
                <View
                  style={{
                    backgroundColor: '#eee',
                    borderColor: COLORS.grayLight,
                    borderRadius: 5,
                    borderStyle: 'dashed',
                    borderWidth: 3,
                    height: 150,
                    padding: 20,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Image
                    source={{
                      uri: this.state.signatureUri,
                    }}
                    style={{
                      flex: 1,
                      width: '100%',
                      height: 150,
                    }}
                  />
                </View>
                <TouchableOpacity onPress={() => this.setState({ signatureUri: null })}>
                  <Button secondary small>
                    Remove
                  </Button>
                </TouchableOpacity>
                <TouchableOpacity onPress={this.rotate90}>
                  <Button secondary small>
                    Rotate
                  </Button>
                </TouchableOpacity>
              </View>
            ) : (
              <View
                style={{
                  backgroundColor: '#eee',
                  borderColor: COLORS.grayLight,
                  borderRadius: 5,
                  borderStyle: 'dashed',
                  borderWidth: 3,
                  height: 150,
                  padding: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <TouchableOpacity onPress={this.openSignatureModal}>
                  <Text style={{ fontSize: 20, color: '#ccc', fontWeight: '800' }}>Tap to add signature</Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
          {ticket.project.signature_notes_for_hour_log ? (
            <View
              style={{
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  GC Notes
                </Text>
              </View>
              <TextInput
                autoGrow
                multiline
                numberOfLines={3}
                onChangeText={(notes) => this.setState({ notes })}
                style={{
                  backgroundColor: COLORS.white,
                  borderColor: COLORS.grayLight,
                  borderRadius: 5,
                  borderWidth: 1,
                  color: COLORS.grayDark,
                  fontSize: 15,
                  lineHeight: 20,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 10,
                }}
                textAlignVertical="top"
                type="textarea"
                value={this.state.notes ? this.state.notes : ''}
              />
            </View>
          ) : null}
          <View
            style={{
              flex: 1,
              marginHorizontal: 20,
            }}
          />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              marginVertical: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <View
              style={{
                paddingRight: 10,
              }}
            >
              <Checkbox
                color={this.state.i_agree === true ? COLORS.orange : COLORS.grayDark}
                onValueChange={() => {
                  this.setState((prevState) => {
                    return {
                      i_agree: !prevState.i_agree,
                    }
                  })
                }}
                value={this.state.i_agree === true}
              />
            </View>
            <TouchableOpacity onPress={() => this.setState({ isVisible: true })}>
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'normal',
                  lineHeight: 20,
                  marginRight: 10,
                }}
              >
                I agree to the{' '}
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  terms and conditions.
                </Text>
              </Text>
            </TouchableOpacity>
          </View>
          <TermsAndConditions
            body={ticket.project.terms.hour_log || 'N/A'}
            isVisible={this.state.isVisible}
            onPressClose={() => this.setState({ isVisible: false })}
          />
        </ScrollView>
        {this.renderRequestSignature(ticket)}
        <View
          style={{
            flexDirection: 'row',
            padding: 20,
          }}
        >
          <View
            style={{
              flex: 1,
              paddingRight: 10,
            }}
          >
            <TouchableOpacity
              disabled={
                !selector(this.props, 'authorizedBy') || this.state.i_agree === false || !this.state.signatureUri
              }
              onPress={this.props.handleSubmit(this.handleSubmit)}
            >
              {!selector(this.props, 'authorizedBy') || this.state.i_agree === false || !this.state.signatureUri ? (
                <Button primary disabled>
                  Authorize Now
                </Button>
              ) : (
                <Button primary>Authorize Now</Button>
              )}
            </TouchableOpacity>
          </View>
          <View
            style={{
              flex: 1,
              paddingLeft: 10,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                this.props.navigation.navigate('TicketsDetail', {
                  id: this.props.navigation.getParam('id', ''),
                })
              }}
            >
              <Button primary>Authorize Later</Button>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginLeft: '20rem',
        marginRight: '20rem',
        marginTop: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  handleSubmit = () => {
    const then = async (signature) => {
      const ticket = this.state.ticket
      const authorizedBy = selector(this.props, 'authorizedBy')
      const authorization = this.buildAuthorization(authorizedBy, signature, ticket)
      const params = {
        ticket: this.props.navigation.getParam('id', ''),
        authorization,
        logs: this.props.navigation.getParam('logs', []).map((log) => log.id),
      }
      authorize(params, this.props.dispatch).then(() => {
        setTimeout(() => {
          this.props.dispatch(
            selectRequestTickets({
              status: 'wip',
              type: 'ticket',
            }),
          )
          this.props.dispatch(reset('tickets-hours-authorization'))
          this.props.navigation.navigate('TicketsHoursAddSuccess', {
            id: this.props.navigation.getParam('id', ''),
          })
        })
      })
      // return Promise.all([
      //   _.map(this.props.navigation.getParam('logs', []), (log) => {
      //     const values = {
      //       ...log,
      //       requires_auth: true,
      //       authorization,
      //       ticket: {
      //         id: this.props.navigation.getParam('id', ''),
      //       },
      //     };
      //     return update(values, this.props.dispatch);
      //   }),
      // ]).then(() => {
      //   setTimeout(() => {
      //     this.props.dispatch(
      //       selectRequestTickets({
      //         status: 'wip',
      //         type: 'ticket',
      //       }),
      //     );
      //     this.props.dispatch(reset('tickets-hours-authorization'));
      //     this.props.navigation.navigate('TicketsDetail', {
      //       id: this.props.navigation.getParam('id', ''),
      //     });
      //   });
      // });
    }
    this.saveCanvas().then(then)
  }

  buildAuthorizedBys = (project) => {
    if (!project.team) {
      return []
    }
    if (!project.team.gc_team) {
      return []
    }
    return project.team.gc_team
      .filter((value) => {
        return value.authorized_signer === true
      })
      .map((value, key) => {
        const v = {
          classifications: value.classifications,
          company: project.contractor.name || '',
          name: value.contact,
        }
        return {
          label: value.contact,
          key,
          value: Platform.OS === 'web' ? JSON.stringify(v) : v,
        }
      })
  }

  buildAuthorization = (authorizedBy, signature, ticket) => {
    const todayStamp = format(new Date(), 'MM/dd/yyyy h:mm a')

    if ((authorizedBy && signature, ticket)) {
      return {
        date: todayStamp,
        authorized_by: Platform.OS === 'web' ? JSON.parse(authorizedBy) : authorizedBy,
        signature_path: signature,
        terms: ticket.project.terms.hour_log,
        notes: this.state.notes,
      }
    }
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

TicketsHoursAuthorization = connect(mapStateToProps, mapDispatchToProps)(TicketsHoursAuthorization)

TicketsHoursAuthorization = reduxForm({
  destroyOnUnmount: false,
  form: 'tickets-hours-authorization',
})(TicketsHoursAuthorization)

export { TicketsHoursAuthorization }
