import { createFormAction } from 'redux-form-saga'

import { PROFILE_FAILURE, PROFILE_REQUEST, PROFILE_SUCCESS, SIGN_OUT } from '../types/users'

const profileRequest = () => {
  return {
    type: PROFILE_REQUEST,
  }
}

const profileSuccess = (data) => {
  return {
    payload: {
      data,
    },
    type: PROFILE_SUCCESS,
  }
}

const profileFailure = (exception) => {
  return {
    payload: {
      exception,
    },
    type: PROFILE_FAILURE,
  }
}

const signIn = createFormAction('USERS_SIGN_IN')

const signOut = () => {
  return {
    type: SIGN_OUT,
  }
}

export { profileRequest, profileSuccess, profileFailure, signIn, signOut }
