import _ from 'lodash'
import { put, takeLatest } from 'redux-saga/effects'

import { selectSuccess, selectFailure, insert, update, delet_ } from '../actions/tickets-materials'
import { api } from '../api'
import { SELECT_REQUEST, INSERT, UPDATE, DELETE } from '../types/tickets-materials'

function* select(action) {
  try {
    const response = yield api({
      method: 'GET',
      url: `/cors/${action.payload.data.ticket.id}/logs`,
    })
    yield put(selectSuccess(_.filter(response.data.data, (item) => item.type === 'material')))
  } catch (error) {
    yield put(selectFailure(error.response.data))
  }
}

function* inser_(action) {
  try {
    const response = yield api({
      data: {
        type: action.payload.type,
        date: action.payload.date,
        data: action.payload.data,
        requires_auth: action.payload.requires_auth,
      },
      method: 'POST',
      url: `/cors/${action.payload.ticket.id}/logs`,
    })
    yield put(insert.success(response.data))
  } catch (error) {
    yield put(insert.failure(error.response.data))
  }
}

function* updat_(action) {
  try {
    const response = yield api({
      data: {
        type: action.payload.type,
        date: action.payload.date,
        data: action.payload.data,
        requires_auth: action.payload.requires_auth,
      },
      method: 'PATCH',
      url: `/cors/${action.payload.ticket.id}/logs/${action.payload.id}`,
    })
    yield put(update.success(response.data))
  } catch (error) {
    yield put(update.failure(error.response.data))
  }
}

function* dele__(action) {
  try {
    const response = yield api({
      method: 'DELETE',
      url: `/cors/${action.payload.ticket.id}/logs/${action.payload.id}`,
    })
    yield put(delet_.success(response.data))
  } catch (error) {
    yield put(delet_.failure(error.response.data))
  }
}

function* ticketsMaterials() {
  yield takeLatest(SELECT_REQUEST, select)
  yield takeLatest(INSERT.REQUEST, inser_)
  yield takeLatest(UPDATE.REQUEST, updat_)
  yield takeLatest(DELETE.REQUEST, dele__)
}

export { ticketsMaterials }
