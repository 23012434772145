import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'

import { Modal } from '../components/modal'
import { COLORS } from '../constants/colors'
import { buildIcon } from '../modules/builders'

const styles = EStyleSheet.create({
  viewcontainer: {
    backgroundColor: COLORS.white,
    borderRadius: '5rem',
  },
  viewTitle: {
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    flexDirection: 'row',
    padding: '15rem',
  },
  textTitleLeft: {
    color: COLORS.grayDark,
    fontSize: '18rem',
    fontWeight: 'bold',
  },
  viewTitleRight: {
    flex: 1,
    paddingLeft: '15rem',
  },
  textTitleRight: {
    color: COLORS.redLight,
    fontSize: '18rem',
    fontWeight: 'bold',
  },
  viewTicket: {
    alignItems: 'center',
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    flexDirection: 'row',
    padding: '15rem',
  },
  viewTicketLeft: {
    paddingRight: '15rem',
  },
  viewTicketRight: {
    flexGrow: 1,
  },
  textTicket: {
    color: COLORS.grayDark,
    fontSize: '15rem',
  },
  viewScheduleDelay: {
    alignItems: 'center',
    borderBottomColor: COLORS.grayLight,
    borderBottomWidth: '1rem',
    flexDirection: 'row',
    padding: '15rem',
  },
  viewScheduleDelayLeft: {
    paddingRight: '15rem',
  },
  viewScheduleDelayRight: {
    flexGrow: 1,
  },
  textScheduleDelay: {
    color: COLORS.grayDark,
    fontSize: '15rem',
  },
  viewDailyReport: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: '15rem',
  },
  viewDailyReportLeft: {
    paddingRight: '15rem',
  },
  viewDailyReportRight: {
    flexGrow: 1,
  },
  textDailyReport: {
    color: COLORS.grayDark,
    fontSize: '15rem',
  },
})

class AddToProject extends React.Component {
  render = () => {
    return (
      <Modal isVisible={this.props.isVisible}>
        <View style={styles.viewcontainer}>
          <View style={styles.viewTitle}>
            <Text style={styles.textTitleLeft}>Add to Project</Text>
            <View style={styles.viewTitleRight}>
              <Text style={styles.textTitleRight}>{this.props.project ? this.props.project.number : 'N/A'}</Text>
            </View>
            <TouchableOpacity onPress={this.props.onPressClose}>
              {buildIcon('regular', COLORS.grayDark, 'times-circle', EStyleSheet.value(20))}
            </TouchableOpacity>
          </View>
          <TouchableOpacity onPress={this.props.onPressTicket}>
            <View style={styles.viewTicket}>
              <View style={styles.viewTicketLeft}>{buildIcon('solid', COLORS.grayDark, 'tasks', 20)}</View>
              <View style={styles.viewTicketRight}>
                <Text style={styles.textTicket}>Add Ticket</Text>
              </View>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={this.props.onPressScheduleDelay}>
            <View style={styles.viewScheduleDelay}>
              <View style={styles.viewScheduleDelayLeft}>{buildIcon('solid', COLORS.grayDark, 'clock', 20)}</View>
              <View style={styles.viewScheduleDelayRight}>
                <Text style={styles.textScheduleDelay}>Add Schedule Delay</Text>
              </View>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={this.props.onPressDailyReport}>
            <View style={styles.viewDailyReport}>
              <View style={styles.viewDailyReportLeft}>
                {buildIcon('solid', COLORS.grayDark, 'calendar-check', 20)}
              </View>
              <View style={styles.viewDailyReportRight}>
                <Text style={styles.textDailyReport}>Add Daily Report</Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      </Modal>
    )
  }
}

export { AddToProject }
