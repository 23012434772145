import * as Sentry from 'sentry-expo'

import { format } from 'date-fns'
import { Camera } from 'expo-camera'
import * as ImagePicker from 'expo-image-picker'
import _ from 'lodash'
import React from 'react'
import {
  ActivityIndicator,
  Button as ReactNativeButton,
  Image,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import EStyleSheet from 'react-native-extended-stylesheet'
import { connect } from 'react-redux'
import { change, Field, reduxForm } from 'redux-form'

import { selectRequest } from '../actions/locations'
import { Button } from '../components/button'
import { DateTimePicker } from '../components/date-time-picker'
import { Input } from '../components/input'
import { Notice } from '../components/notice'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'
import { uploadPhoto, sanitizeNumber } from '../modules/helpers'

const todayStamp = format(new Date(), 'MM/dd/yyyy h:mm a')

const initialValues = {
  date: todayStamp,
  hours_delayed: '',
  location: '',
  issue: '',
  notes: '',
}

const validate = (values) => {
  const errors = {}
  if (!values.date) {
    errors.date = 'Invalid Date and Time'
  }
  if (!values.location) {
    errors.location = 'Invalid Location'
  }
  if (!values.issue) {
    errors.issue = 'Invalid Issue'
  }
  return errors
}

class ScheduleDelaysAddForm extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: navigation.state.params.title ? navigation.state.params.title : '',
    }
    return buildNavigationOptions(target)
  }

  state = {
    photos: [],
    isVisible: false,
    loading: false,
  }

  componentDidMount = () => {
    const data = {
      project: {
        id: this.props.navigation.getParam('id', ''),
      },
    }
    const todayStamp = format(new Date(), 'MM/dd/yyyy h:mm a')
    this.props.dispatch(selectRequest(data))
    this.props.dispatch(change('schedule-delays-add', 'date', todayStamp))
    const project = _.find(
      this.props.projects.data.data,
      (item) => item.id === this.props.navigation.getParam('id', ''),
    )
    if (project) {
      this.props.navigation.setParams({
        title: `PROJECT ${project.number}`,
      })
    }
  }

  renderLoading = () => {
    const styles = EStyleSheet.create({
      scrollView: {
        flexGrow: 1,
        justifyContent: 'center',
        padding: '20rem',
      },
    })
    return (
      <ScrollView contentContainerStyle={styles.scrollView}>
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  render = () => {
    if (this.props.locations.loading || this.state.loading) {
      return this.renderLoading()
    }
    if (!this.props.projects.data) {
      return null
    }
    const project = _.find(this.props.projects.data.data, this.predicate)
    if (!project) {
      return (
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            justifyContent: 'center',
            padding: 20,
          }}
        >
          <Notice failure>Invalid Project</Notice>
        </ScrollView>
      )
    }
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        {/* */}
        <ScrollView
          style={{
            flexGrow: 1,
          }}
        >
          {this.renderError()}
          <View
            style={{
              flex: 1,
            }}
          >
            <View
              style={{
                marginBottom: 10,
                marginTop: 20,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                  }}
                >
                  Date and Time
                </Text>
              </View>
              <Field component={DateTimePicker} mode="datetime" name="date" type="text" />
            </View>
            <View
              style={{
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Number of Hours Delayed
                </Text>
                <Text
                  style={{
                    color: COLORS.grayLight,
                    fontSize: 13,
                    fontWeight: 'bold',
                    lineHeight: 20,
                  }}
                >
                  Optional
                </Text>
              </View>
              <Field
                component={Input}
                keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
                name="hours_delayed"
                normalize={(val) => sanitizeNumber(val)}
                type="text"
              />
            </View>
            <View
              style={{
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Location
                </Text>
              </View>
              <Field component={Input} keyboardType="default" name="location" type="text" />
            </View>
            <View
              style={{
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Issue
                </Text>
              </View>
              <Field
                component={Input}
                keyboardType="default"
                multiline
                name="issue"
                numberOfLines={5}
                textAlignVertical="top"
                type="text"
              />
            </View>
            <View
              style={{
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Additional Notes
                </Text>
                <Text
                  style={{
                    color: COLORS.grayLight,
                    fontSize: 13,
                    fontWeight: 'bold',
                    lineHeight: 20,
                  }}
                >
                  Optional
                </Text>
              </View>
              <Field
                component={Input}
                keyboardType="default"
                multiline
                name="notes"
                numberOfLines={5}
                textAlignVertical="top"
                type="text"
              />
            </View>
            <View
              style={{
                marginBottom: 0,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                    marginRight: 10,
                  }}
                >
                  Photos
                </Text>
              </View>

              <View style={{ padding: 20 }}>
                <View
                  style={{
                    borderColor: COLORS.grayDark,
                    borderRadius: 5,
                    borderWidth: 1,
                    marginBottom: 4,
                  }}
                >
                  <ReactNativeButton color="#666666" onPress={this.handleCameraRoll} title="Choose from Library" />
                </View>
                <View
                  style={{
                    borderColor: COLORS.grayDark,
                    borderRadius: 5,
                    borderWidth: 1,
                    marginBottom: 4,
                  }}
                >
                  <ReactNativeButton color="#666666" onPress={this.handleCamera} title="Take a picture" />
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {this.state.photos.map((value, key) => {
                return (
                  <View
                    key={key}
                    style={{
                      paddingTop: 20,
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <Image
                      resizeMode="cover"
                      source={{
                        uri: typeof value === 'string' ? value : value.uri,
                      }}
                      style={{
                        height: 150,
                        width: 150,
                        borderRadius: 5,
                      }}
                    />
                    <View
                      style={{
                        backgroundColor: COLORS.white,
                        borderRadius: 10,
                        position: 'absolute',
                        right: 20,
                        top: 30,
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          this.setState((prevState) => {
                            return {
                              photos: prevState.photos.filter((_, k) => k !== key),
                            }
                          })
                        }}
                      >
                        {buildIcon('solid', COLORS.redDark, 'times-circle', 20)}
                      </TouchableOpacity>
                    </View>
                  </View>
                )
              })}
            </View>
          </View>
        </ScrollView>
        {/* */}
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <View
            style={{
              flex: 1,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 10,
              paddingTop: 20,
            }}
          >
            <TouchableOpacity onPress={() => this.props.navigation.goBack()}>
              <Button secondary>Cancel</Button>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flex: 1,
              paddingBottom: 20,
              paddingLeft: 10,
              paddingRight: 20,
              paddingTop: 20,
            }}
          >
            <TouchableOpacity onPress={this.props.handleSubmit(this.handleSubmit)}>
              <Button primary>Preview</Button>
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAvoidingView>
    )
  }

  predicate = (item) => {
    return item.id === this.props.navigation.getParam('id', '')
  }

  renderError = () => {
    if (!this.props.error) {
      return null
    }
    const styles = EStyleSheet.create({
      view: {
        marginLeft: '20rem',
        marginRight: '20rem',
        marginTop: '20rem',
      },
    })
    return (
      <View style={styles.view}>
        <Notice failure>{this.props.error}</Notice>
      </View>
    )
  }

  handleSubmit = (values) => {
    const photos = this.state.photos
    this.setState({
      photos: [],
    })
    this.props.navigation.navigate('ScheduleDelaysAddPreview', {
      id: this.props.navigation.getParam('id', ''),
      photos,
    })
  }

  handleCamera = async () => {
    try {
      const { status } = await Camera.requestCameraPermissionsAsync()
      if (status !== 'granted') {
        return
      }
      const width = 600
      const height = 600
      const photo = await ImagePicker.launchCameraAsync({
        allowsEditing: true,
        aspect: [width, height],
        base64: false,
        exif: false,
        quality: 1,
      })
      if (!photo.canceled) {
        this.setState((prevState) => {
          return {
            ...prevState,
            loading: true,
          }
        })

        try {
          const location = await uploadPhoto(photo)
          this.setState((prevState) => {
            return {
              loading: false,
              photos: prevState.photos.concat(location),
            }
          })
        } catch {
          this.setState((prevState) => {
            return {
              ...prevState,
              loading: false,
            }
          })
        }
      }
    } catch (e) {
      console.log(e)
      Sentry.Native.captureException(e.message)
    }
  }

  handleCameraRoll = async () => {
    try {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        return
      }
      const width = 600
      const height = 600
      const photo = await ImagePicker.launchImageLibraryAsync({
        allowsEditing: true,
        aspect: [width, height],
        base64: false,
        exif: false,
        quality: 1,
      })
      if (!photo.canceled) {
        this.setState((prevState) => {
          return {
            ...prevState,
            loading: true,
          }
        })

        try {
          const location = await uploadPhoto(photo)
          this.setState((prevState) => {
            return {
              loading: false,
              photos: prevState.photos.concat(location),
            }
          })
        } catch {
          this.setState((prevState) => {
            return {
              ...prevState,
              loading: false,
            }
          })
        }
      }
    } catch (e) {
      console.log(e)
      Sentry.Native.captureException(e.message)
    }
  }

  buildLocations = () => {
    return this.props.locations.data.map((item) => {
      return {
        label: item,
        key: item,
        value: item,
      }
    })
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

ScheduleDelaysAddForm = connect(mapStateToProps, mapDispatchToProps)(ScheduleDelaysAddForm)

ScheduleDelaysAddForm = reduxForm({
  destroyOnUnmount: false,
  form: 'schedule-delays-add',
  initialValues,
  validate,
})(ScheduleDelaysAddForm)

export { ScheduleDelaysAddForm }
