import _ from 'lodash'
import React from 'react'
import { ActivityIndicator, KeyboardAvoidingView, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { withNavigationFocus } from 'react-navigation'
import { connect } from 'react-redux'

import { Button } from '../components/button'
import { Left } from '../components/left'
import { Radio } from '../components/radio'
import { COLORS } from '../constants/colors'
import { buildIcon, buildNavigationOptions } from '../modules/builders'

class DailyReportsFormSurvey extends React.Component {
  state = {
    report: null,
  }

  static navigationOptions = ({ navigation }) => {
    const left = (
      <View
        style={{
          paddingLeft: 20,
        }}
      >
        <Left
          icon={buildIcon('regular', COLORS.grayDark, 'arrow-left', 20)}
          onPress={() => {
            const onPress = navigation.getParam('onPress', null)
            if (onPress) {
              onPress()
            }
          }}
        />
      </View>
    )
    const target = {
      headerLeft: () => left,
      headerTitle: 'Survey',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.component()
  }

  component = () => {
    this.project = this.props.navigation.getParam('project')
    this.buildState()
    this.props.navigation.setParams({
      onPress: () => {
        this.props.navigation.navigate('DailyReportsForm', {
          project: this.project,
          report: this.state.report,
        })
      },
    })
  }

  render = () => {
    if (!this.state.report) {
      return this.renderLoading()
    }
    if (!this.state.report.data) {
      return this.renderLoading()
    }
    if (!this.state.report.data.user_defined_input_sections) {
      return this.renderLoading()
    }
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <ScrollView
          style={{
            flexGrow: 1,
            padding: 20,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          {this.state.report.data.user_defined_input_sections.map(this.renderSections)}
        </ScrollView>
        {this.state.report.status === 'submitted' ? null : (
          <View
            style={{
              flexDirection: 'row',
              padding: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingRight: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.props.navigation.getParam('report'),
                  })
                }}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.state.report,
                  })
                }}
              >
                <Button primary>Continue</Button>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </KeyboardAvoidingView>
    )
  }

  renderLoading = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: 'center',
          padding: 20,
        }}
      >
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderSections = (section) => {
    return (
      <View key={section.key}>
        <View
          style={{
            paddingTop: 20,
          }}
        >
          <Text
            allowFontScaling={false}
            style={{
              color: COLORS.grayDark,
              fontSize: 15,
              fontWeight: 'bold',
            }}
          >
            {section.label}
          </Text>
          <View
            style={{
              paddingTop: 10,
            }}
          >
            {this.renderQuestions(section.key)}
          </View>
        </View>
      </View>
    )
  }

  renderQuestions = (section) => {
    return this.state.report.data.user_defined_input
      .filter((question) => question.section === section)
      .map(this.renderQuestion)
  }

  renderQuestion = (value, key) => {
    const props = {
      input: {
        onChange: (option) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  user_defined_input: prevState.report.data.user_defined_input.map((v, k) => {
                    if (v.index === value.index) {
                      v.options = v.options.map((o) => {
                        if (o.value === option) {
                          o.checked = true
                          v.value = o.value
                        } else {
                          o.checked = false
                        }
                        return o
                      })
                    }
                    return v
                  }),
                  isDirty: true,
                },
              },
            }
          })
        },
        value: value.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    return (
      <View
        key={key}
        style={{
          marginTop: 10,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Text allowFontScaling={false} style={{ fontSize: 15, marginBottom: 10 }}>
          {value.text}
        </Text>
        {this.state.report.status === 'submitted' ? (
          <Text
            style={{
              color: COLORS.grayMedium,
              fontSize: 15,
              lineHeight: 20,
            }}
          >
            {' '}
            {_.capitalize(props.input.value)}
          </Text>
        ) : (
          <Radio options={value.options} {...props} />
        )}
      </View>
    )
  }

  buildState = () => {
    const report = this.props.navigation.getParam('report')
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...report,
        },
      }
    })
  }
}

const mapStateToProps = (props, ownProps) => {
  return {
    ...props,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

DailyReportsFormSurvey = connect(mapStateToProps, mapDispatchToProps)(DailyReportsFormSurvey)

DailyReportsFormSurvey = withNavigationFocus(DailyReportsFormSurvey)

export { DailyReportsFormSurvey }
