import React from 'react'
import { KeyboardAvoidingView, Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import { withNavigationFocus } from 'react-navigation'
import { connect } from 'react-redux'

import { fetchOpenWeatherMap } from '../api'
import { Button } from '../components/button'
import { Input } from '../components/input'
import { Left } from '../components/left'
import { Select } from '../components/select'
import Dropdown from '../components/Dropdown'
import { Spinner } from '../components/spinner'
import { COLORS } from '../constants/colors'
import { CONDITIONS_GROUND, CONDITIONS_WEATHER } from '../constants/conditions'
import { buildIcon, buildNavigationOptions } from '../modules/builders'

class DailyReportsFormEnvironment extends React.Component {
  state = {
    report: null,
    visible: true,
  }

  static navigationOptions = ({ navigation }) => {
    const left = (
      <View
        style={{
          paddingLeft: 20,
        }}
      >
        <Left
          icon={buildIcon('regular', COLORS.grayDark, 'arrow-left', 20)}
          onPress={() => {
            const onPress = navigation.getParam('onPress', null)
            if (onPress) {
              onPress()
            }
          }}
        />
      </View>
    )
    const target = {
      headerLeft: () => left,
      headerTitle: 'Environment',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.component()
  }

  component = () => {
    this.project = this.props.navigation.getParam('project')
    this.buildState()
    this.props.navigation.setParams({
      onPress: () => {
        this.props.navigation.navigate('DailyReportsForm', {
          project: this.project,
          report: this.state.report,
        })
      },
    })
    fetchOpenWeatherMap(this.project.location.zip).then(
      (response) => {
        this.setState((prevState) => {
          return {
            ...prevState,
            visible: false,
          }
        })
        if (response.weather_condition) {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  weather: {
                    ...prevState.report.data.weather,
                    condition: {
                      ...prevState.report.data.weather.condition,
                      value: response.weather_condition,
                    },
                  },
                  isDirty: true,
                },
              },
            }
          })
        }
        if (response.weather_temperature_high) {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  weather: {
                    ...prevState.report.data.weather,
                    temperature_high: {
                      ...prevState.report.data.weather.temperature_high,
                      value: response.weather_temperature_high,
                    },
                  },
                  isDirty: true,
                },
              },
            }
          })
        }
        if (response.weather_temperature_low) {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  weather: {
                    ...prevState.report.data.weather,
                    temperature_low: {
                      ...prevState.report.data.weather.temperature_low,
                      value: response.weather_temperature_low,
                    },
                  },
                  isDirty: true,
                },
              },
            }
          })
        }
        if (response.ground_condition) {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  ground: {
                    ...prevState.report.data.ground,
                    condition: {
                      ...prevState.report.data.ground.condition,
                      value: response.ground_condition,
                    },
                  },
                  isDirty: true,
                },
              },
            }
          })
        }
      },
      () => {
        this.setState((prevState) => {
          return {
            ...prevState,
            visible: false,
          }
        })
      },
    )
  }

  render = () => {
    if (!this.state.report) {
      return null
    }
    const weather_condition = {
      input: {
        onChange: (value) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  weather: {
                    ...prevState.report.data.weather,
                    condition: {
                      ...prevState.report.data.weather.condition,
                      value,
                    },
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: this.state.report.data.weather.condition.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    const weather_temperature_high = {
      input: {
        onChange: (value) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  weather: {
                    ...prevState.report.data.weather,
                    temperature_high: {
                      ...prevState.report.data.weather.temperature_high,
                      value,
                    },
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: this.state.report.data.weather.temperature_high.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    const weather_temperature_low = {
      input: {
        onChange: (value) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  weather: {
                    ...prevState.report.data.weather,
                    temperature_low: {
                      ...prevState.report.data.weather.temperature_low,
                      value,
                    },
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: this.state.report.data.weather.temperature_low.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    const ground_condition = {
      input: {
        onChange: (value) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  ground: {
                    ...prevState.report.data.ground,
                    condition: {
                      ...prevState.report.data.ground.condition,
                      value,
                    },
                  },
                  isDirty: true,
                },
              },
            }
          })
        },
        value: this.state.report.data.ground.condition.value,
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          flex: 1,
        }}
      >
        <Spinner visible={this.state.visible} />
        <ScrollView
          style={{
            flexGrow: 1,
            padding: 20,
            paddingBottom: 0,
          }}
        >
          <View>
            <Text
              allowFontScaling={false}
              style={{
                color: COLORS.grayDark,
                fontSize: 15,
                fontWeight: 'bold',
                marginBottom: 10,
              }}
            >
              Weather / Condition
            </Text>
            {this.state.report.status === 'submitted' ? (
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {weather_condition.input.value}
              </Text>
            ) : (
              <Dropdown
                data={CONDITIONS_WEATHER.map((item) => {
                  return { label: item, key: item, value: item }
                })}
                {...weather_condition}
              />
            )}
          </View>
          <View
            style={{
              paddingTop: 20,
            }}
          >
            <Text
              style={{
                color: COLORS.grayDark,
                fontSize: 15,
                fontWeight: 'bold',
                marginBottom: 10,
              }}
            >
              Weather / Temperature / Low °
            </Text>
            {this.state.report.status === 'submitted' ? (
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {weather_temperature_low.input.value}
              </Text>
            ) : (
              <Input
                keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
                type="text"
                {...weather_temperature_low}
              />
            )}
          </View>
          <View
            style={{
              paddingTop: 20,
            }}
          >
            <Text
              style={{
                color: COLORS.grayDark,
                fontSize: 15,
                fontWeight: 'bold',
                marginBottom: 10,
              }}
            >
              Weather / Temperature / High °
            </Text>
            {this.state.report.status === 'submitted' ? (
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {weather_temperature_high.input.value}
              </Text>
            ) : (
              <Input
                keyboardType={Platform.OS === 'web' ? 'numeric' : 'decimal-pad'}
                type="text"
                {...weather_temperature_high}
              />
            )}
          </View>
          <View
            style={{
              paddingTop: 20,
            }}
          >
            <Text
              allowFontScaling={false}
              style={{
                color: COLORS.grayDark,
                fontSize: 15,
                fontWeight: 'bold',
                marginBottom: 10,
              }}
            >
              Ground Conditions
            </Text>
            {this.state.report.status === 'submitted' ? (
              <Text
                style={{
                  color: COLORS.grayMedium,
                  fontSize: 15,
                  lineHeight: 20,
                }}
              >
                {ground_condition.input.value}
              </Text>
            ) : (
              <Select
                gray
                items={CONDITIONS_GROUND.map((item) => {
                  return { label: item, key: item, value: item }
                })}
                placeholder={{ label: 'Select a condition...', key: '', value: '' }}
                {...ground_condition}
              />
            )}
          </View>
        </ScrollView>
        {this.state.report.status === 'submitted' ? null : (
          <View
            style={{
              flexDirection: 'row',
              padding: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingRight: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.props.navigation.getParam('report'),
                  })
                }}
              >
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  this.props.navigation.navigate('DailyReportsForm', {
                    project: this.project,
                    report: this.state.report,
                  })
                }}
              >
                <Button primary>Continue</Button>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </KeyboardAvoidingView>
    )
  }

  buildState = () => {
    const report = this.props.navigation.getParam('report')
    this.setState((prevState) => {
      return {
        ...prevState,
        report: {
          ...report,
        },
      }
    })
  }
}

const mapStateToProps = (props, ownProps) => {
  return {
    ...props,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

DailyReportsFormEnvironment = connect(mapStateToProps, mapDispatchToProps)(DailyReportsFormEnvironment)

DailyReportsFormEnvironment = withNavigationFocus(DailyReportsFormEnvironment)

export { DailyReportsFormEnvironment }
