import { put, takeLatest } from 'redux-saga/effects'

import { selectSuccess, selectFailure, insert } from '../actions/schedule-delays'
import { api } from '../api'
import { SELECT_REQUEST, INSERT } from '../types/schedule-delays'

function* select(action) {
  try {
    const response = yield api({
      method: 'GET',
      params: {},
      url: `/projects/${action.payload.data.project.id}/schedule-delays`,
    })
    yield put(selectSuccess(response.data))
  } catch (error) {
    yield put(selectFailure(error.response.data))
  }
}

function* inser_(action) {
  if (!action.payload) {
    return
  }
  try {
    const response = yield api({
      data: {
        date: action.payload.date,
        hours_delayed: action.payload.hours_delayed,
        location: action.payload.location,
        issue: action.payload.issue,
        notes: action.payload.notes,
        photos: action.payload.photos,
      },
      method: 'POST',
      url: `/projects/${action.payload.project.id}/schedule-delays`,
    })
    yield put(insert.success(response.data))
  } catch (error) {
    yield put(insert.failure(error.response.data))
  }
}

function* scheduleDelays() {
  yield takeLatest(SELECT_REQUEST, select)
  yield takeLatest(INSERT.REQUEST, inser_)
}

export { scheduleDelays }
