import React from 'react'
import {
  ActivityIndicator,
  Alert,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { withNavigationFocus } from 'react-navigation'
import { connect } from 'react-redux'

import { insert, update } from '../actions/daily-reports'
import { selectRequest as selectRequestProjects } from '../actions/projects'
import { selectRequest as selectRequestReportTemplates } from '../actions/report-templates'
import { Button } from '../components/button'
import { DateTimePicker } from '../components/date-time-picker'
import { Notice } from '../components/notice'
import { Spinner } from '../components/spinner'
import { COLORS } from '../constants/colors'
import { buildDate, buildError, buildIcon, buildNavigationOptions, buildReport, buildTime } from '../modules/builders'

class DailyReportsForm extends React.Component {
  state = {
    report: null,
    spinner: null,
    error: null,
  }

  static navigationOptions = ({ navigation }) => {
    const target = {
      headerTitle: 'Daily Report',
    }
    return buildNavigationOptions(target)
  }

  componentDidMount = () => {
    this.props.dispatch(selectRequestReportTemplates())
    this.component()
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.isFocused !== prevProps.isFocused) {
      this.component()
    }
    if (this.props.reportTemplates !== prevProps.reportTemplates) {
      this.component()
    }
  }

  component = () => {
    this.project = this.props.navigation.getParam('project')
    this.buildState()
  }

  render = () => {
    if (this.props.dailyReports.loading) {
      return this.renderLoading()
    }
    if (this.props.reportTemplates.loading) {
      return this.renderLoading()
    }
    if (!this.state.report) {
      return this.renderLoading()
    }
    return this.renderData()
  }

  renderLoading = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: 'center',
          padding: 20,
        }}
      >
        <ActivityIndicator color={COLORS.orange} size="large" />
      </ScrollView>
    )
  }

  renderData = () => {
    const date = {
      input: {
        onChange: (value) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                date: value,
                data: {
                  ...prevState.report.data,
                  isDirty: true,
                },
              },
            }
          })
        },
        value: buildDate(this.state.report.date) || buildDate(new Date()),
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    const time = {
      input: {
        onChange: (value) => {
          this.setState((prevState) => {
            return {
              ...prevState,
              report: {
                ...prevState.report,
                data: {
                  ...prevState.report.data,
                  start_time: value,
                  isDirty: true,
                },
              },
            }
          })
        },
        value: this.state.report.data.start_time || buildTime(new Date()),
      },
      meta: {
        touched: false,
        error: false,
      },
    }
    return (
      <KeyboardAvoidingView
        behavior="padding"
        style={{
          backgroundColor: COLORS.white,
          flex: 1,
        }}
      >
        <Spinner visible={this.props.dailyReports.loading} />
        <ScrollView
          contentContainerStyle={{
            flexGrow: 1,
            padding: 20,
            paddingBottom: 0,
          }}
        >
          <View
            style={{
              paddingBottom: 10,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                  }}
                >
                  Date
                </Text>
                {this.state.report.status === 'submitted' ? (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {date.input.value}
                  </Text>
                ) : (
                  <DateTimePicker mode="date" {...date} />
                )}
              </View>
              <View
                style={{
                  flex: 1,
                  paddingLeft: 10,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                    fontWeight: 'bold',
                    lineHeight: 20,
                  }}
                >
                  Start Time
                </Text>
                {this.state.report.status === 'submitted' ? (
                  <Text
                    style={{
                      color: COLORS.grayMedium,
                      fontSize: 15,
                      lineHeight: 20,
                    }}
                  >
                    {time.input.value}
                  </Text>
                ) : (
                  <DateTimePicker mode="time" {...time} />
                )}
              </View>
            </View>
          </View>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('DailyReportsFormEnvironment', {
                project: this.project,
                report: this.state.report,
              })
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.grayLight,
                flexDirection: 'row',
                marginBottom: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  Environment
                </Text>
              </View>
              {buildIcon('solid', COLORS.grayDark, 'chevron-right', 15)}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('DailyReportsFormNotesList', {
                project: this.project,
                report: this.state.report,
              })
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.grayLight,
                flexDirection: 'row',
                marginBottom: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  Notes
                </Text>
              </View>
              {buildIcon('solid', COLORS.grayDark, 'chevron-right', 15)}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('DailyReportsFormSurvey', {
                project: this.project,
                report: this.state.report,
              })
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.grayLight,
                flexDirection: 'row',
                marginBottom: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  Survey
                </Text>
              </View>
              {buildIcon('solid', COLORS.grayDark, 'chevron-right', 15)}
            </View>
          </TouchableOpacity>
          <View
            style={{
              backgroundColor: COLORS.white,
              flexDirection: 'row',
              marginBottom: 10,
            }}
          >
            <View
              style={{
                flexGrow: 1,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Details
              </Text>
            </View>
          </View>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('DailyReportsFormLabor', {
                project: this.project,
                report: this.state.report,
              })
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.grayLight,
                flexDirection: 'row',
                marginBottom: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  Labor
                </Text>
              </View>
              {buildIcon('solid', COLORS.grayDark, 'chevron-right', 15)}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('DailyReportsFormEquipment', {
                project: this.project,
                report: this.state.report,
              })
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.grayLight,
                flexDirection: 'row',
                marginBottom: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  Equipment
                </Text>
              </View>
              {buildIcon('solid', COLORS.grayDark, 'chevron-right', 15)}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('DailyReportsFormTradeInspections', {
                project: this.project,
                report: this.state.report,
              })
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.grayLight,
                flexDirection: 'row',
                marginBottom: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  Trade Inspections
                </Text>
              </View>
              {buildIcon('solid', COLORS.grayDark, 'chevron-right', 15)}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('DailyReportsFormVendorDeliveries', {
                project: this.project,
                report: this.state.report,
              })
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.grayLight,
                flexDirection: 'row',
                marginBottom: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  Vendor Deliveries
                </Text>
              </View>
              {buildIcon('solid', COLORS.grayDark, 'chevron-right', 15)}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('DailyReportsFormSubcontractors', {
                project: this.project,
                report: this.state.report,
              })
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.grayLight,
                flexDirection: 'row',
                marginBottom: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  Subcontractors
                </Text>
              </View>
              {buildIcon('solid', COLORS.grayDark, 'chevron-right', 15)}
            </View>
          </TouchableOpacity>
          <View
            style={{
              backgroundColor: COLORS.white,
              flexDirection: 'row',
              marginBottom: 10,
            }}
          >
            <View
              style={{
                flexGrow: 1,
              }}
            >
              <Text
                style={{
                  color: COLORS.grayDark,
                  fontSize: 15,
                  fontWeight: 'bold',
                }}
              >
                Work Areas
              </Text>
            </View>
          </View>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('DailyReportsFormWorkPerformedTodayList', {
                project: this.project,
                report: this.state.report,
              })
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.grayLight,
                flexDirection: 'row',
                marginBottom: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  Work Performed Today
                </Text>
              </View>
              {buildIcon('solid', COLORS.grayDark, 'chevron-right', 15)}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('DailyReportsFormAreasNotReadyForWorkList', {
                project: this.project,
                report: this.state.report,
              })
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.grayLight,
                flexDirection: 'row',
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontWeight: 'bold',
                  }}
                >
                  Areas Not Ready For Work
                </Text>
              </View>
              {buildIcon('solid', COLORS.grayDark, 'chevron-right', 15)}
            </View>
          </TouchableOpacity>
        </ScrollView>
        <View
          style={{
            padding: 20,
          }}
        >
          {this.renderNotice()}
          {this.renderError()}

          {this.state.report.status === 'submitted' ? null : (
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  flex: 1,
                  paddingRight: 10,
                }}
              >
                <TouchableOpacity
                  disabled={!this.state.report.data.isDirty}
                  onPress={() => {
                    this.handlePress('draft')
                  }}
                >
                  <Button secondary disabled={!this.state.report.data.isDirty}>
                    Save
                  </Button>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flex: 1,
                  paddingLeft: 10,
                }}
              >
                <TouchableOpacity
                  disabled={this.state.report.id === undefined && !this.state.report.data.isDirty}
                  onPress={() => {
                    if (Platform.OS === 'web') {
                      if (window.confirm('Are you sure you want to submit and send?')) {
                        this.handlePress('submitted')
                      }
                    } else {
                      Alert.alert('Finished Daily Report?', 'Are you sure you want to submit and send?', [
                        { text: 'No', style: 'cancel' },
                        {
                          text: 'Yes',
                          onPress: () => {
                            this.handlePress('submitted')
                          },
                        },
                      ])
                    }
                  }}
                >
                  <Button primary disabled={this.state.report.id === undefined && !this.state.report.data.isDirty}>
                    Finish
                  </Button>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </View>
      </KeyboardAvoidingView>
    )
  }

  renderNotice = () => {
    if (this.state.error) {
      return null
    }
    if (!this.state.report.data.isDirty) {
      return null
    }
    return (
      <View
        style={{
          marginBottom: 20,
        }}
      >
        <Notice warning>You have unsaved changes.</Notice>
      </View>
    )
  }

  renderError = () => {
    if (!this.state.error) {
      return null
    }
    return (
      <View
        style={{
          marginBottom: 20,
        }}
      >
        <Notice failure>{this.state.error}</Notice>
      </View>
    )
  }

  buildState = () => {
    const report = this.props.navigation.getParam('report')
    if (report !== undefined) {
      this.setState((prevState) => {
        return {
          ...prevState,
          report: {
            ...report,
          },
        }
      })
      return
    }
    this.setState((prevState) => {
      return {
        ...prevState,
        report: buildReport(this.project, this.props.reportTemplates),
      }
    })
  }

  handlePress = (status) => {
    const object = {
      id: this.state.report.id,
      report: {
        ...this.state.report,
        status,
      },
    }
    const success = (data) => {
      this.props.dispatch(
        selectRequestProjects({
          status: 'active',
        }),
      )
      this.props.navigation.navigate('DailyReportsSuccess', {
        project: this.project,
        status,
      })
    }
    const failure = (data) => {
      this.setState((prevState) => {
        return {
          ...prevState,
          error: buildError(data),
        }
      })
    }
    if (this.state.report.id === undefined) {
      return insert(object, this.props.dispatch).then(success).catch(failure)
    } else {
      return update(object, this.props.dispatch).then(success).catch(failure)
    }
  }
}

const mapStateToProps = (props, ownProps) => {
  return {
    ...props,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

DailyReportsForm = connect(mapStateToProps, mapDispatchToProps)(DailyReportsForm)

DailyReportsForm = withNavigationFocus(DailyReportsForm)

export { DailyReportsForm }
