import _ from 'lodash'
import React from 'react'
import { ScrollView, Text, TextInput, TouchableOpacity, View } from 'react-native'

import { Button } from '../components/button'
import { Modal } from '../components/modal'
import { COLORS } from '../constants/colors'
import { buildIcon } from '../modules/builders'

class MaterialsA extends React.Component {
  state = {
    keywords: '',
    categories: {},
    materials: {},
  }

  render = () => {
    return (
      <Modal isVisible={this.props.isVisible}>
        <View
          style={{
            flex: 1,
            backgroundColor: COLORS.white,
            borderRadius: 5,
          }}
        >
          <View
            style={{
              alignItems: 'center',
              borderBottomColor: COLORS.grayLight,
              borderBottomWidth: 1,
              flexDirection: 'row',
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            {buildIcon('solid', COLORS.grayDark, 'search', 20)}
            <View
              style={{
                flex: 1,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <TextInput
                keyboardType="default"
                onChangeText={(keywords) => this.setState({ keywords })}
                placeholder="Search materials and equipment..."
                style={{
                  backgroundColor: COLORS.white,
                  borderColor: COLORS.grayLight,
                  borderRadius: 5,
                  borderWidth: 1,
                  color: COLORS.grayDark,
                  fontSize: 20,
                  paddingBottom: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 10,
                }}
                value={this.state.keywords}
              />
            </View>
          </View>
          <ScrollView
            style={{
              flexGrow: 1,
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 0,
            }}
          >
            {_.map(this.getCategories(), (category) => {
              return (
                <View key={category}>
                  <TouchableOpacity onPress={() => this.toggleCategories(category)}>
                    <View
                      style={{
                        borderBottomColor: COLORS.grayLight,
                        borderBottomWidth: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        paddingBottom: 10,
                        paddingTop: 10,
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                        }}
                      >
                        <Text
                          style={{
                            color: COLORS.grayDark,
                            fontSize: 15,
                            fontWeight: 'bold',
                          }}
                        >
                          {category}
                        </Text>
                      </View>
                      <View
                        style={{
                          paddingRight: 10,
                        }}
                      >
                        <Text
                          style={{
                            color: COLORS.grayMedium,
                            fontSize: 15,
                            fontWeight: 'normal',
                          }}
                        >
                          Selected: {this.getSelected(category)}
                        </Text>
                      </View>
                      {buildIcon('solid', COLORS.grayDark, this.isOpen(category) ? 'chevron-up' : 'chevron-down', 15)}
                    </View>
                  </TouchableOpacity>
                  {this.renderItems(category)}
                </View>
              )
            })}
          </ScrollView>
          <View
            style={{
              flexDirection: 'row',
            }}
          >
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 10,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity onPress={this.props.onPressCancel}>
                <Button secondary>Cancel</Button>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flex: 1,
                paddingBottom: 20,
                paddingLeft: 10,
                paddingRight: 20,
                paddingTop: 20,
              }}
            >
              <TouchableOpacity onPress={() => this.props.onPressConfirm(this.getData())}>
                <Button primary>Confirm</Button>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    )
  }

  renderItems = (category) => {
    if (!this.isOpen(category)) {
      return null
    }
    return _.map(this.getMaterials(), (material) => {
      if (material.category.name !== category) {
        return null
      }
      const isSelected = this.isSelected(category, material)
      return (
        <View key={material.name}>
          <TouchableOpacity onPress={() => this.toggleMaterials(category, material)}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                paddingBottom: 10,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    color: COLORS.grayDark,
                    fontSize: 15,
                  }}
                >
                  {material.name}
                </Text>
              </View>
              {buildIcon('regular', isSelected ? COLORS.grayDark : COLORS.grayLight, 'check-circle', 15)}
            </View>
          </TouchableOpacity>
        </View>
      )
    })
  }

  getCategories = () => {
    const materials = this.getMaterials()
    const map = _.map(materials, (material) => material.category.name)
    const uniq = _.uniq(map)
    uniq.sort()
    return uniq
  }

  getMaterials = () => {
    const filter = _.filter(this.props.materials, (material) => this.isAMatch(material))
    const sortBy = _.sortBy(filter, (material) => material.name)
    return sortBy
  }

  getData = () => {
    const materials = _.sortBy(this.props.materials, (material) => material.name)
    const map = _.map(materials, (material) => {
      if (this.state.materials[material.category.name]) {
        if (this.state.materials[material.category.name].indexOf(material) !== -1) {
          return {
            category: {
              id: material.category.id,
              name: material.category.name,
            },
            material: {
              id: material.id,
              name: material.name,
              pending: material.pending,
              order_unit: material.order_unit,
              price_unit: material.price_unit,
              price: parseFloat(material.total_price.replace('$', '')) * 100,
              is_equipment: material.item_is_equipment,
              notes: material.notes,
              options: material.options,
            },
            entries: [
              {
                quantity: '',
                size: '',
              },
            ],
            checked: false,
          }
        }
      }
      return undefined
    })
    const flatten = _.flatten(map)
    const filter = _.filter(flatten, (item) => item !== undefined)
    this.setState({
      keywords: '',
      categories: {},
      materials: {},
    })
    return filter
  }

  getSelected = (category) => {
    if (!this.state.materials) {
      return 0
    }
    if (!this.state.materials[category]) {
      return 0
    }
    return this.state.materials[category].length
  }

  toggleCategories = (category) => {
    this.setState((prevState) => {
      return {
        categories: {
          ...prevState.categories,
          [category]: !prevState.categories[category],
        },
      }
    })
  }

  toggleMaterials = (category, material) => {
    this.setState((prevState) => {
      return {
        materials: {
          ...prevState.materials,
          [category]: _.xor(prevState.materials[category], [material]),
        },
      }
    })
  }

  isAMatch = (material) => {
    const keywords = this.state.keywords.toLowerCase()
    if (keywords === '') {
      return true
    }
    if (material.category.name.toLowerCase().indexOf(keywords) !== -1) {
      return true
    }
    if (material.name.toLowerCase().indexOf(keywords) !== -1) {
      return true
    }
    return false
  }

  isOpen = (category) => {
    return this.state.categories[category] === true
  }

  isSelected = (category, material) => {
    if (!this.state.materials) {
      return false
    }
    if (!this.state.materials[category]) {
      return false
    }
    if (this.state.materials[category].indexOf(material) === -1) {
      return false
    }
    return true
  }
}

export { MaterialsA }
