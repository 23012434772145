import { createFormAction } from 'redux-form-saga'

import { SELECT_REQUEST, SELECT_SUCCESS, SELECT_FAILURE } from '../types/tickets-materials'

const selectRequest = (data) => {
  return {
    payload: {
      data,
    },
    type: SELECT_REQUEST,
  }
}

const selectSuccess = (data) => {
  return {
    payload: {
      data,
    },
    type: SELECT_SUCCESS,
  }
}

const selectFailure = (exception) => {
  return {
    payload: {
      exception,
    },
    type: SELECT_FAILURE,
  }
}

const insert = createFormAction('TICKETS_MATERIALS_INSERT')
const update = createFormAction('TICKETS_HOURS_UPDATE')
const delet_ = createFormAction('TICKETS_HOURS_DELETE')

export { selectRequest, selectSuccess, selectFailure, insert, update, delet_ }
